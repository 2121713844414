import mock from '../mock'
/* eslint-disable */
// ** Utils
import { paginateArray, sortCompare } from '../utils'

const data = {
    tests:[
      {
        "id": 197,
        "number": 1,
        "title": "2022 KPSS Genel Yetenek Genel Kültür Canlı Dersler (1.Grup)",
        "cover": "https://dijital.pegemkampus.com//Media/UserData/videolessons/20_10_2021_21_16_20{gygk-eg-i-ti-m-canli-dersler-01}.jpg",
        "description": "Videolar 2022 sınavından sonra erişime kapatılacaktır. Her videoyu en fazla 20 kez izleyebilirsiniz.",
        "questions": [
          {
            "id": 1815,
            "number": 1,
            "title":"1. Soru",
            "correctAnswer":'A',
            "userAnswer":'',
            "type": "text",
            "content": "<p>&Ccedil;oluk &ccedil;ocuk k&ouml;y &ccedil;eşmesinden <u>kana kana</u> su i&ccedil;tik.</p><p><strong>Bu c&uuml;mledeki altı &ccedil;izili s&ouml;zc&uuml;k grubu i&ccedil;in hangisi s&ouml;ylenebilir?</strong></p>",
            "choices": [
              {
                "id":1,
                "choiceText":"A",
                "content":"Arkadaşına yalan söyleyenin yüzü kara olsun.",
                "isTrue":true,
                "isStudentAnswer":"false"

              },
              {
                "id":2,
                "choiceText":"B",
                "content":"Koyunun bulunmadığı yerde keçiye Abdurrahman Çelebi derler.",
                "isTrue":false,
                "isStudentAnswer":"false"

              },
              {
                "id":3,
                "choiceText":"C",
                "content":"Güneş girmeyen eve doktor girer.",
                "isTrue":false,
                "isStudentAnswer":"true"

              },
              {
                "id":4,
                "choiceText":"D",
                "content":"Güneş girmeyen eve doktor girer.",
                "isTrue":false,
                "isStudentAnswer":"false"

              },
              {
                "id":5,
                "choiceText":"E",
                "content":"Güneş girmeyen eve doktor girer.",
                "isTrue":false,
                "isStudentAnswer":"false"

              }]
          },
          {
            "id": 1816,
            "number": 2,
            "title":"2. Soru",
            "type": "text",
            "correctAnswer":'B',
            "userAnswer":'',
            "content": "<p>&Ccedil;oluk &ccedil;ocuk k&ouml;y &ccedil;eşmesinden <u>kana kana</u> su i&ccedil;tik.</p><p><strong>Bu c&uuml;mledeki altı &ccedil;izili s&ouml;zc&uuml;k grubu i&ccedil;in hangisi s&ouml;ylenebilir?</strong></p>",
            "choices": [
              {
                "id":1,
                "choiceText":"A",
                "content":"Arkadaşına yalan söyleyenin yüzü kara olsun.",
                "isTrue":true,
                "isStudentAnswer":"false"

              },
              {
                "id":2,
                "choiceText":"B",
                "content":"Koyunun bulunmadığı yerde keçiye Abdurrahman Çelebi derler.",
                "isTrue":false,
                "isStudentAnswer":"false"

              },
              {
                "id":3,
                "choiceText":"C",
                "content":"Güneş girmeyen eve doktor girer.",
                "isTrue":false,
                "isStudentAnswer":"true"

              },
              {
                "id":4,
                "choiceText":"D",
                "content":"Güneş girmeyen eve doktor girer.",
                "isTrue":false,
                "isStudentAnswer":"false"

              },
              {
                "id":5,
                "choiceText":"E",
                "content":"Güneş girmeyen eve doktor girer.",
                "isTrue":false,
                "isStudentAnswer":"false"

              }]
          },
          {
            "id": 1817,
            "type": "text",
            "number": 3,
            "title":"3. Soru",
            "correctAnswer":'C',
            "userAnswer":'',
            "content":"<p>Aşağıdaki atasözlerinde geçen sıfat-ﬁillerden hangisi diğerlerinden farklı bir görevde kullanılmıştır?</p>",
            "choices": [
              {
                "id":1,
                "choiceText":"A",
                "content":"Arkadaşına yalan söyleyenin yüzü kara olsun.",
                "isTrue":true,
                "isStudentAnswer":"false"

              },
              {
                "id":2,
                "choiceText":"B",
                "content":"Koyunun bulunmadığı yerde keçiye Abdurrahman Çelebi derler.",
                "isTrue":false,
                "isStudentAnswer":"false"

              },
              {
                "id":3,
                "choiceText":"C",
                "content":"Güneş girmeyen eve doktor girer.",
                "isTrue":false,
                "isStudentAnswer":"true"

              },
              {
                "id":4,
                "choiceText":"D",
                "content":"Güneş girmeyen eve doktor girer.",
                "isTrue":false,
                "isStudentAnswer":"false"

              },
              {
                "id":5,
                "choiceText":"E",
                "content":"Güneş girmeyen eve doktor girer.",
                "isTrue":false,
                "isStudentAnswer":"false"

              }]
          },
          {
            "id": 1818,
            "type": "text",
            "number": 4,
            "title":"4. Soru",
            "correctAnswer":'D',
            "userAnswer":'',
            "content":"<p>Aşağıdaki atasözlerinde geçen sıfat-ﬁillerden hangisi diğerlerinden farklı bir görevde kullanılmıştır?</p>",
            "choices": [
              {
                "id":1,
                "choiceText":"A",
                "content":"Arkadaşına yalan söyleyenin yüzü kara olsun.",
                "isTrue":true,
                "isStudentAnswer":"false"

              },
              {
                "id":2,
                "choiceText":"B",
                "content":"Koyunun bulunmadığı yerde keçiye Abdurrahman Çelebi derler.",
                "isTrue":false,
                "isStudentAnswer":"false"

              },
              {
                "id":3,
                "choiceText":"C",
                "content":"Güneş girmeyen eve doktor girer.",
                "isTrue":false,
                "isStudentAnswer":"true"

              },
              {
                "id":4,
                "choiceText":"D",
                "content":"Güneş girmeyen eve doktor girer.",
                "isTrue":false,
                "isStudentAnswer":"false"

              },
              {
                "id":5,
                "choiceText":"E",
                "content":"Güneş girmeyen eve doktor girer.",
                "isTrue":false,
                "isStudentAnswer":"false"

              }]
          },
          {
            "id": 1819,
            "type": "text",
            "number": 5,
            "title":"5. Soru",
            "correctAnswer":'E',
            "userAnswer":'',
            "content":"<p>Aşağıdaki atasözlerinde geçen sıfat-ﬁillerden hangisi diğerlerinden farklı bir görevde kullanılmıştır?</p>",
            "choices": [
              {
                "id":1,
                "choiceText":"A",
                "content":"Arkadaşına yalan söyleyenin yüzü kara olsun.",
                "isTrue":true,
                "isStudentAnswer":"false"

              },
              {
                "id":2,
                "choiceText":"B",
                "content":"Koyunun bulunmadığı yerde keçiye Abdurrahman Çelebi derler.",
                "isTrue":false,
                "isStudentAnswer":"false"

              },
              {
                "id":3,
                "choiceText":"C",
                "content":"Güneş girmeyen eve doktor girer.",
                "isTrue":false,
                "isStudentAnswer":"true"

              },
              {
                "id":4,
                "choiceText":"D",
                "content":"Güneş girmeyen eve doktor girer.",
                "isTrue":false,
                "isStudentAnswer":"false"

              },
              {
                "id":5,
                "choiceText":"E",
                "content":"Güneş girmeyen eve doktor girer.",
                "isTrue":false,
                "isStudentAnswer":"false"

              }]
          },
          {
            "id": 1820,
            "number": 6,
            "title":"6. Soru",
            "type": "text",
            "correctAnswer":'A',
            "userAnswer":'',
            "content":"<p>Aşağıdaki atasözlerinde geçen sıfat-ﬁillerden hangisi diğerlerinden farklı bir görevde kullanılmıştır?</p>",
            "choices": [
              {
                "id":1,
                "choiceText":"A",
                "content":"Arkadaşına yalan söyleyenin yüzü kara olsun.",
                "isTrue":false,
                "isStudentAnswer":"false"

              },
              {
                "id":2,
                "choiceText":"B",
                "content":"Koyunun bulunmadığı yerde keçiye Abdurrahman Çelebi derler.",
                "isTrue":false,
                "isStudentAnswer":"false"

              },
              {
                "id":3,
                "choiceText":"C",
                "content":"Güneş girmeyen eve doktor girer.",
                "isTrue":false,
                "isStudentAnswer":"true"

              },
              {
                "id":4,
                "choiceText":"D",
                "content":"Güneş girmeyen eve doktor girer.",
                "isTrue":false,
                "isStudentAnswer":"false"

              },
              {
                "id":5,
                "choiceText":"E",
                "content":"Güneş girmeyen eve doktor girer.",
                "isTrue":false,
                "isStudentAnswer":"false"

              }]
          },
          {
            "id": 1821,
            "number": 7,
            "title":"7. Soru",
            "type": "text",
            "correctAnswer":'D',
            "userAnswer":'',
            "content":"<p>Aşağıdaki atasözlerinde geçen sıfat-ﬁillerden hangisi diğerlerinden farklı bir görevde kullanılmıştır?</p>",
            "choices": [
              {
                "id":1,
                "choiceText":"A",
                "content":"Arkadaşına yalan söyleyenin yüzü kara olsun.",
                "isTrue":true,
                "isStudentAnswer":"false"

              },
              {
                "id":2,
                "choiceText":"B",
                "content":"Koyunun bulunmadığı yerde keçiye Abdurrahman Çelebi derler.",
                "isTrue":false,
                "isStudentAnswer":"false"

              },
              {
                "id":3,
                "choiceText":"C",
                "content":"Güneş girmeyen eve doktor girer.",
                "isTrue":false,
                "isStudentAnswer":"true"

              },
              {
                "id":4,
                "choiceText":"D",
                "content":"Güneş girmeyen eve doktor girer.",
                "isTrue":false,
                "isStudentAnswer":"false"

              },
              {
                "id":5,
                "choiceText":"E",
                "content":"Güneş girmeyen eve doktor girer.",
                "isTrue":false,
                "isStudentAnswer":"false"

              }]
          },
          {
            "id": 1824,
            "number": 9,
            "title":"9. Soru",
            "type": "text",
            "correctAnswer":'C',
            "userAnswer":'',
            "content":"<p>Aşağıdaki atasözlerinde geçen sıfat-ﬁillerden hangisi diğerlerinden farklı bir görevde kullanılmıştır?</p>",
            "choices": [
              {
                "id":1,
                "choiceText":"A",
                "content":"Arkadaşına yalan söyleyenin yüzü kara olsun.",
                "isTrue":false,
                "isStudentAnswer":"false"

              },
              {
                "id":2,
                "choiceText":"B",
                "content":"Koyunun bulunmadığı yerde keçiye Abdurrahman Çelebi derler.",
                "isTrue":false,
                "isStudentAnswer":"false"

              },
              {
                "id":3,
                "choiceText":"C",
                "content":"Güneş girmeyen eve doktor girer.",
                "isTrue":true,
                "isStudentAnswer":"true"

              },
              {
                "id":4,
                "choiceText":"D",
                "content":"Güneş girmeyen eve doktor girer.",
                "isTrue":true,
                "isStudentAnswer":"false"

              },
              {
                "id":5,
                "choiceText":"E",
                "content":"Güneş girmeyen eve doktor girer.",
                "isTrue":true,
                "isStudentAnswer":"false"

              }]
          },
          {
            "id": 1825,
            "number": 10,
            "title":"10. Soru",
            "type": "text",
            "correctAnswer":'C',
            "userAnswer":'',
            "content":"<p>Aşağıdaki atasözlerinde geçen sıfat-ﬁillerden hangisi diğerlerinden farklı bir görevde kullanılmıştır?</p>",
            "choices": [
              {
                "id":1,
                "choiceText":"A",
                "content":"Arkadaşına yalan söyleyenin yüzü kara olsun.",
                "isTrue":false,
                "isStudentAnswer":"false"

              },
              {
                "id":2,
                "choiceText":"B",
                "content":"Koyunun bulunmadığı yerde keçiye Abdurrahman Çelebi derler.",
                "isTrue":false,
                "isStudentAnswer":"false"

              },
              {
                "id":3,
                "choiceText":"C",
                "content":"Güneş girmeyen eve doktor girer.",
                "isTrue":true,
                "isStudentAnswer":"true"

              },
              {
                "id":4,
                "choiceText":"D",
                "content":"Güneş girmeyen eve doktor girer.",
                "isTrue":true,
                "isStudentAnswer":"false"

              },
              {
                "id":5,
                "choiceText":"E",
                "content":"Güneş girmeyen eve doktor girer.",
                "isTrue":true,
                "isStudentAnswer":"false"

              }]
          },
          {
            "id": 1826,
            "number": 11,
            "title":"11. Soru",
            "type": "text",
            "correctAnswer":'C',
            "userAnswer":'',
            "content":"<p>Aşağıdaki atasözlerinde geçen sıfat-ﬁillerden hangisi diğerlerinden farklı bir görevde kullanılmıştır?</p>",
            "choices": [
              {
                "id":1,
                "choiceText":"A",
                "content":"Arkadaşına yalan söyleyenin yüzü kara olsun.",
                "isTrue":false,
                "isStudentAnswer":"false"

              },
              {
                "id":2,
                "choiceText":"B",
                "content":"Koyunun bulunmadığı yerde keçiye Abdurrahman Çelebi derler.",
                "isTrue":false,
                "isStudentAnswer":"false"

              },
              {
                "id":3,
                "choiceText":"C",
                "content":"Güneş girmeyen eve doktor girer.",
                "isTrue":true,
                "isStudentAnswer":"true"

              },
              {
                "id":4,
                "choiceText":"D",
                "content":"Güneş girmeyen eve doktor girer.",
                "isTrue":true,
                "isStudentAnswer":"false"

              },
              {
                "id":5,
                "choiceText":"E",
                "content":"Güneş girmeyen eve doktor girer.",
                "isTrue":true,
                "isStudentAnswer":"false"

              }]
          },
          {
            "id": 1827,
            "number": 12,
            "title":"12. Soru",
            "type": "text",
            "correctAnswer":'C',
            "userAnswer":'',
            "content":"<p>Aşağıdaki atasözlerinde geçen sıfat-ﬁillerden hangisi diğerlerinden farklı bir görevde kullanılmıştır?</p>",
            "choices": [
              {
                "id":1,
                "choiceText":"A",
                "content":"Arkadaşına yalan söyleyenin yüzü kara olsun.",
                "isTrue":false,
                "isStudentAnswer":"false"

              },
              {
                "id":2,
                "choiceText":"B",
                "content":"Koyunun bulunmadığı yerde keçiye Abdurrahman Çelebi derler.",
                "isTrue":false,
                "isStudentAnswer":"false"

              },
              {
                "id":3,
                "choiceText":"C",
                "content":"Güneş girmeyen eve doktor girer.",
                "isTrue":true,
                "isStudentAnswer":"true"

              },
              {
                "id":4,
                "choiceText":"D",
                "content":"Güneş girmeyen eve doktor girer.",
                "isTrue":true,
                "isStudentAnswer":"false"

              },
              {
                "id":5,
                "choiceText":"E",
                "content":"Güneş girmeyen eve doktor girer.",
                "isTrue":true,
                "isStudentAnswer":"false"

              }]
          },
          {
            "id": 1829,
            "number": 13,
            "title":"13. Soru",
            "type": "text",
            "correctAnswer":'C',
            "userAnswer":'',
            "content":"<p>Aşağıdaki atasözlerinde geçen sıfat-ﬁillerden hangisi diğerlerinden farklı bir görevde kullanılmıştır?</p>",
            "choices": [
              {
                "id":1,
                "choiceText":"A",
                "content":"Arkadaşına yalan söyleyenin yüzü kara olsun.",
                "isTrue":false,
                "isStudentAnswer":"false"

              },
              {
                "id":2,
                "choiceText":"B",
                "content":"Koyunun bulunmadığı yerde keçiye Abdurrahman Çelebi derler.",
                "isTrue":false,
                "isStudentAnswer":"false"

              },
              {
                "id":3,
                "choiceText":"C",
                "content":"Güneş girmeyen eve doktor girer.",
                "isTrue":true,
                "isStudentAnswer":"true"

              },
              {
                "id":4,
                "choiceText":"D",
                "content":"Güneş girmeyen eve doktor girer.",
                "isTrue":true,
                "isStudentAnswer":"false"

              },
              {
                "id":5,
                "choiceText":"E",
                "content":"Güneş girmeyen eve doktor girer.",
                "isTrue":true,
                "isStudentAnswer":"false"

              }]
          },
          {
            "id": 1830,
            "number": 14,
            "title":"14. Soru",
            "type": "text",
            "correctAnswer":'C',
            "userAnswer":'',
            "content":"<p>Aşağıdaki atasözlerinde geçen sıfat-ﬁillerden hangisi diğerlerinden farklı bir görevde kullanılmıştır?</p>",
            "choices": [
              {
                "id":1,
                "choiceText":"A",
                "content":"Arkadaşına yalan söyleyenin yüzü kara olsun.",
                "isTrue":false,
                "isStudentAnswer":"false"

              },
              {
                "id":2,
                "choiceText":"B",
                "content":"Koyunun bulunmadığı yerde keçiye Abdurrahman Çelebi derler.",
                "isTrue":false,
                "isStudentAnswer":"false"

              },
              {
                "id":3,
                "choiceText":"C",
                "content":"Güneş girmeyen eve doktor girer.",
                "isTrue":true,
                "isStudentAnswer":"true"

              },
              {
                "id":4,
                "choiceText":"D",
                "content":"Güneş girmeyen eve doktor girer.",
                "isTrue":true,
                "isStudentAnswer":"false"

              },
              {
                "id":5,
                "choiceText":"E",
                "content":"Güneş girmeyen eve doktor girer.",
                "isTrue":true,
                "isStudentAnswer":"false"

              }]
          },
          {
            "id": 1855,
            "number": 15,
            "title":"15. Soru",
            "type": "text",
            "correctAnswer":'C',
            "userAnswer":'',
            "content":"<p>Aşağıdaki atasözlerinde geçen sıfat-ﬁillerden hangisi diğerlerinden farklı bir görevde kullanılmıştır?</p>",
            "choices": [
              {
                "id":1,
                "choiceText":"A",
                "content":"Arkadaşına yalan söyleyenin yüzü kara olsun.",
                "isTrue":false,
                "isStudentAnswer":"false"

              },
              {
                "id":2,
                "choiceText":"B",
                "content":"Koyunun bulunmadığı yerde keçiye Abdurrahman Çelebi derler.",
                "isTrue":false,
                "isStudentAnswer":"false"

              },
              {
                "id":3,
                "choiceText":"C",
                "content":"Güneş girmeyen eve doktor girer.",
                "isTrue":true,
                "isStudentAnswer":"true"

              },
              {
                "id":4,
                "choiceText":"D",
                "content":"Güneş girmeyen eve doktor girer.",
                "isTrue":true,
                "isStudentAnswer":"false"

              },
              {
                "id":5,
                "choiceText":"E",
                "content":"Güneş girmeyen eve doktor girer.",
                "isTrue":true,
                "isStudentAnswer":"false"

              }]
          },
          {
            "id": 1831,
            "number": 16,
            "title":"16. Soru",
            "type": "text",
            "correctAnswer":'C',
            "userAnswer":'',
            "content":"<p>Aşağıdaki atasözlerinde geçen sıfat-ﬁillerden hangisi diğerlerinden farklı bir görevde kullanılmıştır?</p>",
            "choices": [
              {
                "id":1,
                "choiceText":"A",
                "content":"Arkadaşına yalan söyleyenin yüzü kara olsun.",
                "isTrue":false,
                "isStudentAnswer":"false"

              },
              {
                "id":2,
                "choiceText":"B",
                "content":"Koyunun bulunmadığı yerde keçiye Abdurrahman Çelebi derler.",
                "isTrue":false,
                "isStudentAnswer":"false"

              },
              {
                "id":3,
                "choiceText":"C",
                "content":"Güneş girmeyen eve doktor girer.",
                "isTrue":true,
                "isStudentAnswer":"true"

              },
              {
                "id":4,
                "choiceText":"D",
                "content":"Güneş girmeyen eve doktor girer.",
                "isTrue":true,
                "isStudentAnswer":"false"

              },
              {
                "id":5,
                "choiceText":"E",
                "content":"Güneş girmeyen eve doktor girer.",
                "isTrue":true,
                "isStudentAnswer":"false"

              }]
          },
          {
            "id": 1832,
            "number": 17,
            "title":"17. Soru",
            "type": "text",
            "correctAnswer":'C',
            "userAnswer":'',
            "content":"<p>Aşağıdaki atasözlerinde geçen sıfat-ﬁillerden hangisi diğerlerinden farklı bir görevde kullanılmıştır?</p>",
            "choices": [
              {
                "id":1,
                "choiceText":"A",
                "content":"Arkadaşına yalan söyleyenin yüzü kara olsun.",
                "isTrue":false,
                "isStudentAnswer":"false"

              },
              {
                "id":2,
                "choiceText":"B",
                "content":"Koyunun bulunmadığı yerde keçiye Abdurrahman Çelebi derler.",
                "isTrue":false,
                "isStudentAnswer":"false"

              },
              {
                "id":3,
                "choiceText":"C",
                "content":"Güneş girmeyen eve doktor girer.",
                "isTrue":true,
                "isStudentAnswer":"true"

              },
              {
                "id":4,
                "choiceText":"D",
                "content":"Güneş girmeyen eve doktor girer.",
                "isTrue":true,
                "isStudentAnswer":"false"

              },
              {
                "id":5,
                "choiceText":"E",
                "content":"Güneş girmeyen eve doktor girer.",
                "isTrue":true,
                "isStudentAnswer":"false"

              }]
          },
          {
            "id": 1833,
            "number": 18,
            "title":"18. Soru",
            "type": "text",
            "correctAnswer":'C',
            "userAnswer":'',
            "content":"<p>Aşağıdaki atasözlerinde geçen sıfat-ﬁillerden hangisi diğerlerinden farklı bir görevde kullanılmıştır?</p>",
            "choices": [
              {
                "id":1,
                "choiceText":"A",
                "content":"Arkadaşına yalan söyleyenin yüzü kara olsun.",
                "isTrue":false,
                "isStudentAnswer":"false"

              },
              {
                "id":2,
                "choiceText":"B",
                "content":"Koyunun bulunmadığı yerde keçiye Abdurrahman Çelebi derler.",
                "isTrue":false,
                "isStudentAnswer":"false"

              },
              {
                "id":3,
                "choiceText":"C",
                "content":"Güneş girmeyen eve doktor girer.",
                "isTrue":true,
                "isStudentAnswer":"true"

              },
              {
                "id":4,
                "choiceText":"D",
                "content":"Güneş girmeyen eve doktor girer.",
                "isTrue":true,
                "isStudentAnswer":"false"

              },
              {
                "id":5,
                "choiceText":"E",
                "content":"Güneş girmeyen eve doktor girer.",
                "isTrue":true,
                "isStudentAnswer":"false"

              }]
          },
          {
            "id": 1834,
            "number": 19,
            "title":"19. Soru",
            "type": "text",
            "correctAnswer":'C',
            "userAnswer":'',
            "content":"<p>Aşağıdaki atasözlerinde geçen sıfat-ﬁillerden hangisi diğerlerinden farklı bir görevde kullanılmıştır?</p>",
            "choices": [
              {
                "id":1,
                "choiceText":"A",
                "content":"Arkadaşına yalan söyleyenin yüzü kara olsun.",
                "isTrue":false,
                "isStudentAnswer":"false"

              },
              {
                "id":2,
                "choiceText":"B",
                "content":"Koyunun bulunmadığı yerde keçiye Abdurrahman Çelebi derler.",
                "isTrue":false,
                "isStudentAnswer":"false"

              },
              {
                "id":3,
                "choiceText":"C",
                "content":"Güneş girmeyen eve doktor girer.",
                "isTrue":true,
                "isStudentAnswer":"true"

              },
              {
                "id":4,
                "choiceText":"D",
                "content":"Güneş girmeyen eve doktor girer.",
                "isTrue":true,
                "isStudentAnswer":"false"

              },
              {
                "id":5,
                "choiceText":"E",
                "content":"Güneş girmeyen eve doktor girer.",
                "isTrue":true,
                "isStudentAnswer":"false"

              }]
          },
          {
            "id": 1835,
            "number": 20,
            "title":"20. Soru",
            "type": "text",
            "correctAnswer":'C',
            "userAnswer":'',
            "content":"<p>Aşağıdaki atasözlerinde geçen sıfat-ﬁillerden hangisi diğerlerinden farklı bir görevde kullanılmıştır?</p>",
            "choices": [
              {
                "id":1,
                "choiceText":"A",
                "content":"Arkadaşına yalan söyleyenin yüzü kara olsun.",
                "isTrue":false,
                "isStudentAnswer":"false"

              },
              {
                "id":2,
                "choiceText":"B",
                "content":"Koyunun bulunmadığı yerde keçiye Abdurrahman Çelebi derler.",
                "isTrue":false,
                "isStudentAnswer":"false"

              },
              {
                "id":3,
                "choiceText":"C",
                "content":"Güneş girmeyen eve doktor girer.",
                "isTrue":true,
                "isStudentAnswer":"true"

              },
              {
                "id":4,
                "choiceText":"D",
                "content":"Güneş girmeyen eve doktor girer.",
                "isTrue":true,
                "isStudentAnswer":"false"

              },
              {
                "id":5,
                "choiceText":"E",
                "content":"Güneş girmeyen eve doktor girer.",
                "isTrue":true,
                "isStudentAnswer":"false"

              }]
          },
          {
            "id": 1836,
            "number": 21,
            "title":"21. Soru",
            "type": "text",
            "correctAnswer":'C',
            "userAnswer":'',
            "content":"<p>Aşağıdaki atasözlerinde geçen sıfat-ﬁillerden hangisi diğerlerinden farklı bir görevde kullanılmıştır?</p>",
            "choices": [
              {
                "id":1,
                "choiceText":"A",
                "content":"Arkadaşına yalan söyleyenin yüzü kara olsun.",
                "isTrue":false,
                "isStudentAnswer":"false"

              },
              {
                "id":2,
                "choiceText":"B",
                "content":"Koyunun bulunmadığı yerde keçiye Abdurrahman Çelebi derler.",
                "isTrue":false,
                "isStudentAnswer":"false"

              },
              {
                "id":3,
                "choiceText":"C",
                "content":"Güneş girmeyen eve doktor girer.",
                "isTrue":true,
                "isStudentAnswer":"true"

              },
              {
                "id":4,
                "choiceText":"D",
                "content":"Güneş girmeyen eve doktor girer.",
                "isTrue":true,
                "isStudentAnswer":"false"

              },
              {
                "id":5,
                "choiceText":"E",
                "content":"Güneş girmeyen eve doktor girer.",
                "isTrue":true,
                "isStudentAnswer":"false"

              }]
          }
        
        ]
      }
    ]
  }

  mock.onGet('/apps/testing/getTest').reply(config => {

    const test = data.tests.filter(test => test.id==config.params.id)[0]
    return test? [200, test] : [404] 
  })
  

  mock.onPost('/apps/testing/saveUserAnswer').reply(config => {
    // Get product from post data
    const { questionId, userAnswer } = JSON.parse(config.data)
    var test=data.tests.find(x => x.id === 197);
    var testQuestionIndex= test.questions.findIndex(x => x.id == questionId)
    test.questions[testQuestionIndex].userAnswer=userAnswer;
    return [201,{questionId, userAnswer}]
  })
  