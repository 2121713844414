import mock from "../mock"
/* eslint-disable */
const questions = [
  {
    id: 1815,
    number: 1,
    title: "1. Soru",
    correctAnswer: "A",
    userAnswer: "",
    type: "text",
    content:
      "<p>&Ccedil;oluk &ccedil;ocuk k&ouml;y &ccedil;eşmesinden <u>kana kana</u> su i&ccedil;tik.</p><p><strong>Bu c&uuml;mledeki altı &ccedil;izili s&ouml;zc&uuml;k grubu i&ccedil;in hangisi s&ouml;ylenebilir?</strong></p>",
    choices: [
      {
        id: 1,
        choiceText: "A",
        content: "Arkadaşına yalan söyleyenin yüzü kara olsun.",
        isTrue: true,
        isStudentAnswer: "false",
      },
      {
        id: 2,
        choiceText: "B",
        content: "Koyunun bulunmadığı yerde keçiye Abdurrahman Çelebi derler.",
        isTrue: false,
        isStudentAnswer: "false",
      },
      {
        id: 3,
        choiceText: "C",
        content: "Güneş girmeyen eve doktor girer.",
        isTrue: false,
        isStudentAnswer: "true",
      },
      {
        id: 4,
        choiceText: "D",
        content: "Güneş girmeyen eve doktor girer.",
        isTrue: false,
        isStudentAnswer: "false",
      },
      {
        id: 5,
        choiceText: "E",
        content: "Güneş girmeyen eve doktor girer.",
        isTrue: false,
        isStudentAnswer: "false",
      },
    ],
  },
  {
    id: 1816,
    number: 2,
    title: "2. Soru",
    type: "text",
    correctAnswer: "B",
    userAnswer: "",
    content:
      "<p>&Ccedil;oluk &ccedil;ocuk k&ouml;y &ccedil;eşmesinden <u>kana kana</u> su i&ccedil;tik.</p><p><strong>Bu c&uuml;mledeki altı &ccedil;izili s&ouml;zc&uuml;k grubu i&ccedil;in hangisi s&ouml;ylenebilir?</strong></p>",
    choices: [
      {
        id: 1,
        choiceText: "A",
        content: "Arkadaşına yalan söyleyenin yüzü kara olsun.",
        isTrue: true,
        isStudentAnswer: "false",
      },
      {
        id: 2,
        choiceText: "B",
        content: "Koyunun bulunmadığı yerde keçiye Abdurrahman Çelebi derler.",
        isTrue: false,
        isStudentAnswer: "false",
      },
      {
        id: 3,
        choiceText: "C",
        content: "Güneş girmeyen eve doktor girer.",
        isTrue: false,
        isStudentAnswer: "true",
      },
      {
        id: 4,
        choiceText: "D",
        content: "Güneş girmeyen eve doktor girer.",
        isTrue: false,
        isStudentAnswer: "false",
      },
      {
        id: 5,
        choiceText: "E",
        content: "Güneş girmeyen eve doktor girer.",
        isTrue: false,
        isStudentAnswer: "false",
      },
    ],
  },
  {
    id: 1817,
    type: "text",
    number: 3,
    title: "3. Soru",
    correctAnswer: "C",
    userAnswer: "",
    content:
      "<p>Aşağıdaki atasözlerinde geçen sıfat-ﬁillerden hangisi diğerlerinden farklı bir görevde kullanılmıştır?</p>",
    choices: [
      {
        id: 1,
        choiceText: "A",
        content: "Arkadaşına yalan söyleyenin yüzü kara olsun.",
        isTrue: true,
        isStudentAnswer: "false",
      },
      {
        id: 2,
        choiceText: "B",
        content: "Koyunun bulunmadığı yerde keçiye Abdurrahman Çelebi derler.",
        isTrue: false,
        isStudentAnswer: "false",
      },
      {
        id: 3,
        choiceText: "C",
        content: "Güneş girmeyen eve doktor girer.",
        isTrue: false,
        isStudentAnswer: "true",
      },
      {
        id: 4,
        choiceText: "D",
        content: "Güneş girmeyen eve doktor girer.",
        isTrue: false,
        isStudentAnswer: "false",
      },
      {
        id: 5,
        choiceText: "E",
        content: "Güneş girmeyen eve doktor girer.",
        isTrue: false,
        isStudentAnswer: "false",
      },
    ],
  },
  {
    id: 1818,
    type: "text",
    number: 4,
    title: "4. Soru",
    correctAnswer: "D",
    userAnswer: "",
    content:
      "<p>Aşağıdaki atasözlerinde geçen sıfat-ﬁillerden hangisi diğerlerinden farklı bir görevde kullanılmıştır?</p>",
    choices: [
      {
        id: 1,
        choiceText: "A",
        content: "Arkadaşına yalan söyleyenin yüzü kara olsun.",
        isTrue: true,
        isStudentAnswer: "false",
      },
      {
        id: 2,
        choiceText: "B",
        content: "Koyunun bulunmadığı yerde keçiye Abdurrahman Çelebi derler.",
        isTrue: false,
        isStudentAnswer: "false",
      },
      {
        id: 3,
        choiceText: "C",
        content: "Güneş girmeyen eve doktor girer.",
        isTrue: false,
        isStudentAnswer: "true",
      },
      {
        id: 4,
        choiceText: "D",
        content: "Güneş girmeyen eve doktor girer.",
        isTrue: false,
        isStudentAnswer: "false",
      },
      {
        id: 5,
        choiceText: "E",
        content: "Güneş girmeyen eve doktor girer.",
        isTrue: false,
        isStudentAnswer: "false",
      },
    ],
  },
  {
    id: 1819,
    type: "text",
    number: 5,
    title: "5. Soru",
    correctAnswer: "E",
    userAnswer: "",
    content:
      "<p>Aşağıdaki atasözlerinde geçen sıfat-ﬁillerden hangisi diğerlerinden farklı bir görevde kullanılmıştır?</p>",
    choices: [
      {
        id: 1,
        choiceText: "A",
        content: "Arkadaşına yalan söyleyenin yüzü kara olsun.",
        isTrue: true,
        isStudentAnswer: "false",
      },
      {
        id: 2,
        choiceText: "B",
        content: "Koyunun bulunmadığı yerde keçiye Abdurrahman Çelebi derler.",
        isTrue: false,
        isStudentAnswer: "false",
      },
      {
        id: 3,
        choiceText: "C",
        content: "Güneş girmeyen eve doktor girer.",
        isTrue: false,
        isStudentAnswer: "true",
      },
      {
        id: 4,
        choiceText: "D",
        content: "Güneş girmeyen eve doktor girer.",
        isTrue: false,
        isStudentAnswer: "false",
      },
      {
        id: 5,
        choiceText: "E",
        content: "Güneş girmeyen eve doktor girer.",
        isTrue: false,
        isStudentAnswer: "false",
      },
    ],
  },
  {
    id: 1820,
    number: 6,
    title: "6. Soru",
    type: "text",
    correctAnswer: "A",
    userAnswer: "",
    content:
      "<p>Aşağıdaki atasözlerinde geçen sıfat-ﬁillerden hangisi diğerlerinden farklı bir görevde kullanılmıştır?</p>",
    choices: [
      {
        id: 1,
        choiceText: "A",
        content: "Arkadaşına yalan söyleyenin yüzü kara olsun.",
        isTrue: false,
        isStudentAnswer: "false",
      },
      {
        id: 2,
        choiceText: "B",
        content: "Koyunun bulunmadığı yerde keçiye Abdurrahman Çelebi derler.",
        isTrue: false,
        isStudentAnswer: "false",
      },
      {
        id: 3,
        choiceText: "C",
        content: "Güneş girmeyen eve doktor girer.",
        isTrue: false,
        isStudentAnswer: "true",
      },
      {
        id: 4,
        choiceText: "D",
        content: "Güneş girmeyen eve doktor girer.",
        isTrue: false,
        isStudentAnswer: "false",
      },
      {
        id: 5,
        choiceText: "E",
        content: "Güneş girmeyen eve doktor girer.",
        isTrue: false,
        isStudentAnswer: "false",
      },
    ],
  },
  {
    id: 1821,
    number: 7,
    title: "7. Soru",
    type: "text",
    correctAnswer: "D",
    userAnswer: "",
    content:
      "<p>Aşağıdaki atasözlerinde geçen sıfat-ﬁillerden hangisi diğerlerinden farklı bir görevde kullanılmıştır?</p>",
    choices: [
      {
        id: 1,
        choiceText: "A",
        content: "Arkadaşına yalan söyleyenin yüzü kara olsun.",
        isTrue: true,
        isStudentAnswer: "false",
      },
      {
        id: 2,
        choiceText: "B",
        content: "Koyunun bulunmadığı yerde keçiye Abdurrahman Çelebi derler.",
        isTrue: false,
        isStudentAnswer: "false",
      },
      {
        id: 3,
        choiceText: "C",
        content: "Güneş girmeyen eve doktor girer.",
        isTrue: false,
        isStudentAnswer: "true",
      },
      {
        id: 4,
        choiceText: "D",
        content: "Güneş girmeyen eve doktor girer.",
        isTrue: false,
        isStudentAnswer: "false",
      },
      {
        id: 5,
        choiceText: "E",
        content: "Güneş girmeyen eve doktor girer.",
        isTrue: false,
        isStudentAnswer: "false",
      },
    ],
  },
  {
    id: 1824,
    number: 9,
    title: "9. Soru",
    type: "text",
    correctAnswer: "C",
    userAnswer: "",
    content:
      "<p>Aşağıdaki atasözlerinde geçen sıfat-ﬁillerden hangisi diğerlerinden farklı bir görevde kullanılmıştır?</p>",
    choices: [
      {
        id: 1,
        choiceText: "A",
        content: "Arkadaşına yalan söyleyenin yüzü kara olsun.",
        isTrue: false,
        isStudentAnswer: "false",
      },
      {
        id: 2,
        choiceText: "B",
        content: "Koyunun bulunmadığı yerde keçiye Abdurrahman Çelebi derler.",
        isTrue: false,
        isStudentAnswer: "false",
      },
      {
        id: 3,
        choiceText: "C",
        content: "Güneş girmeyen eve doktor girer.",
        isTrue: true,
        isStudentAnswer: "true",
      },
      {
        id: 4,
        choiceText: "D",
        content: "Güneş girmeyen eve doktor girer.",
        isTrue: true,
        isStudentAnswer: "false",
      },
      {
        id: 5,
        choiceText: "E",
        content: "Güneş girmeyen eve doktor girer.",
        isTrue: true,
        isStudentAnswer: "false",
      },
    ],
  },
  {
    id: 1825,
    number: 10,
    title: "10. Soru",
    type: "text",
    correctAnswer: "C",
    userAnswer: "",
    content:
      "<p>Aşağıdaki atasözlerinde geçen sıfat-ﬁillerden hangisi diğerlerinden farklı bir görevde kullanılmıştır?</p>",
    choices: [
      {
        id: 1,
        choiceText: "A",
        content: "Arkadaşına yalan söyleyenin yüzü kara olsun.",
        isTrue: false,
        isStudentAnswer: "false",
      },
      {
        id: 2,
        choiceText: "B",
        content: "Koyunun bulunmadığı yerde keçiye Abdurrahman Çelebi derler.",
        isTrue: false,
        isStudentAnswer: "false",
      },
      {
        id: 3,
        choiceText: "C",
        content: "Güneş girmeyen eve doktor girer.",
        isTrue: true,
        isStudentAnswer: "true",
      },
      {
        id: 4,
        choiceText: "D",
        content: "Güneş girmeyen eve doktor girer.",
        isTrue: true,
        isStudentAnswer: "false",
      },
      {
        id: 5,
        choiceText: "E",
        content: "Güneş girmeyen eve doktor girer.",
        isTrue: true,
        isStudentAnswer: "false",
      },
    ],
  },
  {
    id: 1826,
    number: 11,
    title: "11. Soru",
    type: "text",
    correctAnswer: "C",
    userAnswer: "",
    content:
      "<p>Aşağıdaki atasözlerinde geçen sıfat-ﬁillerden hangisi diğerlerinden farklı bir görevde kullanılmıştır?</p>",
    choices: [
      {
        id: 1,
        choiceText: "A",
        content: "Arkadaşına yalan söyleyenin yüzü kara olsun.",
        isTrue: false,
        isStudentAnswer: "false",
      },
      {
        id: 2,
        choiceText: "B",
        content: "Koyunun bulunmadığı yerde keçiye Abdurrahman Çelebi derler.",
        isTrue: false,
        isStudentAnswer: "false",
      },
      {
        id: 3,
        choiceText: "C",
        content: "Güneş girmeyen eve doktor girer.",
        isTrue: true,
        isStudentAnswer: "true",
      },
      {
        id: 4,
        choiceText: "D",
        content: "Güneş girmeyen eve doktor girer.",
        isTrue: true,
        isStudentAnswer: "false",
      },
      {
        id: 5,
        choiceText: "E",
        content: "Güneş girmeyen eve doktor girer.",
        isTrue: true,
        isStudentAnswer: "false",
      },
    ],
  },
  {
    id: 1827,
    number: 12,
    title: "12. Soru",
    type: "text",
    correctAnswer: "C",
    userAnswer: "",
    content:
      "<p>Aşağıdaki atasözlerinde geçen sıfat-ﬁillerden hangisi diğerlerinden farklı bir görevde kullanılmıştır?</p>",
    choices: [
      {
        id: 1,
        choiceText: "A",
        content: "Arkadaşına yalan söyleyenin yüzü kara olsun.",
        isTrue: false,
        isStudentAnswer: "false",
      },
      {
        id: 2,
        choiceText: "B",
        content: "Koyunun bulunmadığı yerde keçiye Abdurrahman Çelebi derler.",
        isTrue: false,
        isStudentAnswer: "false",
      },
      {
        id: 3,
        choiceText: "C",
        content: "Güneş girmeyen eve doktor girer.",
        isTrue: true,
        isStudentAnswer: "true",
      },
      {
        id: 4,
        choiceText: "D",
        content: "Güneş girmeyen eve doktor girer.",
        isTrue: true,
        isStudentAnswer: "false",
      },
      {
        id: 5,
        choiceText: "E",
        content: "Güneş girmeyen eve doktor girer.",
        isTrue: true,
        isStudentAnswer: "false",
      },
    ],
  },
  {
    id: 1829,
    number: 13,
    title: "13. Soru",
    type: "text",
    correctAnswer: "C",
    userAnswer: "",
    content:
      "<p>Aşağıdaki atasözlerinde geçen sıfat-ﬁillerden hangisi diğerlerinden farklı bir görevde kullanılmıştır?</p>",
    choices: [
      {
        id: 1,
        choiceText: "A",
        content: "Arkadaşına yalan söyleyenin yüzü kara olsun.",
        isTrue: false,
        isStudentAnswer: "false",
      },
      {
        id: 2,
        choiceText: "B",
        content: "Koyunun bulunmadığı yerde keçiye Abdurrahman Çelebi derler.",
        isTrue: false,
        isStudentAnswer: "false",
      },
      {
        id: 3,
        choiceText: "C",
        content: "Güneş girmeyen eve doktor girer.",
        isTrue: true,
        isStudentAnswer: "true",
      },
      {
        id: 4,
        choiceText: "D",
        content: "Güneş girmeyen eve doktor girer.",
        isTrue: true,
        isStudentAnswer: "false",
      },
      {
        id: 5,
        choiceText: "E",
        content: "Güneş girmeyen eve doktor girer.",
        isTrue: true,
        isStudentAnswer: "false",
      },
    ],
  },
  {
    id: 1830,
    number: 14,
    title: "14. Soru",
    type: "text",
    correctAnswer: "C",
    userAnswer: "",
    content:
      "<p>Aşağıdaki atasözlerinde geçen sıfat-ﬁillerden hangisi diğerlerinden farklı bir görevde kullanılmıştır?</p>",
    choices: [
      {
        id: 1,
        choiceText: "A",
        content: "Arkadaşına yalan söyleyenin yüzü kara olsun.",
        isTrue: false,
        isStudentAnswer: "false",
      },
      {
        id: 2,
        choiceText: "B",
        content: "Koyunun bulunmadığı yerde keçiye Abdurrahman Çelebi derler.",
        isTrue: false,
        isStudentAnswer: "false",
      },
      {
        id: 3,
        choiceText: "C",
        content: "Güneş girmeyen eve doktor girer.",
        isTrue: true,
        isStudentAnswer: "true",
      },
      {
        id: 4,
        choiceText: "D",
        content: "Güneş girmeyen eve doktor girer.",
        isTrue: true,
        isStudentAnswer: "false",
      },
      {
        id: 5,
        choiceText: "E",
        content: "Güneş girmeyen eve doktor girer.",
        isTrue: true,
        isStudentAnswer: "false",
      },
    ],
  },
  {
    id: 1855,
    number: 15,
    title: "15. Soru",
    type: "text",
    correctAnswer: "C",
    userAnswer: "",
    content:
      "<p>Aşağıdaki atasözlerinde geçen sıfat-ﬁillerden hangisi diğerlerinden farklı bir görevde kullanılmıştır?</p>",
    choices: [
      {
        id: 1,
        choiceText: "A",
        content: "Arkadaşına yalan söyleyenin yüzü kara olsun.",
        isTrue: false,
        isStudentAnswer: "false",
      },
      {
        id: 2,
        choiceText: "B",
        content: "Koyunun bulunmadığı yerde keçiye Abdurrahman Çelebi derler.",
        isTrue: false,
        isStudentAnswer: "false",
      },
      {
        id: 3,
        choiceText: "C",
        content: "Güneş girmeyen eve doktor girer.",
        isTrue: true,
        isStudentAnswer: "true",
      },
      {
        id: 4,
        choiceText: "D",
        content: "Güneş girmeyen eve doktor girer.",
        isTrue: true,
        isStudentAnswer: "false",
      },
      {
        id: 5,
        choiceText: "E",
        content: "Güneş girmeyen eve doktor girer.",
        isTrue: true,
        isStudentAnswer: "false",
      },
    ],
  },
  {
    id: 1831,
    number: 16,
    title: "16. Soru",
    type: "text",
    correctAnswer: "C",
    userAnswer: "",
    content:
      "<p>Aşağıdaki atasözlerinde geçen sıfat-ﬁillerden hangisi diğerlerinden farklı bir görevde kullanılmıştır?</p>",
    choices: [
      {
        id: 1,
        choiceText: "A",
        content: "Arkadaşına yalan söyleyenin yüzü kara olsun.",
        isTrue: false,
        isStudentAnswer: "false",
      },
      {
        id: 2,
        choiceText: "B",
        content: "Koyunun bulunmadığı yerde keçiye Abdurrahman Çelebi derler.",
        isTrue: false,
        isStudentAnswer: "false",
      },
      {
        id: 3,
        choiceText: "C",
        content: "Güneş girmeyen eve doktor girer.",
        isTrue: true,
        isStudentAnswer: "true",
      },
      {
        id: 4,
        choiceText: "D",
        content: "Güneş girmeyen eve doktor girer.",
        isTrue: true,
        isStudentAnswer: "false",
      },
      {
        id: 5,
        choiceText: "E",
        content: "Güneş girmeyen eve doktor girer.",
        isTrue: true,
        isStudentAnswer: "false",
      },
    ],
  },
  {
    id: 1832,
    number: 17,
    title: "17. Soru",
    type: "text",
    correctAnswer: "C",
    userAnswer: "",
    content:
      "<p>Aşağıdaki atasözlerinde geçen sıfat-ﬁillerden hangisi diğerlerinden farklı bir görevde kullanılmıştır?</p>",
    choices: [
      {
        id: 1,
        choiceText: "A",
        content: "Arkadaşına yalan söyleyenin yüzü kara olsun.",
        isTrue: false,
        isStudentAnswer: "false",
      },
      {
        id: 2,
        choiceText: "B",
        content: "Koyunun bulunmadığı yerde keçiye Abdurrahman Çelebi derler.",
        isTrue: false,
        isStudentAnswer: "false",
      },
      {
        id: 3,
        choiceText: "C",
        content: "Güneş girmeyen eve doktor girer.",
        isTrue: true,
        isStudentAnswer: "true",
      },
      {
        id: 4,
        choiceText: "D",
        content: "Güneş girmeyen eve doktor girer.",
        isTrue: true,
        isStudentAnswer: "false",
      },
      {
        id: 5,
        choiceText: "E",
        content: "Güneş girmeyen eve doktor girer.",
        isTrue: true,
        isStudentAnswer: "false",
      },
    ],
  },
  {
    id: 1833,
    number: 18,
    title: "18. Soru",
    type: "text",
    correctAnswer: "C",
    userAnswer: "",
    content:
      "<p>Aşağıdaki atasözlerinde geçen sıfat-ﬁillerden hangisi diğerlerinden farklı bir görevde kullanılmıştır?</p>",
    choices: [
      {
        id: 1,
        choiceText: "A",
        content: "Arkadaşına yalan söyleyenin yüzü kara olsun.",
        isTrue: false,
        isStudentAnswer: "false",
      },
      {
        id: 2,
        choiceText: "B",
        content: "Koyunun bulunmadığı yerde keçiye Abdurrahman Çelebi derler.",
        isTrue: false,
        isStudentAnswer: "false",
      },
      {
        id: 3,
        choiceText: "C",
        content: "Güneş girmeyen eve doktor girer.",
        isTrue: true,
        isStudentAnswer: "true",
      },
      {
        id: 4,
        choiceText: "D",
        content: "Güneş girmeyen eve doktor girer.",
        isTrue: true,
        isStudentAnswer: "false",
      },
      {
        id: 5,
        choiceText: "E",
        content: "Güneş girmeyen eve doktor girer.",
        isTrue: true,
        isStudentAnswer: "false",
      },
    ],
  },
  {
    id: 1834,
    number: 19,
    title: "19. Soru",
    type: "text",
    correctAnswer: "C",
    userAnswer: "",
    content:
      "<p>Aşağıdaki atasözlerinde geçen sıfat-ﬁillerden hangisi diğerlerinden farklı bir görevde kullanılmıştır?</p>",
    choices: [
      {
        id: 1,
        choiceText: "A",
        content: "Arkadaşına yalan söyleyenin yüzü kara olsun.",
        isTrue: false,
        isStudentAnswer: "false",
      },
      {
        id: 2,
        choiceText: "B",
        content: "Koyunun bulunmadığı yerde keçiye Abdurrahman Çelebi derler.",
        isTrue: false,
        isStudentAnswer: "false",
      },
      {
        id: 3,
        choiceText: "C",
        content: "Güneş girmeyen eve doktor girer.",
        isTrue: true,
        isStudentAnswer: "true",
      },
      {
        id: 4,
        choiceText: "D",
        content: "Güneş girmeyen eve doktor girer.",
        isTrue: true,
        isStudentAnswer: "false",
      },
      {
        id: 5,
        choiceText: "E",
        content: "Güneş girmeyen eve doktor girer.",
        isTrue: true,
        isStudentAnswer: "false",
      },
    ],
  },
  {
    id: 1835,
    number: 20,
    title: "20. Soru",
    type: "text",
    correctAnswer: "C",
    userAnswer: "",
    content:
      "<p>Aşağıdaki atasözlerinde geçen sıfat-ﬁillerden hangisi diğerlerinden farklı bir görevde kullanılmıştır?</p>",
    choices: [
      {
        id: 1,
        choiceText: "A",
        content: "Arkadaşına yalan söyleyenin yüzü kara olsun.",
        isTrue: false,
        isStudentAnswer: "false",
      },
      {
        id: 2,
        choiceText: "B",
        content: "Koyunun bulunmadığı yerde keçiye Abdurrahman Çelebi derler.",
        isTrue: false,
        isStudentAnswer: "false",
      },
      {
        id: 3,
        choiceText: "C",
        content: "Güneş girmeyen eve doktor girer.",
        isTrue: true,
        isStudentAnswer: "true",
      },
      {
        id: 4,
        choiceText: "D",
        content: "Güneş girmeyen eve doktor girer.",
        isTrue: true,
        isStudentAnswer: "false",
      },
      {
        id: 5,
        choiceText: "E",
        content: "Güneş girmeyen eve doktor girer.",
        isTrue: true,
        isStudentAnswer: "false",
      },
    ],
  },
  {
    id: 1836,
    number: 21,
    title: "21. Soru",
    type: "text",
    correctAnswer: "C",
    userAnswer: "",
    content:
      "<p>Aşağıdaki atasözlerinde geçen sıfat-ﬁillerden hangisi diğerlerinden farklı bir görevde kullanılmıştır?</p>",
    choices: [
      {
        id: 1,
        choiceText: "A",
        content: "Arkadaşına yalan söyleyenin yüzü kara olsun.",
        isTrue: false,
        isStudentAnswer: "false",
      },
      {
        id: 2,
        choiceText: "B",
        content: "Koyunun bulunmadığı yerde keçiye Abdurrahman Çelebi derler.",
        isTrue: false,
        isStudentAnswer: "false",
      },
      {
        id: 3,
        choiceText: "C",
        content: "Güneş girmeyen eve doktor girer.",
        isTrue: true,
        isStudentAnswer: "true",
      },
      {
        id: 4,
        choiceText: "D",
        content: "Güneş girmeyen eve doktor girer.",
        isTrue: true,
        isStudentAnswer: "false",
      },
      {
        id: 5,
        choiceText: "E",
        content: "Güneş girmeyen eve doktor girer.",
        isTrue: true,
        isStudentAnswer: "false",
      },
    ],
  },
];
const data = [
  {
    examCategoryId: 1,
    examCategoryName: "TYT",
    examResults: [
      {
        resultId: 1,
        examId: 1,
        resultDate: "01.01.2022",
        examName: "TYT 1. Deneme Sınavı",
        scores: [
          {
            scoreName: "TYT",
            scoreTypeId: 1,
            score: 280.75,
          },
        ],
        lessonStats: [
          {
            SectionId: 1,
            SectionName: "Türkçe",
            CorrectAnswer: 30,
            WrongAnswer: 25,
            EmptyAnswer: 4,
            Net: 24,
            Lessons: [
              {
                LessonName: "Türkçe",
                LessonId: 1,
                TotalQuestion: 30,
                CorrectAnswer: 25,
                WrongAnswer: 4,
                EmptyAnswer: 1,
                Net: 24,
                UserAnswers: [
                  {
                    questionNo: 1,
                    questionAnswer: "A",
                    studentAnswer: "A",
                    question: questions[0],
                  },
                  {
                    questionNo: 2,
                    questionAnswer: "B",
                    studentAnswer: "B",
                    question: questions[1],
                  },
                  {
                    questionNo: 3,
                    questionAnswer: "C",
                    studentAnswer: "",
                    question: questions[2],
                  },
                  {
                    questionNo: 4,
                    questionAnswer: "A",
                    studentAnswer: "A",
                    question: questions[3],
                  },
                  {
                    questionNo: 5,
                    questionAnswer: "B",
                    studentAnswer: "B",
                    question: questions[4],
                  },
                  {
                    questionNo: 6,
                    questionAnswer: "D",
                    studentAnswer: "D",
                    question: questions[5],
                  },
                  {
                    questionNo: 7,
                    questionAnswer: "E",
                    studentAnswer: "E",
                    question: questions[6],
                  },
                  {
                    questionNo: 8,
                    questionAnswer: "A",
                    studentAnswer: "B",
                    question: questions[7],
                  },
                  {
                    questionNo: 9,
                    questionAnswer: "B",
                    studentAnswer: "B",
                    question: questions[8],
                  },
                  {
                    questionNo: 10,
                    questionAnswer: "A",
                    studentAnswer: "",
                    question: questions[9],
                  },
                  {
                    questionNo: 11,
                    questionAnswer: "A",
                    studentAnswer: "A",
                    question: questions[10],
                  },
                  {
                    questionNo: 12,
                    questionAnswer: "A",
                    studentAnswer: "A",
                    question: questions[11],
                  },
                  {
                    questionNo: 13,
                    questionAnswer: "C",
                    studentAnswer: "C",
                    question: questions[12],
                  },
                  {
                    questionNo: 14,
                    questionAnswer: "D",
                    studentAnswer: "D",
                  },
                  {
                    questionNo: 15,
                    questionAnswer: "E",
                    studentAnswer: "E",
                  },
                  {
                    questionNo: 16,
                    questionAnswer: "D",
                    studentAnswer: "D",
                  },
                  {
                    questionNo: 17,
                    questionAnswer: "E",
                    studentAnswer: "E",
                  },
                  {
                    questionNo: 18,
                    questionAnswer: "A",
                    studentAnswer: "A",
                  },
                  {
                    questionNo: 19,
                    questionAnswer: "A",
                    studentAnswer: "A",
                  },
                  {
                    questionNo: 20,
                    questionAnswer: "C",
                    studentAnswer: "C",
                  },
                  {
                    questionNo: 21,
                    questionAnswer: "C",
                    studentAnswer: "B",
                  },
                  {
                    questionNo: 22,
                    questionAnswer: "A",
                    studentAnswer: "A",
                  },
                  {
                    questionNo: 23,
                    questionAnswer: "E",
                    studentAnswer: "E",
                  },
                  {
                    questionNo: 24,
                    questionAnswer: "E",
                    studentAnswer: "E",
                  },
                  {
                    questionNo: 25,
                    questionAnswer: "C",
                    studentAnswer: "C",
                  },
                  {
                    questionNo: 26,
                    questionAnswer: "D",
                    studentAnswer: "D",
                  },
                  {
                    questionNo: 27,
                    questionAnswer: "A",
                    studentAnswer: "A",
                  },
                  {
                    questionNo: 28,
                    questionAnswer: "B",
                    studentAnswer: "E",
                  },
                  {
                    questionNo: 29,
                    questionAnswer: "A",
                    studentAnswer: "A",
                  },
                  {
                    questionNo: 30,
                    questionAnswer: "C",
                    studentAnswer: "D",
                  },
                ],
              },
            ],
          },
          {
            SectionId: 2,
            SectionName: "Matematik",
            CorrectAnswer: 30,
            WrongAnswer: 25,
            EmptyAnswer: 4,
            Net: 24,
            Lessons: [
              {
                LessonName: "Matematik",
                LessonId: 1,
                TotalQuestion: 30,
                CorrectAnswer: 25,
                WrongAnswer: 4,
                EmptyAnswer: 1,
                Net: 24,
                UserAnswers: [
                  {
                    questionNo: 1,
                    questionAnswer: "A",
                    studentAnswer: "A",
                    question: questions[0],
                  },
                  {
                    questionNo: 2,
                    questionAnswer: "B",
                    studentAnswer: "B",
                    question: questions[1],
                  },
                  {
                    questionNo: 3,
                    questionAnswer: "C",
                    studentAnswer: "C",
                    question: questions[2],
                  },
                  {
                    questionNo: 4,
                    questionAnswer: "A",
                    studentAnswer: "A",
                  },
                  {
                    questionNo: 5,
                    questionAnswer: "B",
                    studentAnswer: "B",
                  },
                  {
                    questionNo: 6,
                    questionAnswer: "D",
                    studentAnswer: "D",
                  },
                  {
                    questionNo: 7,
                    questionAnswer: "E",
                    studentAnswer: "E",
                  },
                  {
                    questionNo: 8,
                    questionAnswer: "A",
                    studentAnswer: "B",
                  },
                  {
                    questionNo: 9,
                    questionAnswer: "B",
                    studentAnswer: "B",
                  },
                  {
                    questionNo: 10,
                    questionAnswer: "A",
                    studentAnswer: "",
                  },
                  {
                    questionNo: 11,
                    questionAnswer: "A",
                    studentAnswer: "A",
                  },
                  {
                    questionNo: 12,
                    questionAnswer: "A",
                    studentAnswer: "A",
                  },
                  {
                    questionNo: 13,
                    questionAnswer: "C",
                    studentAnswer: "C",
                  },
                  {
                    questionNo: 14,
                    questionAnswer: "D",
                    studentAnswer: "D",
                  },
                  {
                    questionNo: 15,
                    questionAnswer: "E",
                    studentAnswer: "E",
                  },
                  {
                    questionNo: 16,
                    questionAnswer: "D",
                    studentAnswer: "D",
                  },
                  {
                    questionNo: 17,
                    questionAnswer: "E",
                    studentAnswer: "E",
                  },
                  {
                    questionNo: 18,
                    questionAnswer: "A",
                    studentAnswer: "A",
                  },
                  {
                    questionNo: 19,
                    questionAnswer: "A",
                    studentAnswer: "A",
                  },
                  {
                    questionNo: 20,
                    questionAnswer: "C",
                    studentAnswer: "C",
                  },
                  {
                    questionNo: 21,
                    questionAnswer: "C",
                    studentAnswer: "B",
                  },
                  {
                    questionNo: 22,
                    questionAnswer: "A",
                    studentAnswer: "A",
                  },
                  {
                    questionNo: 23,
                    questionAnswer: "E",
                    studentAnswer: "E",
                  },
                  {
                    questionNo: 24,
                    questionAnswer: "E",
                    studentAnswer: "E",
                  },
                  {
                    questionNo: 25,
                    questionAnswer: "C",
                    studentAnswer: "C",
                  },
                  {
                    questionNo: 26,
                    questionAnswer: "D",
                    studentAnswer: "D",
                  },
                  {
                    questionNo: 27,
                    questionAnswer: "A",
                    studentAnswer: "A",
                  },
                  {
                    questionNo: 28,
                    questionAnswer: "B",
                    studentAnswer: "E",
                  },
                  {
                    questionNo: 29,
                    questionAnswer: "A",
                    studentAnswer: "A",
                  },
                  {
                    questionNo: 30,
                    questionAnswer: "C",
                    studentAnswer: "D",
                  },
                ],
              },
            ],
          },
          {
            SectionId: 3,
            SectionName: "Sosyal Bilimler",
            CorrectAnswer: 18,
            WrongAnswer: 1,
            EmptyAnswer: 1,
            Net: 17.75,
            Lessons: [
              {
                LessonName: "Tarih",
                LessonId: 1,
                TotalQuestion: 5,
                CorrectAnswer: 4,
                WrongAnswer: 1,
                EmptyAnswer: 0,
                Net: 11.5,
                UserAnswers: [
                  {
                    questionNo: 1,
                    questionAnswer: "A",
                    studentAnswer: "A",
                    question: questions[0],
                  },
                  {
                    questionNo: 2,
                    questionAnswer: "B",
                    studentAnswer: "B",
                  },
                  {
                    questionNo: 3,
                    questionAnswer: "C",
                    studentAnswer: "E",
                  },
                  {
                    questionNo: 4,
                    questionAnswer: "A",
                    studentAnswer: "A",
                  },
                  {
                    questionNo: 5,
                    questionAnswer: "B",
                    studentAnswer: "B",
                  },
                ],
              },
              {
                LessonName: "Coğrafya",
                LessonId: 1,
                TotalQuestion: 5,
                CorrectAnswer: 4,
                WrongAnswer: 1,
                EmptyAnswer: 0,
                Net: 3.75,
                UserAnswers: [
                  {
                    questionNo: 1,
                    questionAnswer: "A",
                    studentAnswer: "c",
                    question: questions[0],
                  },
                  {
                    questionNo: 2,
                    questionAnswer: "B",
                    studentAnswer: "B",
                  },
                  {
                    questionNo: 3,
                    questionAnswer: "C",
                    studentAnswer: "E",
                  },
                  {
                    questionNo: 4,
                    questionAnswer: "A",
                    studentAnswer: "A",
                  },
                  {
                    questionNo: 5,
                    questionAnswer: "B",
                    studentAnswer: "B",
                  },
                ],
              },
              {
                LessonName: "Felsefe",
                LessonId: 1,
                TotalQuestion: 5,
                CorrectAnswer: 5,
                WrongAnswer: 0,
                EmptyAnswer: 0,
                Net: 5,
                UserAnswers: [
                  {
                    questionNo: 1,
                    questionAnswer: "A",
                    studentAnswer: "A",
                    question: questions[0],
                  },
                  {
                    questionNo: 2,
                    questionAnswer: "B",
                    studentAnswer: "B",
                  },
                  {
                    questionNo: 3,
                    questionAnswer: "C",
                    studentAnswer: "E",
                  },
                  {
                    questionNo: 4,
                    questionAnswer: "A",
                    studentAnswer: "A",
                  },
                  {
                    questionNo: 5,
                    questionAnswer: "B",
                    studentAnswer: "B",
                  },
                ],
              },
              {
                LessonName: "Din Kültürü ve Ahlak Bilgisi",
                LessonId: 1,
                TotalQuestion: 5,
                CorrectAnswer: 4,
                WrongAnswer: 0,
                EmptyAnswer: 1,
                Net: 4,
                UserAnswers: [
                  {
                    questionNo: 1,
                    questionAnswer: "A",
                    studentAnswer: "A",
                    question: questions[0],
                  },
                  {
                    questionNo: 2,
                    questionAnswer: "B",
                    studentAnswer: "B",
                  },
                  {
                    questionNo: 3,
                    questionAnswer: "C",
                    studentAnswer: "",
                  },
                  {
                    questionNo: 4,
                    questionAnswer: "A",
                    studentAnswer: "A",
                  },
                  {
                    questionNo: 5,
                    questionAnswer: "B",
                    studentAnswer: "B",
                  },
                ],
              },
            ],
          },
          {
            SectionId: 4,
            SectionName: "Fen Bilimleri",
            CorrectAnswer: 15,
            WrongAnswer: 4,
            EmptyAnswer: 1,
            Net: 14,
            Lessons: [
              {
                LessonName: "Fizik",
                LessonId: 1,
                TotalQuestion: 7,
                CorrectAnswer: 7,
                WrongAnswer: 0,
                EmptyAnswer: 0,
                Net: 7,
                UserAnswers: [
                  {
                    questionNo: 1,
                    questionAnswer: "A",
                    studentAnswer: "A",
                    question: questions[0],
                  },
                  {
                    questionNo: 2,
                    questionAnswer: "B",
                    studentAnswer: "B",
                  },
                  {
                    questionNo: 3,
                    questionAnswer: "C",
                    studentAnswer: "",
                  },
                  {
                    questionNo: 4,
                    questionAnswer: "A",
                    studentAnswer: "A",
                  },
                  {
                    questionNo: 5,
                    questionAnswer: "B",
                    studentAnswer: "B",
                  },
                  {
                    questionNo: 6,
                    questionAnswer: "B",
                    studentAnswer: "B",
                  },
                  {
                    questionNo: 7,
                    questionAnswer: "B",
                    studentAnswer: "B",
                  },
                ],
              },
              {
                LessonName: "Kimya",
                LessonId: 1,
                TotalQuestion: 7,
                CorrectAnswer: 7,
                WrongAnswer: 0,
                EmptyAnswer: 0,
                Net: 7,
                UserAnswers: [
                  {
                    questionNo: 1,
                    questionAnswer: "A",
                    studentAnswer: "A",
                    question: questions[0],
                  },
                  {
                    questionNo: 2,
                    questionAnswer: "B",
                    studentAnswer: "B",
                  },
                  {
                    questionNo: 3,
                    questionAnswer: "C",
                    studentAnswer: "",
                  },
                  {
                    questionNo: 4,
                    questionAnswer: "A",
                    studentAnswer: "A",
                  },
                  {
                    questionNo: 5,
                    questionAnswer: "B",
                    studentAnswer: "B",
                  },
                  {
                    questionNo: 6,
                    questionAnswer: "B",
                    studentAnswer: "B",
                  },
                  {
                    questionNo: 7,
                    questionAnswer: "B",
                    studentAnswer: "B",
                  },
                ],
              },
              {
                LessonName: "Biyoloji",
                LessonId: 1,
                TotalQuestion: 7,
                CorrectAnswer: 7,
                WrongAnswer: 0,
                EmptyAnswer: 0,
                Net: 7,
                UserAnswers: [
                  {
                    questionNo: 1,
                    questionAnswer: "A",
                    studentAnswer: "A",
                    question: questions[0],
                  },
                  {
                    questionNo: 2,
                    questionAnswer: "B",
                    studentAnswer: "B",
                  },
                  {
                    questionNo: 3,
                    questionAnswer: "C",
                    studentAnswer: "",
                  },
                  {
                    questionNo: 4,
                    questionAnswer: "A",
                    studentAnswer: "A",
                  },
                  {
                    questionNo: 5,
                    questionAnswer: "B",
                    studentAnswer: "B",
                  },
                  {
                    questionNo: 6,
                    questionAnswer: "B",
                    studentAnswer: "B",
                  },
                  {
                    questionNo: 7,
                    questionAnswer: "B",
                    studentAnswer: "B",
                  },
                ],
              },
            ],
          },
        ],
        gainStats: [
          {
            lessonId: 1,
            lessonName: "Türkçe",
            gains: [
              {
                gainId: 1,
                gainText: "Dinleme stratejilerini uygular.	",
                questions: [
                  { questionNo: 3, status: "true" },
                  { questionNo: 1, status: "true" },
                  { questionNo: 7, status: "true" },
                  { questionNo: 15, status: "empty" },
                ],
                totalCorrectAnswer: 3,
                totalWrongAnswer: 1,
                totalEmptyAnswer: 0,
                succesRatio: 75,
                generalSuccesRatio: 75,
              },
              {
                gainId: 1,
                gainText: "Okudukları ile ilgili çıkarımlarda bulunur.	",
                questions: [
                  { questionNo: 1, status: "true" },
                  { questionNo: 3, status: "true" },
                  { questionNo: 6, status: "false" },
                  { questionNo: 18, status: "true" },
                ],
                totalCorrectAnswer: 3,
                totalWrongAnswer: 1,
                totalEmptyAnswer: 0,
                succesRatio: 55,
                generalSuccesRatio: 75,
              },
              {
                gainId: 1,
                gainText: "Bir işin işlem basamaklarını yazar.	",
                questions: [
                  { questionNo: 12, status: "warning" },
                  { questionNo: 15, status: "false" },
                ],
                totalCorrectAnswer: 3,
                totalWrongAnswer: 1,
                totalEmptyAnswer: 0,
                succesRatio: 85,
                generalSuccesRatio: 75,
              },
              {
                gainId: 1,
                gainText: "Metinler arasında karşılaştırma yapar.",
                questions: [
                  { questionNo: 2, status: "true" },
                  { questionNo: 12, status: "true" },
                  { questionNo: 22, status: "true" },
                  { questionNo: 25, status: "true" },
                ],
                totalCorrectAnswer: 3,
                totalWrongAnswer: 1,
                totalEmptyAnswer: 0,
                succesRatio: 75,
                generalSuccesRatio: 75,
              },
              {
                gainId: 1,
                gainText:
                  "Okuduğu metnin içeriğine uygun başlık/başlıklar belirler.",
                questions: [
                  { questionNo: 13, status: "true" },
                  { questionNo: 25, status: "warning" },
                  { questionNo: 26, status: "true" },
                  { questionNo: 30, status: "true" },
                ],
                totalCorrectAnswer: 3,
                totalWrongAnswer: 1,
                totalEmptyAnswer: 0,
                succesRatio: 100,
                generalSuccesRatio: 75,
              },
              {
                gainId: 1,
                gainText:
                  "Görsellerden ve başlıktan hareketle okuyacağı metnin konusunu tahmin eder.",
                questions: [
                  { questionNo: 3, status: "true" },
                  { questionNo: 5, status: "true" },
                  { questionNo: 6, status: "false" },
                  { questionNo: 12, status: "true" },
                ],
                totalCorrectAnswer: 3,
                totalWrongAnswer: 1,
                totalEmptyAnswer: 0,
                succesRatio: 25,
                generalSuccesRatio: 75,
              },
              {
                gainId: 1,
                gainText:
                  "Metinde ele alınan sorunlara farklı çözümler üretir.",
                questions: [
                  { questionNo: 3, status: "true" },
                  { questionNo: 5, status: "true" },
                  { questionNo: 6, status: "false" },
                  { questionNo: 12, status: "true" },
                ],
                totalCorrectAnswer: 3,
                totalWrongAnswer: 1,
                totalEmptyAnswer: 0,
                succesRatio: 75,
                generalSuccesRatio: 75,
              },
              {
                gainId: 1,
                gainText: "Konuşmacının sözlü olmayan mesajlarını kavrar.",
                questions: [
                  { questionNo: 3, status: "true" },
                  { questionNo: 5, status: "true" },
                  { questionNo: 6, status: "false" },
                  { questionNo: 12, status: "true" },
                ],
                totalCorrectAnswer: 3,
                totalWrongAnswer: 1,
                totalEmptyAnswer: 0,
                succesRatio: 75,
                generalSuccesRatio: 75,
              },
              {
                gainId: 1,
                gainText:
                  "Dinlediklerinde/izlediklerinde geçen olayların gelişimi ve sonucu hakkında tahminde bulunur.",
                questions: [
                  { questionNo: 3, status: "true" },
                  { questionNo: 5, status: "true" },
                  { questionNo: 6, status: "false" },
                  { questionNo: 12, status: "true" },
                ],
                totalCorrectAnswer: 3,
                totalWrongAnswer: 1,
                totalEmptyAnswer: 0,
                succesRatio: 75,
                generalSuccesRatio: 75,
              },
              {
                gainId: 1,
                gainText:
                  "Farklı yazı karakterleri ile yazılmış yazıları okur.	",
                questions: [
                  { questionNo: 3, status: "true" },
                  { questionNo: 5, status: "true" },
                  { questionNo: 6, status: "false" },
                  { questionNo: 12, status: "true" },
                ],
                totalCorrectAnswer: 3,
                totalWrongAnswer: 1,
                totalEmptyAnswer: 0,
                succesRatio: 75,
                generalSuccesRatio: 75,
              },
              {
                gainId: 1,
                gainText: "Hazırlıksız konuşma yapar.	",
                questions: [
                  { questionNo: 3, status: "true" },
                  { questionNo: 5, status: "true" },
                  { questionNo: 6, status: "false" },
                  { questionNo: 12, status: "true" },
                ],
                totalCorrectAnswer: 3,
                totalWrongAnswer: 1,
                totalEmptyAnswer: 0,
                succesRatio: 75,
                generalSuccesRatio: 75,
              },
            ],
          },
          {
            lessonId: 2,
            lessonName: "Matematik",
            gains: [
              {
                gainId: 1,
                gainText: "Dinleme2222 stratejilerini uygular.	",
                questions: [
                  { questionNo: 3, status: "true" },
                  { questionNo: 1, status: "true" },
                  { questionNo: 7, status: "true" },
                  { questionNo: 15, status: "empty" },
                ],
                totalCorrectAnswer: 3,
                totalWrongAnswer: 1,
                totalEmptyAnswer: 0,
                succesRatio: 75,
                generalSuccesRatio: 75,
              },
              {
                gainId: 1,
                gainText: "Okudukları ile ilgili çıkarımlarda bulunur.	",
                questions: [
                  { questionNo: 1, status: "true" },
                  { questionNo: 3, status: "true" },
                  { questionNo: 6, status: "false" },
                  { questionNo: 18, status: "true" },
                ],
                totalCorrectAnswer: 3,
                totalWrongAnswer: 1,
                totalEmptyAnswer: 0,
                succesRatio: 55,
                generalSuccesRatio: 75,
              },
              {
                gainId: 1,
                gainText: "Bir işin işlem basamaklarını yazar.	",
                questions: [
                  { questionNo: 12, status: "warning" },
                  { questionNo: 15, status: "false" },
                ],
                totalCorrectAnswer: 3,
                totalWrongAnswer: 1,
                totalEmptyAnswer: 0,
                succesRatio: 85,
                generalSuccesRatio: 75,
              },
              {
                gainId: 1,
                gainText: "Metinler arasında karşılaştırma yapar.",
                questions: [
                  { questionNo: 2, status: "true" },
                  { questionNo: 12, status: "true" },
                  { questionNo: 22, status: "true" },
                  { questionNo: 25, status: "true" },
                ],
                totalCorrectAnswer: 3,
                totalWrongAnswer: 1,
                totalEmptyAnswer: 0,
                succesRatio: 75,
                generalSuccesRatio: 75,
              },
              {
                gainId: 1,
                gainText:
                  "Okuduğu metnin içeriğine uygun başlık/başlıklar belirler.",
                questions: [
                  { questionNo: 13, status: "true" },
                  { questionNo: 25, status: "warning" },
                  { questionNo: 26, status: "true" },
                  { questionNo: 30, status: "true" },
                ],
                totalCorrectAnswer: 3,
                totalWrongAnswer: 1,
                totalEmptyAnswer: 0,
                succesRatio: 100,
                generalSuccesRatio: 75,
              },
              {
                gainId: 1,
                gainText:
                  "Görsellerden ve başlıktan hareketle okuyacağı metnin konusunu tahmin eder.",
                questions: [
                  { questionNo: 3, status: "true" },
                  { questionNo: 5, status: "true" },
                  { questionNo: 6, status: "false" },
                  { questionNo: 12, status: "true" },
                ],
                totalCorrectAnswer: 3,
                totalWrongAnswer: 1,
                totalEmptyAnswer: 0,
                succesRatio: 25,
                generalSuccesRatio: 75,
              },
              {
                gainId: 1,
                gainText:
                  "Metinde ele alınan sorunlara farklı çözümler üretir.",
                questions: [
                  { questionNo: 3, status: "true" },
                  { questionNo: 5, status: "true" },
                  { questionNo: 6, status: "false" },
                  { questionNo: 12, status: "true" },
                ],
                totalCorrectAnswer: 3,
                totalWrongAnswer: 1,
                totalEmptyAnswer: 0,
                succesRatio: 75,
                generalSuccesRatio: 75,
              },
              {
                gainId: 1,
                gainText: "Konuşmacının sözlü olmayan mesajlarını kavrar.",
                questions: [
                  { questionNo: 3, status: "true" },
                  { questionNo: 5, status: "true" },
                  { questionNo: 6, status: "false" },
                  { questionNo: 12, status: "true" },
                ],
                totalCorrectAnswer: 3,
                totalWrongAnswer: 1,
                totalEmptyAnswer: 0,
                succesRatio: 75,
                generalSuccesRatio: 75,
              },
              {
                gainId: 1,
                gainText:
                  "Dinlediklerinde/izlediklerinde geçen olayların gelişimi ve sonucu hakkında tahminde bulunur.",
                questions: [
                  { questionNo: 3, status: "true" },
                  { questionNo: 5, status: "true" },
                  { questionNo: 6, status: "false" },
                  { questionNo: 12, status: "true" },
                ],
                totalCorrectAnswer: 3,
                totalWrongAnswer: 1,
                totalEmptyAnswer: 0,
                succesRatio: 75,
                generalSuccesRatio: 75,
              },
              {
                gainId: 1,
                gainText:
                  "Farklı yazı karakterleri ile yazılmış yazıları okur.	",
                questions: [
                  { questionNo: 3, status: "true" },
                  { questionNo: 5, status: "true" },
                  { questionNo: 6, status: "false" },
                  { questionNo: 12, status: "true" },
                ],
                totalCorrectAnswer: 3,
                totalWrongAnswer: 1,
                totalEmptyAnswer: 0,
                succesRatio: 75,
                generalSuccesRatio: 75,
              },
              {
                gainId: 1,
                gainText: "Hazırlıksız konuşma yapar.	",
                questions: [
                  { questionNo: 3, status: "true" },
                  { questionNo: 5, status: "true" },
                  { questionNo: 6, status: "false" },
                  { questionNo: 12, status: "true" },
                ],
                totalCorrectAnswer: 3,
                totalWrongAnswer: 1,
                totalEmptyAnswer: 0,
                succesRatio: 75,
                generalSuccesRatio: 75,
              },
            ],
          },
          {
            lessonId: 3,
            lessonName: "Tarih",
            gains: [
              {
                gainId: 1,
                gainText: "Dinleme stratejilerini uygular.	",
                questions: [
                  { questionNo: 3, status: "true" },
                  { questionNo: 1, status: "true" },
                  { questionNo: 7, status: "true" },
                  { questionNo: 15, status: "empty" },
                ],
                totalCorrectAnswer: 3,
                totalWrongAnswer: 1,
                totalEmptyAnswer: 0,
                succesRatio: 75,
                generalSuccesRatio: 75,
              },
              {
                gainId: 1,
                gainText: "Okudukları ile ilgili çıkarımlarda bulunur.	",
                questions: [
                  { questionNo: 1, status: "true" },
                  { questionNo: 3, status: "true" },
                  { questionNo: 6, status: "false" },
                  { questionNo: 18, status: "true" },
                ],
                totalCorrectAnswer: 3,
                totalWrongAnswer: 1,
                totalEmptyAnswer: 0,
                succesRatio: 55,
                generalSuccesRatio: 75,
              },
              {
                gainId: 1,
                gainText: "Bir işin işlem basamaklarını yazar.	",
                questions: [
                  { questionNo: 12, status: "warning" },
                  { questionNo: 15, status: "false" },
                ],
                totalCorrectAnswer: 3,
                totalWrongAnswer: 1,
                totalEmptyAnswer: 0,
                succesRatio: 85,
                generalSuccesRatio: 75,
              },
              {
                gainId: 1,
                gainText: "Metinler arasında karşılaştırma yapar.",
                questions: [
                  { questionNo: 2, status: "true" },
                  { questionNo: 12, status: "true" },
                  { questionNo: 22, status: "true" },
                  { questionNo: 25, status: "true" },
                ],
                totalCorrectAnswer: 3,
                totalWrongAnswer: 1,
                totalEmptyAnswer: 0,
                succesRatio: 75,
                generalSuccesRatio: 75,
              },
              {
                gainId: 1,
                gainText:
                  "Okuduğu metnin içeriğine uygun başlık/başlıklar belirler.",
                questions: [
                  { questionNo: 13, status: "true" },
                  { questionNo: 25, status: "warning" },
                  { questionNo: 26, status: "true" },
                  { questionNo: 30, status: "true" },
                ],
                totalCorrectAnswer: 3,
                totalWrongAnswer: 1,
                totalEmptyAnswer: 0,
                succesRatio: 100,
                generalSuccesRatio: 75,
              },
              {
                gainId: 1,
                gainText:
                  "Görsellerden ve başlıktan hareketle okuyacağı metnin konusunu tahmin eder.",
                questions: [
                  { questionNo: 3, status: "true" },
                  { questionNo: 5, status: "true" },
                  { questionNo: 6, status: "false" },
                  { questionNo: 12, status: "true" },
                ],
                totalCorrectAnswer: 3,
                totalWrongAnswer: 1,
                totalEmptyAnswer: 0,
                succesRatio: 25,
                generalSuccesRatio: 75,
              },
              {
                gainId: 1,
                gainText:
                  "Metinde ele alınan sorunlara farklı çözümler üretir.",
                questions: [
                  { questionNo: 3, status: "true" },
                  { questionNo: 5, status: "true" },
                  { questionNo: 6, status: "false" },
                  { questionNo: 12, status: "true" },
                ],
                totalCorrectAnswer: 3,
                totalWrongAnswer: 1,
                totalEmptyAnswer: 0,
                succesRatio: 75,
                generalSuccesRatio: 75,
              },
              {
                gainId: 1,
                gainText: "Konuşmacının sözlü olmayan mesajlarını kavrar.",
                questions: [
                  { questionNo: 3, status: "true" },
                  { questionNo: 5, status: "true" },
                  { questionNo: 6, status: "false" },
                  { questionNo: 12, status: "true" },
                ],
                totalCorrectAnswer: 3,
                totalWrongAnswer: 1,
                totalEmptyAnswer: 0,
                succesRatio: 75,
                generalSuccesRatio: 75,
              },
              {
                gainId: 1,
                gainText:
                  "Dinlediklerinde/izlediklerinde geçen olayların gelişimi ve sonucu hakkında tahminde bulunur.",
                questions: [
                  { questionNo: 3, status: "true" },
                  { questionNo: 5, status: "true" },
                  { questionNo: 6, status: "false" },
                  { questionNo: 12, status: "true" },
                ],
                totalCorrectAnswer: 3,
                totalWrongAnswer: 1,
                totalEmptyAnswer: 0,
                succesRatio: 75,
                generalSuccesRatio: 75,
              },
              {
                gainId: 1,
                gainText:
                  "Farklı yazı karakterleri ile yazılmış yazıları okur.	",
                questions: [
                  { questionNo: 3, status: "true" },
                  { questionNo: 5, status: "true" },
                  { questionNo: 6, status: "false" },
                  { questionNo: 12, status: "true" },
                ],
                totalCorrectAnswer: 3,
                totalWrongAnswer: 1,
                totalEmptyAnswer: 0,
                succesRatio: 75,
                generalSuccesRatio: 75,
              },
              {
                gainId: 1,
                gainText: "Hazırlıksız konuşma yapar.	",
                questions: [
                  { questionNo: 3, status: "true" },
                  { questionNo: 5, status: "true" },
                  { questionNo: 6, status: "false" },
                  { questionNo: 12, status: "true" },
                ],
                totalCorrectAnswer: 3,
                totalWrongAnswer: 1,
                totalEmptyAnswer: 0,
                succesRatio: 75,
                generalSuccesRatio: 75,
              },
            ],
          },
          {
            lessonId: 4,
            lessonName: "Coğrafya",
            gains: [
              {
                gainId: 1,
                gainText: "Dinleme stratejilerini uygular.	",
                questions: [
                  { questionNo: 3, status: "true" },
                  { questionNo: 1, status: "true" },
                  { questionNo: 7, status: "true" },
                  { questionNo: 15, status: "empty" },
                ],
                totalCorrectAnswer: 3,
                totalWrongAnswer: 1,
                totalEmptyAnswer: 0,
                succesRatio: 75,
                generalSuccesRatio: 75,
              },
              {
                gainId: 1,
                gainText: "Okudukları ile ilgili çıkarımlarda bulunur.	",
                questions: [
                  { questionNo: 1, status: "true" },
                  { questionNo: 3, status: "true" },
                  { questionNo: 6, status: "false" },
                  { questionNo: 18, status: "true" },
                ],
                totalCorrectAnswer: 3,
                totalWrongAnswer: 1,
                totalEmptyAnswer: 0,
                succesRatio: 55,
                generalSuccesRatio: 75,
              },
              {
                gainId: 1,
                gainText: "Bir işin işlem basamaklarını yazar.	",
                questions: [
                  { questionNo: 12, status: "warning" },
                  { questionNo: 15, status: "false" },
                ],
                totalCorrectAnswer: 3,
                totalWrongAnswer: 1,
                totalEmptyAnswer: 0,
                succesRatio: 85,
                generalSuccesRatio: 75,
              },
              {
                gainId: 1,
                gainText: "Metinler arasında karşılaştırma yapar.",
                questions: [
                  { questionNo: 2, status: "true" },
                  { questionNo: 12, status: "true" },
                  { questionNo: 22, status: "true" },
                  { questionNo: 25, status: "true" },
                ],
                totalCorrectAnswer: 3,
                totalWrongAnswer: 1,
                totalEmptyAnswer: 0,
                succesRatio: 75,
                generalSuccesRatio: 75,
              },
              {
                gainId: 1,
                gainText:
                  "Okuduğu metnin içeriğine uygun başlık/başlıklar belirler.",
                questions: [
                  { questionNo: 13, status: "true" },
                  { questionNo: 25, status: "warning" },
                  { questionNo: 26, status: "true" },
                  { questionNo: 30, status: "true" },
                ],
                totalCorrectAnswer: 3,
                totalWrongAnswer: 1,
                totalEmptyAnswer: 0,
                succesRatio: 100,
                generalSuccesRatio: 75,
              },
              {
                gainId: 1,
                gainText:
                  "Görsellerden ve başlıktan hareketle okuyacağı metnin konusunu tahmin eder.",
                questions: [
                  { questionNo: 3, status: "true" },
                  { questionNo: 5, status: "true" },
                  { questionNo: 6, status: "false" },
                  { questionNo: 12, status: "true" },
                ],
                totalCorrectAnswer: 3,
                totalWrongAnswer: 1,
                totalEmptyAnswer: 0,
                succesRatio: 25,
                generalSuccesRatio: 75,
              },
              {
                gainId: 1,
                gainText:
                  "Metinde ele alınan sorunlara farklı çözümler üretir.",
                questions: [
                  { questionNo: 3, status: "true" },
                  { questionNo: 5, status: "true" },
                  { questionNo: 6, status: "false" },
                  { questionNo: 12, status: "true" },
                ],
                totalCorrectAnswer: 3,
                totalWrongAnswer: 1,
                totalEmptyAnswer: 0,
                succesRatio: 75,
                generalSuccesRatio: 75,
              },
              {
                gainId: 1,
                gainText: "Konuşmacının sözlü olmayan mesajlarını kavrar.",
                questions: [
                  { questionNo: 3, status: "true" },
                  { questionNo: 5, status: "true" },
                  { questionNo: 6, status: "false" },
                  { questionNo: 12, status: "true" },
                ],
                totalCorrectAnswer: 3,
                totalWrongAnswer: 1,
                totalEmptyAnswer: 0,
                succesRatio: 75,
                generalSuccesRatio: 75,
              },
              {
                gainId: 1,
                gainText:
                  "Dinlediklerinde/izlediklerinde geçen olayların gelişimi ve sonucu hakkında tahminde bulunur.",
                questions: [
                  { questionNo: 3, status: "true" },
                  { questionNo: 5, status: "true" },
                  { questionNo: 6, status: "false" },
                  { questionNo: 12, status: "true" },
                ],
                totalCorrectAnswer: 3,
                totalWrongAnswer: 1,
                totalEmptyAnswer: 0,
                succesRatio: 75,
                generalSuccesRatio: 75,
              },
              {
                gainId: 1,
                gainText:
                  "Farklı yazı karakterleri ile yazılmış yazıları okur.	",
                questions: [
                  { questionNo: 3, status: "true" },
                  { questionNo: 5, status: "true" },
                  { questionNo: 6, status: "false" },
                  { questionNo: 12, status: "true" },
                ],
                totalCorrectAnswer: 3,
                totalWrongAnswer: 1,
                totalEmptyAnswer: 0,
                succesRatio: 75,
                generalSuccesRatio: 75,
              },
              {
                gainId: 1,
                gainText: "Hazırlıksız konuşma yapar.	",
                questions: [
                  { questionNo: 3, status: "true" },
                  { questionNo: 5, status: "true" },
                  { questionNo: 6, status: "false" },
                  { questionNo: 12, status: "true" },
                ],
                totalCorrectAnswer: 3,
                totalWrongAnswer: 1,
                totalEmptyAnswer: 0,
                succesRatio: 75,
                generalSuccesRatio: 75,
              },
            ],
          },
          {
            lessonId: 5,
            lessonName: "Felsefe",
            gains: [
              {
                gainId: 1,
                gainText: "Dinleme stratejilerini uygular.	",
                questions: [
                  { questionNo: 3, status: "true" },
                  { questionNo: 1, status: "true" },
                  { questionNo: 7, status: "true" },
                  { questionNo: 15, status: "empty" },
                ],
                totalCorrectAnswer: 3,
                totalWrongAnswer: 1,
                totalEmptyAnswer: 0,
                succesRatio: 75,
                generalSuccesRatio: 75,
              },
              {
                gainId: 1,
                gainText: "Okudukları ile ilgili çıkarımlarda bulunur.	",
                questions: [
                  { questionNo: 1, status: "true" },
                  { questionNo: 3, status: "true" },
                  { questionNo: 6, status: "false" },
                  { questionNo: 18, status: "true" },
                ],
                totalCorrectAnswer: 3,
                totalWrongAnswer: 1,
                totalEmptyAnswer: 0,
                succesRatio: 55,
                generalSuccesRatio: 75,
              },
              {
                gainId: 1,
                gainText: "Bir işin işlem basamaklarını yazar.	",
                questions: [
                  { questionNo: 12, status: "warning" },
                  { questionNo: 15, status: "false" },
                ],
                totalCorrectAnswer: 3,
                totalWrongAnswer: 1,
                totalEmptyAnswer: 0,
                succesRatio: 85,
                generalSuccesRatio: 75,
              },
              {
                gainId: 1,
                gainText: "Metinler arasında karşılaştırma yapar.",
                questions: [
                  { questionNo: 2, status: "true" },
                  { questionNo: 12, status: "true" },
                  { questionNo: 22, status: "true" },
                  { questionNo: 25, status: "true" },
                ],
                totalCorrectAnswer: 3,
                totalWrongAnswer: 1,
                totalEmptyAnswer: 0,
                succesRatio: 75,
                generalSuccesRatio: 75,
              },
              {
                gainId: 1,
                gainText:
                  "Okuduğu metnin içeriğine uygun başlık/başlıklar belirler.",
                questions: [
                  { questionNo: 13, status: "true" },
                  { questionNo: 25, status: "warning" },
                  { questionNo: 26, status: "true" },
                  { questionNo: 30, status: "true" },
                ],
                totalCorrectAnswer: 3,
                totalWrongAnswer: 1,
                totalEmptyAnswer: 0,
                succesRatio: 100,
                generalSuccesRatio: 75,
              },
              {
                gainId: 1,
                gainText:
                  "Görsellerden ve başlıktan hareketle okuyacağı metnin konusunu tahmin eder.",
                questions: [
                  { questionNo: 3, status: "true" },
                  { questionNo: 5, status: "true" },
                  { questionNo: 6, status: "false" },
                  { questionNo: 12, status: "true" },
                ],
                totalCorrectAnswer: 3,
                totalWrongAnswer: 1,
                totalEmptyAnswer: 0,
                succesRatio: 25,
                generalSuccesRatio: 75,
              },
              {
                gainId: 1,
                gainText:
                  "Metinde ele alınan sorunlara farklı çözümler üretir.",
                questions: [
                  { questionNo: 3, status: "true" },
                  { questionNo: 5, status: "true" },
                  { questionNo: 6, status: "false" },
                  { questionNo: 12, status: "true" },
                ],
                totalCorrectAnswer: 3,
                totalWrongAnswer: 1,
                totalEmptyAnswer: 0,
                succesRatio: 75,
                generalSuccesRatio: 75,
              },
              {
                gainId: 1,
                gainText: "Konuşmacının sözlü olmayan mesajlarını kavrar.",
                questions: [
                  { questionNo: 3, status: "true" },
                  { questionNo: 5, status: "true" },
                  { questionNo: 6, status: "false" },
                  { questionNo: 12, status: "true" },
                ],
                totalCorrectAnswer: 3,
                totalWrongAnswer: 1,
                totalEmptyAnswer: 0,
                succesRatio: 75,
                generalSuccesRatio: 75,
              },
              {
                gainId: 1,
                gainText:
                  "Dinlediklerinde/izlediklerinde geçen olayların gelişimi ve sonucu hakkında tahminde bulunur.",
                questions: [
                  { questionNo: 3, status: "true" },
                  { questionNo: 5, status: "true" },
                  { questionNo: 6, status: "false" },
                  { questionNo: 12, status: "true" },
                ],
                totalCorrectAnswer: 3,
                totalWrongAnswer: 1,
                totalEmptyAnswer: 0,
                succesRatio: 75,
                generalSuccesRatio: 75,
              },
              {
                gainId: 1,
                gainText:
                  "Farklı yazı karakterleri ile yazılmış yazıları okur.	",
                questions: [
                  { questionNo: 3, status: "true" },
                  { questionNo: 5, status: "true" },
                  { questionNo: 6, status: "false" },
                  { questionNo: 12, status: "true" },
                ],
                totalCorrectAnswer: 3,
                totalWrongAnswer: 1,
                totalEmptyAnswer: 0,
                succesRatio: 75,
                generalSuccesRatio: 75,
              },
              {
                gainId: 1,
                gainText: "Hazırlıksız konuşma yapar.	",
                questions: [
                  { questionNo: 3, status: "true" },
                  { questionNo: 5, status: "true" },
                  { questionNo: 6, status: "false" },
                  { questionNo: 12, status: "true" },
                ],
                totalCorrectAnswer: 3,
                totalWrongAnswer: 1,
                totalEmptyAnswer: 0,
                succesRatio: 75,
                generalSuccesRatio: 75,
              },
            ],
          },
          {
            lessonId: 6,
            lessonName: "Din Kültürü ve Ahlak Bil",
            gains: [
              {
                gainId: 1,
                gainText: "Dinleme stratejilerini uygular.	",
                questions: [
                  { questionNo: 3, status: "true" },
                  { questionNo: 1, status: "true" },
                  { questionNo: 7, status: "true" },
                  { questionNo: 15, status: "empty" },
                ],
                totalCorrectAnswer: 3,
                totalWrongAnswer: 1,
                totalEmptyAnswer: 0,
                succesRatio: 75,
                generalSuccesRatio: 75,
              },
              {
                gainId: 1,
                gainText: "Okudukları ile ilgili çıkarımlarda bulunur.	",
                questions: [
                  { questionNo: 1, status: "true" },
                  { questionNo: 3, status: "true" },
                  { questionNo: 6, status: "false" },
                  { questionNo: 18, status: "true" },
                ],
                totalCorrectAnswer: 3,
                totalWrongAnswer: 1,
                totalEmptyAnswer: 0,
                succesRatio: 55,
                generalSuccesRatio: 75,
              },
              {
                gainId: 1,
                gainText: "Bir işin işlem basamaklarını yazar.	",
                questions: [
                  { questionNo: 12, status: "warning" },
                  { questionNo: 15, status: "false" },
                ],
                totalCorrectAnswer: 3,
                totalWrongAnswer: 1,
                totalEmptyAnswer: 0,
                succesRatio: 85,
                generalSuccesRatio: 75,
              },
              {
                gainId: 1,
                gainText: "Metinler arasında karşılaştırma yapar.",
                questions: [
                  { questionNo: 2, status: "true" },
                  { questionNo: 12, status: "true" },
                  { questionNo: 22, status: "true" },
                  { questionNo: 25, status: "true" },
                ],
                totalCorrectAnswer: 3,
                totalWrongAnswer: 1,
                totalEmptyAnswer: 0,
                succesRatio: 75,
                generalSuccesRatio: 75,
              },
              {
                gainId: 1,
                gainText:
                  "Okuduğu metnin içeriğine uygun başlık/başlıklar belirler.",
                questions: [
                  { questionNo: 13, status: "true" },
                  { questionNo: 25, status: "warning" },
                  { questionNo: 26, status: "true" },
                  { questionNo: 30, status: "true" },
                ],
                totalCorrectAnswer: 3,
                totalWrongAnswer: 1,
                totalEmptyAnswer: 0,
                succesRatio: 100,
                generalSuccesRatio: 75,
              },
              {
                gainId: 1,
                gainText:
                  "Görsellerden ve başlıktan hareketle okuyacağı metnin konusunu tahmin eder.",
                questions: [
                  { questionNo: 3, status: "true" },
                  { questionNo: 5, status: "true" },
                  { questionNo: 6, status: "false" },
                  { questionNo: 12, status: "true" },
                ],
                totalCorrectAnswer: 3,
                totalWrongAnswer: 1,
                totalEmptyAnswer: 0,
                succesRatio: 25,
                generalSuccesRatio: 75,
              },
              {
                gainId: 1,
                gainText:
                  "Metinde ele alınan sorunlara farklı çözümler üretir.",
                questions: [
                  { questionNo: 3, status: "true" },
                  { questionNo: 5, status: "true" },
                  { questionNo: 6, status: "false" },
                  { questionNo: 12, status: "true" },
                ],
                totalCorrectAnswer: 3,
                totalWrongAnswer: 1,
                totalEmptyAnswer: 0,
                succesRatio: 75,
                generalSuccesRatio: 75,
              },
              {
                gainId: 1,
                gainText: "Konuşmacının sözlü olmayan mesajlarını kavrar.",
                questions: [
                  { questionNo: 3, status: "true" },
                  { questionNo: 5, status: "true" },
                  { questionNo: 6, status: "false" },
                  { questionNo: 12, status: "true" },
                ],
                totalCorrectAnswer: 3,
                totalWrongAnswer: 1,
                totalEmptyAnswer: 0,
                succesRatio: 75,
                generalSuccesRatio: 75,
              },
              {
                gainId: 1,
                gainText:
                  "Dinlediklerinde/izlediklerinde geçen olayların gelişimi ve sonucu hakkında tahminde bulunur.",
                questions: [
                  { questionNo: 3, status: "true" },
                  { questionNo: 5, status: "true" },
                  { questionNo: 6, status: "false" },
                  { questionNo: 12, status: "true" },
                ],
                totalCorrectAnswer: 3,
                totalWrongAnswer: 1,
                totalEmptyAnswer: 0,
                succesRatio: 75,
                generalSuccesRatio: 75,
              },
              {
                gainId: 1,
                gainText:
                  "Farklı yazı karakterleri ile yazılmış yazıları okur.	",
                questions: [
                  { questionNo: 3, status: "true" },
                  { questionNo: 5, status: "true" },
                  { questionNo: 6, status: "false" },
                  { questionNo: 12, status: "true" },
                ],
                totalCorrectAnswer: 3,
                totalWrongAnswer: 1,
                totalEmptyAnswer: 0,
                succesRatio: 75,
                generalSuccesRatio: 75,
              },
              {
                gainId: 1,
                gainText: "Hazırlıksız konuşma yapar.	",
                questions: [
                  { questionNo: 3, status: "true" },
                  { questionNo: 5, status: "true" },
                  { questionNo: 6, status: "false" },
                  { questionNo: 12, status: "true" },
                ],
                totalCorrectAnswer: 3,
                totalWrongAnswer: 1,
                totalEmptyAnswer: 0,
                succesRatio: 75,
                generalSuccesRatio: 75,
              },
            ],
          },
          {
            lessonId: 10,
            lessonName: "Fizik",
            gains: [
              {
                gainId: 1,
                gainText: "Dinleme stratejilerini uygular.	",
                questions: [
                  { questionNo: 3, status: "true" },
                  { questionNo: 1, status: "true" },
                  { questionNo: 7, status: "true" },
                  { questionNo: 15, status: "empty" },
                ],
                totalCorrectAnswer: 3,
                totalWrongAnswer: 1,
                totalEmptyAnswer: 0,
                succesRatio: 75,
                generalSuccesRatio: 75,
              },
              {
                gainId: 1,
                gainText: "Okudukları ile ilgili çıkarımlarda bulunur.	",
                questions: [
                  { questionNo: 1, status: "true" },
                  { questionNo: 3, status: "true" },
                  { questionNo: 6, status: "false" },
                  { questionNo: 18, status: "true" },
                ],
                totalCorrectAnswer: 3,
                totalWrongAnswer: 1,
                totalEmptyAnswer: 0,
                succesRatio: 55,
                generalSuccesRatio: 75,
              },
              {
                gainId: 1,
                gainText: "Bir işin işlem basamaklarını yazar.	",
                questions: [
                  { questionNo: 12, status: "warning" },
                  { questionNo: 15, status: "false" },
                ],
                totalCorrectAnswer: 3,
                totalWrongAnswer: 1,
                totalEmptyAnswer: 0,
                succesRatio: 85,
                generalSuccesRatio: 75,
              },
              {
                gainId: 1,
                gainText: "Metinler arasında karşılaştırma yapar.",
                questions: [
                  { questionNo: 2, status: "true" },
                  { questionNo: 12, status: "true" },
                  { questionNo: 22, status: "true" },
                  { questionNo: 25, status: "true" },
                ],
                totalCorrectAnswer: 3,
                totalWrongAnswer: 1,
                totalEmptyAnswer: 0,
                succesRatio: 75,
                generalSuccesRatio: 75,
              },
              {
                gainId: 1,
                gainText:
                  "Okuduğu metnin içeriğine uygun başlık/başlıklar belirler.",
                questions: [
                  { questionNo: 13, status: "true" },
                  { questionNo: 25, status: "warning" },
                  { questionNo: 26, status: "true" },
                  { questionNo: 30, status: "true" },
                ],
                totalCorrectAnswer: 3,
                totalWrongAnswer: 1,
                totalEmptyAnswer: 0,
                succesRatio: 100,
                generalSuccesRatio: 75,
              },
              {
                gainId: 1,
                gainText:
                  "Görsellerden ve başlıktan hareketle okuyacağı metnin konusunu tahmin eder.",
                questions: [
                  { questionNo: 3, status: "true" },
                  { questionNo: 5, status: "true" },
                  { questionNo: 6, status: "false" },
                  { questionNo: 12, status: "true" },
                ],
                totalCorrectAnswer: 3,
                totalWrongAnswer: 1,
                totalEmptyAnswer: 0,
                succesRatio: 25,
                generalSuccesRatio: 75,
              },
              {
                gainId: 1,
                gainText:
                  "Metinde ele alınan sorunlara farklı çözümler üretir.",
                questions: [
                  { questionNo: 3, status: "true" },
                  { questionNo: 5, status: "true" },
                  { questionNo: 6, status: "false" },
                  { questionNo: 12, status: "true" },
                ],
                totalCorrectAnswer: 3,
                totalWrongAnswer: 1,
                totalEmptyAnswer: 0,
                succesRatio: 75,
                generalSuccesRatio: 75,
              },
              {
                gainId: 1,
                gainText: "Konuşmacının sözlü olmayan mesajlarını kavrar.",
                questions: [
                  { questionNo: 3, status: "true" },
                  { questionNo: 5, status: "true" },
                  { questionNo: 6, status: "false" },
                  { questionNo: 12, status: "true" },
                ],
                totalCorrectAnswer: 3,
                totalWrongAnswer: 1,
                totalEmptyAnswer: 0,
                succesRatio: 75,
                generalSuccesRatio: 75,
              },
              {
                gainId: 1,
                gainText:
                  "Dinlediklerinde/izlediklerinde geçen olayların gelişimi ve sonucu hakkında tahminde bulunur.",
                questions: [
                  { questionNo: 3, status: "true" },
                  { questionNo: 5, status: "true" },
                  { questionNo: 6, status: "false" },
                  { questionNo: 12, status: "true" },
                ],
                totalCorrectAnswer: 3,
                totalWrongAnswer: 1,
                totalEmptyAnswer: 0,
                succesRatio: 75,
                generalSuccesRatio: 75,
              },
              {
                gainId: 1,
                gainText:
                  "Farklı yazı karakterleri ile yazılmış yazıları okur.	",
                questions: [
                  { questionNo: 3, status: "true" },
                  { questionNo: 5, status: "true" },
                  { questionNo: 6, status: "false" },
                  { questionNo: 12, status: "true" },
                ],
                totalCorrectAnswer: 3,
                totalWrongAnswer: 1,
                totalEmptyAnswer: 0,
                succesRatio: 75,
                generalSuccesRatio: 75,
              },
              {
                gainId: 1,
                gainText: "Hazırlıksız konuşma yapar.	",
                questions: [
                  { questionNo: 3, status: "true" },
                  { questionNo: 5, status: "true" },
                  { questionNo: 6, status: "false" },
                  { questionNo: 12, status: "true" },
                ],
                totalCorrectAnswer: 3,
                totalWrongAnswer: 1,
                totalEmptyAnswer: 0,
                succesRatio: 75,
                generalSuccesRatio: 75,
              },
            ],
          },
          {
            lessonId: 11,
            lessonName: "Kimya",
            gains: [
              {
                gainId: 1,
                gainText: "Dinleme stratejilerini uygular.	",
                questions: [
                  { questionNo: 3, status: "true" },
                  { questionNo: 1, status: "true" },
                  { questionNo: 7, status: "true" },
                  { questionNo: 15, status: "empty" },
                ],
                totalCorrectAnswer: 3,
                totalWrongAnswer: 1,
                totalEmptyAnswer: 0,
                succesRatio: 75,
                generalSuccesRatio: 75,
              },
              {
                gainId: 1,
                gainText: "Okudukları ile ilgili çıkarımlarda bulunur.	",
                questions: [
                  { questionNo: 1, status: "true" },
                  { questionNo: 3, status: "true" },
                  { questionNo: 6, status: "false" },
                  { questionNo: 18, status: "true" },
                ],
                totalCorrectAnswer: 3,
                totalWrongAnswer: 1,
                totalEmptyAnswer: 0,
                succesRatio: 55,
                generalSuccesRatio: 75,
              },
              {
                gainId: 1,
                gainText: "Bir işin işlem basamaklarını yazar.	",
                questions: [
                  { questionNo: 12, status: "warning" },
                  { questionNo: 15, status: "false" },
                ],
                totalCorrectAnswer: 3,
                totalWrongAnswer: 1,
                totalEmptyAnswer: 0,
                succesRatio: 85,
                generalSuccesRatio: 75,
              },
              {
                gainId: 1,
                gainText: "Metinler arasında karşılaştırma yapar.",
                questions: [
                  { questionNo: 2, status: "true" },
                  { questionNo: 12, status: "true" },
                  { questionNo: 22, status: "true" },
                  { questionNo: 25, status: "true" },
                ],
                totalCorrectAnswer: 3,
                totalWrongAnswer: 1,
                totalEmptyAnswer: 0,
                succesRatio: 75,
                generalSuccesRatio: 75,
              },
              {
                gainId: 1,
                gainText:
                  "Okuduğu metnin içeriğine uygun başlık/başlıklar belirler.",
                questions: [
                  { questionNo: 13, status: "true" },
                  { questionNo: 25, status: "warning" },
                  { questionNo: 26, status: "true" },
                  { questionNo: 30, status: "true" },
                ],
                totalCorrectAnswer: 3,
                totalWrongAnswer: 1,
                totalEmptyAnswer: 0,
                succesRatio: 100,
                generalSuccesRatio: 75,
              },
              {
                gainId: 1,
                gainText:
                  "Görsellerden ve başlıktan hareketle okuyacağı metnin konusunu tahmin eder.",
                questions: [
                  { questionNo: 3, status: "true" },
                  { questionNo: 5, status: "true" },
                  { questionNo: 6, status: "false" },
                  { questionNo: 12, status: "true" },
                ],
                totalCorrectAnswer: 3,
                totalWrongAnswer: 1,
                totalEmptyAnswer: 0,
                succesRatio: 25,
                generalSuccesRatio: 75,
              },
              {
                gainId: 1,
                gainText:
                  "Metinde ele alınan sorunlara farklı çözümler üretir.",
                questions: [
                  { questionNo: 3, status: "true" },
                  { questionNo: 5, status: "true" },
                  { questionNo: 6, status: "false" },
                  { questionNo: 12, status: "true" },
                ],
                totalCorrectAnswer: 3,
                totalWrongAnswer: 1,
                totalEmptyAnswer: 0,
                succesRatio: 75,
                generalSuccesRatio: 75,
              },
              {
                gainId: 1,
                gainText: "Konuşmacının sözlü olmayan mesajlarını kavrar.",
                questions: [
                  { questionNo: 3, status: "true" },
                  { questionNo: 5, status: "true" },
                  { questionNo: 6, status: "false" },
                  { questionNo: 12, status: "true" },
                ],
                totalCorrectAnswer: 3,
                totalWrongAnswer: 1,
                totalEmptyAnswer: 0,
                succesRatio: 75,
                generalSuccesRatio: 75,
              },
              {
                gainId: 1,
                gainText:
                  "Dinlediklerinde/izlediklerinde geçen olayların gelişimi ve sonucu hakkında tahminde bulunur.",
                questions: [
                  { questionNo: 3, status: "true" },
                  { questionNo: 5, status: "true" },
                  { questionNo: 6, status: "false" },
                  { questionNo: 12, status: "true" },
                ],
                totalCorrectAnswer: 3,
                totalWrongAnswer: 1,
                totalEmptyAnswer: 0,
                succesRatio: 75,
                generalSuccesRatio: 75,
              },
              {
                gainId: 1,
                gainText:
                  "Farklı yazı karakterleri ile yazılmış yazıları okur.	",
                questions: [
                  { questionNo: 3, status: "true" },
                  { questionNo: 5, status: "true" },
                  { questionNo: 6, status: "false" },
                  { questionNo: 12, status: "true" },
                ],
                totalCorrectAnswer: 3,
                totalWrongAnswer: 1,
                totalEmptyAnswer: 0,
                succesRatio: 75,
                generalSuccesRatio: 75,
              },
              {
                gainId: 1,
                gainText: "Hazırlıksız konuşma yapar.	",
                questions: [
                  { questionNo: 3, status: "true" },
                  { questionNo: 5, status: "true" },
                  { questionNo: 6, status: "false" },
                  { questionNo: 12, status: "true" },
                ],
                totalCorrectAnswer: 3,
                totalWrongAnswer: 1,
                totalEmptyAnswer: 0,
                succesRatio: 75,
                generalSuccesRatio: 75,
              },
            ],
          },
          {
            lessonId: 12,
            lessonName: "Biyoloji",
            gains: [
              {
                gainId: 1,
                gainText: "Dinleme stratejilerini uygular.	",
                questions: [
                  { questionNo: 3, status: "true" },
                  { questionNo: 1, status: "true" },
                  { questionNo: 7, status: "true" },
                  { questionNo: 15, status: "empty" },
                ],
                totalCorrectAnswer: 3,
                totalWrongAnswer: 1,
                totalEmptyAnswer: 0,
                succesRatio: 75,
                generalSuccesRatio: 75,
              },
              {
                gainId: 1,
                gainText: "Okudukları ile ilgili çıkarımlarda bulunur.	",
                questions: [
                  { questionNo: 1, status: "true" },
                  { questionNo: 3, status: "true" },
                  { questionNo: 6, status: "false" },
                  { questionNo: 18, status: "true" },
                ],
                totalCorrectAnswer: 3,
                totalWrongAnswer: 1,
                totalEmptyAnswer: 0,
                succesRatio: 55,
                generalSuccesRatio: 75,
              },
              {
                gainId: 1,
                gainText: "Bir işin işlem basamaklarını yazar.	",
                questions: [
                  { questionNo: 12, status: "warning" },
                  { questionNo: 15, status: "false" },
                ],
                totalCorrectAnswer: 3,
                totalWrongAnswer: 1,
                totalEmptyAnswer: 0,
                succesRatio: 85,
                generalSuccesRatio: 75,
              },
              {
                gainId: 1,
                gainText: "Metinler arasında karşılaştırma yapar.",
                questions: [
                  { questionNo: 2, status: "true" },
                  { questionNo: 12, status: "true" },
                  { questionNo: 22, status: "true" },
                  { questionNo: 25, status: "true" },
                ],
                totalCorrectAnswer: 3,
                totalWrongAnswer: 1,
                totalEmptyAnswer: 0,
                succesRatio: 75,
                generalSuccesRatio: 75,
              },
              {
                gainId: 1,
                gainText:
                  "Okuduğu metnin içeriğine uygun başlık/başlıklar belirler.",
                questions: [
                  { questionNo: 13, status: "true" },
                  { questionNo: 25, status: "warning" },
                  { questionNo: 26, status: "true" },
                  { questionNo: 30, status: "true" },
                ],
                totalCorrectAnswer: 3,
                totalWrongAnswer: 1,
                totalEmptyAnswer: 0,
                succesRatio: 100,
                generalSuccesRatio: 75,
              },
              {
                gainId: 1,
                gainText:
                  "Görsellerden ve başlıktan hareketle okuyacağı metnin konusunu tahmin eder.",
                questions: [
                  { questionNo: 3, status: "true" },
                  { questionNo: 5, status: "true" },
                  { questionNo: 6, status: "false" },
                  { questionNo: 12, status: "true" },
                ],
                totalCorrectAnswer: 3,
                totalWrongAnswer: 1,
                totalEmptyAnswer: 0,
                succesRatio: 25,
                generalSuccesRatio: 75,
              },
              {
                gainId: 1,
                gainText:
                  "Metinde ele alınan sorunlara farklı çözümler üretir.",
                questions: [
                  { questionNo: 3, status: "true" },
                  { questionNo: 5, status: "true" },
                  { questionNo: 6, status: "false" },
                  { questionNo: 12, status: "true" },
                ],
                totalCorrectAnswer: 3,
                totalWrongAnswer: 1,
                totalEmptyAnswer: 0,
                succesRatio: 75,
                generalSuccesRatio: 75,
              },
              {
                gainId: 1,
                gainText: "Konuşmacının sözlü olmayan mesajlarını kavrar.",
                questions: [
                  { questionNo: 3, status: "true" },
                  { questionNo: 5, status: "true" },
                  { questionNo: 6, status: "false" },
                  { questionNo: 12, status: "true" },
                ],
                totalCorrectAnswer: 3,
                totalWrongAnswer: 1,
                totalEmptyAnswer: 0,
                succesRatio: 75,
                generalSuccesRatio: 75,
              },
              {
                gainId: 1,
                gainText:
                  "Dinlediklerinde/izlediklerinde geçen olayların gelişimi ve sonucu hakkında tahminde bulunur.",
                questions: [
                  { questionNo: 3, status: "true" },
                  { questionNo: 5, status: "true" },
                  { questionNo: 6, status: "false" },
                  { questionNo: 12, status: "true" },
                ],
                totalCorrectAnswer: 3,
                totalWrongAnswer: 1,
                totalEmptyAnswer: 0,
                succesRatio: 75,
                generalSuccesRatio: 75,
              },
              {
                gainId: 1,
                gainText:
                  "Farklı yazı karakterleri ile yazılmış yazıları okur.	",
                questions: [
                  { questionNo: 3, status: "true" },
                  { questionNo: 5, status: "true" },
                  { questionNo: 6, status: "false" },
                  { questionNo: 12, status: "true" },
                ],
                totalCorrectAnswer: 3,
                totalWrongAnswer: 1,
                totalEmptyAnswer: 0,
                succesRatio: 75,
                generalSuccesRatio: 75,
              },
              {
                gainId: 1,
                gainText: "Hazırlıksız konuşma yapar.	",
                questions: [
                  { questionNo: 3, status: "true" },
                  { questionNo: 5, status: "true" },
                  { questionNo: 6, status: "false" },
                  { questionNo: 12, status: "true" },
                ],
                totalCorrectAnswer: 3,
                totalWrongAnswer: 1,
                totalEmptyAnswer: 0,
                succesRatio: 75,
                generalSuccesRatio: 75,
              },
            ],
          },
        ],
      },
      {
        resultId: 2,
        examId: 2,
        resultDate: "01.02.2022",
        examName: "TYT 2. Deneme Sınavı",
        scores: [
          {
            scoreName: "TYT",
            scoreTypeId: 1,
            score: 290.75,
          },
        ],
        lessonStats: [
          {
            SectionId: 1,
            SectionName: "Türkçe",
            LessonName: "Türkçe",
            LessonId: 1,
            TotalQuestion: 30,
            CorrectAnswer: 25,
            WrongAnswer: 4,
            EmptyAnswer: 1,
            Net: 24,
            UserAnswers: [
              {
                questionNo: 1,
                questionAnswer: "A",
                studentAnswer: "A",
              },
              {
                questionNo: 2,
                questionAnswer: "B",
                studentAnswer: "B",
              },
              {
                questionNo: 3,
                questionAnswer: "C",
                studentAnswer: "C",
              },
              {
                questionNo: 4,
                questionAnswer: "A",
                studentAnswer: "A",
              },
              {
                questionNo: 5,
                questionAnswer: "B",
                studentAnswer: "B",
              },
              {
                questionNo: 6,
                questionAnswer: "D",
                studentAnswer: "D",
              },
              {
                questionNo: 7,
                questionAnswer: "E",
                studentAnswer: "E",
              },
              {
                questionNo: 8,
                questionAnswer: "A",
                studentAnswer: "B",
              },
              {
                questionNo: 9,
                questionAnswer: "B",
                studentAnswer: "B",
              },
              {
                questionNo: 10,
                questionAnswer: "A",
                studentAnswer: "",
              },
              {
                questionNo: 11,
                questionAnswer: "A",
                studentAnswer: "A",
              },
              {
                questionNo: 12,
                questionAnswer: "A",
                studentAnswer: "A",
              },
              {
                questionNo: 13,
                questionAnswer: "C",
                studentAnswer: "C",
              },
              {
                questionNo: 14,
                questionAnswer: "D",
                studentAnswer: "D",
              },
              {
                questionNo: 15,
                questionAnswer: "E",
                studentAnswer: "E",
              },
              {
                questionNo: 16,
                questionAnswer: "D",
                studentAnswer: "D",
              },
              {
                questionNo: 17,
                questionAnswer: "E",
                studentAnswer: "E",
              },
              {
                questionNo: 18,
                questionAnswer: "A",
                studentAnswer: "A",
              },
              {
                questionNo: 19,
                questionAnswer: "A",
                studentAnswer: "A",
              },
              {
                questionNo: 20,
                questionAnswer: "C",
                studentAnswer: "C",
              },
              {
                questionNo: 21,
                questionAnswer: "C",
                studentAnswer: "B",
              },
              {
                questionNo: 22,
                questionAnswer: "A",
                studentAnswer: "A",
              },
              {
                questionNo: 23,
                questionAnswer: "E",
                studentAnswer: "E",
              },
              {
                questionNo: 24,
                questionAnswer: "E",
                studentAnswer: "E",
              },
              {
                questionNo: 25,
                questionAnswer: "C",
                studentAnswer: "C",
              },
              {
                questionNo: 26,
                questionAnswer: "D",
                studentAnswer: "D",
              },
              {
                questionNo: 27,
                questionAnswer: "A",
                studentAnswer: "A",
              },
              {
                questionNo: 28,
                questionAnswer: "B",
                studentAnswer: "E",
              },
              {
                questionNo: 29,
                questionAnswer: "A",
                studentAnswer: "A",
              },
              {
                questionNo: 30,
                questionAnswer: "C",
                studentAnswer: "D",
              },
            ],
          },
          {
            SectionId: 2,
            SectionName: "Matematik",
            LessonName: "Matematik",
            LessonId: 1,
            TotalQuestion: 30,
            CorrectAnswer: 25,
            WrongAnswer: 4,
            EmptyAnswer: 1,
            Net: 24,
            UserAnswers: [
              {
                questionNo: 1,
                questionAnswer: "A",
                studentAnswer: "A",
              },
              {
                questionNo: 2,
                questionAnswer: "B",
                studentAnswer: "B",
              },
              {
                questionNo: 3,
                questionAnswer: "C",
                studentAnswer: "C",
              },
              {
                questionNo: 4,
                questionAnswer: "A",
                studentAnswer: "A",
              },
              {
                questionNo: 5,
                questionAnswer: "B",
                studentAnswer: "B",
              },
              {
                questionNo: 6,
                questionAnswer: "D",
                studentAnswer: "D",
              },
              {
                questionNo: 7,
                questionAnswer: "E",
                studentAnswer: "E",
              },
              {
                questionNo: 8,
                questionAnswer: "A",
                studentAnswer: "B",
              },
              {
                questionNo: 9,
                questionAnswer: "B",
                studentAnswer: "B",
              },
              {
                questionNo: 10,
                questionAnswer: "A",
                studentAnswer: "",
              },
              {
                questionNo: 11,
                questionAnswer: "A",
                studentAnswer: "A",
              },
              {
                questionNo: 12,
                questionAnswer: "A",
                studentAnswer: "A",
              },
              {
                questionNo: 13,
                questionAnswer: "C",
                studentAnswer: "C",
              },
              {
                questionNo: 14,
                questionAnswer: "D",
                studentAnswer: "D",
              },
              {
                questionNo: 15,
                questionAnswer: "E",
                studentAnswer: "E",
              },
              {
                questionNo: 16,
                questionAnswer: "D",
                studentAnswer: "D",
              },
              {
                questionNo: 17,
                questionAnswer: "E",
                studentAnswer: "E",
              },
              {
                questionNo: 18,
                questionAnswer: "A",
                studentAnswer: "A",
              },
              {
                questionNo: 19,
                questionAnswer: "A",
                studentAnswer: "A",
              },
              {
                questionNo: 20,
                questionAnswer: "C",
                studentAnswer: "C",
              },
              {
                questionNo: 21,
                questionAnswer: "C",
                studentAnswer: "B",
              },
              {
                questionNo: 22,
                questionAnswer: "A",
                studentAnswer: "A",
              },
              {
                questionNo: 23,
                questionAnswer: "E",
                studentAnswer: "E",
              },
              {
                questionNo: 24,
                questionAnswer: "E",
                studentAnswer: "E",
              },
              {
                questionNo: 25,
                questionAnswer: "C",
                studentAnswer: "C",
              },
              {
                questionNo: 26,
                questionAnswer: "D",
                studentAnswer: "D",
              },
              {
                questionNo: 27,
                questionAnswer: "A",
                studentAnswer: "A",
              },
              {
                questionNo: 28,
                questionAnswer: "B",
                studentAnswer: "E",
              },
              {
                questionNo: 29,
                questionAnswer: "A",
                studentAnswer: "A",
              },
              {
                questionNo: 30,
                questionAnswer: "C",
                studentAnswer: "D",
              },
            ],
          },
          {
            SectionId: 3,
            SectionName: "Sosyal Bilimler",
            LessonName: "Tarih",
            LessonId: 1,
            TotalQuestion: 5,
            CorrectAnswer: 4,
            WrongAnswer: 1,
            EmptyAnswer: 0,
            Net: 11.5,
            UserAnswers: [
              {
                questionNo: 1,
                questionAnswer: "A",
                studentAnswer: "A",
              },
              {
                questionNo: 2,
                questionAnswer: "B",
                studentAnswer: "B",
              },
              {
                questionNo: 3,
                questionAnswer: "C",
                studentAnswer: "E",
              },
              {
                questionNo: 4,
                questionAnswer: "A",
                studentAnswer: "A",
              },
              {
                questionNo: 5,
                questionAnswer: "B",
                studentAnswer: "B",
              },
            ],
          },
          {
            SectionId: 3,
            SectionName: "Sosyal Bilimler",
            LessonName: "Coğrafya",
            LessonId: 1,
            TotalQuestion: 5,
            CorrectAnswer: 4,
            WrongAnswer: 1,
            EmptyAnswer: 0,
            Net: 3.75,
            UserAnswers: [
              {
                questionNo: 1,
                questionAnswer: "A",
                studentAnswer: "c",
              },
              {
                questionNo: 2,
                questionAnswer: "B",
                studentAnswer: "B",
              },
              {
                questionNo: 3,
                questionAnswer: "C",
                studentAnswer: "E",
              },
              {
                questionNo: 4,
                questionAnswer: "A",
                studentAnswer: "A",
              },
              {
                questionNo: 5,
                questionAnswer: "B",
                studentAnswer: "B",
              },
            ],
          },
          {
            SectionId: 3,
            SectionName: "Sosyal Bilimler",
            LessonName: "Felsefe",
            LessonId: 1,
            TotalQuestion: 5,
            CorrectAnswer: 5,
            WrongAnswer: 0,
            EmptyAnswer: 0,
            Net: 5,
            UserAnswers: [
              {
                questionNo: 1,
                questionAnswer: "A",
                studentAnswer: "A",
              },
              {
                questionNo: 2,
                questionAnswer: "B",
                studentAnswer: "B",
              },
              {
                questionNo: 3,
                questionAnswer: "C",
                studentAnswer: "E",
              },
              {
                questionNo: 4,
                questionAnswer: "A",
                studentAnswer: "A",
              },
              {
                questionNo: 5,
                questionAnswer: "B",
                studentAnswer: "B",
              },
            ],
          },
          {
            SectionId: 3,
            SectionName: "Sosyal Bilimler",
            LessonName: "Din Kültürü ve Ahlak Bilgisi",
            LessonId: 1,
            TotalQuestion: 5,
            CorrectAnswer: 4,
            WrongAnswer: 0,
            EmptyAnswer: 1,
            Net: 4,
            UserAnswers: [
              {
                questionNo: 1,
                questionAnswer: "A",
                studentAnswer: "A",
              },
              {
                questionNo: 2,
                questionAnswer: "B",
                studentAnswer: "B",
              },
              {
                questionNo: 3,
                questionAnswer: "C",
                studentAnswer: "",
              },
              {
                questionNo: 4,
                questionAnswer: "A",
                studentAnswer: "A",
              },
              {
                questionNo: 5,
                questionAnswer: "B",
                studentAnswer: "B",
              },
            ],
          },
          {
            SectionId: 4,
            SectionName: "Fen Bilimleri",
            LessonName: "Fizik",
            LessonId: 1,
            TotalQuestion: 7,
            CorrectAnswer: 7,
            WrongAnswer: 0,
            EmptyAnswer: 0,
            Net: 7,
            UserAnswers: [
              {
                questionNo: 1,
                questionAnswer: "A",
                studentAnswer: "A",
              },
              {
                questionNo: 2,
                questionAnswer: "B",
                studentAnswer: "B",
              },
              {
                questionNo: 3,
                questionAnswer: "C",
                studentAnswer: "",
              },
              {
                questionNo: 4,
                questionAnswer: "A",
                studentAnswer: "A",
              },
              {
                questionNo: 5,
                questionAnswer: "B",
                studentAnswer: "B",
              },
              {
                questionNo: 6,
                questionAnswer: "B",
                studentAnswer: "B",
              },
              {
                questionNo: 7,
                questionAnswer: "B",
                studentAnswer: "B",
              },
            ],
          },
          {
            SectionId: 4,
            SectionName: "Fen Bilimleri",
            LessonName: "Kimya",
            LessonId: 1,
            TotalQuestion: 7,
            CorrectAnswer: 7,
            WrongAnswer: 0,
            EmptyAnswer: 0,
            Net: 7,
            UserAnswers: [
              {
                questionNo: 1,
                questionAnswer: "A",
                studentAnswer: "A",
              },
              {
                questionNo: 2,
                questionAnswer: "B",
                studentAnswer: "B",
              },
              {
                questionNo: 3,
                questionAnswer: "C",
                studentAnswer: "",
              },
              {
                questionNo: 4,
                questionAnswer: "A",
                studentAnswer: "A",
              },
              {
                questionNo: 5,
                questionAnswer: "B",
                studentAnswer: "B",
              },
              {
                questionNo: 6,
                questionAnswer: "B",
                studentAnswer: "B",
              },
              {
                questionNo: 7,
                questionAnswer: "B",
                studentAnswer: "B",
              },
            ],
          },
          {
            SectionId: 4,
            SectionName: "Fen Bilimleri",
            LessonName: "Biyoloji",
            LessonId: 1,
            TotalQuestion: 7,
            CorrectAnswer: 7,
            WrongAnswer: 0,
            EmptyAnswer: 0,
            Net: 7,
            UserAnswers: [
              {
                questionNo: 1,
                questionAnswer: "A",
                studentAnswer: "A",
              },
              {
                questionNo: 2,
                questionAnswer: "B",
                studentAnswer: "B",
              },
              {
                questionNo: 3,
                questionAnswer: "C",
                studentAnswer: "",
              },
              {
                questionNo: 4,
                questionAnswer: "A",
                studentAnswer: "A",
              },
              {
                questionNo: 5,
                questionAnswer: "B",
                studentAnswer: "B",
              },
              {
                questionNo: 6,
                questionAnswer: "B",
                studentAnswer: "B",
              },
              {
                questionNo: 7,
                questionAnswer: "B",
                studentAnswer: "B",
              },
            ],
          },
        ],
      },
      {
        resultId: 3,
        examId: 3,
        resultDate: "01.03.2022",
        examName: "TYT 3. Deneme Sınavı",
        scores: [
          {
            scoreName: "TYT",
            scoreTypeId: 1,
            score: 285.75,
          },
        ],
        lessonStats: [
          {
            SectionId: 1,
            SectionName: "Türkçe",
            LessonName: "Türkçe",
            LessonId: 1,
            TotalQuestion: 30,
            CorrectAnswer: 25,
            WrongAnswer: 4,
            EmptyAnswer: 1,
            Net: 24,
            UserAnswers: [
              {
                questionNo: 1,
                questionAnswer: "A",
                studentAnswer: "A",
              },
              {
                questionNo: 2,
                questionAnswer: "B",
                studentAnswer: "B",
              },
              {
                questionNo: 3,
                questionAnswer: "C",
                studentAnswer: "C",
              },
              {
                questionNo: 4,
                questionAnswer: "A",
                studentAnswer: "A",
              },
              {
                questionNo: 5,
                questionAnswer: "B",
                studentAnswer: "B",
              },
              {
                questionNo: 6,
                questionAnswer: "D",
                studentAnswer: "D",
              },
              {
                questionNo: 7,
                questionAnswer: "E",
                studentAnswer: "E",
              },
              {
                questionNo: 8,
                questionAnswer: "A",
                studentAnswer: "B",
              },
              {
                questionNo: 9,
                questionAnswer: "B",
                studentAnswer: "B",
              },
              {
                questionNo: 10,
                questionAnswer: "A",
                studentAnswer: "",
              },
              {
                questionNo: 11,
                questionAnswer: "A",
                studentAnswer: "A",
              },
              {
                questionNo: 12,
                questionAnswer: "A",
                studentAnswer: "A",
              },
              {
                questionNo: 13,
                questionAnswer: "C",
                studentAnswer: "C",
              },
              {
                questionNo: 14,
                questionAnswer: "D",
                studentAnswer: "D",
              },
              {
                questionNo: 15,
                questionAnswer: "E",
                studentAnswer: "E",
              },
              {
                questionNo: 16,
                questionAnswer: "D",
                studentAnswer: "D",
              },
              {
                questionNo: 17,
                questionAnswer: "E",
                studentAnswer: "E",
              },
              {
                questionNo: 18,
                questionAnswer: "A",
                studentAnswer: "A",
              },
              {
                questionNo: 19,
                questionAnswer: "A",
                studentAnswer: "A",
              },
              {
                questionNo: 20,
                questionAnswer: "C",
                studentAnswer: "C",
              },
              {
                questionNo: 21,
                questionAnswer: "C",
                studentAnswer: "B",
              },
              {
                questionNo: 22,
                questionAnswer: "A",
                studentAnswer: "A",
              },
              {
                questionNo: 23,
                questionAnswer: "E",
                studentAnswer: "E",
              },
              {
                questionNo: 24,
                questionAnswer: "E",
                studentAnswer: "E",
              },
              {
                questionNo: 25,
                questionAnswer: "C",
                studentAnswer: "C",
              },
              {
                questionNo: 26,
                questionAnswer: "D",
                studentAnswer: "D",
              },
              {
                questionNo: 27,
                questionAnswer: "A",
                studentAnswer: "A",
              },
              {
                questionNo: 28,
                questionAnswer: "B",
                studentAnswer: "E",
              },
              {
                questionNo: 29,
                questionAnswer: "A",
                studentAnswer: "A",
              },
              {
                questionNo: 30,
                questionAnswer: "C",
                studentAnswer: "D",
              },
            ],
          },
          {
            SectionId: 2,
            SectionName: "Matematik",
            LessonName: "Matematik",
            LessonId: 1,
            TotalQuestion: 30,
            CorrectAnswer: 25,
            WrongAnswer: 4,
            EmptyAnswer: 1,
            Net: 24,
            UserAnswers: [
              {
                questionNo: 1,
                questionAnswer: "A",
                studentAnswer: "A",
              },
              {
                questionNo: 2,
                questionAnswer: "B",
                studentAnswer: "B",
              },
              {
                questionNo: 3,
                questionAnswer: "C",
                studentAnswer: "C",
              },
              {
                questionNo: 4,
                questionAnswer: "A",
                studentAnswer: "A",
              },
              {
                questionNo: 5,
                questionAnswer: "B",
                studentAnswer: "B",
              },
              {
                questionNo: 6,
                questionAnswer: "D",
                studentAnswer: "D",
              },
              {
                questionNo: 7,
                questionAnswer: "E",
                studentAnswer: "E",
              },
              {
                questionNo: 8,
                questionAnswer: "A",
                studentAnswer: "B",
              },
              {
                questionNo: 9,
                questionAnswer: "B",
                studentAnswer: "B",
              },
              {
                questionNo: 10,
                questionAnswer: "A",
                studentAnswer: "",
              },
              {
                questionNo: 11,
                questionAnswer: "A",
                studentAnswer: "A",
              },
              {
                questionNo: 12,
                questionAnswer: "A",
                studentAnswer: "A",
              },
              {
                questionNo: 13,
                questionAnswer: "C",
                studentAnswer: "C",
              },
              {
                questionNo: 14,
                questionAnswer: "D",
                studentAnswer: "D",
              },
              {
                questionNo: 15,
                questionAnswer: "E",
                studentAnswer: "E",
              },
              {
                questionNo: 16,
                questionAnswer: "D",
                studentAnswer: "D",
              },
              {
                questionNo: 17,
                questionAnswer: "E",
                studentAnswer: "E",
              },
              {
                questionNo: 18,
                questionAnswer: "A",
                studentAnswer: "A",
              },
              {
                questionNo: 19,
                questionAnswer: "A",
                studentAnswer: "A",
              },
              {
                questionNo: 20,
                questionAnswer: "C",
                studentAnswer: "C",
              },
              {
                questionNo: 21,
                questionAnswer: "C",
                studentAnswer: "B",
              },
              {
                questionNo: 22,
                questionAnswer: "A",
                studentAnswer: "A",
              },
              {
                questionNo: 23,
                questionAnswer: "E",
                studentAnswer: "E",
              },
              {
                questionNo: 24,
                questionAnswer: "E",
                studentAnswer: "E",
              },
              {
                questionNo: 25,
                questionAnswer: "C",
                studentAnswer: "C",
              },
              {
                questionNo: 26,
                questionAnswer: "D",
                studentAnswer: "D",
              },
              {
                questionNo: 27,
                questionAnswer: "A",
                studentAnswer: "A",
              },
              {
                questionNo: 28,
                questionAnswer: "B",
                studentAnswer: "E",
              },
              {
                questionNo: 29,
                questionAnswer: "A",
                studentAnswer: "A",
              },
              {
                questionNo: 30,
                questionAnswer: "C",
                studentAnswer: "D",
              },
            ],
          },
          {
            SectionId: 3,
            SectionName: "Sosyal Bilimler",
            LessonName: "Tarih",
            LessonId: 1,
            TotalQuestion: 5,
            CorrectAnswer: 4,
            WrongAnswer: 1,
            EmptyAnswer: 0,
            Net: 11.5,
            UserAnswers: [
              {
                questionNo: 1,
                questionAnswer: "A",
                studentAnswer: "A",
              },
              {
                questionNo: 2,
                questionAnswer: "B",
                studentAnswer: "B",
              },
              {
                questionNo: 3,
                questionAnswer: "C",
                studentAnswer: "E",
              },
              {
                questionNo: 4,
                questionAnswer: "A",
                studentAnswer: "A",
              },
              {
                questionNo: 5,
                questionAnswer: "B",
                studentAnswer: "B",
              },
            ],
          },
          {
            SectionId: 3,
            SectionName: "Sosyal Bilimler",
            LessonName: "Coğrafya",
            LessonId: 1,
            TotalQuestion: 5,
            CorrectAnswer: 4,
            WrongAnswer: 1,
            EmptyAnswer: 0,
            Net: 3.75,
            UserAnswers: [
              {
                questionNo: 1,
                questionAnswer: "A",
                studentAnswer: "c",
              },
              {
                questionNo: 2,
                questionAnswer: "B",
                studentAnswer: "B",
              },
              {
                questionNo: 3,
                questionAnswer: "C",
                studentAnswer: "E",
              },
              {
                questionNo: 4,
                questionAnswer: "A",
                studentAnswer: "A",
              },
              {
                questionNo: 5,
                questionAnswer: "B",
                studentAnswer: "B",
              },
            ],
          },
          {
            SectionId: 3,
            SectionName: "Sosyal Bilimler",
            LessonName: "Felsefe",
            LessonId: 1,
            TotalQuestion: 5,
            CorrectAnswer: 5,
            WrongAnswer: 0,
            EmptyAnswer: 0,
            Net: 5,
            UserAnswers: [
              {
                questionNo: 1,
                questionAnswer: "A",
                studentAnswer: "A",
              },
              {
                questionNo: 2,
                questionAnswer: "B",
                studentAnswer: "B",
              },
              {
                questionNo: 3,
                questionAnswer: "C",
                studentAnswer: "E",
              },
              {
                questionNo: 4,
                questionAnswer: "A",
                studentAnswer: "A",
              },
              {
                questionNo: 5,
                questionAnswer: "B",
                studentAnswer: "B",
              },
            ],
          },
          {
            SectionId: 3,
            SectionName: "Sosyal Bilimler",
            LessonName: "Din Kültürü ve Ahlak Bilgisi",
            LessonId: 1,
            TotalQuestion: 5,
            CorrectAnswer: 4,
            WrongAnswer: 0,
            EmptyAnswer: 1,
            Net: 4,
            UserAnswers: [
              {
                questionNo: 1,
                questionAnswer: "A",
                studentAnswer: "A",
              },
              {
                questionNo: 2,
                questionAnswer: "B",
                studentAnswer: "B",
              },
              {
                questionNo: 3,
                questionAnswer: "C",
                studentAnswer: "",
              },
              {
                questionNo: 4,
                questionAnswer: "A",
                studentAnswer: "A",
              },
              {
                questionNo: 5,
                questionAnswer: "B",
                studentAnswer: "B",
              },
            ],
          },
          {
            SectionId: 4,
            SectionName: "Fen Bilimleri",
            LessonName: "Fizik",
            LessonId: 1,
            TotalQuestion: 7,
            CorrectAnswer: 7,
            WrongAnswer: 0,
            EmptyAnswer: 0,
            Net: 7,
            UserAnswers: [
              {
                questionNo: 1,
                questionAnswer: "A",
                studentAnswer: "A",
              },
              {
                questionNo: 2,
                questionAnswer: "B",
                studentAnswer: "B",
              },
              {
                questionNo: 3,
                questionAnswer: "C",
                studentAnswer: "",
              },
              {
                questionNo: 4,
                questionAnswer: "A",
                studentAnswer: "A",
              },
              {
                questionNo: 5,
                questionAnswer: "B",
                studentAnswer: "B",
              },
              {
                questionNo: 6,
                questionAnswer: "B",
                studentAnswer: "B",
              },
              {
                questionNo: 7,
                questionAnswer: "B",
                studentAnswer: "B",
              },
            ],
          },
          {
            SectionId: 4,
            SectionName: "Fen Bilimleri",
            LessonName: "Kimya",
            LessonId: 1,
            TotalQuestion: 7,
            CorrectAnswer: 7,
            WrongAnswer: 0,
            EmptyAnswer: 0,
            Net: 7,
            UserAnswers: [
              {
                questionNo: 1,
                questionAnswer: "A",
                studentAnswer: "A",
              },
              {
                questionNo: 2,
                questionAnswer: "B",
                studentAnswer: "B",
              },
              {
                questionNo: 3,
                questionAnswer: "C",
                studentAnswer: "",
              },
              {
                questionNo: 4,
                questionAnswer: "A",
                studentAnswer: "A",
              },
              {
                questionNo: 5,
                questionAnswer: "B",
                studentAnswer: "B",
              },
              {
                questionNo: 6,
                questionAnswer: "B",
                studentAnswer: "B",
              },
              {
                questionNo: 7,
                questionAnswer: "B",
                studentAnswer: "B",
              },
            ],
          },
          {
            SectionId: 4,
            SectionName: "Fen Bilimleri",
            LessonName: "Biyoloji",
            LessonId: 1,
            TotalQuestion: 7,
            CorrectAnswer: 7,
            WrongAnswer: 0,
            EmptyAnswer: 0,
            Net: 7,
            UserAnswers: [
              {
                questionNo: 1,
                questionAnswer: "A",
                studentAnswer: "A",
              },
              {
                questionNo: 2,
                questionAnswer: "B",
                studentAnswer: "B",
              },
              {
                questionNo: 3,
                questionAnswer: "C",
                studentAnswer: "",
              },
              {
                questionNo: 4,
                questionAnswer: "A",
                studentAnswer: "A",
              },
              {
                questionNo: 5,
                questionAnswer: "B",
                studentAnswer: "B",
              },
              {
                questionNo: 6,
                questionAnswer: "B",
                studentAnswer: "B",
              },
              {
                questionNo: 7,
                questionAnswer: "B",
                studentAnswer: "B",
              },
            ],
          },
        ],
      },
      {
        resultId: 4,
        examId: 4,
        resultDate: "24.04.2022",
        examName: "TYT 3. Deneme Sınavı",
        scores: [
          {
            scoreName: "TYT",
            scoreTypeId: 1,
            score: 295.75,
          },
        ],
        lessonStats: [
          {
            SectionId: 1,
            SectionName: "Türkçe",
            LessonName: "Türkçe",
            LessonId: 1,
            TotalQuestion: 30,
            CorrectAnswer: 25,
            WrongAnswer: 4,
            EmptyAnswer: 1,
            Net: 24,
            UserAnswers: [
              {
                questionNo: 1,
                questionAnswer: "A",
                studentAnswer: "A",
              },
              {
                questionNo: 2,
                questionAnswer: "B",
                studentAnswer: "B",
              },
              {
                questionNo: 3,
                questionAnswer: "C",
                studentAnswer: "C",
              },
              {
                questionNo: 4,
                questionAnswer: "A",
                studentAnswer: "A",
              },
              {
                questionNo: 5,
                questionAnswer: "B",
                studentAnswer: "B",
              },
              {
                questionNo: 6,
                questionAnswer: "D",
                studentAnswer: "D",
              },
              {
                questionNo: 7,
                questionAnswer: "E",
                studentAnswer: "E",
              },
              {
                questionNo: 8,
                questionAnswer: "A",
                studentAnswer: "B",
              },
              {
                questionNo: 9,
                questionAnswer: "B",
                studentAnswer: "B",
              },
              {
                questionNo: 10,
                questionAnswer: "A",
                studentAnswer: "",
              },
              {
                questionNo: 11,
                questionAnswer: "A",
                studentAnswer: "A",
              },
              {
                questionNo: 12,
                questionAnswer: "A",
                studentAnswer: "A",
              },
              {
                questionNo: 13,
                questionAnswer: "C",
                studentAnswer: "C",
              },
              {
                questionNo: 14,
                questionAnswer: "D",
                studentAnswer: "D",
              },
              {
                questionNo: 15,
                questionAnswer: "E",
                studentAnswer: "E",
              },
              {
                questionNo: 16,
                questionAnswer: "D",
                studentAnswer: "D",
              },
              {
                questionNo: 17,
                questionAnswer: "E",
                studentAnswer: "E",
              },
              {
                questionNo: 18,
                questionAnswer: "A",
                studentAnswer: "A",
              },
              {
                questionNo: 19,
                questionAnswer: "A",
                studentAnswer: "A",
              },
              {
                questionNo: 20,
                questionAnswer: "C",
                studentAnswer: "C",
              },
              {
                questionNo: 21,
                questionAnswer: "C",
                studentAnswer: "B",
              },
              {
                questionNo: 22,
                questionAnswer: "A",
                studentAnswer: "A",
              },
              {
                questionNo: 23,
                questionAnswer: "E",
                studentAnswer: "E",
              },
              {
                questionNo: 24,
                questionAnswer: "E",
                studentAnswer: "E",
              },
              {
                questionNo: 25,
                questionAnswer: "C",
                studentAnswer: "C",
              },
              {
                questionNo: 26,
                questionAnswer: "D",
                studentAnswer: "D",
              },
              {
                questionNo: 27,
                questionAnswer: "A",
                studentAnswer: "A",
              },
              {
                questionNo: 28,
                questionAnswer: "B",
                studentAnswer: "E",
              },
              {
                questionNo: 29,
                questionAnswer: "A",
                studentAnswer: "A",
              },
              {
                questionNo: 30,
                questionAnswer: "C",
                studentAnswer: "D",
              },
            ],
          },
          {
            SectionId: 2,
            SectionName: "Matematik",
            LessonName: "Matematik",
            LessonId: 1,
            TotalQuestion: 30,
            CorrectAnswer: 25,
            WrongAnswer: 4,
            EmptyAnswer: 1,
            Net: 24,
            UserAnswers: [
              {
                questionNo: 1,
                questionAnswer: "A",
                studentAnswer: "A",
              },
              {
                questionNo: 2,
                questionAnswer: "B",
                studentAnswer: "B",
              },
              {
                questionNo: 3,
                questionAnswer: "C",
                studentAnswer: "C",
              },
              {
                questionNo: 4,
                questionAnswer: "A",
                studentAnswer: "A",
              },
              {
                questionNo: 5,
                questionAnswer: "B",
                studentAnswer: "B",
              },
              {
                questionNo: 6,
                questionAnswer: "D",
                studentAnswer: "D",
              },
              {
                questionNo: 7,
                questionAnswer: "E",
                studentAnswer: "E",
              },
              {
                questionNo: 8,
                questionAnswer: "A",
                studentAnswer: "B",
              },
              {
                questionNo: 9,
                questionAnswer: "B",
                studentAnswer: "B",
              },
              {
                questionNo: 10,
                questionAnswer: "A",
                studentAnswer: "",
              },
              {
                questionNo: 11,
                questionAnswer: "A",
                studentAnswer: "A",
              },
              {
                questionNo: 12,
                questionAnswer: "A",
                studentAnswer: "A",
              },
              {
                questionNo: 13,
                questionAnswer: "C",
                studentAnswer: "C",
              },
              {
                questionNo: 14,
                questionAnswer: "D",
                studentAnswer: "D",
              },
              {
                questionNo: 15,
                questionAnswer: "E",
                studentAnswer: "E",
              },
              {
                questionNo: 16,
                questionAnswer: "D",
                studentAnswer: "D",
              },
              {
                questionNo: 17,
                questionAnswer: "E",
                studentAnswer: "E",
              },
              {
                questionNo: 18,
                questionAnswer: "A",
                studentAnswer: "A",
              },
              {
                questionNo: 19,
                questionAnswer: "A",
                studentAnswer: "A",
              },
              {
                questionNo: 20,
                questionAnswer: "C",
                studentAnswer: "C",
              },
              {
                questionNo: 21,
                questionAnswer: "C",
                studentAnswer: "B",
              },
              {
                questionNo: 22,
                questionAnswer: "A",
                studentAnswer: "A",
              },
              {
                questionNo: 23,
                questionAnswer: "E",
                studentAnswer: "E",
              },
              {
                questionNo: 24,
                questionAnswer: "E",
                studentAnswer: "E",
              },
              {
                questionNo: 25,
                questionAnswer: "C",
                studentAnswer: "C",
              },
              {
                questionNo: 26,
                questionAnswer: "D",
                studentAnswer: "D",
              },
              {
                questionNo: 27,
                questionAnswer: "A",
                studentAnswer: "A",
              },
              {
                questionNo: 28,
                questionAnswer: "B",
                studentAnswer: "E",
              },
              {
                questionNo: 29,
                questionAnswer: "A",
                studentAnswer: "A",
              },
              {
                questionNo: 30,
                questionAnswer: "C",
                studentAnswer: "D",
              },
            ],
          },
          {
            SectionId: 3,
            SectionName: "Sosyal Bilimler",
            LessonName: "Tarih",
            LessonId: 1,
            TotalQuestion: 5,
            CorrectAnswer: 4,
            WrongAnswer: 1,
            EmptyAnswer: 0,
            Net: 11.5,
            UserAnswers: [
              {
                questionNo: 1,
                questionAnswer: "A",
                studentAnswer: "A",
              },
              {
                questionNo: 2,
                questionAnswer: "B",
                studentAnswer: "B",
              },
              {
                questionNo: 3,
                questionAnswer: "C",
                studentAnswer: "E",
              },
              {
                questionNo: 4,
                questionAnswer: "A",
                studentAnswer: "A",
              },
              {
                questionNo: 5,
                questionAnswer: "B",
                studentAnswer: "B",
              },
            ],
          },
          {
            SectionId: 3,
            SectionName: "Sosyal Bilimler",
            LessonName: "Coğrafya",
            LessonId: 1,
            TotalQuestion: 5,
            CorrectAnswer: 4,
            WrongAnswer: 1,
            EmptyAnswer: 0,
            Net: 3.75,
            UserAnswers: [
              {
                questionNo: 1,
                questionAnswer: "A",
                studentAnswer: "c",
              },
              {
                questionNo: 2,
                questionAnswer: "B",
                studentAnswer: "B",
              },
              {
                questionNo: 3,
                questionAnswer: "C",
                studentAnswer: "E",
              },
              {
                questionNo: 4,
                questionAnswer: "A",
                studentAnswer: "A",
              },
              {
                questionNo: 5,
                questionAnswer: "B",
                studentAnswer: "B",
              },
            ],
          },
          {
            SectionId: 3,
            SectionName: "Sosyal Bilimler",
            LessonName: "Felsefe",
            LessonId: 1,
            TotalQuestion: 5,
            CorrectAnswer: 5,
            WrongAnswer: 0,
            EmptyAnswer: 0,
            Net: 5,
            UserAnswers: [
              {
                questionNo: 1,
                questionAnswer: "A",
                studentAnswer: "A",
              },
              {
                questionNo: 2,
                questionAnswer: "B",
                studentAnswer: "B",
              },
              {
                questionNo: 3,
                questionAnswer: "C",
                studentAnswer: "E",
              },
              {
                questionNo: 4,
                questionAnswer: "A",
                studentAnswer: "A",
              },
              {
                questionNo: 5,
                questionAnswer: "B",
                studentAnswer: "B",
              },
            ],
          },
          {
            SectionId: 3,
            SectionName: "Sosyal Bilimler",
            LessonName: "Din Kültürü ve Ahlak Bilgisi",
            LessonId: 1,
            TotalQuestion: 5,
            CorrectAnswer: 4,
            WrongAnswer: 0,
            EmptyAnswer: 1,
            Net: 4,
            UserAnswers: [
              {
                questionNo: 1,
                questionAnswer: "A",
                studentAnswer: "A",
              },
              {
                questionNo: 2,
                questionAnswer: "B",
                studentAnswer: "B",
              },
              {
                questionNo: 3,
                questionAnswer: "C",
                studentAnswer: "",
              },
              {
                questionNo: 4,
                questionAnswer: "A",
                studentAnswer: "A",
              },
              {
                questionNo: 5,
                questionAnswer: "B",
                studentAnswer: "B",
              },
            ],
          },
          {
            SectionId: 4,
            SectionName: "Fen Bilimleri",
            LessonName: "Fizik",
            LessonId: 1,
            TotalQuestion: 7,
            CorrectAnswer: 7,
            WrongAnswer: 0,
            EmptyAnswer: 0,
            Net: 7,
            UserAnswers: [
              {
                questionNo: 1,
                questionAnswer: "A",
                studentAnswer: "A",
              },
              {
                questionNo: 2,
                questionAnswer: "B",
                studentAnswer: "B",
              },
              {
                questionNo: 3,
                questionAnswer: "C",
                studentAnswer: "",
              },
              {
                questionNo: 4,
                questionAnswer: "A",
                studentAnswer: "A",
              },
              {
                questionNo: 5,
                questionAnswer: "B",
                studentAnswer: "B",
              },
              {
                questionNo: 6,
                questionAnswer: "B",
                studentAnswer: "B",
              },
              {
                questionNo: 7,
                questionAnswer: "B",
                studentAnswer: "B",
              },
            ],
          },
          {
            SectionId: 4,
            SectionName: "Fen Bilimleri",
            LessonName: "Kimya",
            LessonId: 1,
            TotalQuestion: 7,
            CorrectAnswer: 7,
            WrongAnswer: 0,
            EmptyAnswer: 0,
            Net: 7,
            UserAnswers: [
              {
                questionNo: 1,
                questionAnswer: "A",
                studentAnswer: "A",
              },
              {
                questionNo: 2,
                questionAnswer: "B",
                studentAnswer: "B",
              },
              {
                questionNo: 3,
                questionAnswer: "C",
                studentAnswer: "",
              },
              {
                questionNo: 4,
                questionAnswer: "A",
                studentAnswer: "A",
              },
              {
                questionNo: 5,
                questionAnswer: "B",
                studentAnswer: "B",
              },
              {
                questionNo: 6,
                questionAnswer: "B",
                studentAnswer: "B",
              },
              {
                questionNo: 7,
                questionAnswer: "B",
                studentAnswer: "B",
              },
            ],
          },
          {
            SectionId: 4,
            SectionName: "Fen Bilimleri",
            LessonName: "Biyoloji",
            LessonId: 1,
            TotalQuestion: 7,
            CorrectAnswer: 7,
            WrongAnswer: 0,
            EmptyAnswer: 0,
            Net: 7,
            UserAnswers: [
              {
                questionNo: 1,
                questionAnswer: "A",
                studentAnswer: "A",
              },
              {
                questionNo: 2,
                questionAnswer: "B",
                studentAnswer: "B",
              },
              {
                questionNo: 3,
                questionAnswer: "C",
                studentAnswer: "",
              },
              {
                questionNo: 4,
                questionAnswer: "A",
                studentAnswer: "A",
              },
              {
                questionNo: 5,
                questionAnswer: "B",
                studentAnswer: "B",
              },
              {
                questionNo: 6,
                questionAnswer: "B",
                studentAnswer: "B",
              },
              {
                questionNo: 7,
                questionAnswer: "B",
                studentAnswer: "B",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    examCategoryId: 2,
    examCategoryName: "AYT",
    examResults: [
      {
        resultId: 5,
        resultDate: "24.01.2022",
        examName: "AYT 1. Deneme Sınavı",
        scores: [
          {
            scoreName: "Say",
            scoreTypeId: 2,
            score: 295.75,
          },
          {
            scoreName: "Söz",
            scoreTypeId: 3,
            score: 295.75,
          },
          {
            scoreName: "EA",
            scoreTypeId: 4,
            score: 295.75,
          },
        ],
        lessonStats: [],
      },
      {
        resultId: 6,
        resultDate: "22.02.2022",
        examName: "AYT 2. Deneme Sınavı",
        scores: [
          {
            scoreName: "Say",
            scoreTypeId: 2,
            score: 295.75,
          },
          {
            scoreName: "Söz",
            scoreTypeId: 3,
            score: 295.75,
          },
          {
            scoreName: "EA",
            scoreTypeId: 4,
            score: 295.75,
          },
        ],
        lessonStats: [],
      },
      {
        resultId: 7,
        resultDate: "21.03.2022",
        examName: "AYT 3. Deneme Sınavı",
        scores: [
          {
            scoreName: "Say",
            scoreTypeId: 2,
            score: 295.75,
          },
          {
            scoreName: "Söz",
            scoreTypeId: 3,
            score: 295.75,
          },
          {
            scoreName: "EA",
            scoreTypeId: 4,
            score: 295.75,
          },
        ],
        lessonStats: [],
      },
    ],
  },
];

// ------------------------------------------------
// GET: Return books
// ------------------------------------------------
mock.onGet("/apps/examReports/getExamResults").reply((config) => {
  // eslint-disable-next-line object-curly-newline
  return [
    200,
    {
      examResults: data,
    },
  ];
});

mock.onGet(/\/apps\/examReports\/getExamResult\/\d+/).reply((config) => {
  let resultId = config.url.substring(config.url.lastIndexOf("/") + 1);
  const examResults = data.map((aa) => aa.examResults);
  const merged = [].concat.apply([], examResults);

  const filteredData = merged.filter(
    (result) => result.resultId === parseInt(resultId)
  )[0];

  return [
    200,
    {
      examResult: filteredData,
    },
  ];
});
