// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "axios"

export const getBook = createAsyncThunk("smartBook/getBook", async (params) => {
  const response = await axios.get("/apps/smartBook/getbookbyId", { params })
  return response.data
})

export const smartBookSlice = createSlice({
  name: "smartBook",
  initialState: {
    pages: [],
    currentPageNumber: 1,
    currentPage: null,
    currentPageItem: null,
    showPageItem: true,
    bookName: "",
    currentTool: "pencil",
    currentLineColor: "#0000FF",
    currentThickness: 5
  },
  reducers: {
    setTool: (state, action) => {
      state.currentTool = action.payload
    },
    setLineColor: (state, action) => {
      state.currentLineColor = action.payload
    },
    setThickness: (state, action) => {
      state.currentThickness = action.payload
    },
    showPageItem: (state, action) => {
      state.showPageItem = false
      const pageItem = state.currentPage.pageItems.filter(
        (item) => item.id === action.payload
      )[0]
      state.currentPageItem = pageItem
    },
    nextPage: (state) => {
      if (state.showPageItem) {
        const bookPage = state.pages.find(
          (page) => page.pageNumber === state.currentPage.pageNumber + 1
        )
        state.currentPage = bookPage
      } else {
        //Soru-Boşluk Doldurma alıştırmarları
        const pageItemIndex = state.currentPage.pageItems.findIndex(
          (item) => item.id === state.currentPageItem.id
        )
        if (state.currentPage.pageItems[pageItemIndex + 1]) {
          state.currentPageItem =
            state.currentPage.pageItems[pageItemIndex + 1]
        } else {
          const bookPage = state.pages.find(
            (page) => page.pageNumber === state.currentPage.pageNumber + 1
          )
          state.currentPage = bookPage
          state.currentPageItem = bookPage.pageItems[0]
        }
      }
    },
    previousPage: (state) => {
      if (state.showPageItem) {
      const bookPage = state.pages.find(
        (page) => page.pageNumber === state.currentPage.pageNumber - 1
      )
      state.currentPage = bookPage
      } else {
          //Soru-Boşluk Doldurma alıştırmarları
          const pageItemIndex = state.currentPage.pageItems.findIndex(
            (item) => item.id === state.currentPageItem.id
          )
          if (state.currentPage.pageItems[pageItemIndex - 1]) {
            state.currentPageItem =
              state.currentPage.pageItems[pageItemIndex - 1]
          } else {
            const bookPage = state.pages.find(
              (page) => page.pageNumber === state.currentPage.pageNumber - 1
            )
            state.currentPage = bookPage
            state.currentPageItem = bookPage.pageItems[pageItemIndex - 1]
          }
      }
    },
    closeTestScreen: (state) => {
      state.showPageItem = true
      state.currentPageItem = null

    }
  },
  extraReducers: (builder) => {
    builder.addCase(getBook.fulfilled, (state, action) => {
      state.bookName = action.payload.name
      state.pages = action.payload.pages
      state.currentPage = action.payload.pages[0]
    })
  }
})
export const { nextPage, previousPage, showPageItem, setTool, setLineColor, setThickness, closeTestScreen } =
  smartBookSlice.actions
export default smartBookSlice.reducer
