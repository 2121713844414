// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "axios"

export const getExamReports = createAsyncThunk(
  "tryingExamReports/getExamReports",
  async (params) => {
    const response = await axios.get("/apps/examReports/getExamResults", { params })
    return response.data
  }
)
export const getExamReport = createAsyncThunk("tryingExamReports/getExamReport", async slug => {
  const response = await axios.get(`/apps/examReports/getExamResult/${slug}`)
  return response.data
})

export const appReportSlice = createSlice({
  name: "tryingExamReports",
  initialState: {
    examReports: [],
    examReport: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getExamReports.fulfilled, (state, action) => {
        state.examReports = action.payload.examResults
      })
      .addCase(getExamReport.fulfilled, (state, action) => {
        state.examReport = action.payload.examResult
      })
  }
})

export default appReportSlice.reducer
