// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "axios"

export const getTopics = createAsyncThunk(
  "reports/getTopics",
  async (params) => {
    const response = await axios.get("/apps/topicReports/getTopics", {
      params
    })
    return response.data
  }
)

export const getTopicDetail = createAsyncThunk(
  "reports/getTopicDetail",
  async (params) => {
    const response = await axios.get(`/apps/topicReports/getTopicDetails`, {
      params
    })
    return response.data
  }
)

export const appReportSlice = createSlice({
  name: "reports",
  initialState: {
    topics: [],
    topicDetails: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTopics.fulfilled, (state, action) => {
        state.topics = action.payload.lessonTopics
      })
      .addCase(getTopicDetail.fulfilled, (state, action) => {
        state.topicDetails = action.payload.topicDetails
      })
  }
})

export default appReportSlice.reducer
