import mock from '../mock'
/* eslint-disable */
// ** Utils
import { paginateArray, sortCompare } from '../utils'

const data = {
  books:[
    {
      "id": 2,
      "name": "TYT Türkçe PSF Palme Soru Fasikülü ",
      "cover": "https://kurumsal.palmeyayinevi.com/Media/UserData/kapak/tyt/04_06_2020_13_28_49{Turkce}.png",
      "description": "\"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.\"",
      "class": "TYT - AYT",
      "lesson": "Türkçe",
      "brand": "Palme Yayınevi",
      "bookContent": [
        {
          "id": 419,
          "name": "Sözcük ve Söz Öbeğinde Anlam",
          "total": 94,
          "completed": 79,
          "tests": [
            {
              "id":1,
              "name": "Söz Öbeğinde Testi 1",
            },
            {
              "id":2,
              "name": "Söz Öbeğinde Testi 2",
            },
          ]
        },
        {
          "id": 420,
          "name": "Cümlede Anlam",
          "total": 87,
          "completed": 42,
          "tests": [
            {
              "id":3,
              "name": "Cümlede Anlam Testi 1",
            },
            {
              "id":4,
              "name": "Cümlede Anlam Testi 2",
            },
          ]
        },
        {
          "id": 421,
          "name": "Anlatım Biçimleri - Düşünceyi Geliştirme Yolları / Anlatımın Temel Nitelikleri",
          "total": 93,
          "completed": 57
        },
        {
          "id": 422,
          "name": "Paragrafta Yapı ",
          "total": 99,
          "completed": 42
        },
        {
          "id": 423,
          "name": "Paragrafta Düşünce (Ana Düşünce - Yardımcı Düşünce)",
          "total": 94,
          "completed": 78
        },
        {
          "id": 424,
          "name": "Ses Bilgisi - Biçim Bilgisi",
          "total": 93,
          "completed": 36
        },
        {
          "id": 425,
          "name": "Yazım Kuralları",
          "total": 82,
          "completed": 52
        },
        {
          "id": 426,
          "name": "Noktalama İşaretleri",
          "total": 94,
          "completed": 57
        },
        {
          "id": 427,
          "name": "İsim - Sıfat - İsim ve Sıfat Tamlaması - Zamir",
          "total": 84,
          "completed": 70
        },
        {
          "id": 428,
          "name": "Zarf - Edat - Bağlaç - Ünlem",
          "total": 88,
          "completed": 61
        },
        {
          "id": 429,
          "name": "Fiil I (Kip - Kişi - Zaman / Ek Fiil)",
          "total": 98,
          "completed": 26
        },
        {
          "id": 430,
          "name": "Fiil II (Fiilde Yapı / Fiilde Çatı / Fiilimsi)",
          "total": 95,
          "completed": 33
        },
        {
          "id": 431,
          "name": "Cümlenin Ögeleri / Cümle Türleri",
          "total": 93,
          "completed": 42
        },
        {
          "id": 432,
          "name": "Anlatım Bozuklukları ",
          "total": 86,
          "completed": 33
        }
      ]
    },
    {
      "id": 6,
      "name": "TYT Türkçe PAF PAlme  Anlatım Föyü ",
      "cover": "https://kurumsal.palmeyayinevi.com/Media/UserData/kapak/tyt/04_06_2020_13_26_45{PafTurkce}.png",
      "description": "\"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.\"",
      "class": "TYT - AYT",
      "lesson": "Türkçe",
      "brand": "Palme Yayınevi",
      "bookContent": [
        {
          "id": 66,
          "name": "PAF 1: Sözcükte ve Söz Öbeğinde Anlam",
          "total": 99,
          "completed": 70
        },
        {
          "id": 67,
          "name": "PAF 2: Cümlenin Anlamı ve Yorumu",
          "total": 83,
          "completed": 55
        },
        {
          "id": 68,
          "name": "PAF 3: Anlatım Biçimleri ve Anlatımın Nitelikleri",
          "total": 83,
          "completed": 58
        },
        {
          "id": 69,
          "name": "PAF 4: Paragrafta Yapı ",
          "total": 97,
          "completed": 49
        },
        {
          "id": 70,
          "name": "PAF 5: Paragrafta Düşünce ",
          "total": 92,
          "completed": 70
        },
        {
          "id": 71,
          "name": "PAF 6: Ses Bilgisi ve Yapı Bilgisi ",
          "total": 88,
          "completed": 56
        },
        {
          "id": 72,
          "name": "PAF 7: Yazım Kuralları ",
          "total": 98,
          "completed": 47
        },
        {
          "id": 73,
          "name": "PAF 8: Noktalama İşaretleri",
          "total": 85,
          "completed": 67
        },
        {
          "id": 74,
          "name": "PAF 9: Sözcük Türleri(İsim-Sıfat-Zamir)",
          "total": 97,
          "completed": 31
        },
        {
          "id": 75,
          "name": "PAF 10: Sözcük Türleri II (Zarf-Edat-Bağlaç-Ünlem)",
          "total": 98,
          "completed": 55
        },
        {
          "id": 76,
          "name": "PAF 11: Fiilde Anlam,Kip,Kişi,Ek Fiil",
          "total": 90,
          "completed": 58
        },
        {
          "id": 77,
          "name": "PAF 12: Fiilde Yapı,Çatı-Fiilimsiler ",
          "total": 84,
          "completed": 71
        },
        {
          "id": 78,
          "name": "PAF 13: Cümlenin Ögeleri-Cümle Türleri",
          "total": 95,
          "completed": 42
        },
        {
          "id": 79,
          "name": "PAF 14: Anlatım Bozukluğu ",
          "total": 90,
          "completed": 42
        }
      ]
    },
    {
      "id": 10,
      "name": "TYT Kimya POT Palme Okul Testi ",
      "cover": "https://kurumsal.palmeyayinevi.com/Media/UserData/kapak/tyt/02_07_2020_11_43_34{tytkimyapot}.png",
      "description": "\"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.\"",
      "class": "TYT - AYT",
      "lesson": "Kimya",
      "brand": "Palme Yayınevi",
      "bookContent": [
        {
          "id": 178,
          "name": "KİMYA TESTLERİ",
          "total": 82,
          "completed": 64,
          "tests": [
            {
              "id":4,
              "name": "Söz Öbeğinde Testi 1",
            },
            {
              "id":5,
              "name": "Söz Öbeğinde Testi 2",
            },
          ]
        },
        {
          "id": 179,
          "name": "Simyadan Kimyaya",
          "total": 98,
          "completed": 74
        },
        {
          "id": 180,
          "name": "Kimyanın Uğraş Alanları",
          "total": 91,
          "completed": 40
        },
        {
          "id": 181,
          "name": "Kimyanın Sembolik Dili",
          "total": 81,
          "completed": 36
        },
        {
          "id": 182,
          "name": "Kimya Uygulamalarında İş Sağlığı ve Güvenliği ",
          "total": 99,
          "completed": 62
        },
        {
          "id": 183,
          "name": "Atom Modelleri",
          "total": 81,
          "completed": 33
        },
        {
          "id": 184,
          "name": "Atomu Oluşturan Temel Tanecikler",
          "total": 93,
          "completed": 58
        },
        {
          "id": 185,
          "name": "İzotop, İzobar, İzoton, İzoelektronik",
          "total": 91,
          "completed": 66
        },
        {
          "id": 186,
          "name": "Periyodik Sistem, Periyodik Sistemde Yer Bulma",
          "total": 92,
          "completed": 42
        },
        {
          "id": 187,
          "name": "Elementlerin Sınıflandırılması ve Özellikleri",
          "total": 89,
          "completed": 74
        },
        {
          "id": 188,
          "name": "Periyodik Özellikler ve Değişimi",
          "total": 89,
          "completed": 65
        },
        {
          "id": 189,
          "name": "Kimyasal Türler, İyonik Bileşikler",
          "total": 82,
          "completed": 78
        },
        {
          "id": 190,
          "name": "İyonik Bağ ve Kovalent Bağ",
          "total": 82,
          "completed": 48
        },
        {
          "id": 191,
          "name": "Kovalent Bağ ve Metalik Bağ",
          "total": 95,
          "completed": 58
        },
        {
          "id": 192,
          "name": "Zayıf Etkileşimler",
          "total": 90,
          "completed": 40
        },
        {
          "id": 193,
          "name": "Zayıf Etkileşimler, Fiziksel ve Kimyasal Değişmeler ",
          "total": 94,
          "completed": 67
        },
        {
          "id": 194,
          "name": "Maddenin Farklı Hâlleri",
          "total": 84,
          "completed": 73
        },
        {
          "id": 195,
          "name": "Katılar",
          "total": 81,
          "completed": 52
        },
        {
          "id": 196,
          "name": "Sıvılar",
          "total": 95,
          "completed": 52
        },
        {
          "id": 197,
          "name": "Hal Değişimi",
          "total": 97,
          "completed": 43
        },
        {
          "id": 198,
          "name": "Su ve Hayat",
          "total": 87,
          "completed": 57
        },
        {
          "id": 199,
          "name": "Çevre Kimyası",
          "total": 81,
          "completed": 60
        },
        {
          "id": 200,
          "name": "Kütlenin Korunumu Kanunu ve Sabit Oranlar Kanunu",
          "total": 84,
          "completed": 58
        },
        {
          "id": 201,
          "name": "Sabit Oranlar Kanunu",
          "total": 93,
          "completed": 74
        },
        {
          "id": 202,
          "name": "Katlı Oranlar Kanunu",
          "total": 97,
          "completed": 62
        },
        {
          "id": 203,
          "name": "Mol Kavramı",
          "total": 83,
          "completed": 68
        },
        {
          "id": 204,
          "name": "Tepkime Denklemleri ve Tepkime Türleri",
          "total": 87,
          "completed": 78
        },
        {
          "id": 205,
          "name": "Denklemli Miktar Geçişine Dayanan Problemler",
          "total": 88,
          "completed": 28
        },
        {
          "id": 206,
          "name": "Artan Madde Problemleri",
          "total": 96,
          "completed": 49
        },
        {
          "id": 207,
          "name": "Kimyasal Tepkimelerin Verimi, Birbirini İzleyen Tepkimelere İlişkin Problemler, Aynı Anda Oluşan Tepkime Problemleri",
          "total": 90,
          "completed": 42
        },
        {
          "id": 208,
          "name": "Homojen Karışımlar",
          "total": 83,
          "completed": 75
        },
        {
          "id": 209,
          "name": "Heterojen Karışımlar",
          "total": 82,
          "completed": 48
        },
        {
          "id": 210,
          "name": "Çözünme Olayı",
          "total": 93,
          "completed": 55
        },
        {
          "id": 211,
          "name": "Çözeltilerde Derişim",
          "total": 81,
          "completed": 36
        },
        {
          "id": 212,
          "name": "Koligatif Özellikler",
          "total": 86,
          "completed": 31
        },
        {
          "id": 213,
          "name": "Ayırma ve Saflaştırma Teknikleri ",
          "total": 85,
          "completed": 72
        },
        {
          "id": 214,
          "name": "Asitleri ve Bazları Tanıyalım",
          "total": 97,
          "completed": 56
        },
        {
          "id": 215,
          "name": "Asitlerin ve Bazların Tepkimeleri",
          "total": 94,
          "completed": 37
        },
        {
          "id": 216,
          "name": "Hayatımızdaki Asitler ve Bazlar",
          "total": 94,
          "completed": 72
        },
        {
          "id": 217,
          "name": "Tuzlar",
          "total": 81,
          "completed": 43
        },
        {
          "id": 218,
          "name": "Temizlik Maddelerinin Özellikleri",
          "total": 99,
          "completed": 33
        },
        {
          "id": 219,
          "name": "Yaygın Polimerler ve Kullanım Alanları",
          "total": 96,
          "completed": 72
        },
        {
          "id": 220,
          "name": "Kozmetikler ve ilaçlar",
          "total": 91,
          "completed": 78
        },
        {
          "id": 221,
          "name": "Gıdalar",
          "total": 86,
          "completed": 30
        }
      ]
    },
    {
      "id": 15,
      "name": "TYT Biyoloji PAF Palme Anlatım Föyü ",
      "cover": "https://kurumsal.palmeyayinevi.com/Media/UserData/kapak/tyt/04_06_2020_09_45_49{b94f4303-d5fd-42e0-b8db-68338c1eb410}.jpg",
      "description": "\"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.\"",
      "class": "TYT - AYT",
      "lesson": "Biyoloji",
      "brand": "Palme Yayınevi",
      "bookContent": [
        {
          "id": 246,
          "name": "PAF 1 Yaşam Bilimi Biyoloji 1",
          "total": 88,
          "completed": 52
        },
        {
          "id": 247,
          "name": "PAF 2 Yaşam Bilimi Biyoloji 2",
          "total": 86,
          "completed": 40
        },
        {
          "id": 248,
          "name": "PAF 3 Hücre 1",
          "total": 84,
          "completed": 26
        },
        {
          "id": 249,
          "name": "PAF 4 Hücre 2",
          "total": 98,
          "completed": 48
        },
        {
          "id": 250,
          "name": "PAF 5 Canlılar Dünyası 1",
          "total": 91,
          "completed": 49
        },
        {
          "id": 251,
          "name": "PAF 6 Canlılar Dünyası 2",
          "total": 94,
          "completed": 58
        },
        {
          "id": 252,
          "name": "PAF 7 Mitoz ve Eşeysiz Üreme",
          "total": 85,
          "completed": 71
        },
        {
          "id": 253,
          "name": "PAF 8 Mitoz ve Eşeyli Üreme",
          "total": 89,
          "completed": 27
        },
        {
          "id": 254,
          "name": "PAF 9 Ekosistem Ekolojisi ve Güncel Çevre Sorunları 1",
          "total": 99,
          "completed": 30
        },
        {
          "id": 255,
          "name": "PAF 10 Ekosistem Ekolojisi ve Güncel Çevre Sorunları 2",
          "total": 82,
          "completed": 60
        },
        {
          "id": 256,
          "name": "PAF 11 Kalıtım 1(Mendel Genetiği ve Uzantıları)",
          "total": 86,
          "completed": 48
        },
        {
          "id": 257,
          "name": "PAF 12 Kalıtım 2(Modern Genetik)",
          "total": 87,
          "completed": 29
        }
      ]
    },
    {
      "id": 16,
      "name": "TYT Coğrafya PAF Palme Anlatım Föyü  ",
      "cover": "https://kurumsal.palmeyayinevi.com/Media/UserData/kapak/tyt/04_06_2020_09_49_56{PafCoğrafya}.png",
      "description": "\"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.\"",
      "class": "TYT - AYT",
      "lesson": "Coğrafya",
      "brand": "Palme Yayınevi",
      "bookContent": [
        {
          "id": 258,
          "name": "PAF 1: Doğa ve İnsan Etkileşimi ",
          "total": 88,
          "completed": 51
        },
        {
          "id": 259,
          "name": "PAF 2: Dünya'nın Şekli ve Hareketleri ",
          "total": 99,
          "completed": 65
        },
        {
          "id": 260,
          "name": "PAF 3: Yer ve Zaman",
          "total": 92,
          "completed": 53
        },
        {
          "id": 261,
          "name": "PAF 4: Harita Okuryazarlığı ",
          "total": 85,
          "completed": 71
        },
        {
          "id": 262,
          "name": "PAF 5: Atmosfer ve İklim",
          "total": 97,
          "completed": 74
        },
        {
          "id": 263,
          "name": "PAF 6: Basınç ve Rüzgarlar ",
          "total": 84,
          "completed": 33
        },
        {
          "id": 264,
          "name": "PAF 7: Nemlik ve Yağışlar ",
          "total": 92,
          "completed": 63
        },
        {
          "id": 265,
          "name": "PAF 8: Yeryüzündeki İklim Tipleri ",
          "total": 93,
          "completed": 46
        },
        {
          "id": 266,
          "name": "PAF 9: Türkiye'de İklim Elemanları ve İklim Tipleri ",
          "total": 85,
          "completed": 50
        },
        {
          "id": 267,
          "name": "PAF 10: Dünya'nın Yapısı ve Oluşum Süreci",
          "total": 85,
          "completed": 72
        },
        {
          "id": 268,
          "name": "PAF 11: Kayaçlar ve Türkiye'de İç Kuvvetler ",
          "total": 99,
          "completed": 35
        },
        {
          "id": 269,
          "name": "PAF 12: Dış Kuvvetler ( Rüzgar-Akarsular-Karstik Şekiller)",
          "total": 91,
          "completed": 75
        },
        {
          "id": 270,
          "name": "PAF 13: Dış Kuvvetler ( Buzullar-Kıyıların Şekillenmesi ve Kıyı Tipleri )",
          "total": 86,
          "completed": 42
        },
        {
          "id": 271,
          "name": "PAF 14: Türkiye'de Dış Kuvvetler ",
          "total": 83,
          "completed": 32
        },
        {
          "id": 272,
          "name": "PAF 15: Su Kaynakları ",
          "total": 81,
          "completed": 26
        },
        {
          "id": 273,
          "name": "PAF 16: Topraklar ve Bitki Örtüsü",
          "total": 82,
          "completed": 74
        },
        {
          "id": 274,
          "name": "PAF 17: Nüfus",
          "total": 99,
          "completed": 75
        },
        {
          "id": 275,
          "name": "PAF 18: Göçler ",
          "total": 86,
          "completed": 70
        },
        {
          "id": 276,
          "name": "PAF 19: Yerleşme",
          "total": 84,
          "completed": 54
        },
        {
          "id": 277,
          "name": "PAF 20: Ekonomik Faaliyetler-Bölgeler",
          "total": 94,
          "completed": 35
        },
        {
          "id": 278,
          "name": "PAF 21: Ulaşım-Doğal Afetler",
          "total": 91,
          "completed": 57
        },
        {
          "id": 279,
          "name": "Cevap Anahtarı ",
          "total": 99,
          "completed": 64
        }
      ]
    },
    {
      "id": 17,
      "name": "TYT Coğrafya POT Palme Okul Testi  ",
      "cover": "https://kurumsal.palmeyayinevi.com/Media/UserData/kapak/tyt/02_07_2020_11_42_05{tytcoğrafyapot}.png",
      "description": "\"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.\"",
      "class": "TYT - AYT",
      "lesson": "Coğrafya",
      "brand": "Palme Yayınevi",
      "bookContent": [
        {
          "id": 280,
          "name": "Coğrafya ",
          "total": 96,
          "completed": 46
        }
      ]
    },
    {
      "id": 18,
      "name": "TYT Coğrafya PSF Palme Soru Föyü",
      "cover": "https://kurumsal.palmeyayinevi.com/Media/UserData/kapak/tyt/04_06_2020_09_50_16{psfcoğrafya}.png",
      "description": "\"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.\"",
      "class": "TYT - AYT",
      "lesson": "Coğrafya",
      "brand": "Palme Yayınevi",
      "bookContent": [
        {
          "id": 281,
          "name": "PSF",
          "total": 91,
          "completed": 50
        },
        {
          "id": 282,
          "name": "PSF 2",
          "total": 93,
          "completed": 39
        },
        {
          "id": 283,
          "name": "PSF 3",
          "total": 99,
          "completed": 65
        },
        {
          "id": 284,
          "name": "PSF 4",
          "total": 92,
          "completed": 70
        },
        {
          "id": 285,
          "name": "PSF 5",
          "total": 96,
          "completed": 60
        },
        {
          "id": 286,
          "name": "PSF 6",
          "total": 90,
          "completed": 79
        },
        {
          "id": 287,
          "name": "PSF 7",
          "total": 97,
          "completed": 43
        },
        {
          "id": 288,
          "name": "PSF 8",
          "total": 92,
          "completed": 60
        },
        {
          "id": 289,
          "name": "PSF 9",
          "total": 98,
          "completed": 43
        },
        {
          "id": 290,
          "name": "PSF 10",
          "total": 94,
          "completed": 48
        },
        {
          "id": 291,
          "name": "PSF 11",
          "total": 83,
          "completed": 31
        },
        {
          "id": 292,
          "name": "PSF 12",
          "total": 81,
          "completed": 33
        },
        {
          "id": 293,
          "name": "PSF 13",
          "total": 88,
          "completed": 43
        },
        {
          "id": 294,
          "name": "PSF 14",
          "total": 81,
          "completed": 67
        }
      ]
    },
    {
      "id": 19,
      "name": "TYT Biyoloji POT Palme Okul Testi ",
      "cover": "https://kurumsal.palmeyayinevi.com/Media/UserData/kapak/tyt/02_07_2020_11_41_40{tytbiyolojipot}.png",
      "description": "\"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.\"",
      "class": "TYT - AYT",
      "lesson": "Biyoloji",
      "brand": "Palme Yayınevi",
      "bookContent": [
        {
          "id": 295,
          "name": "Biyoloji ",
          "total": 99,
          "completed": 54
        }
      ]
    },
    {
      "id": 21,
      "name": "TYT Tarih POT Palme Okul Testi ",
      "cover": "https://kurumsal.palmeyayinevi.com/Media/UserData/kapak/tyt/02_07_2020_11_44_46{tyttarihpot}.png",
      "description": "\"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.\"",
      "class": "TYT - AYT",
      "lesson": "Tarih",
      "brand": "Palme Yayınevi",
      "bookContent": [
        {
          "id": 308,
          "name": "TYT TARİH ",
          "total": 97,
          "completed": 34
        }
      ]
    },
    {
      "id": 57,
      "name": "TYT Kimya PAF Palme Anlatım Föyü ",
      "cover": "https://kurumsal.palmeyayinevi.com/Media/UserData/kapak/tyt/04_06_2020_09_53_02{PAFKimya}.png",
      "description": "\"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.\"",
      "class": "TYT - AYT",
      "lesson": "Kimya",
      "brand": "Palme Yayınevi",
      "bookContent": [
        {
          "id": 342,
          "name": "PAF 1",
          "total": 89,
          "completed": 56
        },
        {
          "id": 343,
          "name": "PAF 2",
          "total": 84,
          "completed": 66
        },
        {
          "id": 344,
          "name": "PAF 3",
          "total": 81,
          "completed": 66
        },
        {
          "id": 345,
          "name": "PAF 4",
          "total": 85,
          "completed": 62
        },
        {
          "id": 346,
          "name": "PAF 5",
          "total": 98,
          "completed": 45
        },
        {
          "id": 347,
          "name": "PAF 6",
          "total": 86,
          "completed": 48
        },
        {
          "id": 348,
          "name": "PAF 7",
          "total": 85,
          "completed": 77
        },
        {
          "id": 349,
          "name": "PAF 8",
          "total": 85,
          "completed": 49
        },
        {
          "id": 350,
          "name": "PAF 9",
          "total": 98,
          "completed": 33
        },
        {
          "id": 351,
          "name": "PAF 10",
          "total": 89,
          "completed": 74
        },
        {
          "id": 352,
          "name": "PAF 11",
          "total": 92,
          "completed": 26
        },
        {
          "id": 353,
          "name": "PAF 12",
          "total": 97,
          "completed": 60
        },
        {
          "id": 354,
          "name": "PAF 13",
          "total": 83,
          "completed": 54
        },
        {
          "id": 355,
          "name": "PAF 14",
          "total": 97,
          "completed": 65
        },
        {
          "id": 356,
          "name": "PAF 15",
          "total": 97,
          "completed": 52
        },
        {
          "id": 357,
          "name": "PAF 16",
          "total": 90,
          "completed": 69
        },
        {
          "id": 358,
          "name": "PAF 17",
          "total": 85,
          "completed": 39
        },
        {
          "id": 359,
          "name": "PAF 18",
          "total": 88,
          "completed": 39
        },
        {
          "id": 360,
          "name": "PAF 19",
          "total": 94,
          "completed": 54
        },
        {
          "id": 361,
          "name": "PAF 20",
          "total": 82,
          "completed": 29
        },
        {
          "id": 362,
          "name": "PAF 21",
          "total": 94,
          "completed": 59
        },
        {
          "id": 363,
          "name": "PAF 22",
          "total": 81,
          "completed": 44
        }
      ]
    },
    {
      "id": 78,
      "name": "TYT Fizik PSF Palme Soru Föyü ",
      "cover": "https://kurumsal.palmeyayinevi.com/Media/UserData/kapak/tyt/04_06_2020_09_51_55{PSFFizik}.png",
      "description": "\"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.\"",
      "class": "TYT - AYT",
      "lesson": "Fizik",
      "brand": "Palme Yayınevi",
      "bookContent": [
        {
          "id": 364,
          "name": "PSF 1",
          "total": 99,
          "completed": 55
        },
        {
          "id": 365,
          "name": "PSF 2",
          "total": 99,
          "completed": 60
        },
        {
          "id": 366,
          "name": "PSF 3",
          "total": 84,
          "completed": 67
        },
        {
          "id": 367,
          "name": "PSF 4",
          "total": 90,
          "completed": 44
        },
        {
          "id": 368,
          "name": "PSF 5",
          "total": 97,
          "completed": 29
        },
        {
          "id": 369,
          "name": "PSF 6",
          "total": 84,
          "completed": 64
        },
        {
          "id": 370,
          "name": "PSF 7",
          "total": 81,
          "completed": 33
        },
        {
          "id": 371,
          "name": "PSF 8",
          "total": 87,
          "completed": 76
        },
        {
          "id": 372,
          "name": "PSF 9",
          "total": 95,
          "completed": 53
        },
        {
          "id": 373,
          "name": "PSF 10",
          "total": 87,
          "completed": 33
        },
        {
          "id": 374,
          "name": "PSF 11",
          "total": 99,
          "completed": 71
        }
      ]
    },
    {
      "id": 79,
      "name": "TYT Fizik POT Palme Okul Testi ",
      "cover": "https://kurumsal.palmeyayinevi.com/Media/UserData/kapak/tyt/02_07_2020_11_42_36{tytfizikpot}.png",
      "description": "\"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.\"",
      "class": "TYT - AYT",
      "lesson": "Fizik",
      "brand": "Palme Yayınevi",
      "bookContent": [
        {
          "id": 375,
          "name": "POT - 1 ",
          "total": 85,
          "completed": 46
        },
        {
          "id": 376,
          "name": "POT - 2 ",
          "total": 93,
          "completed": 56
        },
        {
          "id": 377,
          "name": "POT - 3 ",
          "total": 89,
          "completed": 29
        },
        {
          "id": 378,
          "name": "POT - 4 ",
          "total": 96,
          "completed": 52
        },
        {
          "id": 379,
          "name": "POT - 5 ",
          "total": 93,
          "completed": 42
        },
        {
          "id": 380,
          "name": "POT - 6 ",
          "total": 89,
          "completed": 73
        },
        {
          "id": 381,
          "name": "POT - 7 ",
          "total": 88,
          "completed": 54
        },
        {
          "id": 382,
          "name": "POT - 8 ",
          "total": 92,
          "completed": 63
        },
        {
          "id": 383,
          "name": "POT - 9 ",
          "total": 96,
          "completed": 36
        },
        {
          "id": 384,
          "name": "POT - 10 ",
          "total": 87,
          "completed": 70
        },
        {
          "id": 385,
          "name": "POT - 11 ",
          "total": 91,
          "completed": 60
        },
        {
          "id": 386,
          "name": "POT - 12 ",
          "total": 95,
          "completed": 58
        },
        {
          "id": 387,
          "name": "POT - 13 ",
          "total": 98,
          "completed": 49
        },
        {
          "id": 388,
          "name": "POT - 14 ",
          "total": 99,
          "completed": 65
        },
        {
          "id": 389,
          "name": "POT - 15 ",
          "total": 87,
          "completed": 77
        },
        {
          "id": 390,
          "name": "POT - 16 ",
          "total": 93,
          "completed": 39
        },
        {
          "id": 391,
          "name": "POT - 17 ",
          "total": 92,
          "completed": 75
        },
        {
          "id": 392,
          "name": "POT - 18 ",
          "total": 84,
          "completed": 54
        },
        {
          "id": 393,
          "name": "POT - 19 ",
          "total": 88,
          "completed": 73
        },
        {
          "id": 394,
          "name": "POT - 20 ",
          "total": 89,
          "completed": 42
        },
        {
          "id": 395,
          "name": "POT - 21 ",
          "total": 86,
          "completed": 67
        },
        {
          "id": 396,
          "name": "POT - 22 ",
          "total": 97,
          "completed": 77
        },
        {
          "id": 397,
          "name": "POT - 23 ",
          "total": 96,
          "completed": 69
        },
        {
          "id": 398,
          "name": "POT - 24 ",
          "total": 82,
          "completed": 42
        },
        {
          "id": 399,
          "name": "POT - 25 ",
          "total": 89,
          "completed": 53
        },
        {
          "id": 400,
          "name": "POT - 26 ",
          "total": 82,
          "completed": 72
        },
        {
          "id": 401,
          "name": "POT - 27 ",
          "total": 99,
          "completed": 67
        },
        {
          "id": 402,
          "name": "POT - 28 ",
          "total": 93,
          "completed": 71
        },
        {
          "id": 403,
          "name": "POT - 29 ",
          "total": 85,
          "completed": 41
        },
        {
          "id": 404,
          "name": "POT - 30 ",
          "total": 84,
          "completed": 41
        },
        {
          "id": 405,
          "name": "POT - 31 ",
          "total": 90,
          "completed": 30
        },
        {
          "id": 406,
          "name": "POT - 32 ",
          "total": 83,
          "completed": 60
        },
        {
          "id": 407,
          "name": "POT - 33 ",
          "total": 96,
          "completed": 35
        },
        {
          "id": 408,
          "name": "POT - 34 ",
          "total": 85,
          "completed": 40
        },
        {
          "id": 409,
          "name": "POT - 35 ",
          "total": 92,
          "completed": 47
        },
        {
          "id": 410,
          "name": "POT - 36 ",
          "total": 96,
          "completed": 71
        },
        {
          "id": 411,
          "name": "POT - 37 ",
          "total": 89,
          "completed": 48
        },
        {
          "id": 412,
          "name": "POT - 38 ",
          "total": 84,
          "completed": 54
        },
        {
          "id": 413,
          "name": "POT - 39 ",
          "total": 84,
          "completed": 42
        },
        {
          "id": 414,
          "name": "POT - 40 ",
          "total": 87,
          "completed": 61
        },
        {
          "id": 415,
          "name": "POT - 41 ",
          "total": 94,
          "completed": 48
        },
        {
          "id": 416,
          "name": "POT - 42 ",
          "total": 97,
          "completed": 52
        },
        {
          "id": 417,
          "name": "POT - 43 ",
          "total": 89,
          "completed": 39
        },
        {
          "id": 418,
          "name": "POT - 44 ",
          "total": 94,
          "completed": 75
        }
      ]
    },
    {
      "id": 107,
      "name": "TYT Kimya PSF Palme Soru Föyü ",
      "cover": "https://kurumsal.palmeyayinevi.com/Media/UserData/kapak/tyt/04_06_2020_09_53_25{PSFKimya}.png",
      "description": "\"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.\"",
      "class": "TYT - AYT",
      "lesson": "Kimya",
      "brand": "Palme Yayınevi",
      "bookContent": [
        {
          "id": 433,
          "name": "PSF 1",
          "total": 98,
          "completed": 50
        },
        {
          "id": 434,
          "name": "PSF 2",
          "total": 89,
          "completed": 73
        },
        {
          "id": 435,
          "name": "PSF 3",
          "total": 91,
          "completed": 58
        },
        {
          "id": 436,
          "name": "PSF 4",
          "total": 85,
          "completed": 44
        },
        {
          "id": 437,
          "name": "PSF 5",
          "total": 89,
          "completed": 52
        },
        {
          "id": 438,
          "name": "PSF 6",
          "total": 95,
          "completed": 28
        },
        {
          "id": 439,
          "name": "PSF 7",
          "total": 98,
          "completed": 30
        },
        {
          "id": 440,
          "name": "PSF 8",
          "total": 96,
          "completed": 61
        },
        {
          "id": 441,
          "name": "PSF 9",
          "total": 88,
          "completed": 46
        },
        {
          "id": 442,
          "name": "PSF 10",
          "total": 82,
          "completed": 70
        },
        {
          "id": 443,
          "name": "PSF 11",
          "total": 90,
          "completed": 75
        },
        {
          "id": 444,
          "name": "PSF 12",
          "total": 85,
          "completed": 75
        },
        {
          "id": 445,
          "name": "PSF 13",
          "total": 90,
          "completed": 40
        },
        {
          "id": 446,
          "name": "PSF 14",
          "total": 83,
          "completed": 45
        },
        {
          "id": 447,
          "name": "PSF 15",
          "total": 98,
          "completed": 57
        },
        {
          "id": 448,
          "name": "PSF 16",
          "total": 85,
          "completed": 60
        },
        {
          "id": 449,
          "name": "PSF 17",
          "total": 96,
          "completed": 42
        },
        {
          "id": 450,
          "name": "PSF 18",
          "total": 84,
          "completed": 63
        },
        {
          "id": 451,
          "name": "PSF 19",
          "total": 81,
          "completed": 46
        },
        {
          "id": 452,
          "name": "PSF 20",
          "total": 83,
          "completed": 52
        },
        {
          "id": 453,
          "name": "PSF 21",
          "total": 99,
          "completed": 42
        },
        {
          "id": 454,
          "name": "PSF 22",
          "total": 95,
          "completed": 60
        }
      ]
    },
    {
      "id": 108,
      "name": "TYT Türkçe POT Palme Okul Testi ",
      "cover": "https://kurumsal.palmeyayinevi.com/Media/UserData/kapak/tyt/05_06_2020_16_05_43{türkçepot}.png",
      "description": "\"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.\"",
      "class": "TYT - AYT",
      "lesson": "Türkçe",
      "brand": "Palme Yayınevi",
      "bookContent": [
        {
          "id": 455,
          "name": "POT - 1",
          "total": 81,
          "completed": 31
        },
        {
          "id": 456,
          "name": "POT - 2",
          "total": 90,
          "completed": 53
        },
        {
          "id": 457,
          "name": "POT - 3",
          "total": 88,
          "completed": 49
        },
        {
          "id": 458,
          "name": "POT - 4",
          "total": 90,
          "completed": 77
        },
        {
          "id": 459,
          "name": "POT - 5",
          "total": 91,
          "completed": 28
        },
        {
          "id": 460,
          "name": "POT - 6",
          "total": 90,
          "completed": 62
        },
        {
          "id": 461,
          "name": "POT - 7",
          "total": 91,
          "completed": 65
        },
        {
          "id": 462,
          "name": "POT - 8",
          "total": 94,
          "completed": 66
        },
        {
          "id": 463,
          "name": "POT - 9",
          "total": 96,
          "completed": 44
        },
        {
          "id": 464,
          "name": "POT - 10",
          "total": 81,
          "completed": 26
        },
        {
          "id": 465,
          "name": "POT - 11",
          "total": 91,
          "completed": 75
        },
        {
          "id": 466,
          "name": "POT - 12",
          "total": 95,
          "completed": 30
        },
        {
          "id": 467,
          "name": "POT - 13",
          "total": 85,
          "completed": 58
        },
        {
          "id": 468,
          "name": "POT - 14",
          "total": 94,
          "completed": 49
        },
        {
          "id": 469,
          "name": "POT - 15",
          "total": 94,
          "completed": 37
        },
        {
          "id": 470,
          "name": "POT - 16",
          "total": 97,
          "completed": 72
        },
        {
          "id": 471,
          "name": "POT - 17",
          "total": 83,
          "completed": 73
        },
        {
          "id": 472,
          "name": "POT - 18",
          "total": 85,
          "completed": 26
        },
        {
          "id": 473,
          "name": "POT - 19",
          "total": 99,
          "completed": 60
        },
        {
          "id": 474,
          "name": "POT - 20",
          "total": 89,
          "completed": 55
        },
        {
          "id": 475,
          "name": "POT - 21",
          "total": 86,
          "completed": 42
        },
        {
          "id": 476,
          "name": "POT - 22",
          "total": 86,
          "completed": 66
        },
        {
          "id": 477,
          "name": "POT - 23",
          "total": 88,
          "completed": 43
        },
        {
          "id": 478,
          "name": "POT - 24",
          "total": 89,
          "completed": 67
        },
        {
          "id": 479,
          "name": "POT - 25",
          "total": 93,
          "completed": 47
        },
        {
          "id": 480,
          "name": "POT - 26",
          "total": 94,
          "completed": 45
        },
        {
          "id": 481,
          "name": "POT - 27",
          "total": 81,
          "completed": 39
        },
        {
          "id": 482,
          "name": "POT - 28",
          "total": 87,
          "completed": 33
        },
        {
          "id": 483,
          "name": "POT - 29",
          "total": 94,
          "completed": 33
        },
        {
          "id": 484,
          "name": "POT - 30",
          "total": 88,
          "completed": 38
        },
        {
          "id": 485,
          "name": "POT - 31",
          "total": 82,
          "completed": 69
        },
        {
          "id": 486,
          "name": "POT - 32",
          "total": 96,
          "completed": 62
        },
        {
          "id": 487,
          "name": "POT - 33",
          "total": 94,
          "completed": 48
        },
        {
          "id": 488,
          "name": "POT - 34",
          "total": 82,
          "completed": 56
        },
        {
          "id": 489,
          "name": "POT - 35",
          "total": 91,
          "completed": 37
        },
        {
          "id": 490,
          "name": "POT - 36",
          "total": 82,
          "completed": 52
        },
        {
          "id": 491,
          "name": "POT - 37",
          "total": 99,
          "completed": 42
        },
        {
          "id": 492,
          "name": "POT - 38",
          "total": 85,
          "completed": 46
        },
        {
          "id": 493,
          "name": "POT - 39",
          "total": 81,
          "completed": 31
        },
        {
          "id": 494,
          "name": "POT - 40",
          "total": 93,
          "completed": 59
        },
        {
          "id": 495,
          "name": "POT - 41",
          "total": 94,
          "completed": 70
        },
        {
          "id": 496,
          "name": "POT - 42",
          "total": 83,
          "completed": 57
        },
        {
          "id": 497,
          "name": "POT - 43",
          "total": 84,
          "completed": 38
        },
        {
          "id": 498,
          "name": "POT - 44",
          "total": 87,
          "completed": 51
        },
        {
          "id": 499,
          "name": "POT - 45",
          "total": 92,
          "completed": 69
        },
        {
          "id": 500,
          "name": "POT - 46",
          "total": 83,
          "completed": 53
        },
        {
          "id": 501,
          "name": "POT - 47",
          "total": 99,
          "completed": 29
        },
        {
          "id": 502,
          "name": "POT - 48",
          "total": 95,
          "completed": 43
        },
        {
          "id": 503,
          "name": "POT - 49",
          "total": 83,
          "completed": 57
        },
        {
          "id": 504,
          "name": "POT - 50",
          "total": 89,
          "completed": 53
        },
        {
          "id": 505,
          "name": "POT - 51",
          "total": 93,
          "completed": 56
        },
        {
          "id": 506,
          "name": "POT - 52",
          "total": 87,
          "completed": 77
        },
        {
          "id": 507,
          "name": "POT - 53",
          "total": 99,
          "completed": 76
        },
        {
          "id": 508,
          "name": "POT - 54",
          "total": 96,
          "completed": 55
        },
        {
          "id": 509,
          "name": "POT - 55",
          "total": 95,
          "completed": 38
        },
        {
          "id": 510,
          "name": "POT - 56",
          "total": 98,
          "completed": 70
        }
      ]
    },
    {
      "id": 114,
      "name": "TYT Fizik PAF Palme Anlatım Föyü ",
      "cover": "https://kurumsal.palmeyayinevi.com/Media/UserData/kapak/tyt/04_06_2020_09_51_36{PAFFizik}.png",
      "description": "\"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.\"",
      "class": "TYT - AYT",
      "lesson": "Fizik",
      "brand": "Palme Yayınevi",
      "bookContent": [
        {
          "id": 591,
          "name": "PAF 1: Fizik Bilimine Giriş",
          "total": 85,
          "completed": 55
        },
        {
          "id": 592,
          "name": "PAF 2: Fizik Bilimine Giriş",
          "total": 91,
          "completed": 79
        },
        {
          "id": 593,
          "name": "PAF 3: Madde ve Özellikleri",
          "total": 82,
          "completed": 41
        },
        {
          "id": 594,
          "name": "PAF 4: Madde ve Özellikleri",
          "total": 81,
          "completed": 65
        },
        {
          "id": 595,
          "name": "PAF 5: Hareket",
          "total": 89,
          "completed": 44
        },
        {
          "id": 596,
          "name": "PAF 6: Kuvvet",
          "total": 88,
          "completed": 30
        },
        {
          "id": 597,
          "name": "PAF 7: İş - Enerji - Güç",
          "total": 85,
          "completed": 47
        },
        {
          "id": 598,
          "name": "PAF 8: Isı ve Sıcaklık",
          "total": 95,
          "completed": 60
        },
        {
          "id": 599,
          "name": "PAF 9: Isıl Denge",
          "total": 89,
          "completed": 34
        },
        {
          "id": 600,
          "name": "PAF 10: Elektrostatik",
          "total": 98,
          "completed": 37
        },
        {
          "id": 601,
          "name": "PAF 11: Elektrik ve Manyetizma",
          "total": 93,
          "completed": 72
        },
        {
          "id": 602,
          "name": "PAF 12: Elektrik ve Manyetizma",
          "total": 87,
          "completed": 47
        },
        {
          "id": 603,
          "name": "PAF 13: Elektrik Enerjisi ve Elektriksel Güç",
          "total": 94,
          "completed": 33
        },
        {
          "id": 604,
          "name": "PAF 14: Basınç",
          "total": 81,
          "completed": 35
        },
        {
          "id": 605,
          "name": "PAF 15: Gaz Basıncı",
          "total": 96,
          "completed": 26
        },
        {
          "id": 606,
          "name": "PAF 16: Dalgalar",
          "total": 99,
          "completed": 59
        },
        {
          "id": 607,
          "name": "PAF 17: Ortam Değiştiren Su Dalgalarının Davranışı",
          "total": 91,
          "completed": 32
        },
        {
          "id": 608,
          "name": "PAF 18: Optik",
          "total": 93,
          "completed": 31
        },
        {
          "id": 609,
          "name": "PAF 19: Aynalar ve Yansıma",
          "total": 91,
          "completed": 40
        },
        {
          "id": 610,
          "name": "PAF 20: Kırılma",
          "total": 85,
          "completed": 55
        }
      ]
    },
    {
      "id": 123,
      "name": "TYT Tarih PSF Palme Soru Föyü",
      "cover": "https://kurumsal.palmeyayinevi.com/Media/UserData/kapak/tyt/05_06_2020_10_52_24{TarihPSF}.png",
      "description": "\"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.\"",
      "class": "TYT - AYT",
      "lesson": "Tarih",
      "brand": "Palme Yayınevi",
      "bookContent": [
        {
          "id": 677,
          "name": "PSF - 1",
          "total": 88,
          "completed": 42
        },
        {
          "id": 678,
          "name": "PSF - 2",
          "total": 83,
          "completed": 46
        },
        {
          "id": 679,
          "name": "PSF - 3",
          "total": 88,
          "completed": 41
        },
        {
          "id": 680,
          "name": "PSF - 4",
          "total": 97,
          "completed": 52
        },
        {
          "id": 681,
          "name": "PSF - 5",
          "total": 93,
          "completed": 61
        },
        {
          "id": 682,
          "name": "PSF - 6",
          "total": 83,
          "completed": 69
        },
        {
          "id": 683,
          "name": "PSF - 7",
          "total": 90,
          "completed": 26
        },
        {
          "id": 684,
          "name": "PSF - 8",
          "total": 82,
          "completed": 44
        },
        {
          "id": 685,
          "name": "PSF - 9",
          "total": 81,
          "completed": 69
        },
        {
          "id": 686,
          "name": "PSF - 10",
          "total": 95,
          "completed": 53
        },
        {
          "id": 687,
          "name": "PSF - 11",
          "total": 89,
          "completed": 51
        },
        {
          "id": 688,
          "name": "PSF - 12",
          "total": 87,
          "completed": 46
        },
        {
          "id": 689,
          "name": "PSF - 13",
          "total": 84,
          "completed": 32
        },
        {
          "id": 690,
          "name": "PSF - 14",
          "total": 81,
          "completed": 32
        },
        {
          "id": 691,
          "name": "PSF - 15",
          "total": 87,
          "completed": 27
        },
        {
          "id": 692,
          "name": "PSF - 16",
          "total": 83,
          "completed": 40
        },
        {
          "id": 693,
          "name": "PSF - 17",
          "total": 86,
          "completed": 42
        },
        {
          "id": 694,
          "name": "PSF - 18",
          "total": 91,
          "completed": 57
        },
        {
          "id": 695,
          "name": "PSF - 19",
          "total": 91,
          "completed": 32
        },
        {
          "id": 696,
          "name": "PSF - 20",
          "total": 84,
          "completed": 70
        },
        {
          "id": 697,
          "name": "PSF - 21",
          "total": 86,
          "completed": 65
        },
        {
          "id": 698,
          "name": "PSF - 22",
          "total": 96,
          "completed": 68
        },
        {
          "id": 699,
          "name": "PSF - 23",
          "total": 84,
          "completed": 58
        },
        {
          "id": 700,
          "name": "PSF - 24",
          "total": 83,
          "completed": 40
        },
        {
          "id": 701,
          "name": "PSF - 25",
          "total": 98,
          "completed": 72
        },
        {
          "id": 702,
          "name": "PSF - 26",
          "total": 92,
          "completed": 57
        },
        {
          "id": 703,
          "name": "PSF - 27",
          "total": 88,
          "completed": 48
        }
      ]
    },
    {
      "id": 125,
      "name": "TYT-AYT Felsefe PAF Palme Anlatım Föyü ",
      "cover": "https://kurumsal.palmeyayinevi.com/Media/UserData/kapak/tyt/04_06_2020_09_46_45{70e52740-96a3-440d-923f-dbb4b0ed15d2}.jpg",
      "description": "\"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.\"",
      "class": "TYT - AYT",
      "lesson": "Felsefe",
      "brand": "Palme Yayınevi",
      "bookContent": [
        {
          "id": 728,
          "name": "PAF - 1",
          "total": 94,
          "completed": 68
        },
        {
          "id": 729,
          "name": "PAF - 2",
          "total": 82,
          "completed": 63
        },
        {
          "id": 730,
          "name": "PAF - 3",
          "total": 92,
          "completed": 33
        },
        {
          "id": 731,
          "name": "PAF - 4",
          "total": 90,
          "completed": 28
        },
        {
          "id": 732,
          "name": "PAF - 5",
          "total": 81,
          "completed": 67
        },
        {
          "id": 733,
          "name": "PAF - 6",
          "total": 98,
          "completed": 78
        },
        {
          "id": 734,
          "name": "PAF - 7",
          "total": 99,
          "completed": 48
        },
        {
          "id": 735,
          "name": "PAF - 8",
          "total": 83,
          "completed": 49
        },
        {
          "id": 736,
          "name": "PAF - 9",
          "total": 89,
          "completed": 48
        },
        {
          "id": 737,
          "name": "PAF - 10",
          "total": 88,
          "completed": 55
        },
        {
          "id": 738,
          "name": "PAF - 11",
          "total": 89,
          "completed": 73
        },
        {
          "id": 739,
          "name": "PAF - 12",
          "total": 99,
          "completed": 55
        },
        {
          "id": 764,
          "name": "PAF - 13",
          "total": 89,
          "completed": 36
        },
        {
          "id": 765,
          "name": "PAF - 14",
          "total": 86,
          "completed": 44
        }
      ]
    },
    {
      "id": 128,
      "name": "TYT-AYT Felsefe PSF Palme Soru Fasikülü ",
      "cover": "https://kurumsal.palmeyayinevi.com/Media/UserData/kapak/tyt/04_06_2020_09_47_04{PSFFelsefe}.png",
      "description": "\"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.\"",
      "class": "TYT - AYT",
      "lesson": "Felsefe",
      "brand": "Palme Yayınevi",
      "bookContent": [
        {
          "id": 790,
          "name": "PSF 1",
          "total": 84,
          "completed": 66
        },
        {
          "id": 791,
          "name": "PSF 2",
          "total": 88,
          "completed": 45
        },
        {
          "id": 792,
          "name": "PSF 3",
          "total": 90,
          "completed": 64
        },
        {
          "id": 793,
          "name": "PSF 4",
          "total": 84,
          "completed": 27
        },
        {
          "id": 794,
          "name": "PSF 5",
          "total": 86,
          "completed": 46
        },
        {
          "id": 795,
          "name": "PSF 6",
          "total": 94,
          "completed": 75
        },
        {
          "id": 796,
          "name": "PSF 7",
          "total": 99,
          "completed": 27
        },
        {
          "id": 797,
          "name": "PSF 8",
          "total": 89,
          "completed": 34
        },
        {
          "id": 798,
          "name": "PSF 9",
          "total": 90,
          "completed": 56
        },
        {
          "id": 799,
          "name": "PSF 10",
          "total": 81,
          "completed": 69
        },
        {
          "id": 800,
          "name": "PSF 11",
          "total": 91,
          "completed": 61
        },
        {
          "id": 801,
          "name": "PSF 12",
          "total": 82,
          "completed": 49
        },
        {
          "id": 802,
          "name": "PSF 13",
          "total": 85,
          "completed": 71
        },
        {
          "id": 803,
          "name": "PSF 14",
          "total": 89,
          "completed": 31
        },
        {
          "id": 804,
          "name": "Cevap Anahtarı Föyü",
          "total": 92,
          "completed": 51
        }
      ]
    },
    {
      "id": 136,
      "name": "AYT Coğrafya PAF Palme Anlatım Föyü ",
      "cover": "https://kurumsal.palmeyayinevi.com/Media/UserData/kapak/ayt/02_07_2020_17_39_01{aytcoğrafyapaf}.png",
      "description": "\"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.\"",
      "class": "TYT - AYT",
      "lesson": "Coğrafya",
      "brand": "Palme Yayınevi",
      "bookContent": [
        {
          "id": 894,
          "name": "PAF 1: Ekosistem ve Madde Döngüsü",
          "total": 89,
          "completed": 58
        },
        {
          "id": 895,
          "name": "PAF 2: Ekstrem Doğa Olayları",
          "total": 97,
          "completed": 77
        },
        {
          "id": 896,
          "name": "PAF 3: Küresel İklim Değişmesi",
          "total": 83,
          "completed": 26
        },
        {
          "id": 897,
          "name": "PAF 4: Nüfus Politikaları",
          "total": 99,
          "completed": 46
        },
        {
          "id": 898,
          "name": "PAF 5: Geçmişten Geleceğe Şehir ve Ekonomi",
          "total": 98,
          "completed": 38
        },
        {
          "id": 899,
          "name": "PAF 6: Ekonomik Faaliyetler ve Doğal Kaynaklar",
          "total": 81,
          "completed": 61
        },
        {
          "id": 900,
          "name": "PAF 7: Türkiye'nin Ekonomik Politikaları ve Türkiye'de Tarım",
          "total": 95,
          "completed": 33
        },
        {
          "id": 901,
          "name": "PAF 8: Türkiye'de Hayvancılık",
          "total": 91,
          "completed": 40
        },
        {
          "id": 902,
          "name": "PAF 9: Türkiye'de Madencilik, Enerji Kaynakları ve Sanayi",
          "total": 93,
          "completed": 44
        },
        {
          "id": 903,
          "name": "PAF 10: Hizmet Sektörünün Ekonomideki Yeri",
          "total": 95,
          "completed": 65
        },
        {
          "id": 904,
          "name": "PAF 11: Türkiye'nin İşlevsel Bölgeleri ve Kalkınma Bölgeleri",
          "total": 86,
          "completed": 73
        },
        {
          "id": 905,
          "name": "PAF 12: Jeopolitik Konum",
          "total": 81,
          "completed": 48
        },
        {
          "id": 906,
          "name": "PAF 13: Kültür Bölgeleri",
          "total": 94,
          "completed": 50
        },
        {
          "id": 907,
          "name": "PAF 14: Küreselleşen Dünya",
          "total": 86,
          "completed": 74
        },
        {
          "id": 908,
          "name": "PAF 15: Çevre ve Toplum",
          "total": 95,
          "completed": 41
        },
        {
          "id": 909,
          "name": "PAF 16: Çevre ve Toplum-2",
          "total": 82,
          "completed": 37
        }
      ]
    },
    {
      "id": 137,
      "name": "TYT-AYT Din Kültürü ve Ahlak Bilgisi PAF Palme Anlatım Föyü ",
      "cover": "https://kurumsal.palmeyayinevi.com/Media/UserData/kapak/tyt/04_06_2020_09_48_19{428ca952-33f1-4a15-b885-7cabe9dd3650}.jpg",
      "description": "\"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.\"",
      "class": "TYT - AYT",
      "lesson": "Din Kültürü ve Ahlak Bilgisi",
      "brand": "Palme Yayınevi",
      "bookContent": [
        {
          "id": 910,
          "name": "PAF 1: İnanç",
          "total": 85,
          "completed": 30
        },
        {
          "id": 911,
          "name": "PAF 2: İnançla İlgili Meseleler ve Yaşayan Dünya Dinleri",
          "total": 90,
          "completed": 36
        },
        {
          "id": 912,
          "name": "PAF 3: Yaşayan Dünya Dinleri",
          "total": 94,
          "completed": 27
        },
        {
          "id": 913,
          "name": "PAF 4: Kur'an'a Göre Hz. Muhammed ve Bazı Genç Sahabeler",
          "total": 85,
          "completed": 72
        },
        {
          "id": 914,
          "name": "PAF 5: İslam ve İbadet / Gençlik ve Değerler",
          "total": 93,
          "completed": 26
        },
        {
          "id": 915,
          "name": "PAF 6: İslam Düşüncesinde Dini Yorum Farklılıkları ve Meseleler",
          "total": 84,
          "completed": 61
        },
        {
          "id": 916,
          "name": "PAF 7: İslam, Bilim ve Anadolu İslam Düşüncesinde Tasavvufi Yorumlar",
          "total": 98,
          "completed": 39
        }
      ]
    },
    {
      "id": 138,
      "name": "TYT-AYT Din Kültürü ve Ahlak Bilgisi PSF Palme Soru Föyü ",
      "cover": "https://kurumsal.palmeyayinevi.com/Media/UserData/kapak/tyt/05_06_2020_17_41_01{dinyenipsf}.png",
      "description": "\"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.\"",
      "class": "TYT - AYT",
      "lesson": "Din Kültürü ve Ahlak Bilgisi",
      "brand": "Palme Yayınevi",
      "bookContent": [
        {
          "id": 917,
          "name": "PSF 1",
          "total": 92,
          "completed": 72
        },
        {
          "id": 918,
          "name": "PSF 2",
          "total": 84,
          "completed": 72
        },
        {
          "id": 919,
          "name": "PSF 3",
          "total": 93,
          "completed": 55
        },
        {
          "id": 920,
          "name": "PSF 4",
          "total": 94,
          "completed": 45
        },
        {
          "id": 921,
          "name": "PSF 5",
          "total": 94,
          "completed": 65
        },
        {
          "id": 922,
          "name": "PSF 6",
          "total": 82,
          "completed": 53
        }
      ]
    },
    {
      "id": 139,
      "name": "TYT-AYT Din Kültürü ve Ahlak Bilgisi POT Palme Okul Testi ",
      "cover": "https://kurumsal.palmeyayinevi.com/Media/UserData/kapak/tyt/05_06_2020_14_02_28{dintyt-ayt}.png",
      "description": "\"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.\"",
      "class": "TYT - AYT",
      "lesson": "Din Kültürü ve Ahlak Bilgisi",
      "brand": "Palme Yayınevi",
      "bookContent": [
        {
          "id": 923,
          "name": "POT",
          "total": 92,
          "completed": 43
        }
      ]
    },
    {
      "id": 140,
      "name": "TYT Tarih PAF Palme Anlatım Föyü ",
      "cover": "https://kurumsal.palmeyayinevi.com/Media/UserData/kapak/tyt/05_06_2020_10_51_46{TarihPAF}.png",
      "description": "\"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.\"",
      "class": "TYT - AYT",
      "lesson": "Tarih",
      "brand": "Palme Yayınevi",
      "bookContent": [
        {
          "id": 924,
          "name": "PAF 1",
          "total": 93,
          "completed": 40
        },
        {
          "id": 925,
          "name": "PAF 2",
          "total": 81,
          "completed": 35
        },
        {
          "id": 926,
          "name": "PAF 3",
          "total": 91,
          "completed": 35
        },
        {
          "id": 927,
          "name": "PAF 4",
          "total": 89,
          "completed": 36
        },
        {
          "id": 928,
          "name": "PAF 5",
          "total": 84,
          "completed": 60
        },
        {
          "id": 929,
          "name": "PAF 6",
          "total": 92,
          "completed": 54
        },
        {
          "id": 930,
          "name": "PAF 7",
          "total": 83,
          "completed": 77
        },
        {
          "id": 931,
          "name": "PAF 8",
          "total": 81,
          "completed": 63
        },
        {
          "id": 932,
          "name": "PAF 9",
          "total": 82,
          "completed": 43
        },
        {
          "id": 933,
          "name": "PAF 10",
          "total": 82,
          "completed": 58
        },
        {
          "id": 934,
          "name": "PAF 11",
          "total": 90,
          "completed": 68
        },
        {
          "id": 935,
          "name": "PAF 12",
          "total": 94,
          "completed": 42
        },
        {
          "id": 936,
          "name": "PAF 13",
          "total": 84,
          "completed": 75
        },
        {
          "id": 937,
          "name": "PAF 14",
          "total": 96,
          "completed": 37
        },
        {
          "id": 938,
          "name": "PAF 15",
          "total": 83,
          "completed": 38
        },
        {
          "id": 939,
          "name": "PAF 16",
          "total": 91,
          "completed": 41
        },
        {
          "id": 940,
          "name": "PAF 17",
          "total": 94,
          "completed": 47
        },
        {
          "id": 941,
          "name": "PAF 18",
          "total": 87,
          "completed": 78
        },
        {
          "id": 942,
          "name": "PAF 19",
          "total": 93,
          "completed": 36
        },
        {
          "id": 943,
          "name": "PAF 20",
          "total": 87,
          "completed": 42
        },
        {
          "id": 944,
          "name": "PAF 21",
          "total": 98,
          "completed": 69
        },
        {
          "id": 945,
          "name": "PAF 22",
          "total": 99,
          "completed": 66
        },
        {
          "id": 946,
          "name": "PAF 23",
          "total": 94,
          "completed": 66
        },
        {
          "id": 947,
          "name": "PAF 24",
          "total": 94,
          "completed": 62
        },
        {
          "id": 948,
          "name": "PAF 25",
          "total": 96,
          "completed": 37
        },
        {
          "id": 949,
          "name": "PAF 26",
          "total": 87,
          "completed": 43
        },
        {
          "id": 950,
          "name": "PAF 27",
          "total": 87,
          "completed": 56
        },
        {
          "id": 951,
          "name": "PAF 28",
          "total": 82,
          "completed": 75
        },
        {
          "id": 952,
          "name": "PAF 29",
          "total": 92,
          "completed": 56
        }
      ]
    },
    {
      "id": 145,
      "name": "TYT Matematik PAF Palme Anlatım Föyü ",
      "cover": "https://kurumsal.palmeyayinevi.com/Media/UserData/kapak/tyt/04_06_2020_09_54_30{PAFMatematik}.png",
      "description": "\"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.\"",
      "class": "TYT - AYT",
      "lesson": "Matematik",
      "brand": "Palme Yayınevi",
      "bookContent": [
        {
          "id": 995,
          "name": "PAF-1",
          "total": 88,
          "completed": 38
        },
        {
          "id": 996,
          "name": "PAF-2",
          "total": 91,
          "completed": 56
        },
        {
          "id": 997,
          "name": "PAF-3",
          "total": 95,
          "completed": 56
        },
        {
          "id": 998,
          "name": "PAF-4",
          "total": 88,
          "completed": 26
        },
        {
          "id": 999,
          "name": "PAF-5",
          "total": 95,
          "completed": 65
        },
        {
          "id": 1000,
          "name": "PAF-6",
          "total": 82,
          "completed": 32
        },
        {
          "id": 1001,
          "name": "PAF-7",
          "total": 82,
          "completed": 44
        },
        {
          "id": 1002,
          "name": "PAF-8",
          "total": 84,
          "completed": 78
        },
        {
          "id": 1003,
          "name": "PAF-9",
          "total": 85,
          "completed": 66
        },
        {
          "id": 1004,
          "name": "PAF-10",
          "total": 82,
          "completed": 68
        },
        {
          "id": 1005,
          "name": "PAF-11",
          "total": 89,
          "completed": 29
        },
        {
          "id": 1006,
          "name": "PAF-12",
          "total": 88,
          "completed": 78
        },
        {
          "id": 1007,
          "name": "PAF-13",
          "total": 83,
          "completed": 33
        },
        {
          "id": 1008,
          "name": "PAF-14",
          "total": 89,
          "completed": 35
        },
        {
          "id": 1009,
          "name": "PAF-15",
          "total": 93,
          "completed": 40
        },
        {
          "id": 1010,
          "name": "PAF-16",
          "total": 82,
          "completed": 64
        },
        {
          "id": 1011,
          "name": "PAF-17",
          "total": 87,
          "completed": 48
        },
        {
          "id": 1012,
          "name": "PAF-18",
          "total": 86,
          "completed": 35
        },
        {
          "id": 1013,
          "name": "PAF-19",
          "total": 99,
          "completed": 49
        },
        {
          "id": 1014,
          "name": "PAF-20",
          "total": 97,
          "completed": 72
        },
        {
          "id": 1015,
          "name": "PAF-21",
          "total": 82,
          "completed": 44
        },
        {
          "id": 1016,
          "name": "PAF-22",
          "total": 91,
          "completed": 70
        },
        {
          "id": 1017,
          "name": "CEVAP ANAHTARI",
          "total": 83,
          "completed": 42
        }
      ]
    },
    {
      "id": 146,
      "name": "AYT Coğrafya POT Palme Okul Testi ",
      "cover": "https://kurumsal.palmeyayinevi.com/Media/UserData/kapak/ayt/02_07_2020_11_35_22{aytcoğrafyapot}.png",
      "description": "\"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.\"",
      "class": "TYT - AYT",
      "lesson": "Coğrafya",
      "brand": "Palme Yayınevi",
      "bookContent": [
        {
          "id": 1018,
          "name": "Test : 1",
          "total": 89,
          "completed": 35
        },
        {
          "id": 1019,
          "name": "Test : 2",
          "total": 92,
          "completed": 41
        },
        {
          "id": 1020,
          "name": "Test : 3",
          "total": 91,
          "completed": 57
        },
        {
          "id": 1021,
          "name": "Test : 4",
          "total": 99,
          "completed": 78
        },
        {
          "id": 1022,
          "name": "Test : 5",
          "total": 87,
          "completed": 45
        },
        {
          "id": 1023,
          "name": "Test : 6",
          "total": 86,
          "completed": 71
        },
        {
          "id": 1024,
          "name": "Test : 7",
          "total": 82,
          "completed": 32
        },
        {
          "id": 1025,
          "name": "Test : 8",
          "total": 81,
          "completed": 74
        },
        {
          "id": 1026,
          "name": "Test : 9",
          "total": 90,
          "completed": 26
        },
        {
          "id": 1027,
          "name": "Test : 10",
          "total": 87,
          "completed": 79
        },
        {
          "id": 1028,
          "name": "Test : 11",
          "total": 94,
          "completed": 51
        },
        {
          "id": 1029,
          "name": "Test : 12",
          "total": 93,
          "completed": 45
        },
        {
          "id": 1030,
          "name": "Test : 13",
          "total": 85,
          "completed": 66
        },
        {
          "id": 1031,
          "name": "Test : 14",
          "total": 94,
          "completed": 75
        },
        {
          "id": 1032,
          "name": "Test : 15",
          "total": 93,
          "completed": 64
        },
        {
          "id": 1033,
          "name": "Test : 16",
          "total": 99,
          "completed": 49
        },
        {
          "id": 1034,
          "name": "Test : 17",
          "total": 98,
          "completed": 45
        },
        {
          "id": 1035,
          "name": "Test : 18",
          "total": 86,
          "completed": 72
        },
        {
          "id": 1036,
          "name": "Test : 19",
          "total": 99,
          "completed": 58
        },
        {
          "id": 1037,
          "name": "Test : 20",
          "total": 95,
          "completed": 48
        },
        {
          "id": 1038,
          "name": "Test : 21",
          "total": 82,
          "completed": 78
        },
        {
          "id": 1039,
          "name": "Test : 22",
          "total": 99,
          "completed": 58
        },
        {
          "id": 1040,
          "name": "Test : 23",
          "total": 92,
          "completed": 71
        },
        {
          "id": 1041,
          "name": "Test : 24",
          "total": 91,
          "completed": 76
        },
        {
          "id": 1042,
          "name": "Test : 25",
          "total": 81,
          "completed": 60
        },
        {
          "id": 1043,
          "name": "Test : 26",
          "total": 91,
          "completed": 71
        },
        {
          "id": 1044,
          "name": "Test : 27",
          "total": 83,
          "completed": 30
        },
        {
          "id": 1045,
          "name": "Test : 28",
          "total": 87,
          "completed": 76
        },
        {
          "id": 1046,
          "name": "Test : 29",
          "total": 88,
          "completed": 59
        },
        {
          "id": 1047,
          "name": "Test : 30",
          "total": 86,
          "completed": 74
        },
        {
          "id": 1048,
          "name": "Test : 31",
          "total": 88,
          "completed": 71
        },
        {
          "id": 1049,
          "name": "Test : 32",
          "total": 97,
          "completed": 33
        },
        {
          "id": 1050,
          "name": "Test : 33",
          "total": 91,
          "completed": 33
        },
        {
          "id": 1051,
          "name": "Test : 34",
          "total": 99,
          "completed": 76
        },
        {
          "id": 1052,
          "name": "Test : 35",
          "total": 85,
          "completed": 30
        },
        {
          "id": 1053,
          "name": "Test : 36",
          "total": 87,
          "completed": 63
        },
        {
          "id": 1054,
          "name": "Test : 37",
          "total": 92,
          "completed": 69
        },
        {
          "id": 1055,
          "name": "Test : 38",
          "total": 98,
          "completed": 34
        },
        {
          "id": 1056,
          "name": "Test : 39",
          "total": 81,
          "completed": 77
        },
        {
          "id": 1057,
          "name": "Test : 40",
          "total": 86,
          "completed": 77
        }
      ]
    },
    {
      "id": 151,
      "name": "TYT Matematik PSF Palme Soru Föyü ",
      "cover": "https://kurumsal.palmeyayinevi.com/Media/UserData/kapak/tyt/04_06_2020_09_54_48{PSFmatematik}.png",
      "description": "\"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.\"",
      "class": "TYT - AYT",
      "lesson": "Matematik",
      "brand": "Palme Yayınevi",
      "bookContent": [
        {
          "id": 1139,
          "name": "PSF 1",
          "total": 94,
          "completed": 28
        },
        {
          "id": 1140,
          "name": "PSF 2",
          "total": 97,
          "completed": 71
        },
        {
          "id": 1141,
          "name": "PSF 3",
          "total": 94,
          "completed": 26
        },
        {
          "id": 1142,
          "name": "PSF 4",
          "total": 81,
          "completed": 55
        },
        {
          "id": 1143,
          "name": "PSF 5",
          "total": 90,
          "completed": 77
        },
        {
          "id": 1144,
          "name": "PSF 6",
          "total": 96,
          "completed": 54
        },
        {
          "id": 1145,
          "name": "PSF 7",
          "total": 99,
          "completed": 69
        },
        {
          "id": 1146,
          "name": "PSF 8",
          "total": 89,
          "completed": 51
        },
        {
          "id": 1147,
          "name": "PSF 9",
          "total": 94,
          "completed": 73
        },
        {
          "id": 1148,
          "name": "PSF 10",
          "total": 95,
          "completed": 69
        },
        {
          "id": 1149,
          "name": "PSF 11",
          "total": 93,
          "completed": 62
        },
        {
          "id": 1150,
          "name": "PSF 12",
          "total": 98,
          "completed": 76
        },
        {
          "id": 1151,
          "name": "PSF 13",
          "total": 91,
          "completed": 56
        },
        {
          "id": 1152,
          "name": "PSF 14",
          "total": 96,
          "completed": 53
        },
        {
          "id": 1153,
          "name": "PSF 15",
          "total": 95,
          "completed": 58
        },
        {
          "id": 1154,
          "name": "PSF 16",
          "total": 86,
          "completed": 68
        },
        {
          "id": 1155,
          "name": "PSF 17",
          "total": 88,
          "completed": 65
        },
        {
          "id": 1156,
          "name": "PSF 18",
          "total": 84,
          "completed": 38
        },
        {
          "id": 1157,
          "name": "PSF 19",
          "total": 95,
          "completed": 58
        },
        {
          "id": 1158,
          "name": "PSF 20",
          "total": 88,
          "completed": 33
        }
      ]
    },
    {
      "id": 152,
      "name": "AYT Coğrafya PSF Palme Soru Föyü ",
      "cover": "https://kurumsal.palmeyayinevi.com/Media/UserData/kapak/ayt/02_07_2020_17_39_29{aytcoğrafyapsf}.png",
      "description": "\"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.\"",
      "class": "TYT - AYT",
      "lesson": "Coğrafya",
      "brand": "Palme Yayınevi",
      "bookContent": [
        {
          "id": 1159,
          "name": "PSF 1",
          "total": 96,
          "completed": 48
        },
        {
          "id": 1160,
          "name": "PSF 2",
          "total": 88,
          "completed": 29
        },
        {
          "id": 1161,
          "name": "PSF 3",
          "total": 86,
          "completed": 31
        },
        {
          "id": 1162,
          "name": "PSF 4",
          "total": 86,
          "completed": 52
        },
        {
          "id": 1163,
          "name": "PSF 5",
          "total": 91,
          "completed": 29
        },
        {
          "id": 1164,
          "name": "PSF 6",
          "total": 90,
          "completed": 34
        },
        {
          "id": 1165,
          "name": "PSF 7",
          "total": 90,
          "completed": 65
        },
        {
          "id": 1166,
          "name": "PSF 8",
          "total": 81,
          "completed": 46
        },
        {
          "id": 1167,
          "name": "PSF 9",
          "total": 82,
          "completed": 46
        },
        {
          "id": 1168,
          "name": "PSF 10",
          "total": 92,
          "completed": 51
        },
        {
          "id": 1169,
          "name": "PSF 11",
          "total": 87,
          "completed": 35
        },
        {
          "id": 1170,
          "name": "PSF 12",
          "total": 96,
          "completed": 74
        },
        {
          "id": 1171,
          "name": "PSF 13",
          "total": 97,
          "completed": 68
        },
        {
          "id": 1172,
          "name": "PSF 14",
          "total": 98,
          "completed": 31
        },
        {
          "id": 1173,
          "name": "PSF 15",
          "total": 99,
          "completed": 60
        },
        {
          "id": 1174,
          "name": "PSF 16",
          "total": 84,
          "completed": 60
        }
      ]
    },
    {
      "id": 153,
      "name": "AYT Kimya POT Palme Okul Testi",
      "cover": "https://kurumsal.palmeyayinevi.com/Media/UserData/kapak/ayt/02_07_2020_11_37_09{aytkimyapot}.png",
      "description": "\"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.\"",
      "class": "TYT - AYT",
      "lesson": "Kimya",
      "brand": "Palme Yayınevi",
      "bookContent": [
        {
          "id": 1175,
          "name": "POT 1",
          "total": 89,
          "completed": 38
        },
        {
          "id": 1176,
          "name": "POT 2",
          "total": 88,
          "completed": 77
        },
        {
          "id": 1177,
          "name": "POT 3",
          "total": 91,
          "completed": 34
        },
        {
          "id": 1178,
          "name": "POT 4",
          "total": 94,
          "completed": 79
        },
        {
          "id": 1179,
          "name": "POT 5",
          "total": 86,
          "completed": 66
        },
        {
          "id": 1180,
          "name": "POT 6",
          "total": 95,
          "completed": 32
        },
        {
          "id": 1181,
          "name": "POT 7",
          "total": 87,
          "completed": 65
        },
        {
          "id": 1182,
          "name": "POT 8",
          "total": 82,
          "completed": 67
        },
        {
          "id": 1183,
          "name": "POT 9",
          "total": 85,
          "completed": 44
        },
        {
          "id": 1184,
          "name": "POT 10",
          "total": 93,
          "completed": 28
        },
        {
          "id": 1185,
          "name": "POT 11",
          "total": 82,
          "completed": 74
        },
        {
          "id": 1186,
          "name": "POT 12",
          "total": 93,
          "completed": 56
        },
        {
          "id": 1187,
          "name": "POT 13",
          "total": 87,
          "completed": 42
        },
        {
          "id": 1188,
          "name": "POT 14",
          "total": 93,
          "completed": 36
        },
        {
          "id": 1189,
          "name": "POT 15",
          "total": 93,
          "completed": 76
        },
        {
          "id": 1190,
          "name": "POT 16",
          "total": 85,
          "completed": 77
        },
        {
          "id": 1191,
          "name": "POT 17",
          "total": 88,
          "completed": 52
        },
        {
          "id": 1192,
          "name": "POT 18",
          "total": 82,
          "completed": 70
        },
        {
          "id": 1193,
          "name": "POT 19",
          "total": 93,
          "completed": 53
        },
        {
          "id": 1194,
          "name": "POT 20",
          "total": 94,
          "completed": 64
        },
        {
          "id": 1195,
          "name": "POT 21",
          "total": 81,
          "completed": 76
        },
        {
          "id": 1196,
          "name": "POT 22",
          "total": 96,
          "completed": 51
        },
        {
          "id": 1197,
          "name": "POT 23",
          "total": 84,
          "completed": 70
        },
        {
          "id": 1198,
          "name": "POT 24",
          "total": 92,
          "completed": 62
        },
        {
          "id": 1199,
          "name": "POT 25",
          "total": 94,
          "completed": 36
        },
        {
          "id": 1200,
          "name": "POT 26",
          "total": 99,
          "completed": 77
        },
        {
          "id": 1201,
          "name": "POT 27",
          "total": 98,
          "completed": 61
        },
        {
          "id": 1202,
          "name": "POT 28",
          "total": 94,
          "completed": 73
        },
        {
          "id": 1203,
          "name": "POT 29",
          "total": 99,
          "completed": 78
        },
        {
          "id": 1204,
          "name": "POT 30",
          "total": 94,
          "completed": 54
        },
        {
          "id": 1205,
          "name": "POT 31",
          "total": 82,
          "completed": 50
        },
        {
          "id": 1206,
          "name": "POT 32",
          "total": 98,
          "completed": 45
        },
        {
          "id": 1207,
          "name": "POT 33",
          "total": 87,
          "completed": 75
        },
        {
          "id": 1208,
          "name": "POT 34",
          "total": 97,
          "completed": 30
        },
        {
          "id": 1209,
          "name": "POT 35",
          "total": 85,
          "completed": 68
        },
        {
          "id": 1210,
          "name": "POT 36",
          "total": 83,
          "completed": 41
        },
        {
          "id": 1211,
          "name": "POT 37",
          "total": 84,
          "completed": 42
        },
        {
          "id": 1212,
          "name": "POT 38",
          "total": 98,
          "completed": 47
        },
        {
          "id": 1213,
          "name": "POT 39",
          "total": 85,
          "completed": 42
        },
        {
          "id": 1214,
          "name": "POT 40",
          "total": 92,
          "completed": 50
        },
        {
          "id": 1215,
          "name": "POT 41",
          "total": 90,
          "completed": 30
        },
        {
          "id": 1216,
          "name": "POT 42",
          "total": 87,
          "completed": 31
        },
        {
          "id": 1217,
          "name": "POT 43",
          "total": 98,
          "completed": 62
        },
        {
          "id": 1218,
          "name": "POT 44",
          "total": 85,
          "completed": 26
        },
        {
          "id": 1219,
          "name": "POT 45",
          "total": 84,
          "completed": 69
        },
        {
          "id": 1220,
          "name": "POT 46",
          "total": 93,
          "completed": 29
        },
        {
          "id": 1221,
          "name": "POT 47",
          "total": 92,
          "completed": 39
        },
        {
          "id": 1222,
          "name": "POT 48",
          "total": 87,
          "completed": 70
        },
        {
          "id": 1223,
          "name": "POT 49",
          "total": 83,
          "completed": 61
        },
        {
          "id": 1224,
          "name": "POT 50",
          "total": 93,
          "completed": 72
        },
        {
          "id": 1225,
          "name": "POT 51",
          "total": 81,
          "completed": 34
        },
        {
          "id": 1226,
          "name": "POT 52",
          "total": 97,
          "completed": 56
        },
        {
          "id": 1227,
          "name": "POT 53",
          "total": 91,
          "completed": 36
        },
        {
          "id": 1228,
          "name": "POT 54",
          "total": 92,
          "completed": 71
        },
        {
          "id": 1229,
          "name": "POT 55",
          "total": 98,
          "completed": 66
        },
        {
          "id": 1230,
          "name": "POT 56",
          "total": 92,
          "completed": 52
        },
        {
          "id": 1231,
          "name": "POT 57",
          "total": 85,
          "completed": 75
        },
        {
          "id": 1232,
          "name": "POT 58",
          "total": 94,
          "completed": 37
        },
        {
          "id": 1233,
          "name": "POT 59",
          "total": 98,
          "completed": 73
        },
        {
          "id": 1234,
          "name": "POT 60",
          "total": 97,
          "completed": 40
        }
      ]
    },
    {
      "id": 154,
      "name": "TYT-AYT Geometri PAF Palme Anlatım Föyü",
      "cover": "https://kurumsal.palmeyayinevi.com/Media/UserData/kapak/tyt-ayt/04_08_2021_09_59_29{tyt-aytgeometripaf-1}.jpg",
      "description": "\"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.\"",
      "class": "TYT - AYT",
      "lesson": "Geometri",
      "brand": "Palme Yayınevi",
      "bookContent": [
        {
          "id": 1263,
          "name": "PAF 1: Temel Kavramlar - Doğruda Açı",
          "total": 90,
          "completed": 36
        },
        {
          "id": 1264,
          "name": "PAF 2: Üçgende Açılar",
          "total": 91,
          "completed": 63
        },
        {
          "id": 1265,
          "name": "PAF 3: Dik Üçgen - Trigonometri",
          "total": 97,
          "completed": 60
        },
        {
          "id": 1266,
          "name": "PAF 4: İkizkenar Üçgen - Eşkenar Üçgen",
          "total": 84,
          "completed": 38
        },
        {
          "id": 1267,
          "name": "PAF 5: Üçgende Yükseklik - Üçgende Açıortay",
          "total": 92,
          "completed": 71
        },
        {
          "id": 1268,
          "name": "PAF 6: Üçgende Kenarortay",
          "total": 96,
          "completed": 65
        },
        {
          "id": 1269,
          "name": "PAF 7: Üçgende Eşlik - Benzerlik",
          "total": 83,
          "completed": 31
        },
        {
          "id": 1270,
          "name": "PAF 8: Üçgende Alan",
          "total": 93,
          "completed": 74
        },
        {
          "id": 1271,
          "name": "PAF 9: Üçgende Açı-Kenar Bağıntıları",
          "total": 97,
          "completed": 69
        },
        {
          "id": 1272,
          "name": "PAF 10: Çokgenler",
          "total": 91,
          "completed": 36
        },
        {
          "id": 1273,
          "name": "PAF 11: Genel Dörtgenler",
          "total": 98,
          "completed": 57
        },
        {
          "id": 1274,
          "name": "PAF 12: Yamuk - I",
          "total": 97,
          "completed": 71
        },
        {
          "id": 1275,
          "name": "PAF 13: Yamuk - II",
          "total": 96,
          "completed": 32
        },
        {
          "id": 1276,
          "name": "PAF 14: Yamuk - III",
          "total": 84,
          "completed": 74
        },
        {
          "id": 1277,
          "name": "PAF 15: Paralelkenar",
          "total": 81,
          "completed": 31
        },
        {
          "id": 1278,
          "name": "PAF 16: Dikdörtgen - Kare",
          "total": 84,
          "completed": 44
        },
        {
          "id": 1279,
          "name": "PAF 17: Eşkenar Dörtgen - Deltoid",
          "total": 97,
          "completed": 49
        },
        {
          "id": 1280,
          "name": "PAF 18: Çemberin Temel Elemanları ve Çemberde Açı",
          "total": 91,
          "completed": 40
        },
        {
          "id": 1281,
          "name": "PAF 19: Çemberde Uzunluk",
          "total": 81,
          "completed": 52
        },
        {
          "id": 1282,
          "name": "PAF 20: Çemberde Çevre ve Dairede Alan",
          "total": 88,
          "completed": 38
        },
        {
          "id": 1283,
          "name": "PAF 21: Dik Prizmalar",
          "total": 82,
          "completed": 63
        },
        {
          "id": 1284,
          "name": "PAF 22: Piramit ve Küre",
          "total": 94,
          "completed": 73
        },
        {
          "id": 1285,
          "name": "PAF 23: Analitik Düzlemde Nokta ve Doğrular-I",
          "total": 98,
          "completed": 35
        },
        {
          "id": 1286,
          "name": "PAF 24: Analitik Düzlemde Nokta ve Doğrular-II",
          "total": 82,
          "completed": 71
        },
        {
          "id": 1287,
          "name": "PAF 25: Analitik Düzlemde Dönüşümler",
          "total": 96,
          "completed": 77
        },
        {
          "id": 1288,
          "name": "PAF 26: Çemberin Analitik İncelenmesi",
          "total": 90,
          "completed": 39
        },
        {
          "id": 1289,
          "name": "PAF 27: Trigonometri - I",
          "total": 90,
          "completed": 76
        },
        {
          "id": 1290,
          "name": "PAF 28: Trigonometri - II",
          "total": 90,
          "completed": 36
        }
      ]
    },
    {
      "id": 155,
      "name": "TYT Matematik POT Palme Okul Testi",
      "cover": "https://kurumsal.palmeyayinevi.com/Media/UserData/kapak/tyt/02_07_2020_11_43_54{tytmatematikpot}.png",
      "description": "\"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.\"",
      "class": "TYT - AYT",
      "lesson": "Matematik",
      "brand": "Palme Yayınevi",
      "bookContent": [
        {
          "id": 1291,
          "name": "POT 1 ",
          "total": 94,
          "completed": 28
        },
        {
          "id": 1292,
          "name": "POT 2 ",
          "total": 81,
          "completed": 26
        },
        {
          "id": 1293,
          "name": "POT 3 ",
          "total": 87,
          "completed": 57
        },
        {
          "id": 1294,
          "name": "POT 4 ",
          "total": 98,
          "completed": 38
        },
        {
          "id": 1295,
          "name": "POT 5 ",
          "total": 93,
          "completed": 51
        },
        {
          "id": 1296,
          "name": "POT 6 ",
          "total": 93,
          "completed": 33
        },
        {
          "id": 1297,
          "name": "POT 7 ",
          "total": 96,
          "completed": 28
        },
        {
          "id": 1298,
          "name": "POT 8 ",
          "total": 89,
          "completed": 71
        },
        {
          "id": 1299,
          "name": "POT 9 ",
          "total": 81,
          "completed": 48
        },
        {
          "id": 1300,
          "name": "POT 10 ",
          "total": 90,
          "completed": 46
        },
        {
          "id": 1301,
          "name": "POT 11 ",
          "total": 88,
          "completed": 26
        },
        {
          "id": 1302,
          "name": "POT 12 ",
          "total": 95,
          "completed": 46
        },
        {
          "id": 1303,
          "name": "POT 13 ",
          "total": 81,
          "completed": 35
        },
        {
          "id": 1304,
          "name": "POT 14 ",
          "total": 89,
          "completed": 75
        },
        {
          "id": 1305,
          "name": "POT 15 ",
          "total": 97,
          "completed": 38
        },
        {
          "id": 1306,
          "name": "POT 16 ",
          "total": 90,
          "completed": 39
        },
        {
          "id": 1307,
          "name": "POT 17 ",
          "total": 89,
          "completed": 43
        },
        {
          "id": 1308,
          "name": "POT 18 ",
          "total": 92,
          "completed": 67
        },
        {
          "id": 1309,
          "name": "POT 19 ",
          "total": 86,
          "completed": 60
        },
        {
          "id": 1310,
          "name": "POT 20 ",
          "total": 84,
          "completed": 68
        },
        {
          "id": 1311,
          "name": "POT 21 ",
          "total": 91,
          "completed": 64
        },
        {
          "id": 1312,
          "name": "POT 22 ",
          "total": 83,
          "completed": 30
        },
        {
          "id": 1313,
          "name": "POT 23 ",
          "total": 85,
          "completed": 47
        },
        {
          "id": 1314,
          "name": "POT 24 ",
          "total": 87,
          "completed": 34
        },
        {
          "id": 1315,
          "name": "POT 25 ",
          "total": 81,
          "completed": 63
        },
        {
          "id": 1316,
          "name": "POT 26 ",
          "total": 87,
          "completed": 79
        },
        {
          "id": 1317,
          "name": "POT 27 ",
          "total": 85,
          "completed": 73
        },
        {
          "id": 1318,
          "name": "POT 28 ",
          "total": 84,
          "completed": 26
        },
        {
          "id": 1319,
          "name": "POT 29 ",
          "total": 89,
          "completed": 43
        },
        {
          "id": 1320,
          "name": "POT 30 ",
          "total": 83,
          "completed": 38
        },
        {
          "id": 1321,
          "name": "POT 31 ",
          "total": 82,
          "completed": 72
        },
        {
          "id": 1322,
          "name": "POT 32 ",
          "total": 95,
          "completed": 45
        },
        {
          "id": 1323,
          "name": "POT 33 ",
          "total": 86,
          "completed": 58
        },
        {
          "id": 1324,
          "name": "POT 34 ",
          "total": 82,
          "completed": 70
        },
        {
          "id": 1325,
          "name": "POT 35 ",
          "total": 88,
          "completed": 53
        },
        {
          "id": 1326,
          "name": "POT 36 ",
          "total": 96,
          "completed": 56
        },
        {
          "id": 1327,
          "name": "POT 37 ",
          "total": 87,
          "completed": 50
        },
        {
          "id": 1328,
          "name": "POT 38 ",
          "total": 93,
          "completed": 59
        },
        {
          "id": 1329,
          "name": "POT 39 ",
          "total": 89,
          "completed": 71
        },
        {
          "id": 1330,
          "name": "POT 40 ",
          "total": 90,
          "completed": 49
        },
        {
          "id": 1331,
          "name": "POT 41 ",
          "total": 84,
          "completed": 79
        },
        {
          "id": 1332,
          "name": "POT 42 ",
          "total": 98,
          "completed": 71
        },
        {
          "id": 1333,
          "name": "POT 43 ",
          "total": 95,
          "completed": 31
        },
        {
          "id": 1334,
          "name": "POT 44 ",
          "total": 82,
          "completed": 61
        },
        {
          "id": 1335,
          "name": "POT 45 ",
          "total": 82,
          "completed": 70
        },
        {
          "id": 1336,
          "name": "POT 46 ",
          "total": 88,
          "completed": 31
        },
        {
          "id": 1337,
          "name": "POT 47 ",
          "total": 86,
          "completed": 60
        },
        {
          "id": 1338,
          "name": "POT 48 ",
          "total": 97,
          "completed": 77
        },
        {
          "id": 1339,
          "name": "POT 49 ",
          "total": 98,
          "completed": 58
        },
        {
          "id": 1340,
          "name": "POT 50 ",
          "total": 99,
          "completed": 45
        },
        {
          "id": 1341,
          "name": "POT 51 ",
          "total": 99,
          "completed": 46
        },
        {
          "id": 1342,
          "name": "POT 52 ",
          "total": 96,
          "completed": 39
        },
        {
          "id": 1343,
          "name": "POT 53 ",
          "total": 87,
          "completed": 65
        },
        {
          "id": 1344,
          "name": "POT 54 ",
          "total": 81,
          "completed": 45
        },
        {
          "id": 1345,
          "name": "POT 55 ",
          "total": 96,
          "completed": 68
        },
        {
          "id": 1346,
          "name": "POT 56 ",
          "total": 88,
          "completed": 70
        },
        {
          "id": 1347,
          "name": "POT 57 ",
          "total": 88,
          "completed": 32
        },
        {
          "id": 1348,
          "name": "POT 58 ",
          "total": 82,
          "completed": 65
        },
        {
          "id": 1349,
          "name": "POT 59 ",
          "total": 90,
          "completed": 79
        },
        {
          "id": 1350,
          "name": "POT 60 ",
          "total": 87,
          "completed": 75
        },
        {
          "id": 1351,
          "name": "POT 61 ",
          "total": 91,
          "completed": 39
        },
        {
          "id": 1352,
          "name": "POT 62 ",
          "total": 87,
          "completed": 36
        },
        {
          "id": 1353,
          "name": "POT 63 ",
          "total": 83,
          "completed": 51
        },
        {
          "id": 1354,
          "name": "POT 64 ",
          "total": 81,
          "completed": 74
        },
        {
          "id": 1355,
          "name": "POT 65 ",
          "total": 93,
          "completed": 63
        },
        {
          "id": 1356,
          "name": "POT 66 ",
          "total": 86,
          "completed": 36
        },
        {
          "id": 1357,
          "name": "POT 67 ",
          "total": 84,
          "completed": 72
        },
        {
          "id": 1358,
          "name": "POT 68 ",
          "total": 98,
          "completed": 33
        },
        {
          "id": 1359,
          "name": "POT 69 ",
          "total": 99,
          "completed": 36
        },
        {
          "id": 1360,
          "name": "POT 70 ",
          "total": 89,
          "completed": 55
        },
        {
          "id": 1361,
          "name": "POT 71 ",
          "total": 88,
          "completed": 35
        },
        {
          "id": 1362,
          "name": "POT 72 ",
          "total": 86,
          "completed": 33
        }
      ]
    },
    {
      "id": 156,
      "name": "AYT Edebiyat POT Palme Okul Testi",
      "cover": "https://kurumsal.palmeyayinevi.com/Media/UserData/kapak/ayt/19_03_2021_16_49_49{ayt-edb-pot-kapak}.png",
      "description": "\"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.\"",
      "class": "TYT - AYT",
      "lesson": "Türk Dili ve Edebiyatı",
      "brand": "Palme Yayınevi",
      "bookContent": [
        {
          "id": 1363,
          "name": "POT 1",
          "total": 87,
          "completed": 68
        },
        {
          "id": 1364,
          "name": "POT 2",
          "total": 81,
          "completed": 69
        },
        {
          "id": 1365,
          "name": "POT 3",
          "total": 91,
          "completed": 59
        },
        {
          "id": 1366,
          "name": "POT 4",
          "total": 94,
          "completed": 62
        },
        {
          "id": 1367,
          "name": "POT 5",
          "total": 88,
          "completed": 46
        },
        {
          "id": 1368,
          "name": "POT 6",
          "total": 92,
          "completed": 28
        },
        {
          "id": 1369,
          "name": "POT 7",
          "total": 99,
          "completed": 46
        },
        {
          "id": 1370,
          "name": "POT 8",
          "total": 91,
          "completed": 29
        },
        {
          "id": 1371,
          "name": "POT 9",
          "total": 97,
          "completed": 44
        },
        {
          "id": 1372,
          "name": "POT 10",
          "total": 96,
          "completed": 61
        },
        {
          "id": 1373,
          "name": "POT 11",
          "total": 93,
          "completed": 58
        },
        {
          "id": 1374,
          "name": "POT 12",
          "total": 81,
          "completed": 74
        },
        {
          "id": 1375,
          "name": "POT 13",
          "total": 96,
          "completed": 29
        },
        {
          "id": 1376,
          "name": "POT 14",
          "total": 99,
          "completed": 29
        },
        {
          "id": 1377,
          "name": "POT 15",
          "total": 96,
          "completed": 50
        },
        {
          "id": 1378,
          "name": "POT 16",
          "total": 85,
          "completed": 54
        },
        {
          "id": 1379,
          "name": "POT 17",
          "total": 89,
          "completed": 58
        },
        {
          "id": 1380,
          "name": "POT 18",
          "total": 83,
          "completed": 68
        },
        {
          "id": 1381,
          "name": "POT 19",
          "total": 97,
          "completed": 61
        },
        {
          "id": 1382,
          "name": "POT 20",
          "total": 83,
          "completed": 76
        },
        {
          "id": 1383,
          "name": "POT 21",
          "total": 92,
          "completed": 45
        },
        {
          "id": 1384,
          "name": "POT 22",
          "total": 91,
          "completed": 78
        },
        {
          "id": 1385,
          "name": "POT 23",
          "total": 84,
          "completed": 37
        },
        {
          "id": 1386,
          "name": "POT 24",
          "total": 96,
          "completed": 72
        },
        {
          "id": 1387,
          "name": "POT 25",
          "total": 92,
          "completed": 61
        },
        {
          "id": 1388,
          "name": "POT 26",
          "total": 89,
          "completed": 44
        },
        {
          "id": 1389,
          "name": "POT 27",
          "total": 81,
          "completed": 76
        },
        {
          "id": 1390,
          "name": "POT 28",
          "total": 96,
          "completed": 47
        },
        {
          "id": 1391,
          "name": "POT 29",
          "total": 95,
          "completed": 70
        },
        {
          "id": 1392,
          "name": "POT 30",
          "total": 90,
          "completed": 37
        },
        {
          "id": 1393,
          "name": "POT 31",
          "total": 98,
          "completed": 59
        },
        {
          "id": 1394,
          "name": "POT 32",
          "total": 86,
          "completed": 26
        },
        {
          "id": 1395,
          "name": "POT 33",
          "total": 92,
          "completed": 56
        },
        {
          "id": 1396,
          "name": "POT 34",
          "total": 85,
          "completed": 30
        },
        {
          "id": 1397,
          "name": "POT 35",
          "total": 87,
          "completed": 56
        },
        {
          "id": 1398,
          "name": "POT 36",
          "total": 87,
          "completed": 38
        },
        {
          "id": 1399,
          "name": "POT 37",
          "total": 99,
          "completed": 39
        },
        {
          "id": 1400,
          "name": "POT 38",
          "total": 94,
          "completed": 65
        },
        {
          "id": 1401,
          "name": "POT 39",
          "total": 94,
          "completed": 62
        },
        {
          "id": 1402,
          "name": "POT 40",
          "total": 89,
          "completed": 27
        },
        {
          "id": 1403,
          "name": "POT 41",
          "total": 81,
          "completed": 38
        },
        {
          "id": 1404,
          "name": "POT 42",
          "total": 92,
          "completed": 30
        },
        {
          "id": 1405,
          "name": "POT 43",
          "total": 98,
          "completed": 49
        },
        {
          "id": 1406,
          "name": "POT 44",
          "total": 91,
          "completed": 53
        },
        {
          "id": 1407,
          "name": "POT 45",
          "total": 95,
          "completed": 74
        },
        {
          "id": 1408,
          "name": "POT 46",
          "total": 99,
          "completed": 79
        },
        {
          "id": 1409,
          "name": "POT 47",
          "total": 83,
          "completed": 48
        },
        {
          "id": 1410,
          "name": "POT 48",
          "total": 99,
          "completed": 71
        },
        {
          "id": 1411,
          "name": "POT 49",
          "total": 90,
          "completed": 40
        },
        {
          "id": 1412,
          "name": "POT 50",
          "total": 87,
          "completed": 50
        },
        {
          "id": 1413,
          "name": "POT 51",
          "total": 91,
          "completed": 58
        },
        {
          "id": 1414,
          "name": "POT 52",
          "total": 84,
          "completed": 40
        }
      ]
    },
    {
      "id": 157,
      "name": "AYT Kimya PAF Palme Anlatım Föyü",
      "cover": "https://kurumsal.palmeyayinevi.com/Media/UserData/kapak/ayt/02_07_2020_17_44_12{aytkimyapaf}.png",
      "description": "\"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.\"",
      "class": "TYT - AYT",
      "lesson": "Kimya",
      "brand": "Palme Yayınevi",
      "bookContent": [
        {
          "id": 1419,
          "name": "PAF 1 : ATOMUN KUANTUM MODELİ",
          "total": 97,
          "completed": 75
        },
        {
          "id": 1420,
          "name": "PAF 2 : PERİYODİK ÖZELLİKLER",
          "total": 81,
          "completed": 28
        },
        {
          "id": 1421,
          "name": "PAF 3 : ELEMENTLERİ TANIYALIM",
          "total": 93,
          "completed": 38
        },
        {
          "id": 1422,
          "name": "PAF 4 : GAZLARIN ÖZELLİKLERİ VE GAZ YASALARI",
          "total": 89,
          "completed": 75
        },
        {
          "id": 1423,
          "name": "PAF 5 : GAZLARDA KİNETİK TEORİ",
          "total": 93,
          "completed": 58
        },
        {
          "id": 1424,
          "name": "PAF 6 : ÇÖZÜCÜ VE ÇÖZÜNEN ETKİLEŞİMLERİ",
          "total": 93,
          "completed": 39
        },
        {
          "id": 1425,
          "name": "PAF 7 : KOLİGATİF ÖZELLİKLER",
          "total": 97,
          "completed": 38
        },
        {
          "id": 1426,
          "name": "PAF 8 : TEPKİMELERDE ISI DEĞİŞİMİ",
          "total": 88,
          "completed": 50
        },
        {
          "id": 1427,
          "name": "PAF 9 : TEPKİME HIZLARI",
          "total": 83,
          "completed": 76
        },
        {
          "id": 1428,
          "name": "PAF 10 : TEPKİME HIZINA ETKİ EDEN FAKTÖRLER",
          "total": 98,
          "completed": 60
        },
        {
          "id": 1429,
          "name": "PAF 11 : KİMYASAL DENGE",
          "total": 97,
          "completed": 51
        },
        {
          "id": 1430,
          "name": "PAF 12 : DENGEYİ ETKİLEYEN FAKTÖRLER",
          "total": 92,
          "completed": 66
        },
        {
          "id": 1431,
          "name": "PAF 13 : SULU ÇÖZELTİ DENGELERİ",
          "total": 95,
          "completed": 61
        },
        {
          "id": 1432,
          "name": "PAF 14 : ÇÖZÜNME VE ÇÖKELME DENGELERİ",
          "total": 99,
          "completed": 28
        },
        {
          "id": 1433,
          "name": "PAF 15 : KİMYA VE ELEKTRİK - I",
          "total": 96,
          "completed": 48
        },
        {
          "id": 1434,
          "name": "PAF 16 : KİMYA VE ELEKTRİK – II",
          "total": 93,
          "completed": 29
        },
        {
          "id": 1435,
          "name": "PAF 17 : KİMYA VE ELEKTRİK – III",
          "total": 88,
          "completed": 72
        },
        {
          "id": 1436,
          "name": "PAF 18 : ANORGANİK VE ORGANİK BİLEŞİKLER",
          "total": 95,
          "completed": 33
        },
        {
          "id": 1437,
          "name": "PAF 19 : HİDROKARBONLAR",
          "total": 93,
          "completed": 62
        },
        {
          "id": 1438,
          "name": "PAF 20 : ALKENLER (OLEFİNLER)",
          "total": 91,
          "completed": 77
        },
        {
          "id": 1439,
          "name": "PAF 21 : ALKİNLER",
          "total": 98,
          "completed": 44
        },
        {
          "id": 1440,
          "name": "PAF 22 : AROMATİK BİLEŞİKLER",
          "total": 85,
          "completed": 40
        },
        {
          "id": 1441,
          "name": "PAF 23 : ALKOLLER VE ETERLER",
          "total": 87,
          "completed": 71
        },
        {
          "id": 1442,
          "name": "PAF 24 : KARBONİL BİLEŞİKLERİ",
          "total": 91,
          "completed": 79
        },
        {
          "id": 1443,
          "name": "PAF 25 : KARBOKSİLİK ASİTLER",
          "total": 86,
          "completed": 47
        },
        {
          "id": 1444,
          "name": "PAF 26 : ENERJİ KAYNAKLARI VE BİLİMSEL GELİŞMELER",
          "total": 90,
          "completed": 28
        }
      ]
    },
    {
      "id": 159,
      "name": "AYT Tarih POT Palme Okul Testi",
      "cover": "https://kurumsal.palmeyayinevi.com/Media/UserData/kapak/ayt/02_07_2020_11_40_01{ayttarihpot}.png",
      "description": "\"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.\"",
      "class": "TYT - AYT",
      "lesson": "Tarih",
      "brand": "Palme Yayınevi",
      "bookContent": [
        {
          "id": 1445,
          "name": "Tarih ve Zaman",
          "total": 81,
          "completed": 61
        },
        {
          "id": 1446,
          "name": "İnsanlığın İlk Dönemleri",
          "total": 90,
          "completed": 59
        },
        {
          "id": 1447,
          "name": "Orta Çağ'da Dünya",
          "total": 96,
          "completed": 71
        },
        {
          "id": 1448,
          "name": "İlk ve Orta Çağlarda Türk Dünyası",
          "total": 90,
          "completed": 71
        },
        {
          "id": 1449,
          "name": "İslam Medeniyetinin Doğuşu",
          "total": 98,
          "completed": 30
        },
        {
          "id": 1450,
          "name": "Türkler'in İslamiyet'i Kabulü ve İlk Türk İslam Devletleri",
          "total": 91,
          "completed": 43
        },
        {
          "id": 1451,
          "name": "Yerleşme ve Devletleşme Sürecinde Selçuklu Türkiyesi",
          "total": 96,
          "completed": 76
        },
        {
          "id": 1452,
          "name": "Beylikten Devlete Osmanlı Siyaseti (1302-1453)",
          "total": 85,
          "completed": 64
        },
        {
          "id": 1453,
          "name": "Devletleşme Sürecinde Savaşçılar ve Askerler",
          "total": 83,
          "completed": 72
        },
        {
          "id": 1454,
          "name": "Beylikten Devlete Osmanlı Medeniyeti",
          "total": 93,
          "completed": 32
        },
        {
          "id": 1455,
          "name": "Dünya Gücü Osmanlı (1453-1595)",
          "total": 88,
          "completed": 60
        },
        {
          "id": 1456,
          "name": "Sultan ve Osmanlı Merkez Teşkilatı",
          "total": 98,
          "completed": 57
        },
        {
          "id": 1457,
          "name": "Klasik Çağda Osmanlı Toplum Düzeni",
          "total": 97,
          "completed": 52
        },
        {
          "id": 1458,
          "name": "Değişen Dünya Dengeleri Karşısında Osmanlı Siyaseti",
          "total": 82,
          "completed": 53
        },
        {
          "id": 1459,
          "name": "Değişen Dünya Dengeleri Karşısında Osmanlı Siyaseti (1595-1774)",
          "total": 87,
          "completed": 46
        },
        {
          "id": 1460,
          "name": "Değişim Çağında Avrupa ve Osmanlı",
          "total": 94,
          "completed": 51
        },
        {
          "id": 1461,
          "name": "Uluslararası İlişkilerde Denge Siyaseti",
          "total": 96,
          "completed": 37
        },
        {
          "id": 1462,
          "name": "Uluslararası İlişkilerde Denge Stratejisi (1774-1914)",
          "total": 87,
          "completed": 56
        },
        {
          "id": 1463,
          "name": "Devrimler Çağında Değişen Devlet- Toplum İlişkileri",
          "total": 84,
          "completed": 54
        },
        {
          "id": 1464,
          "name": "Sermaye ve Emek",
          "total": 84,
          "completed": 27
        },
        {
          "id": 1465,
          "name": "XIX. ve XX Yüzyılda Değişen Gündelik Hayat",
          "total": 99,
          "completed": 73
        },
        {
          "id": 1466,
          "name": "XX. Yüzyıl Başlarında Osmanlı Devleti ve Dünya",
          "total": 96,
          "completed": 53
        },
        {
          "id": 1467,
          "name": "Milli Mücadele",
          "total": 91,
          "completed": 74
        },
        {
          "id": 1468,
          "name": "Atatürkçülük ve Türk İnkılabı",
          "total": 95,
          "completed": 59
        },
        {
          "id": 1469,
          "name": "İki Savaş Arasındaki Dönemde Türkiye ve Dünya",
          "total": 99,
          "completed": 61
        },
        {
          "id": 1470,
          "name": "İkinci Dünya Savaşı Sürecinde Türkiye ve Dünya",
          "total": 85,
          "completed": 59
        },
        {
          "id": 1471,
          "name": "İkinci Dünya Savaşı Sonrasında Türkiye ve Dünya",
          "total": 92,
          "completed": 73
        },
        {
          "id": 1472,
          "name": "Toplumsal Devrim Çağında Dünya ve Türkiye",
          "total": 97,
          "completed": 64
        },
        {
          "id": 1473,
          "name": "XXI. Yüzyılın Eşiğinde Türkiye ve Dünya",
          "total": 91,
          "completed": 63
        },
        {
          "id": 1474,
          "name": "İki Küresel Savaş Arasında Dünya",
          "total": 85,
          "completed": 41
        },
        {
          "id": 1475,
          "name": "II. Dünya Savaşı",
          "total": 81,
          "completed": 53
        },
        {
          "id": 1476,
          "name": "Soğuk Savaş Dönemi",
          "total": 91,
          "completed": 79
        },
        {
          "id": 1477,
          "name": "Yumuşama Dönemi ve Sonrası",
          "total": 98,
          "completed": 41
        },
        {
          "id": 1478,
          "name": "Küreselleşen Dünya",
          "total": 89,
          "completed": 26
        }
      ]
    },
    {
      "id": 160,
      "name": "AYT Psikoloji, Sosyoloji, Mantık POT Palme Okul Testi",
      "cover": "https://kurumsal.palmeyayinevi.com/Media/UserData/kapak/ayt/02_07_2020_11_38_54{aytpsikolojisosyolojimantıkpot}.png",
      "description": "\"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.\"",
      "class": "TYT - AYT",
      "lesson": "Psikoloji",
      "brand": "Palme Yayınevi",
      "bookContent": [
        {
          "id": 1479,
          "name": "Ünite - 1",
          "total": 87,
          "completed": 75
        }
      ]
    },
    {
      "id": 167,
      "name": "AYT Matematik PAF Palme Anlatım Föyü",
      "cover": "https://kurumsal.palmeyayinevi.com/Media/UserData/kapak/ayt/02_07_2020_17_45_42{aytmatematikpaf}.png",
      "description": "\"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.\"",
      "class": "TYT - AYT",
      "lesson": "Matematik",
      "brand": "Palme Yayınevi",
      "bookContent": [
        {
          "id": 1507,
          "name": "PAF 1 : Fonksiyonlarda Uygulamalar",
          "total": 88,
          "completed": 68
        },
        {
          "id": 1508,
          "name": "PAF 2 : İkinci Dereceden Fonksiyonlar ve Grafikleri - I",
          "total": 99,
          "completed": 49
        },
        {
          "id": 1509,
          "name": "PAF 3 : İkinci Dereceden Fonksiyonlar ve Grafikleri - II",
          "total": 83,
          "completed": 58
        },
        {
          "id": 1510,
          "name": "PAF 4 : Fonksiyonların Dönüşümleri",
          "total": 87,
          "completed": 71
        },
        {
          "id": 1511,
          "name": "PAF 5 : Denklem ve Eşitsizlik Sistemleri",
          "total": 96,
          "completed": 37
        },
        {
          "id": 1512,
          "name": "PAF 6 : Olasılık",
          "total": 83,
          "completed": 65
        },
        {
          "id": 1513,
          "name": "PAF 7 : Üstel ve Logaritma Fonksiyonları",
          "total": 92,
          "completed": 26
        },
        {
          "id": 1514,
          "name": "PAF 8 : Üstel ve Logaritmalı Denklem ve Eşitsizlikler",
          "total": 82,
          "completed": 47
        },
        {
          "id": 1515,
          "name": "PAF 9 : Diziler",
          "total": 94,
          "completed": 45
        },
        {
          "id": 1516,
          "name": "PAF 10 : Aritmetik ve Geometrik Diziler",
          "total": 98,
          "completed": 63
        },
        {
          "id": 1517,
          "name": "PAF 11 : Türev (Limit)",
          "total": 81,
          "completed": 47
        },
        {
          "id": 1518,
          "name": "PAF 12 : Türev (Süreklilik)",
          "total": 90,
          "completed": 69
        },
        {
          "id": 1519,
          "name": "PAF 13 : Türev (Anlık Değişim Oranı ve Türevin Tanımı)",
          "total": 95,
          "completed": 41
        },
        {
          "id": 1520,
          "name": "PAF 14 : Türev (Türev Alma Kuralları)",
          "total": 83,
          "completed": 51
        },
        {
          "id": 1521,
          "name": "PAF 15 : Türev (Parçalı ve Mutlak Değerli Fonksiyonların Türevi)",
          "total": 95,
          "completed": 72
        },
        {
          "id": 1522,
          "name": "PAF 16 : Türev (Geometrik Yorum) (Fiziksel Yorum)",
          "total": 81,
          "completed": 47
        },
        {
          "id": 1523,
          "name": "PAF 17 : Türev (Artan - Azalan Fonksiyonlar  ve Ekstremum Noktaları)",
          "total": 83,
          "completed": 46
        },
        {
          "id": 1524,
          "name": "PAF 18 : Türev (Maksimum - Minimum  Problemleri ve Polinom  Fonksiyonların Grafikleri)",
          "total": 89,
          "completed": 66
        },
        {
          "id": 1525,
          "name": "PAF 19 : Belirsiz İntegral",
          "total": 99,
          "completed": 38
        },
        {
          "id": 1526,
          "name": "PAF 20 : İntegral (Belirli İntegral)",
          "total": 89,
          "completed": 62
        },
        {
          "id": 1527,
          "name": "PAF 21 : İntegral (Belirli İntegral Uygulamaları)",
          "total": 98,
          "completed": 32
        }
      ]
    },
    {
      "id": 172,
      "name": "AYT Psikoloji Sosyoloji Mantık PAF Palme Anlatım Föyü",
      "cover": "https://kurumsal.palmeyayinevi.com/Media/UserData/kapak/ayt/02_07_2020_17_47_59{aytpsikolojisosyolojimantıkpaf}.png",
      "description": "\"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.\"",
      "class": "TYT - AYT",
      "lesson": "Psikoloji",
      "brand": "Palme Yayınevi",
      "bookContent": [
        {
          "id": 1615,
          "name": "PAF 1: Psikoloji Bilimini Tanıyalım",
          "total": 87,
          "completed": 34
        },
        {
          "id": 1616,
          "name": "PAF 2: Psikolojinin Temel Süreçleri",
          "total": 91,
          "completed": 55
        },
        {
          "id": 1617,
          "name": "PAF 3: Öğrenme-Bellek-Düşünme-Zekâ",
          "total": 83,
          "completed": 26
        },
        {
          "id": 1618,
          "name": "PAF 4: Ruh Sağlığının Temelleri",
          "total": 95,
          "completed": 42
        },
        {
          "id": 1619,
          "name": "PAF 5: Sosyolojiye Giriş",
          "total": 94,
          "completed": 54
        },
        {
          "id": 1620,
          "name": "PAF 6: Birey Ve Toplum",
          "total": 96,
          "completed": 74
        },
        {
          "id": 1621,
          "name": "PAF 7: Toplumsal Değişme ve Gelişme",
          "total": 86,
          "completed": 55
        },
        {
          "id": 1622,
          "name": "PAF 8: Toplumsal Kurumlar ve İşlevleri",
          "total": 93,
          "completed": 56
        },
        {
          "id": 1623,
          "name": "PAF 9: Mantık",
          "total": 94,
          "completed": 73
        },
        {
          "id": 1624,
          "name": "PAF 10: Mantığa Giriş",
          "total": 88,
          "completed": 50
        },
        {
          "id": 1625,
          "name": "PAF 11: Çıkarım,Mantık ve Dil",
          "total": 86,
          "completed": 38
        },
        {
          "id": 1626,
          "name": "PAF 12: Sembolik (Modern) Mantık",
          "total": 84,
          "completed": 65
        }
      ]
    },
    {
      "id": 174,
      "name": "AYT Kimya PSF Palme Soru Föyü",
      "cover": "https://kurumsal.palmeyayinevi.com/Media/UserData/kapak/ayt/02_07_2020_17_45_08{aytkimyapsf}.png",
      "description": "\"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.\"",
      "class": "TYT - AYT",
      "lesson": "Kimya",
      "brand": "Palme Yayınevi",
      "bookContent": [
        {
          "id": 1651,
          "name": "PSF 1",
          "total": 99,
          "completed": 69
        },
        {
          "id": 1652,
          "name": "PSF 2",
          "total": 82,
          "completed": 45
        },
        {
          "id": 1653,
          "name": "PSF 3",
          "total": 89,
          "completed": 26
        },
        {
          "id": 1654,
          "name": "PSF 4",
          "total": 87,
          "completed": 49
        },
        {
          "id": 1655,
          "name": "PSF 5",
          "total": 95,
          "completed": 60
        },
        {
          "id": 1656,
          "name": "PSF 6",
          "total": 83,
          "completed": 46
        },
        {
          "id": 1657,
          "name": "PSF 7",
          "total": 90,
          "completed": 44
        },
        {
          "id": 1658,
          "name": "PSF 8",
          "total": 85,
          "completed": 38
        },
        {
          "id": 1659,
          "name": "PSF 9",
          "total": 89,
          "completed": 27
        },
        {
          "id": 1660,
          "name": "PSF 10",
          "total": 87,
          "completed": 32
        },
        {
          "id": 1661,
          "name": "PSF 11",
          "total": 99,
          "completed": 26
        },
        {
          "id": 1662,
          "name": "PSF 12",
          "total": 87,
          "completed": 44
        },
        {
          "id": 1663,
          "name": "PSF 13",
          "total": 81,
          "completed": 50
        },
        {
          "id": 1664,
          "name": "PSF 14",
          "total": 92,
          "completed": 59
        },
        {
          "id": 1665,
          "name": "PSF 15",
          "total": 89,
          "completed": 51
        },
        {
          "id": 1666,
          "name": "PSF 16",
          "total": 84,
          "completed": 30
        },
        {
          "id": 1667,
          "name": "PSF 17",
          "total": 85,
          "completed": 64
        },
        {
          "id": 1668,
          "name": "PSF 18",
          "total": 90,
          "completed": 76
        },
        {
          "id": 1669,
          "name": "PSF 19",
          "total": 87,
          "completed": 69
        },
        {
          "id": 1670,
          "name": "PSF 20",
          "total": 91,
          "completed": 47
        },
        {
          "id": 1671,
          "name": "PSF 21",
          "total": 95,
          "completed": 27
        },
        {
          "id": 1672,
          "name": "PSF 22",
          "total": 96,
          "completed": 29
        },
        {
          "id": 1673,
          "name": "PSF 23",
          "total": 81,
          "completed": 42
        },
        {
          "id": 1674,
          "name": "PSF 24",
          "total": 91,
          "completed": 56
        },
        {
          "id": 1675,
          "name": "PSF 25",
          "total": 83,
          "completed": 70
        },
        {
          "id": 1676,
          "name": "PSF 26",
          "total": 94,
          "completed": 76
        },
        {
          "id": 1677,
          "name": "PSF 27",
          "total": 87,
          "completed": 29
        }
      ]
    },
    {
      "id": 175,
      "name": "TYT-AYT Felsefe POT Palme Okul Testi",
      "cover": "https://kurumsal.palmeyayinevi.com/Media/UserData/kapak/tyt-ayt/02_07_2020_11_47_02{tytaytfelsefepot}.png",
      "description": "\"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.\"",
      "class": "TYT - AYT",
      "lesson": "Felsefe",
      "brand": "Palme Yayınevi",
      "bookContent": [
        {
          "id": 1678,
          "name": "POT 1",
          "total": 85,
          "completed": 43
        },
        {
          "id": 1679,
          "name": "POT 2",
          "total": 81,
          "completed": 41
        },
        {
          "id": 1680,
          "name": "POT 3",
          "total": 90,
          "completed": 66
        },
        {
          "id": 1681,
          "name": "POT 4",
          "total": 86,
          "completed": 57
        },
        {
          "id": 1682,
          "name": "POT 5",
          "total": 93,
          "completed": 41
        },
        {
          "id": 1683,
          "name": "POT 6",
          "total": 86,
          "completed": 59
        },
        {
          "id": 1684,
          "name": "POT 7",
          "total": 92,
          "completed": 29
        },
        {
          "id": 1685,
          "name": "POT 8",
          "total": 87,
          "completed": 36
        },
        {
          "id": 1686,
          "name": "POT 9",
          "total": 89,
          "completed": 26
        },
        {
          "id": 1687,
          "name": "POT 10",
          "total": 93,
          "completed": 26
        },
        {
          "id": 1688,
          "name": "POT 11",
          "total": 99,
          "completed": 57
        },
        {
          "id": 1689,
          "name": "POT 12",
          "total": 90,
          "completed": 41
        },
        {
          "id": 1690,
          "name": "POT 13",
          "total": 95,
          "completed": 41
        },
        {
          "id": 1691,
          "name": "POT 14",
          "total": 94,
          "completed": 57
        },
        {
          "id": 1692,
          "name": "POT 15",
          "total": 87,
          "completed": 51
        },
        {
          "id": 1693,
          "name": "POT 16",
          "total": 88,
          "completed": 65
        },
        {
          "id": 1694,
          "name": "POT 17",
          "total": 89,
          "completed": 69
        },
        {
          "id": 1695,
          "name": "POT 18",
          "total": 86,
          "completed": 70
        },
        {
          "id": 1696,
          "name": "POT 19",
          "total": 97,
          "completed": 75
        },
        {
          "id": 1697,
          "name": "POT 20",
          "total": 89,
          "completed": 62
        },
        {
          "id": 1698,
          "name": "POT 21",
          "total": 93,
          "completed": 47
        },
        {
          "id": 1699,
          "name": "POT 22",
          "total": 82,
          "completed": 51
        },
        {
          "id": 1700,
          "name": "POT 23",
          "total": 89,
          "completed": 50
        },
        {
          "id": 1701,
          "name": "POT 24",
          "total": 85,
          "completed": 43
        }
      ]
    },
    {
      "id": 177,
      "name": "TYT - AYT Geometri POT Palme Okul Testi",
      "cover": "https://kurumsal.palmeyayinevi.com/Media/UserData/kapak/tyt-ayt/02_07_2020_11_47_27{tytaytgeometripot}.png",
      "description": "\"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.\"",
      "class": "TYT - AYT",
      "lesson": "Geometri",
      "brand": "Palme Yayınevi",
      "bookContent": [
        {
          "id": 1702,
          "name": "Geometri",
          "total": 86,
          "completed": 27
        }
      ]
    },
    {
      "id": 178,
      "name": "AYT Psikoloji Sosyoloji Mantık PSF Palme Soru Föyü",
      "cover": "https://kurumsal.palmeyayinevi.com/Media/UserData/kapak/ayt/02_07_2020_17_48_31{aytpsikolojisosyolojimantıkpsf}.png",
      "description": "\"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.\"",
      "class": "TYT - AYT",
      "lesson": "Psikoloji",
      "brand": "Palme Yayınevi",
      "bookContent": [
        {
          "id": 1709,
          "name": "PSF 1",
          "total": 99,
          "completed": 58
        },
        {
          "id": 1710,
          "name": "PSF 2",
          "total": 82,
          "completed": 38
        },
        {
          "id": 1711,
          "name": "PSF 3",
          "total": 91,
          "completed": 48
        },
        {
          "id": 1712,
          "name": "PSF 4",
          "total": 86,
          "completed": 37
        },
        {
          "id": 1713,
          "name": "PSF 5",
          "total": 84,
          "completed": 53
        },
        {
          "id": 1714,
          "name": "PSF 6",
          "total": 93,
          "completed": 76
        },
        {
          "id": 1715,
          "name": "PSF 7",
          "total": 86,
          "completed": 51
        },
        {
          "id": 1716,
          "name": "PSF 8",
          "total": 90,
          "completed": 45
        },
        {
          "id": 1717,
          "name": "PSF 9",
          "total": 97,
          "completed": 55
        },
        {
          "id": 1718,
          "name": "PSF 10",
          "total": 83,
          "completed": 76
        },
        {
          "id": 1719,
          "name": "PSF 11",
          "total": 92,
          "completed": 57
        },
        {
          "id": 1720,
          "name": "PSF 12",
          "total": 97,
          "completed": 65
        }
      ]
    },
    {
      "id": 179,
      "name": "AYT Edebiyat PAF Palme Anlatım Föyü",
      "cover": "https://kurumsal.palmeyayinevi.com/Media/UserData/kapak/ayt/02_07_2020_17_39_57{aytedebiyatpaf}.png",
      "description": "\"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.\"",
      "class": "TYT - AYT",
      "lesson": "Türk Dili ve Edebiyatı",
      "brand": "Palme Yayınevi",
      "bookContent": [
        {
          "id": 1721,
          "name": "PAF 1",
          "total": 99,
          "completed": 52
        },
        {
          "id": 1722,
          "name": "PAF 2",
          "total": 95,
          "completed": 41
        },
        {
          "id": 1723,
          "name": "PAF 3",
          "total": 84,
          "completed": 48
        },
        {
          "id": 1724,
          "name": "PAF 4",
          "total": 95,
          "completed": 73
        },
        {
          "id": 1725,
          "name": "PAF 5",
          "total": 93,
          "completed": 53
        },
        {
          "id": 1726,
          "name": "PAF 6",
          "total": 82,
          "completed": 64
        },
        {
          "id": 1727,
          "name": "PAF 7",
          "total": 95,
          "completed": 42
        },
        {
          "id": 1728,
          "name": "PAF 8",
          "total": 91,
          "completed": 72
        },
        {
          "id": 1729,
          "name": "PAF 9",
          "total": 91,
          "completed": 26
        },
        {
          "id": 1730,
          "name": "PAF 10",
          "total": 83,
          "completed": 74
        },
        {
          "id": 1731,
          "name": "PAF 11",
          "total": 88,
          "completed": 55
        },
        {
          "id": 1732,
          "name": "PAF 12",
          "total": 96,
          "completed": 26
        },
        {
          "id": 1733,
          "name": "PAF 13",
          "total": 86,
          "completed": 79
        },
        {
          "id": 1734,
          "name": "PAF 14",
          "total": 83,
          "completed": 49
        },
        {
          "id": 1735,
          "name": "PAF 15",
          "total": 89,
          "completed": 42
        },
        {
          "id": 1736,
          "name": "PAF 16",
          "total": 91,
          "completed": 48
        },
        {
          "id": 1737,
          "name": "PAF 17",
          "total": 84,
          "completed": 44
        },
        {
          "id": 1738,
          "name": "PAF 18",
          "total": 82,
          "completed": 37
        },
        {
          "id": 1739,
          "name": "PAF 19",
          "total": 90,
          "completed": 61
        },
        {
          "id": 1740,
          "name": "PAF 20",
          "total": 95,
          "completed": 66
        },
        {
          "id": 1741,
          "name": "PAF 21",
          "total": 93,
          "completed": 32
        },
        {
          "id": 1742,
          "name": "PAF 22",
          "total": 89,
          "completed": 45
        },
        {
          "id": 1743,
          "name": "PAF 23",
          "total": 96,
          "completed": 66
        },
        {
          "id": 1744,
          "name": "PAF 24",
          "total": 99,
          "completed": 44
        },
        {
          "id": 1745,
          "name": "PAF 25",
          "total": 91,
          "completed": 66
        },
        {
          "id": 1746,
          "name": "PAF 26",
          "total": 98,
          "completed": 29
        },
        {
          "id": 1747,
          "name": "PAF 27",
          "total": 94,
          "completed": 51
        },
        {
          "id": 1748,
          "name": "PAF 28",
          "total": 91,
          "completed": 57
        },
        {
          "id": 1749,
          "name": "PAF 29",
          "total": 84,
          "completed": 75
        },
        {
          "id": 1750,
          "name": "PAF 30",
          "total": 93,
          "completed": 33
        },
        {
          "id": 1751,
          "name": "PAF 31",
          "total": 95,
          "completed": 69
        },
        {
          "id": 1752,
          "name": "PAF 32",
          "total": 85,
          "completed": 40
        },
        {
          "id": 1753,
          "name": "PAF 33",
          "total": 98,
          "completed": 31
        },
        {
          "id": 1754,
          "name": "PAF 34",
          "total": 92,
          "completed": 42
        },
        {
          "id": 1755,
          "name": "PAF 35",
          "total": 83,
          "completed": 66
        },
        {
          "id": 1756,
          "name": "PAF 36",
          "total": 98,
          "completed": 74
        },
        {
          "id": 1757,
          "name": "PAF 37",
          "total": 96,
          "completed": 71
        },
        {
          "id": 1758,
          "name": "PAF 38",
          "total": 90,
          "completed": 35
        }
      ]
    },
    {
      "id": 181,
      "name": "AYT Fizik PSF Palme Soru Föyü",
      "cover": "https://kurumsal.palmeyayinevi.com/Media/UserData/kapak/ayt/02_07_2020_17_43_24{aytfizikpsf}.png",
      "description": "\"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.\"",
      "class": "TYT - AYT",
      "lesson": "Fizik",
      "brand": "Palme Yayınevi",
      "bookContent": [
        {
          "id": 1763,
          "name": "PSF 1",
          "total": 97,
          "completed": 35
        },
        {
          "id": 1764,
          "name": "PSF 2",
          "total": 91,
          "completed": 55
        },
        {
          "id": 1765,
          "name": "PSF 3",
          "total": 96,
          "completed": 60
        },
        {
          "id": 1766,
          "name": "PSF 4",
          "total": 98,
          "completed": 77
        },
        {
          "id": 1767,
          "name": "PSF 5",
          "total": 83,
          "completed": 50
        },
        {
          "id": 1768,
          "name": "PSF 6",
          "total": 86,
          "completed": 73
        },
        {
          "id": 1769,
          "name": "PSF 7",
          "total": 98,
          "completed": 63
        },
        {
          "id": 1770,
          "name": "PSF 8",
          "total": 89,
          "completed": 66
        },
        {
          "id": 1771,
          "name": "PSF 9",
          "total": 98,
          "completed": 34
        },
        {
          "id": 1772,
          "name": "PSF 10",
          "total": 99,
          "completed": 59
        },
        {
          "id": 1773,
          "name": "PSF 11",
          "total": 90,
          "completed": 53
        },
        {
          "id": 1774,
          "name": "PSF 12",
          "total": 83,
          "completed": 26
        },
        {
          "id": 1775,
          "name": "PSF 13",
          "total": 95,
          "completed": 72
        },
        {
          "id": 1776,
          "name": "PSF 14",
          "total": 88,
          "completed": 51
        },
        {
          "id": 1777,
          "name": "PSF 15",
          "total": 88,
          "completed": 78
        }
      ]
    },
    {
      "id": 182,
      "name": "AYT Biyoloji PAF Palme Anlatım Föyü",
      "cover": "https://kurumsal.palmeyayinevi.com/Media/UserData/kapak/ayt/02_07_2020_17_28_51{aytbiyolojipaf}.png",
      "description": "\"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.\"",
      "class": "TYT - AYT",
      "lesson": "Biyoloji",
      "brand": "Palme Yayınevi",
      "bookContent": [
        {
          "id": 1778,
          "name": "PAF 1",
          "total": 97,
          "completed": 44
        },
        {
          "id": 1779,
          "name": "PAF 2",
          "total": 83,
          "completed": 37
        },
        {
          "id": 1780,
          "name": "PAF 3",
          "total": 98,
          "completed": 36
        },
        {
          "id": 1781,
          "name": "PAF 4",
          "total": 84,
          "completed": 43
        },
        {
          "id": 1782,
          "name": "PAF 5",
          "total": 81,
          "completed": 67
        },
        {
          "id": 1783,
          "name": "PAF 6",
          "total": 90,
          "completed": 29
        },
        {
          "id": 1784,
          "name": "PAF 7",
          "total": 83,
          "completed": 31
        },
        {
          "id": 1785,
          "name": "PAF 8",
          "total": 89,
          "completed": 58
        },
        {
          "id": 1786,
          "name": "PAF 9",
          "total": 84,
          "completed": 52
        },
        {
          "id": 1787,
          "name": "PAF 10",
          "total": 92,
          "completed": 42
        },
        {
          "id": 1788,
          "name": "PAF 11",
          "total": 82,
          "completed": 61
        },
        {
          "id": 1789,
          "name": "PAF 12",
          "total": 90,
          "completed": 37
        },
        {
          "id": 1790,
          "name": "PAF 13",
          "total": 96,
          "completed": 41
        },
        {
          "id": 1791,
          "name": "PAF 14",
          "total": 97,
          "completed": 54
        },
        {
          "id": 1792,
          "name": "PAF 15",
          "total": 95,
          "completed": 72
        },
        {
          "id": 1793,
          "name": "PAF 16",
          "total": 99,
          "completed": 30
        }
      ]
    },
    {
      "id": 184,
      "name": "AYT Biyoloji PSF Palme Soru Fasikülü",
      "cover": "https://kurumsal.palmeyayinevi.com/Media/UserData/kapak/ayt/02_07_2020_17_29_27{aytbiyolojipsf}.png",
      "description": "\"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.\"",
      "class": "TYT - AYT",
      "lesson": "Biyoloji",
      "brand": "Palme Yayınevi",
      "bookContent": [
        {
          "id": 1820,
          "name": "PSF 1",
          "total": 90,
          "completed": 54
        },
        {
          "id": 1821,
          "name": "PSF 2",
          "total": 85,
          "completed": 65
        },
        {
          "id": 1822,
          "name": "PSF 3",
          "total": 91,
          "completed": 32
        },
        {
          "id": 1823,
          "name": "PSF 4",
          "total": 91,
          "completed": 71
        },
        {
          "id": 1824,
          "name": "PSF 5",
          "total": 98,
          "completed": 59
        },
        {
          "id": 1825,
          "name": "PSF 6",
          "total": 81,
          "completed": 68
        },
        {
          "id": 1826,
          "name": "PSF 7",
          "total": 90,
          "completed": 44
        },
        {
          "id": 1827,
          "name": "PSF 8",
          "total": 95,
          "completed": 76
        },
        {
          "id": 1828,
          "name": "PSF 9",
          "total": 85,
          "completed": 79
        },
        {
          "id": 1829,
          "name": "PSF 10",
          "total": 81,
          "completed": 68
        },
        {
          "id": 1830,
          "name": "PSF 11",
          "total": 89,
          "completed": 69
        },
        {
          "id": 1831,
          "name": "PSF 12",
          "total": 96,
          "completed": 68
        },
        {
          "id": 1832,
          "name": "PSF 13",
          "total": 91,
          "completed": 29
        }
      ]
    },
    {
      "id": 190,
      "name": "AYT Matematik PSF Palme Soru Föyü",
      "cover": "https://kurumsal.palmeyayinevi.com/Media/UserData/kapak/ayt/02_07_2020_17_46_31{aytmatematikpsf}.png",
      "description": "\"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.\"",
      "class": "TYT - AYT",
      "lesson": "Matematik",
      "brand": "Palme Yayınevi",
      "bookContent": [
        {
          "id": 1857,
          "name": "PSF 1 : Fonksiyonlarda Uygulamalar",
          "total": 82,
          "completed": 52
        },
        {
          "id": 1858,
          "name": "PSF 2 : İkinci Dereceden Fonksiyonlar ve Grafikleri",
          "total": 83,
          "completed": 69
        },
        {
          "id": 1859,
          "name": "PSF 3 : Fonksiyonların Dönüşümleri",
          "total": 81,
          "completed": 72
        },
        {
          "id": 1860,
          "name": "PSF 4 : Denklem ve Eşitsizlik Sistemleri ",
          "total": 91,
          "completed": 55
        },
        {
          "id": 1861,
          "name": "PSF 5 : Olasılık",
          "total": 92,
          "completed": 68
        },
        {
          "id": 1862,
          "name": "PSF 6 : Logaritma ve Üstel Fonksiyonları",
          "total": 99,
          "completed": 70
        },
        {
          "id": 1863,
          "name": "PSF 7 : Diziler",
          "total": 96,
          "completed": 76
        },
        {
          "id": 1864,
          "name": "PSF 8 : Türev (Limit ve Süreklilik)",
          "total": 94,
          "completed": 69
        },
        {
          "id": 1865,
          "name": "PSF 9 : Anlık Değişim Oranı ve Türev",
          "total": 84,
          "completed": 77
        },
        {
          "id": 1866,
          "name": "PSF 10 : Türev (Geometrik Yorum, Artan ve Azalan)",
          "total": 99,
          "completed": 70
        },
        {
          "id": 1867,
          "name": "PSF 11 : Türev (Ekstremum, Maksimum-Minimum)",
          "total": 91,
          "completed": 52
        },
        {
          "id": 1868,
          "name": "PSF 12 : İntegral (Belirsiz İntegral)",
          "total": 94,
          "completed": 68
        },
        {
          "id": 1869,
          "name": "PSF 13 : İntegral (Belirli İntegral)",
          "total": 99,
          "completed": 37
        },
        {
          "id": 1870,
          "name": "PSF 14 : İntegral (Belirli İntegral Uygulamaları)",
          "total": 99,
          "completed": 64
        }
      ]
    },
    {
      "id": 191,
      "name": "TYT-AYT Geometri PSF Palme Soru Föyü",
      "cover": "https://kurumsal.palmeyayinevi.com/Media/UserData/kapak/tyt-ayt/04_08_2021_09_59_46{tyt-ayt-psfgeometri}.jpg",
      "description": "\"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.\"",
      "class": "TYT - AYT",
      "lesson": "Geometri",
      "brand": "Palme Yayınevi",
      "bookContent": [
        {
          "id": 1871,
          "name": "PSF-1",
          "total": 98,
          "completed": 62
        },
        {
          "id": 1872,
          "name": "PSF-2",
          "total": 90,
          "completed": 57
        },
        {
          "id": 1873,
          "name": "PSF-3",
          "total": 81,
          "completed": 57
        },
        {
          "id": 1874,
          "name": "PSF-4",
          "total": 83,
          "completed": 79
        },
        {
          "id": 1875,
          "name": "PSF-5",
          "total": 84,
          "completed": 39
        },
        {
          "id": 1876,
          "name": "PSF-6",
          "total": 94,
          "completed": 77
        },
        {
          "id": 1877,
          "name": "PSF-7",
          "total": 83,
          "completed": 62
        },
        {
          "id": 1878,
          "name": "PSF-8",
          "total": 81,
          "completed": 46
        },
        {
          "id": 1879,
          "name": "PSF-9",
          "total": 90,
          "completed": 75
        },
        {
          "id": 1880,
          "name": "PSF-10",
          "total": 92,
          "completed": 71
        },
        {
          "id": 1881,
          "name": "PSF-11",
          "total": 83,
          "completed": 78
        },
        {
          "id": 1882,
          "name": "PSF-12",
          "total": 87,
          "completed": 60
        },
        {
          "id": 1883,
          "name": "PSF-13",
          "total": 86,
          "completed": 67
        },
        {
          "id": 1884,
          "name": "PSF-14",
          "total": 85,
          "completed": 75
        },
        {
          "id": 1885,
          "name": "PSF-15",
          "total": 96,
          "completed": 62
        },
        {
          "id": 1886,
          "name": "PSF-16",
          "total": 99,
          "completed": 57
        },
        {
          "id": 1887,
          "name": "PSF-17",
          "total": 81,
          "completed": 34
        },
        {
          "id": 1888,
          "name": "PSF-18",
          "total": 95,
          "completed": 55
        },
        {
          "id": 1889,
          "name": "PSF-19",
          "total": 97,
          "completed": 79
        },
        {
          "id": 1890,
          "name": "PSF-20",
          "total": 93,
          "completed": 75
        },
        {
          "id": 1891,
          "name": "PSF-21",
          "total": 96,
          "completed": 36
        },
        {
          "id": 1892,
          "name": "PSF-22",
          "total": 91,
          "completed": 33
        },
        {
          "id": 1893,
          "name": "PSF-23",
          "total": 97,
          "completed": 26
        },
        {
          "id": 1894,
          "name": "PSF-24",
          "total": 84,
          "completed": 68
        },
        {
          "id": 1895,
          "name": "PSF-25",
          "total": 87,
          "completed": 63
        },
        {
          "id": 1896,
          "name": "PSF-26",
          "total": 84,
          "completed": 46
        }
      ]
    },
    {
      "id": 192,
      "name": "AYT Edebiyat PSF Palme Soru Föyü",
      "cover": "https://kurumsal.palmeyayinevi.com/Media/UserData/kapak/ayt/02_07_2020_17_40_41{aytedebiyatpsf}.png",
      "description": "\"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.\"",
      "class": "TYT - AYT",
      "lesson": "Türk Dili ve Edebiyatı",
      "brand": "Palme Yayınevi",
      "bookContent": [
        {
          "id": 1897,
          "name": "PSF 1",
          "total": 96,
          "completed": 62
        },
        {
          "id": 1898,
          "name": "PSF 2",
          "total": 93,
          "completed": 51
        },
        {
          "id": 1899,
          "name": "PSF 3",
          "total": 92,
          "completed": 56
        },
        {
          "id": 1900,
          "name": "PSF 4",
          "total": 95,
          "completed": 31
        },
        {
          "id": 1901,
          "name": "PSF 5",
          "total": 87,
          "completed": 69
        },
        {
          "id": 1902,
          "name": "PSF 6",
          "total": 86,
          "completed": 70
        },
        {
          "id": 1903,
          "name": "PSF 7",
          "total": 87,
          "completed": 49
        },
        {
          "id": 1904,
          "name": "PSF 8",
          "total": 99,
          "completed": 55
        },
        {
          "id": 1905,
          "name": "PSF 9",
          "total": 98,
          "completed": 56
        },
        {
          "id": 1906,
          "name": "PSF 10",
          "total": 95,
          "completed": 53
        },
        {
          "id": 1907,
          "name": "PSF 11",
          "total": 88,
          "completed": 50
        },
        {
          "id": 1908,
          "name": "PSF 12",
          "total": 85,
          "completed": 75
        },
        {
          "id": 1909,
          "name": "PSF 13",
          "total": 82,
          "completed": 26
        },
        {
          "id": 1910,
          "name": "PSF 14",
          "total": 90,
          "completed": 36
        },
        {
          "id": 1911,
          "name": "PSF 15",
          "total": 89,
          "completed": 52
        },
        {
          "id": 1912,
          "name": "PSF 16",
          "total": 96,
          "completed": 46
        },
        {
          "id": 1913,
          "name": "PSF 17",
          "total": 92,
          "completed": 33
        },
        {
          "id": 1914,
          "name": "PSF 18",
          "total": 83,
          "completed": 61
        },
        {
          "id": 1915,
          "name": "PSF 19",
          "total": 99,
          "completed": 33
        },
        {
          "id": 1916,
          "name": "PSF 20",
          "total": 87,
          "completed": 62
        },
        {
          "id": 1917,
          "name": "PSF 21",
          "total": 97,
          "completed": 77
        },
        {
          "id": 1918,
          "name": "PSF 22",
          "total": 98,
          "completed": 78
        },
        {
          "id": 1919,
          "name": "PSF 23",
          "total": 90,
          "completed": 29
        },
        {
          "id": 1920,
          "name": "PSF 24",
          "total": 90,
          "completed": 75
        },
        {
          "id": 1921,
          "name": "PSF 25",
          "total": 84,
          "completed": 52
        },
        {
          "id": 1922,
          "name": "PSF 26",
          "total": 88,
          "completed": 30
        },
        {
          "id": 1923,
          "name": "PSF 27",
          "total": 88,
          "completed": 31
        },
        {
          "id": 1924,
          "name": "PSF 28",
          "total": 98,
          "completed": 60
        },
        {
          "id": 1925,
          "name": "PSF 29",
          "total": 85,
          "completed": 69
        },
        {
          "id": 1926,
          "name": "PSF 30",
          "total": 95,
          "completed": 35
        },
        {
          "id": 1927,
          "name": "PSF 31",
          "total": 96,
          "completed": 63
        },
        {
          "id": 1928,
          "name": "PSF 32",
          "total": 87,
          "completed": 75
        },
        {
          "id": 1929,
          "name": "PSF 33",
          "total": 94,
          "completed": 49
        },
        {
          "id": 1930,
          "name": "PSF 34",
          "total": 89,
          "completed": 46
        },
        {
          "id": 1931,
          "name": "PSF 35",
          "total": 86,
          "completed": 57
        }
      ]
    },
    {
      "id": 206,
      "name": "AYT Fizik PAF Palme Anlatım Föyü",
      "cover": "https://kurumsal.palmeyayinevi.com/Media/UserData/kapak/ayt/02_07_2020_17_41_26{aytfizikpaf}.png",
      "description": "\"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.\"",
      "class": "TYT - AYT",
      "lesson": "Fizik",
      "brand": "Palme Yayınevi",
      "bookContent": [
        {
          "id": 1993,
          "name": "PAF 1: Kuvvet ve Hareket - 1",
          "total": 94,
          "completed": 75
        },
        {
          "id": 1994,
          "name": "PAF 2: Kuvvet ve Hareket - 2",
          "total": 99,
          "completed": 32
        },
        {
          "id": 1995,
          "name": "PAF 3: Kuvvet ve Hareket - 3",
          "total": 95,
          "completed": 70
        },
        {
          "id": 1996,
          "name": "PAF 4: Kuvvet ve Hareket - 4",
          "total": 91,
          "completed": 68
        },
        {
          "id": 1997,
          "name": "PAF 5: Kuvvet ve Hareket - 5",
          "total": 91,
          "completed": 67
        },
        {
          "id": 1998,
          "name": "PAF 6: İtme - Momentum",
          "total": 95,
          "completed": 52
        },
        {
          "id": 1999,
          "name": "PAF 7: Tork - Denge ve Denge Şartları",
          "total": 88,
          "completed": 64
        },
        {
          "id": 2000,
          "name": "PAF 8: Kuvvet ve Hareket - 8",
          "total": 83,
          "completed": 30
        },
        {
          "id": 2001,
          "name": "PAF 9: Elektrik ve Manyetizma - 1",
          "total": 86,
          "completed": 28
        },
        {
          "id": 2002,
          "name": "PAF 10: Elektrik ve Manyetizma - 2",
          "total": 83,
          "completed": 58
        },
        {
          "id": 2003,
          "name": "PAF 11: Elektrik ve Manyetizma - 3",
          "total": 90,
          "completed": 63
        },
        {
          "id": 2004,
          "name": "PAF 12: Elektrik ve Manyetizma - 4",
          "total": 90,
          "completed": 53
        },
        {
          "id": 2005,
          "name": "PAF 13: Elektrik ve Manyetizma - 5",
          "total": 99,
          "completed": 69
        },
        {
          "id": 2006,
          "name": "PAF 14: Elektrik ve Manyetizma - 6",
          "total": 97,
          "completed": 56
        },
        {
          "id": 2007,
          "name": "PAF 15: Çembersel Hareket - 1",
          "total": 98,
          "completed": 52
        },
        {
          "id": 2008,
          "name": "PAF 16: Momentum ve Tork",
          "total": 88,
          "completed": 78
        },
        {
          "id": 2009,
          "name": "PAF 17: Dalga Mekaniği",
          "total": 90,
          "completed": 61
        },
        {
          "id": 2010,
          "name": "PAF 18: Atom Fiziğine Giriş ve Radyoaktivite - 1",
          "total": 88,
          "completed": 71
        },
        {
          "id": 2011,
          "name": "PAF 19: Atom Fiziğine Giriş ve Radyoaktivite - 2",
          "total": 95,
          "completed": 65
        },
        {
          "id": 2012,
          "name": "PAF 20: Modern Fizik",
          "total": 91,
          "completed": 62
        },
        {
          "id": 2013,
          "name": "PAF 21: Modern Fiziğin Teknolojiye Uygulamaları",
          "total": 90,
          "completed": 66
        }
      ]
    },
    {
      "id": 207,
      "name": "AYT Biyoloji POT Palme Okul Testi",
      "cover": "https://kurumsal.palmeyayinevi.com/Media/UserData/kapak/ayt/02_07_2020_11_34_59{aytbiyolojipot}.png",
      "description": "\"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.\"",
      "class": "TYT - AYT",
      "lesson": "Biyoloji",
      "brand": "Palme Yayınevi",
      "bookContent": [
        {
          "id": 2014,
          "name": "POT 1",
          "total": 91,
          "completed": 71
        },
        {
          "id": 2015,
          "name": "POT 2",
          "total": 87,
          "completed": 36
        },
        {
          "id": 2016,
          "name": "POT 3",
          "total": 94,
          "completed": 67
        },
        {
          "id": 2017,
          "name": "POT 4",
          "total": 82,
          "completed": 48
        },
        {
          "id": 2018,
          "name": "POT 5",
          "total": 89,
          "completed": 36
        },
        {
          "id": 2019,
          "name": "POT 6",
          "total": 98,
          "completed": 56
        },
        {
          "id": 2020,
          "name": "POT 7",
          "total": 96,
          "completed": 34
        },
        {
          "id": 2021,
          "name": "POT 8",
          "total": 99,
          "completed": 47
        },
        {
          "id": 2022,
          "name": "POT 9",
          "total": 82,
          "completed": 71
        },
        {
          "id": 2023,
          "name": "POT 10",
          "total": 83,
          "completed": 51
        },
        {
          "id": 2024,
          "name": "POT 11",
          "total": 92,
          "completed": 71
        },
        {
          "id": 2025,
          "name": "POT 12",
          "total": 82,
          "completed": 27
        },
        {
          "id": 2026,
          "name": "POT 13",
          "total": 87,
          "completed": 41
        },
        {
          "id": 2027,
          "name": "POT 14",
          "total": 83,
          "completed": 64
        },
        {
          "id": 2028,
          "name": "POT 15",
          "total": 86,
          "completed": 26
        },
        {
          "id": 2029,
          "name": "POT 16",
          "total": 97,
          "completed": 65
        },
        {
          "id": 2030,
          "name": "POT 17",
          "total": 91,
          "completed": 59
        },
        {
          "id": 2031,
          "name": "POT 18",
          "total": 98,
          "completed": 75
        },
        {
          "id": 2032,
          "name": "POT 19",
          "total": 92,
          "completed": 55
        },
        {
          "id": 2033,
          "name": "POT 20",
          "total": 99,
          "completed": 27
        },
        {
          "id": 2034,
          "name": "POT 21",
          "total": 94,
          "completed": 58
        },
        {
          "id": 2035,
          "name": "POT 22",
          "total": 87,
          "completed": 56
        },
        {
          "id": 2036,
          "name": "POT 23",
          "total": 88,
          "completed": 72
        },
        {
          "id": 2037,
          "name": "POT 24",
          "total": 99,
          "completed": 43
        },
        {
          "id": 2038,
          "name": "POT 25",
          "total": 81,
          "completed": 69
        },
        {
          "id": 2039,
          "name": "POT 26",
          "total": 98,
          "completed": 73
        },
        {
          "id": 2040,
          "name": "POT 27",
          "total": 81,
          "completed": 40
        },
        {
          "id": 2041,
          "name": "POT 28",
          "total": 87,
          "completed": 51
        },
        {
          "id": 2042,
          "name": "POT 29",
          "total": 99,
          "completed": 32
        },
        {
          "id": 2043,
          "name": "POT 30",
          "total": 84,
          "completed": 50
        },
        {
          "id": 2044,
          "name": "POT 31",
          "total": 93,
          "completed": 60
        },
        {
          "id": 2045,
          "name": "POT 32",
          "total": 89,
          "completed": 27
        },
        {
          "id": 2046,
          "name": "POT 33",
          "total": 96,
          "completed": 32
        },
        {
          "id": 2047,
          "name": "POT 34",
          "total": 84,
          "completed": 56
        },
        {
          "id": 2048,
          "name": "POT 35",
          "total": 85,
          "completed": 60
        },
        {
          "id": 2049,
          "name": "POT 36 ",
          "total": 92,
          "completed": 74
        },
        {
          "id": 2050,
          "name": "POT 37",
          "total": 96,
          "completed": 41
        },
        {
          "id": 2051,
          "name": "POT 38",
          "total": 88,
          "completed": 58
        },
        {
          "id": 2052,
          "name": "POT 39",
          "total": 96,
          "completed": 61
        },
        {
          "id": 2053,
          "name": "POT 40",
          "total": 93,
          "completed": 67
        },
        {
          "id": 2054,
          "name": "POT 41",
          "total": 91,
          "completed": 72
        },
        {
          "id": 2055,
          "name": "POT 42",
          "total": 99,
          "completed": 48
        },
        {
          "id": 2056,
          "name": "POT 43",
          "total": 94,
          "completed": 62
        },
        {
          "id": 2057,
          "name": "POT 44",
          "total": 98,
          "completed": 30
        }
      ]
    },
    {
      "id": 213,
      "name": "AYT Matematik POT Palme Okul Testi",
      "cover": "https://kurumsal.palmeyayinevi.com/Media/UserData/kapak/ayt/02_07_2020_11_38_11{aytmatematikpot}.png",
      "description": "\"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.\"",
      "class": "TYT - AYT",
      "lesson": "Matematik",
      "brand": "Palme Yayınevi",
      "bookContent": [
        {
          "id": 2078,
          "name": "Test 1 Fonksiyonlarda Uygulamalar",
          "total": 90,
          "completed": 70
        },
        {
          "id": 2079,
          "name": "Test 2 Fonksiyonlarda Uygulamalar",
          "total": 89,
          "completed": 32
        },
        {
          "id": 2080,
          "name": "Test 3 İkinci Dereceden Fonksiyonlar ve Grafikleri",
          "total": 90,
          "completed": 55
        },
        {
          "id": 2081,
          "name": "Test 4 İkinci Dereceden Fonksiyonlar ve Grafikleri",
          "total": 96,
          "completed": 44
        },
        {
          "id": 2082,
          "name": "Test 5 İkinci Dereceden Fonksiyonlar ve Grafikleri",
          "total": 95,
          "completed": 79
        },
        {
          "id": 2083,
          "name": "Test 6 İkinci Dereceden Fonksiyonlar ve Grafikleri",
          "total": 87,
          "completed": 76
        },
        {
          "id": 2084,
          "name": "Test 7 Fonksiyonların Dönüşümleri",
          "total": 92,
          "completed": 54
        },
        {
          "id": 2085,
          "name": "Test 8 Fonksiyonların Dönüşümleri",
          "total": 99,
          "completed": 30
        },
        {
          "id": 2086,
          "name": "Test 9 Denklem ve Eşitsizlik Sistemleri",
          "total": 84,
          "completed": 79
        },
        {
          "id": 2087,
          "name": "Test 10 Denklem ve Eşitsizlik Sistemleri",
          "total": 95,
          "completed": 34
        },
        {
          "id": 2088,
          "name": "Test 11 Olasılık",
          "total": 96,
          "completed": 43
        },
        {
          "id": 2089,
          "name": "Test 12 Olasılık",
          "total": 87,
          "completed": 33
        },
        {
          "id": 2090,
          "name": "Test 13 Üstel Fonksiyon - Logaritma Fonksiyonu",
          "total": 94,
          "completed": 75
        },
        {
          "id": 2091,
          "name": "Test 14 Üstel Fonksiyon - Logaritma Fonksiyonu",
          "total": 84,
          "completed": 34
        },
        {
          "id": 2092,
          "name": "Test 15 Üstel Fonksiyon - Logaritma Fonksiyonu",
          "total": 88,
          "completed": 34
        },
        {
          "id": 2093,
          "name": "Test 16 Üstel Fonksiyon - Logaritma Fonksiyonu",
          "total": 81,
          "completed": 73
        },
        {
          "id": 2094,
          "name": "Test 17 Üstel Fonksiyon - Logaritma Fonksiyonu",
          "total": 98,
          "completed": 51
        },
        {
          "id": 2095,
          "name": "Test 18 Üstel Fonksiyon - Logaritma Fonksiyonu",
          "total": 82,
          "completed": 69
        },
        {
          "id": 2096,
          "name": "Test 19 Diziler",
          "total": 96,
          "completed": 77
        },
        {
          "id": 2097,
          "name": "Test 20 Diziler",
          "total": 95,
          "completed": 78
        },
        {
          "id": 2098,
          "name": "Test 21 Diziler",
          "total": 81,
          "completed": 36
        },
        {
          "id": 2099,
          "name": "Test 22 Diziler",
          "total": 84,
          "completed": 32
        },
        {
          "id": 2100,
          "name": "Test 23 Türev (Limit ve Süreklilik)",
          "total": 90,
          "completed": 46
        },
        {
          "id": 2101,
          "name": "Test 24 Türev (Limit ve Süreklilik)",
          "total": 94,
          "completed": 77
        },
        {
          "id": 2102,
          "name": "Test 25 Türev (Limit ve Süreklilik)",
          "total": 96,
          "completed": 72
        },
        {
          "id": 2103,
          "name": "Test 26 Türev (Limit ve Süreklilik)",
          "total": 96,
          "completed": 75
        },
        {
          "id": 2104,
          "name": "Test 27 Anlık Değişim Oranı ve Türev",
          "total": 92,
          "completed": 58
        },
        {
          "id": 2105,
          "name": "Test 28 Anlık Değişim Oranı ve Türev",
          "total": 96,
          "completed": 59
        },
        {
          "id": 2106,
          "name": "Test 29 Anlık Değişim Oranı ve Türev",
          "total": 81,
          "completed": 29
        },
        {
          "id": 2107,
          "name": "Test 30 Anlık Değişim Oranı ve Türev",
          "total": 85,
          "completed": 49
        },
        {
          "id": 2108,
          "name": "Test 31 Anlık Değişim Oranı ve Türev",
          "total": 88,
          "completed": 70
        },
        {
          "id": 2109,
          "name": "Test 32 Anlık Değişim Oranı ve Türev",
          "total": 91,
          "completed": 59
        },
        {
          "id": 2110,
          "name": "Test 33 Türev Uygulamaları",
          "total": 99,
          "completed": 30
        },
        {
          "id": 2111,
          "name": "Test 34 Türev Uygulamaları",
          "total": 95,
          "completed": 57
        },
        {
          "id": 2112,
          "name": "Test 35 Türev Uygulamaları",
          "total": 92,
          "completed": 59
        },
        {
          "id": 2113,
          "name": "Test 36 Türev Uygulamaları",
          "total": 94,
          "completed": 50
        },
        {
          "id": 2114,
          "name": "Test 37 Türev Uygulamaları",
          "total": 95,
          "completed": 31
        },
        {
          "id": 2115,
          "name": "Test 38 Türev Uygulamaları",
          "total": 89,
          "completed": 79
        },
        {
          "id": 2116,
          "name": "Test 39 Türev Uygulamaları",
          "total": 92,
          "completed": 34
        },
        {
          "id": 2117,
          "name": "Test 40 Türev Uygulamaları",
          "total": 87,
          "completed": 59
        },
        {
          "id": 2118,
          "name": "Test 41 Belirsiz - Belirli İntegral",
          "total": 88,
          "completed": 34
        },
        {
          "id": 2119,
          "name": "Test 42 Belirsiz - Belirli İntegral",
          "total": 91,
          "completed": 63
        },
        {
          "id": 2120,
          "name": "Test 43 Belirsiz - Belirli İntegral",
          "total": 93,
          "completed": 67
        },
        {
          "id": 2121,
          "name": "Test 44 Belirsiz - Belirli İntegral",
          "total": 82,
          "completed": 41
        },
        {
          "id": 2122,
          "name": "Test 45 Belirsiz - Belirli İntegral",
          "total": 81,
          "completed": 66
        },
        {
          "id": 2123,
          "name": "Test 46 Belirsiz - Belirli İntegral",
          "total": 92,
          "completed": 78
        },
        {
          "id": 2124,
          "name": "Test 47 Belirli İntegral Uygulamaları",
          "total": 82,
          "completed": 65
        },
        {
          "id": 2125,
          "name": "Test 48 Belirli İntegral Uygulamaları",
          "total": 95,
          "completed": 59
        },
        {
          "id": 2126,
          "name": "Test 49 Belirli İntegral Uygulamaları",
          "total": 93,
          "completed": 58
        },
        {
          "id": 2127,
          "name": "Test 50 Belirli İntegral Uygulamaları",
          "total": 89,
          "completed": 59
        },
        {
          "id": 2128,
          "name": "Test 51 Belirli İntegral Uygulamaları",
          "total": 88,
          "completed": 64
        },
        {
          "id": 2129,
          "name": "Test 52 Belirli İntegral Uygulamaları",
          "total": 92,
          "completed": 35
        }
      ]
    },
    {
      "id": 214,
      "name": "AYT Fizik POT Palme Okul Testi",
      "cover": "https://kurumsal.palmeyayinevi.com/Media/UserData/kapak/ayt/02_07_2020_11_36_14{aytfizikpot}.png",
      "description": "\"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.\"",
      "class": "TYT - AYT",
      "lesson": "Fizik",
      "brand": "Palme Yayınevi",
      "bookContent": [
        {
          "id": 2130,
          "name": "POT 1",
          "total": 81,
          "completed": 55
        },
        {
          "id": 2131,
          "name": "POT 2",
          "total": 93,
          "completed": 49
        },
        {
          "id": 2132,
          "name": "POT 3",
          "total": 91,
          "completed": 35
        },
        {
          "id": 2133,
          "name": "POT 4",
          "total": 87,
          "completed": 41
        },
        {
          "id": 2134,
          "name": "POT 5",
          "total": 82,
          "completed": 38
        },
        {
          "id": 2135,
          "name": "POT 6",
          "total": 94,
          "completed": 47
        },
        {
          "id": 2136,
          "name": "POT 7",
          "total": 82,
          "completed": 66
        },
        {
          "id": 2137,
          "name": "POT 8",
          "total": 81,
          "completed": 58
        },
        {
          "id": 2138,
          "name": "POT 9",
          "total": 82,
          "completed": 38
        },
        {
          "id": 2139,
          "name": "POT 10",
          "total": 96,
          "completed": 27
        },
        {
          "id": 2140,
          "name": "POT 11",
          "total": 99,
          "completed": 78
        },
        {
          "id": 2141,
          "name": "POT 12",
          "total": 85,
          "completed": 70
        },
        {
          "id": 2142,
          "name": "POT 13",
          "total": 84,
          "completed": 39
        },
        {
          "id": 2143,
          "name": "POT 14",
          "total": 93,
          "completed": 39
        },
        {
          "id": 2144,
          "name": "POT 15",
          "total": 84,
          "completed": 68
        },
        {
          "id": 2145,
          "name": "POT 16",
          "total": 82,
          "completed": 78
        },
        {
          "id": 2146,
          "name": "POT 17",
          "total": 91,
          "completed": 64
        },
        {
          "id": 2147,
          "name": "POT 18",
          "total": 89,
          "completed": 27
        },
        {
          "id": 2148,
          "name": "POT 19",
          "total": 99,
          "completed": 61
        },
        {
          "id": 2149,
          "name": "POT 20",
          "total": 97,
          "completed": 75
        },
        {
          "id": 2150,
          "name": "POT 21",
          "total": 92,
          "completed": 59
        },
        {
          "id": 2151,
          "name": "POT 22",
          "total": 83,
          "completed": 44
        },
        {
          "id": 2152,
          "name": "POT 23",
          "total": 81,
          "completed": 37
        },
        {
          "id": 2153,
          "name": "POT 24",
          "total": 93,
          "completed": 78
        },
        {
          "id": 2154,
          "name": "POT 25",
          "total": 88,
          "completed": 75
        },
        {
          "id": 2155,
          "name": "POT 26",
          "total": 85,
          "completed": 44
        },
        {
          "id": 2156,
          "name": "POT 27",
          "total": 96,
          "completed": 73
        },
        {
          "id": 2157,
          "name": "POT 28",
          "total": 92,
          "completed": 35
        },
        {
          "id": 2158,
          "name": "POT 29",
          "total": 81,
          "completed": 48
        },
        {
          "id": 2159,
          "name": "POT 30",
          "total": 99,
          "completed": 45
        },
        {
          "id": 2160,
          "name": "POT 31",
          "total": 91,
          "completed": 66
        },
        {
          "id": 2161,
          "name": "POT 32",
          "total": 88,
          "completed": 37
        },
        {
          "id": 2162,
          "name": "POT 33",
          "total": 88,
          "completed": 43
        },
        {
          "id": 2163,
          "name": "POT 34",
          "total": 83,
          "completed": 52
        },
        {
          "id": 2164,
          "name": "POT 35",
          "total": 90,
          "completed": 57
        },
        {
          "id": 2165,
          "name": "POT 36",
          "total": 85,
          "completed": 47
        },
        {
          "id": 2166,
          "name": "POT 37",
          "total": 94,
          "completed": 76
        },
        {
          "id": 2167,
          "name": "POT 38",
          "total": 97,
          "completed": 42
        },
        {
          "id": 2168,
          "name": "POT 39",
          "total": 97,
          "completed": 63
        },
        {
          "id": 2169,
          "name": "POT 40",
          "total": 87,
          "completed": 66
        },
        {
          "id": 2170,
          "name": "POT 41",
          "total": 93,
          "completed": 62
        },
        {
          "id": 2171,
          "name": "POT 42",
          "total": 92,
          "completed": 62
        },
        {
          "id": 2172,
          "name": "POT 43",
          "total": 94,
          "completed": 76
        },
        {
          "id": 2173,
          "name": "POT 44",
          "total": 88,
          "completed": 43
        },
        {
          "id": 2174,
          "name": "POT 45",
          "total": 89,
          "completed": 27
        },
        {
          "id": 2175,
          "name": "POT 46",
          "total": 97,
          "completed": 47
        },
        {
          "id": 2176,
          "name": "POT 47",
          "total": 87,
          "completed": 38
        },
        {
          "id": 2177,
          "name": "POT 48",
          "total": 95,
          "completed": 68
        },
        {
          "id": 2178,
          "name": "POT 49",
          "total": 87,
          "completed": 29
        },
        {
          "id": 2179,
          "name": "POT 50",
          "total": 98,
          "completed": 76
        },
        {
          "id": 2180,
          "name": "POT 51",
          "total": 90,
          "completed": 65
        },
        {
          "id": 2181,
          "name": "POT 52",
          "total": 87,
          "completed": 33
        },
        {
          "id": 2182,
          "name": "POT 53",
          "total": 85,
          "completed": 75
        },
        {
          "id": 2183,
          "name": "POT 54",
          "total": 97,
          "completed": 47
        },
        {
          "id": 2184,
          "name": "POT 55",
          "total": 81,
          "completed": 66
        },
        {
          "id": 2185,
          "name": "POT 56",
          "total": 88,
          "completed": 30
        },
        {
          "id": 2186,
          "name": "POT 57",
          "total": 98,
          "completed": 34
        },
        {
          "id": 2187,
          "name": "POT 58",
          "total": 97,
          "completed": 33
        },
        {
          "id": 2188,
          "name": "POT 59",
          "total": 95,
          "completed": 52
        },
        {
          "id": 2189,
          "name": "POT 60",
          "total": 99,
          "completed": 46
        },
        {
          "id": 2190,
          "name": "POT 61",
          "total": 97,
          "completed": 27
        },
        {
          "id": 2191,
          "name": "POT 62",
          "total": 93,
          "completed": 55
        },
        {
          "id": 2192,
          "name": "POT 63",
          "total": 81,
          "completed": 76
        },
        {
          "id": 2193,
          "name": "POT 64",
          "total": 84,
          "completed": 78
        },
        {
          "id": 2194,
          "name": "POT 65",
          "total": 90,
          "completed": 38
        },
        {
          "id": 2195,
          "name": "POT 66",
          "total": 96,
          "completed": 30
        },
        {
          "id": 2196,
          "name": "POT 67",
          "total": 82,
          "completed": 55
        },
        {
          "id": 2197,
          "name": "POT 68",
          "total": 87,
          "completed": 51
        },
        {
          "id": 2198,
          "name": "POT 69",
          "total": 86,
          "completed": 40
        },
        {
          "id": 2199,
          "name": "POT 70",
          "total": 85,
          "completed": 40
        },
        {
          "id": 2200,
          "name": "POT 71",
          "total": 87,
          "completed": 75
        },
        {
          "id": 2201,
          "name": "POT 72",
          "total": 87,
          "completed": 76
        }
      ]
    },
    {
      "id": 216,
      "name": "AYT Tarih PSF Palme Soru Föyü",
      "cover": "https://kurumsal.palmeyayinevi.com/Media/UserData/kapak/ayt/02_07_2020_17_49_28{ayttarihpsf}.png",
      "description": "\"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.\"",
      "class": "TYT - AYT",
      "lesson": "Tarih",
      "brand": "Palme Yayınevi",
      "bookContent": [
        {
          "id": 2206,
          "name": "PSF 1: Tarih ve Zaman",
          "total": 83,
          "completed": 68
        },
        {
          "id": 2207,
          "name": "PSF 2: İnsanlığın İlk Dönemleri",
          "total": 86,
          "completed": 50
        },
        {
          "id": 2208,
          "name": "PSF 3: Orta Çağ’da Dünya",
          "total": 97,
          "completed": 38
        },
        {
          "id": 2209,
          "name": "PSF 4: İlk ve Orta Çağ’larda Türk Dünyası",
          "total": 94,
          "completed": 60
        },
        {
          "id": 2210,
          "name": "PSF 5: İslam Medeniyetinin Doğuşu",
          "total": 86,
          "completed": 50
        },
        {
          "id": 2211,
          "name": "PSF 6: Türklerin İslamiyet’i Kabulü ve İlk Türk İslam Devletleri",
          "total": 93,
          "completed": 55
        },
        {
          "id": 2212,
          "name": "PSF 7: Yerleşme ve Devletleşme Sürecinde Selçuklu Türkiyesi",
          "total": 98,
          "completed": 77
        },
        {
          "id": 2213,
          "name": "PSF 8: Beylikten Devlete Osmanlı Siyaseti (1300 - 1453)",
          "total": 91,
          "completed": 49
        },
        {
          "id": 2214,
          "name": "PSF 9: Devletleşme Sürecinde Savaşçılar ve Askerler",
          "total": 91,
          "completed": 63
        },
        {
          "id": 2215,
          "name": "PSF 10: Beylikten Devlete Osmanlı Medeniyeti",
          "total": 93,
          "completed": 68
        },
        {
          "id": 2216,
          "name": "PSF 11: Dünya Gücü Osmanlı (1453 - 1595)",
          "total": 83,
          "completed": 74
        },
        {
          "id": 2217,
          "name": "PSF 12: Sultan ve Osmanlı Merkez Teşkilatı",
          "total": 84,
          "completed": 42
        },
        {
          "id": 2218,
          "name": "PSF 13: Klasik Çağda Osmanlı Toplum Düzeni",
          "total": 96,
          "completed": 41
        },
        {
          "id": 2219,
          "name": "PSF 14: Değişen Dünya Dengeleri Karşısında Osmanlı Siyaseti (1595 - 1774)",
          "total": 86,
          "completed": 64
        },
        {
          "id": 2220,
          "name": "PSF 15: Değişim Çağında Avrupa ve Osmanlı",
          "total": 87,
          "completed": 30
        },
        {
          "id": 2221,
          "name": "PSF 16: Uluslararası İlişkilerde Denge Stratejisi (1774 – 1914)",
          "total": 85,
          "completed": 34
        },
        {
          "id": 2222,
          "name": "PSF 17: Devrimler Çağında Değişen Devlet – Toplum İlişkileri",
          "total": 90,
          "completed": 27
        },
        {
          "id": 2223,
          "name": "PSF 18: Sermaye Ve Emek",
          "total": 86,
          "completed": 44
        },
        {
          "id": 2224,
          "name": "PSF 19: XIX. ve XX. Yüzyılda Değişen Gündelik Hayat",
          "total": 92,
          "completed": 38
        },
        {
          "id": 2225,
          "name": "PSF 20: XX. yy. Başlarında Osmanlı Devleti ve Dünya",
          "total": 86,
          "completed": 55
        },
        {
          "id": 2226,
          "name": "PSF 21: Milli Mücadele",
          "total": 84,
          "completed": 56
        },
        {
          "id": 2227,
          "name": "PSF 22: Atatürkçülük ve Türk İnkılabı",
          "total": 86,
          "completed": 38
        },
        {
          "id": 2228,
          "name": "PSF 23: İki Savaş Arasındaki Dönemde Türkiye ve Dünya",
          "total": 86,
          "completed": 74
        },
        {
          "id": 2229,
          "name": "PSF 24: İkinci Dünya Savaşı Sürecinde Türkiye ve Dünya",
          "total": 86,
          "completed": 78
        },
        {
          "id": 2230,
          "name": "PSF 25: İkinci Dünya Savaşı Sonrasında Türkiye ve Dünya",
          "total": 83,
          "completed": 35
        },
        {
          "id": 2231,
          "name": "PSF 26: Toplumsal Devrim Çağında Dünya ve Türkiye",
          "total": 87,
          "completed": 51
        },
        {
          "id": 2232,
          "name": "PSF 27: XXI. Yüzyılın Eşiğinde Türkiye ve Dünya",
          "total": 84,
          "completed": 75
        },
        {
          "id": 2233,
          "name": "PSF 28: Çağdaş Türk Dünya Tarihi",
          "total": 86,
          "completed": 78
        }
      ]
    },
    {
      "id": 222,
      "name": "AYT Tarih PAF Palme Anlatım Föyü ",
      "cover": "https://kurumsal.palmeyayinevi.com/Media/UserData/kapak/ayt/02_07_2020_17_49_12{ayttarihpaf}.png",
      "description": "\"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.\"",
      "class": "TYT - AYT",
      "lesson": "Tarih",
      "brand": "Palme Yayınevi",
      "bookContent": [
        {
          "id": 2287,
          "name": "PAF 1",
          "total": 98,
          "completed": 54
        },
        {
          "id": 2288,
          "name": "PAF 2",
          "total": 83,
          "completed": 42
        },
        {
          "id": 2289,
          "name": "PAF 3",
          "total": 83,
          "completed": 38
        },
        {
          "id": 2290,
          "name": "PAF 4",
          "total": 84,
          "completed": 48
        },
        {
          "id": 2291,
          "name": "PAF 5",
          "total": 99,
          "completed": 65
        },
        {
          "id": 2292,
          "name": "PAF 6",
          "total": 86,
          "completed": 60
        },
        {
          "id": 2293,
          "name": "PAF 7",
          "total": 90,
          "completed": 29
        },
        {
          "id": 2294,
          "name": "PAF 8",
          "total": 91,
          "completed": 74
        },
        {
          "id": 2295,
          "name": "PAF 9",
          "total": 81,
          "completed": 79
        },
        {
          "id": 2296,
          "name": "PAF 10",
          "total": 95,
          "completed": 72
        },
        {
          "id": 2297,
          "name": "PAF 11",
          "total": 86,
          "completed": 35
        },
        {
          "id": 2298,
          "name": "PAF 12",
          "total": 91,
          "completed": 53
        },
        {
          "id": 2299,
          "name": "PAF 13",
          "total": 98,
          "completed": 64
        },
        {
          "id": 2300,
          "name": "PAF 14",
          "total": 99,
          "completed": 67
        },
        {
          "id": 2301,
          "name": "PAF 15",
          "total": 97,
          "completed": 39
        },
        {
          "id": 2302,
          "name": "PAF 16",
          "total": 91,
          "completed": 46
        },
        {
          "id": 2303,
          "name": "PAF 17",
          "total": 81,
          "completed": 72
        },
        {
          "id": 2304,
          "name": "PAF 18",
          "total": 90,
          "completed": 62
        },
        {
          "id": 2305,
          "name": "PAF 19",
          "total": 85,
          "completed": 67
        },
        {
          "id": 2306,
          "name": "PAF 20",
          "total": 87,
          "completed": 34
        },
        {
          "id": 2307,
          "name": "PAF 21",
          "total": 83,
          "completed": 47
        },
        {
          "id": 2308,
          "name": "PAF 22",
          "total": 92,
          "completed": 77
        },
        {
          "id": 2309,
          "name": "PAF 23",
          "total": 99,
          "completed": 72
        },
        {
          "id": 2310,
          "name": "PAF 24",
          "total": 92,
          "completed": 67
        },
        {
          "id": 2311,
          "name": "PAF 25",
          "total": 81,
          "completed": 79
        },
        {
          "id": 2312,
          "name": "PAF 26",
          "total": 90,
          "completed": 77
        },
        {
          "id": 2313,
          "name": "PAF 27",
          "total": 85,
          "completed": 78
        },
        {
          "id": 2314,
          "name": "PAF 28",
          "total": 81,
          "completed": 63
        },
        {
          "id": 2315,
          "name": "PAF 29",
          "total": 83,
          "completed": 60
        },
        {
          "id": 2316,
          "name": "PAF 30",
          "total": 83,
          "completed": 60
        },
        {
          "id": 2317,
          "name": "PAF 31",
          "total": 96,
          "completed": 42
        },
        {
          "id": 2318,
          "name": "PAF 32",
          "total": 96,
          "completed": 28
        },
        {
          "id": 2319,
          "name": "PAF 33",
          "total": 88,
          "completed": 41
        },
        {
          "id": 2320,
          "name": "PAF 34",
          "total": 91,
          "completed": 37
        },
        {
          "id": 2321,
          "name": "PAF 35",
          "total": 85,
          "completed": 29
        }
      ]
    },
    {
      "id": 296,
      "name": "TYT AYT EKSTRA EŞİT AĞIRLIK 1. Dönem Tekrar Kitabı",
      "cover": "https://kurumsal.palmeyayinevi.com/Media/UserData/kapak/tyt-ayt/04_01_2021_16_17_16{esit-agirlik-ekstra}.png",
      "description": "\"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.\"",
      "class": "TYT - AYT",
      "lesson": "Tüm Dersler",
      "brand": "Palme Yayınevi",
      "bookContent": [
        {
          "id": 2669,
          "name": "EKSTRA 1",
          "total": 88,
          "completed": 47
        },
        {
          "id": 2670,
          "name": "EKSTRA 2",
          "total": 91,
          "completed": 70
        },
        {
          "id": 2671,
          "name": "EKSTRA 3",
          "total": 90,
          "completed": 48
        },
        {
          "id": 2672,
          "name": "EKSTRA 4",
          "total": 91,
          "completed": 70
        },
        {
          "id": 2673,
          "name": "EKSTRA 5",
          "total": 88,
          "completed": 33
        },
        {
          "id": 2674,
          "name": "EKSTRA 6",
          "total": 85,
          "completed": 32
        },
        {
          "id": 2675,
          "name": "EKSTRA 7",
          "total": 95,
          "completed": 54
        }
      ]
    },
    {
      "id": 309,
      "name": " TYT AYT EKSTRA SAYISAL 1. Dönem Tekrar Kitabı ",
      "cover": "https://kurumsal.palmeyayinevi.com/Media/UserData/smartbookitems/52/coverimage/2021-05-1--10-55-382021-04-1--17-59-48eşit ağırlık sayısal.png",
      "description": "\"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.\"",
      "class": "TYT - AYT",
      "lesson": "Tüm Dersler",
      "brand": "Palme Yayınevi",
      "bookContent": [
        {
          "id": 2712,
          "name": "EXTRA 1",
          "total": 85,
          "completed": 61
        },
        {
          "id": 2713,
          "name": "EXTRA 2",
          "total": 86,
          "completed": 50
        },
        {
          "id": 2714,
          "name": "EXTRA 3",
          "total": 82,
          "completed": 68
        },
        {
          "id": 2715,
          "name": "EXTRA 4",
          "total": 83,
          "completed": 51
        },
        {
          "id": 2716,
          "name": "EXTRA 5",
          "total": 91,
          "completed": 56
        },
        {
          "id": 2717,
          "name": "EXTRA 6",
          "total": 90,
          "completed": 58
        },
        {
          "id": 2718,
          "name": "EXTRA 7",
          "total": 95,
          "completed": 44
        }
      ]
    },
    {
      "id": 529,
      "name": "TYT Biyoloji PSF Palme Soru Föyü (Genişletilmiş Baskı)",
      "cover": "https://kurumsal.palmeyayinevi.com/Media/UserData/smartbookitems/277/coverimage/17_06_2021_13_52_27{tyt-biyoloji-psf-2021}.jpg",
      "description": "\"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.\"",
      "class": "TYT - AYT",
      "lesson": "Biyoloji",
      "brand": "Palme Yayınevi",
      "bookContent": [
        {
          "id": 4113,
          "name": "PSF 1",
          "total": 88,
          "completed": 26
        },
        {
          "id": 4114,
          "name": "PSF 2",
          "total": 93,
          "completed": 53
        },
        {
          "id": 4115,
          "name": "PSF 3",
          "total": 95,
          "completed": 30
        },
        {
          "id": 4116,
          "name": "PSF 4",
          "total": 94,
          "completed": 57
        },
        {
          "id": 4117,
          "name": "PSF 5",
          "total": 97,
          "completed": 71
        },
        {
          "id": 4118,
          "name": "PSF 6",
          "total": 99,
          "completed": 44
        },
        {
          "id": 4119,
          "name": "PSF 7",
          "total": 93,
          "completed": 28
        },
        {
          "id": 4120,
          "name": "PSF 8",
          "total": 92,
          "completed": 32
        },
        {
          "id": 4121,
          "name": "PSF 9",
          "total": 90,
          "completed": 56
        },
        {
          "id": 4122,
          "name": "PSF 10",
          "total": 91,
          "completed": 57
        },
        {
          "id": 4123,
          "name": "PSF 11",
          "total": 98,
          "completed": 36
        },
        {
          "id": 4124,
          "name": "PSF 12",
          "total": 85,
          "completed": 61
        },
        {
          "id": 4125,
          "name": "PSF 13",
          "total": 87,
          "completed": 69
        },
        {
          "id": 4126,
          "name": "PSF 14",
          "total": 84,
          "completed": 62
        },
        {
          "id": 4127,
          "name": "PSF 15",
          "total": 87,
          "completed": 36
        }
      ]
    }
  ],
  questions:[
      {
        "testId":1,
        "questionNumber": 1,
        "name": "1. Soru",
        "badge": "Kazanım 1",
        "videoSolution": "Soru 1 Video Gelecek",
        "imageSolution": "https://advdijital.com//Media/UserData/bookimages/73b2a739-dc36-440d-aa03-351aa4a678a4/nite-1/test-1/cozum-1.jpg"
      },
      {
        "testId":1,
        "questionNumber": 2,
        "name": "2. Soru",
        "badge": "Kazanım 2",
        "videoSolution": "Soru 2 Video Gelecek",
        "imageSolution": "Soru 2 Görsel Gelecek"
      },
      {
        "testId":1,
        "questionNumber": 3,
        "name": "3. Soru",
        "badge": "Kazanım 3",
        "videoSolution": "Soru 3 Video Gelecek",
        "imageSolution": "Soru 3 Görsel Gelecek"
      },
      {
        "testId":2,
        "questionNumber": 1,
        "name": "1. Soru",
        "badge": "Kazanım 1",
        "videoSolution": "Soru 1 Video Gelecek",
        "imageSolution": "Soru 1 Görsel Gelecek"
      },
  ]
}
// ------------------------------------------------
// GET: Return books
// ------------------------------------------------
mock.onGet('/apps/books/getBooks').reply(config => {
  // eslint-disable-next-line object-curly-newline
  const { q = '', perPage = 9, page = 1 } = config.params

  const queryLowered = q.toLowerCase()

  const filteredData = data.books.filter(book => book.name.toLowerCase().includes(queryLowered))

  const paginatedData = JSON.parse(JSON.stringify(paginateArray(filteredData, perPage, page)))
  return [
    200,
    {
      books: paginatedData,
      total: filteredData.length
    }
  ]
})

mock.onGet('/apps/question/getQuestions').reply(config => {
  const filteredData = data.questions.filter(question => question.testId === config.params)
  return [
    200,
    {
      questions: filteredData
    }
  ]
})


// ------------------------------------------------
// GET: Return Single Product
// ------------------------------------------------
mock.onGet(/\/apps\/books\/getBook\/\d+/).reply(config => {

  // Get product id from URL
  let bookId = config.url.substring(config.url.lastIndexOf('/') + 1)

  // Convert Id to number
  bookId = Number(bookId)

  const bookIndex = data.books.findIndex(p => p.id === bookId)
  const book = data.books[bookIndex]

  if (book) {

    return [200, { book }]
  }
  return [404]
})


