// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getVideos = createAsyncThunk('videos/getVideos', async params => {
  const response = await axios.get('/apps/videos/getVideos', { params })
  return { params, data: response.data }
})

export const getVideo = createAsyncThunk('videos/getVideo', async slug => {
  const response = await axios.get(`/apps/videos/getVideo/${slug}`)
  return response.data
})


export const appVideoSlice = createSlice({
  name: 'videos',
  initialState: {
    params: {},
    videos: [],
    totalVideos: 0,
    videoDetail: {},
    videoUrl:''
  },
  reducers: {
    setVideoUrl: (state, action) => {
      state.videoUrl = action.payload
    }
  },
  extraReducers: builder => {
    builder
    .addCase(getVideos.fulfilled, (state, action) => {
      state.params = action.payload.params
      state.videos = action.payload.data.videos
      state.totalVideos = action.payload.data.total

    })
    .addCase(getVideo.fulfilled, (state, action) => {
      state.videoDetail = action.payload.video
      state.videoUrl = action.payload.video.content[0].videos[0].url
    }) 
  }
})
export const { setVideoUrl } = appVideoSlice.actions
export default appVideoSlice.reducer
