import mock from "../mock"
/* eslint-disable */
const data = [
  {
    lessonId: 1,
    lessonName: "Türk Dili ve Edebiyatı",
    topics: [
      {
        id: 1,
        name: "A-1:OKUMA (METNİ ANLAMA VE ÇÖZÜMLEME)-ŞiiR",
        successRatio: 96.07,
        totalQuestion: 255,
        correctAnswer: 245,
        wrongAnswer: 10,
        emptyAnswer: 0,
        gains: [
          {
            id: 1,
            totalQuestion: 45,
            correctAnswer: 40,
            wrongAnswer: 2,
            emptyAnswer: 0,
            title:
              "A.1. 1. Metinde geçen kelime ve kelime gruplarının anlamlarını tespit eder.",
          },
          {
            id: 2,
            totalQuestion: 45,
            correctAnswer: 40,
            wrongAnswer: 2,
            emptyAnswer: 0,
            title: "A.1. 2. Şiirin temasını belirler.",
          },
          {
            id: 3,
            totalQuestion: 45,
            correctAnswer: 40,
            wrongAnswer: 2,
            emptyAnswer: 0,
            title:
              "A.1. 3. Şiirde ahengi sağlayan özellikleri/unsurları belirler.",
          },
          {
            id: 4,
            totalQuestion: 45,
            correctAnswer: 40,
            wrongAnswer: 2,
            emptyAnswer: 0,
            title: "A.1. 4. Şiirin nazım biçimini ve nazım türünü tespit eder.",
          },
          {
            id: 5,
            totalQuestion: 45,
            correctAnswer: 40,
            wrongAnswer: 2,
            emptyAnswer: 0,
            title:
              "A.1. 5. Şiirdeki mazmun, imge ve edebî sanatları belirleyerek bunların anlama katkısınıdeğerlendirir",
          },
        ],
      },
      {
        id: 2,
        name: "A-2:OKUMA ÖYKÜLEYİCİ EDEBÎ METİNLER",
        successRatio: 80,
        totalQuestion: 125,
        correctAnswer: 100,
        wrongAnswer: 10,
        emptyAnswer: 5,

        questionPatterns: [
          {
            title: "2018",
            count: 4,
          },
          {
            title: "2019",
            count: 5,
          },
          {
            title: "2020",
            count: 4,
          },
          {
            title: "2021",
            count: 3,
          },
        ],
        gains: [
          {
            id: 1,
            totalQuestion: 45,
            correctAnswer: 40,
            wrongAnswer: 2,
            emptyAnswer: 0,
            title:
              "A.1. 1. Metinde geçen kelime ve kelime gruplarının anlamlarını tespit eder.",
          },
          {
            id: 2,
            totalQuestion: 45,
            correctAnswer: 40,
            wrongAnswer: 2,
            emptyAnswer: 0,
            title: "A.1. 2. Şiirin temasını belirler.",
          },
          {
            id: 3,
            totalQuestion: 45,
            correctAnswer: 40,
            wrongAnswer: 2,
            emptyAnswer: 0,
            title:
              "A.1. 3. Şiirde ahengi sağlayan özellikleri/unsurları belirler.",
          },
          {
            id: 4,
            totalQuestion: 45,
            correctAnswer: 40,
            wrongAnswer: 2,
            emptyAnswer: 0,
            title: "A.1. 4. Şiirin nazım biçimini ve nazım türünü tespit eder.",
          },
          {
            id: 5,
            totalQuestion: 45,
            correctAnswer: 40,
            wrongAnswer: 2,
            emptyAnswer: 0,
            title:
              "A.1. 5. Şiirdeki mazmun, imge ve edebî sanatları belirleyerek bunların anlama katkısınıdeğerlendirir",
          },
        ],
      },
      {
        id: 3,
        name: "B:YAZMA-TİYATRO",
        successRatio: 55,
        totalQuestion: 255,
        correctAnswer: 245,
        wrongAnswer: 10,
        emptyAnswer: 0,

        gains: [
          {
            id: 1,
            totalQuestion: 45,
            correctAnswer: 40,
            wrongAnswer: 2,
            emptyAnswer: 0,
            title:
              "A.1. 1. Metinde geçen kelime ve kelime gruplarının anlamlarını tespit eder.",
          },
          {
            id: 2,
            totalQuestion: 45,
            correctAnswer: 40,
            wrongAnswer: 2,
            emptyAnswer: 0,
            title: "A.1. 2. Şiirin temasını belirler.",
          },
          {
            id: 3,
            totalQuestion: 45,
            correctAnswer: 40,
            wrongAnswer: 2,
            emptyAnswer: 0,
            title:
              "A.1. 3. Şiirde ahengi sağlayan özellikleri/unsurları belirler.",
          },
          {
            id: 4,
            totalQuestion: 45,
            correctAnswer: 40,
            wrongAnswer: 2,
            emptyAnswer: 0,
            title: "A.1. 4. Şiirin nazım biçimini ve nazım türünü tespit eder.",
          },
          {
            id: 5,
            totalQuestion: 45,
            correctAnswer: 40,
            wrongAnswer: 2,
            emptyAnswer: 0,
            title:
              "A.1. 5. Şiirdeki mazmun, imge ve edebî sanatları belirleyerek bunların anlama katkısınıdeğerlendirir",
          },
        ],
      },
      {
        id: 4,
        name: "C:SÖZLÜ İLETİŞİM-KONUŞMA",
        successRatio: 32,
        totalQuestion: 255,
        correctAnswer: 245,
        wrongAnswer: 10,
        emptyAnswer: 0,

        gains: [
          {
            id: 1,
            totalQuestion: 45,
            correctAnswer: 40,
            wrongAnswer: 2,
            emptyAnswer: 0,
            title:
              "A.1. 1. Metinde geçen kelime ve kelime gruplarının anlamlarını tespit eder.",
          },
          {
            id: 2,
            totalQuestion: 45,
            correctAnswer: 40,
            wrongAnswer: 2,
            emptyAnswer: 0,
            title: "A.1. 2. Şiirin temasını belirler.",
          },
          {
            id: 3,
            totalQuestion: 45,
            correctAnswer: 40,
            wrongAnswer: 2,
            emptyAnswer: 0,
            title:
              "A.1. 3. Şiirde ahengi sağlayan özellikleri/unsurları belirler.",
          },
          {
            id: 4,
            totalQuestion: 45,
            correctAnswer: 40,
            wrongAnswer: 2,
            emptyAnswer: 0,
            title: "A.1. 4. Şiirin nazım biçimini ve nazım türünü tespit eder.",
          },
          {
            id: 5,
            totalQuestion: 45,
            correctAnswer: 40,
            wrongAnswer: 2,
            emptyAnswer: 0,
            title:
              "A.1. 5. Şiirdeki mazmun, imge ve edebî sanatları belirleyerek bunların anlama katkısınıdeğerlendirir",
          },
        ],
      },
      {
        id: 5,
        name: "C:SÖZLÜ İLETİŞİM-DİNLEME",
        successRatio: 75,
        totalQuestion: 255,
        correctAnswer: 245,
        wrongAnswer: 10,
        emptyAnswer: 0,
        gains: [
          {
            id: 1,
            totalQuestion: 45,
            correctAnswer: 40,
            wrongAnswer: 2,
            emptyAnswer: 0,
            title:
              "A.1. 1. Metinde geçen kelime ve kelime gruplarının anlamlarını tespit eder.",
          },
          {
            id: 2,
            totalQuestion: 45,
            correctAnswer: 40,
            wrongAnswer: 2,
            emptyAnswer: 0,
            title: "A.1. 2. Şiirin temasını belirler.",
          },
          {
            id: 3,
            totalQuestion: 45,
            correctAnswer: 40,
            wrongAnswer: 2,
            emptyAnswer: 0,
            title:
              "A.1. 3. Şiirde ahengi sağlayan özellikleri/unsurları belirler.",
          },
          {
            id: 4,
            totalQuestion: 45,
            correctAnswer: 40,
            wrongAnswer: 2,
            emptyAnswer: 0,
            title: "A.1. 4. Şiirin nazım biçimini ve nazım türünü tespit eder.",
          },
          {
            id: 5,
            totalQuestion: 45,
            correctAnswer: 40,
            wrongAnswer: 2,
            emptyAnswer: 0,
            title:
              "A.1. 5. Şiirdeki mazmun, imge ve edebî sanatları belirleyerek bunların anlama katkısınıdeğerlendirir",
          },
        ],
      },
    ],
  },
  {
    lessonId: 2,
    lessonName: "Matematik",
    topics: [
      {
        id: 1,
        name: "A-1:OKUMA (METNİ ANLAMA VE ÇÖZÜMLEME)-ŞiiR",
        successRatio: 65,
        totalQuestion: 255,
        correctAnswer: 245,
        wrongAnswer: 10,
        emptyAnswer: 0,
        gains: [
          {
            id: 1,
            totalQuestion: 45,
            correctAnswer: 40,
            wrongAnswer: 2,
            emptyAnswer: 0,
            title:
              "A.1. 1. Metinde geçen kelime ve kelime gruplarının anlamlarını tespit eder.",
          },
          {
            id: 2,
            totalQuestion: 45,
            correctAnswer: 40,
            wrongAnswer: 2,
            emptyAnswer: 0,
            title: "A.1. 2. Şiirin temasını belirler.",
          },
          {
            id: 3,
            totalQuestion: 45,
            correctAnswer: 40,
            wrongAnswer: 2,
            emptyAnswer: 0,
            title:
              "A.1. 3. Şiirde ahengi sağlayan özellikleri/unsurları belirler.",
          },
          {
            id: 4,
            totalQuestion: 45,
            correctAnswer: 40,
            wrongAnswer: 2,
            emptyAnswer: 0,
            title: "A.1. 4. Şiirin nazım biçimini ve nazım türünü tespit eder.",
          },
          {
            id: 5,
            totalQuestion: 45,
            correctAnswer: 40,
            wrongAnswer: 2,
            emptyAnswer: 0,
            title:
              "A.1. 5. Şiirdeki mazmun, imge ve edebî sanatları belirleyerek bunların anlama katkısınıdeğerlendirir",
          },
        ],
      },
      {
        id: 2,
        name: "A-2:OKUMA (METNİ ANLAMA VE ÇÖZÜMLEME)-ÖYKÜLEYİCİ (ANLATMAYA BAĞLI) EDEBÎ METİNLER (Masal/Fabl, Destan, Mesnevi, Hikâye,Roman vb.)",
        successRatio: 45,
        totalQuestion: 255,
        correctAnswer: 245,
        wrongAnswer: 10,
        emptyAnswer: 0,
        gains: [
          {
            id: 1,
            totalQuestion: 45,
            correctAnswer: 40,
            wrongAnswer: 2,
            emptyAnswer: 0,
            title:
              "A.1. 1. Metinde geçen kelime ve kelime gruplarının anlamlarını tespit eder.",
          },
          {
            id: 2,
            totalQuestion: 45,
            correctAnswer: 40,
            wrongAnswer: 2,
            emptyAnswer: 0,
            title: "A.1. 2. Şiirin temasını belirler.",
          },
          {
            id: 3,
            totalQuestion: 45,
            correctAnswer: 40,
            wrongAnswer: 2,
            emptyAnswer: 0,
            title:
              "A.1. 3. Şiirde ahengi sağlayan özellikleri/unsurları belirler.",
          },
          {
            id: 4,
            totalQuestion: 45,
            correctAnswer: 40,
            wrongAnswer: 2,
            emptyAnswer: 0,
            title: "A.1. 4. Şiirin nazım biçimini ve nazım türünü tespit eder.",
          },
          {
            id: 5,
            totalQuestion: 45,
            correctAnswer: 40,
            wrongAnswer: 2,
            emptyAnswer: 0,
            title:
              "A.1. 5. Şiirdeki mazmun, imge ve edebî sanatları belirleyerek bunların anlama katkısınıdeğerlendirir",
          },
        ],
      },
      {
        id: 3,
        name: "B:YAZMA-TİYATRO",
        successRatio: 55,
        totalQuestion: 255,
        correctAnswer: 245,
        wrongAnswer: 10,
        emptyAnswer: 0,
        gains: [
          {
            id: 1,
            totalQuestion: 45,
            correctAnswer: 40,
            wrongAnswer: 2,
            emptyAnswer: 0,
            title:
              "A.1. 1. Metinde geçen kelime ve kelime gruplarının anlamlarını tespit eder.",
          },
          {
            id: 2,
            totalQuestion: 45,
            correctAnswer: 40,
            wrongAnswer: 2,
            emptyAnswer: 0,
            title: "A.1. 2. Şiirin temasını belirler.",
          },
          {
            id: 3,
            totalQuestion: 45,
            correctAnswer: 40,
            wrongAnswer: 2,
            emptyAnswer: 0,
            title:
              "A.1. 3. Şiirde ahengi sağlayan özellikleri/unsurları belirler.",
          },
          {
            id: 4,
            totalQuestion: 45,
            correctAnswer: 40,
            wrongAnswer: 2,
            emptyAnswer: 0,
            title: "A.1. 4. Şiirin nazım biçimini ve nazım türünü tespit eder.",
          },
          {
            id: 5,
            totalQuestion: 45,
            correctAnswer: 40,
            wrongAnswer: 2,
            emptyAnswer: 0,
            title:
              "A.1. 5. Şiirdeki mazmun, imge ve edebî sanatları belirleyerek bunların anlama katkısınıdeğerlendirir",
          },
        ],
      },
      {
        id: 4,
        name: "C:SÖZLÜ İLETİŞİM-KONUŞMA",
        successRatio: 32,
        totalQuestion: 255,
        correctAnswer: 245,
        wrongAnswer: 10,
        emptyAnswer: 0,
        gains: [
          {
            id: 1,
            totalQuestion: 45,
            correctAnswer: 40,
            wrongAnswer: 2,
            emptyAnswer: 0,
            title:
              "A.1. 1. Metinde geçen kelime ve kelime gruplarının anlamlarını tespit eder.",
          },
          {
            id: 2,
            totalQuestion: 45,
            correctAnswer: 40,
            wrongAnswer: 2,
            emptyAnswer: 0,
            title: "A.1. 2. Şiirin temasını belirler.",
          },
          {
            id: 3,
            totalQuestion: 45,
            correctAnswer: 40,
            wrongAnswer: 2,
            emptyAnswer: 0,
            title:
              "A.1. 3. Şiirde ahengi sağlayan özellikleri/unsurları belirler.",
          },
          {
            id: 4,
            totalQuestion: 45,
            correctAnswer: 40,
            wrongAnswer: 2,
            emptyAnswer: 0,
            title: "A.1. 4. Şiirin nazım biçimini ve nazım türünü tespit eder.",
          },
          {
            id: 5,
            totalQuestion: 45,
            correctAnswer: 40,
            wrongAnswer: 2,
            emptyAnswer: 0,
            title:
              "A.1. 5. Şiirdeki mazmun, imge ve edebî sanatları belirleyerek bunların anlama katkısınıdeğerlendirir",
          },
        ],
      },
      {
        id: 5,
        name: "C:SÖZLÜ İLETİŞİM-DİNLEME",
        successRatio: 75,
        totalQuestion: 255,
        correctAnswer: 245,
        wrongAnswer: 10,
        emptyAnswer: 0,
        gains: [
          {
            id: 1,
            totalQuestion: 45,
            correctAnswer: 40,
            wrongAnswer: 2,
            emptyAnswer: 0,
            title:
              "A.1. 1. Metinde geçen kelime ve kelime gruplarının anlamlarını tespit eder.",
          },
          {
            id: 2,
            totalQuestion: 45,
            correctAnswer: 40,
            wrongAnswer: 2,
            emptyAnswer: 0,
            title: "A.1. 2. Şiirin temasını belirler.",
          },
          {
            id: 3,
            totalQuestion: 45,
            correctAnswer: 40,
            wrongAnswer: 2,
            emptyAnswer: 0,
            title:
              "A.1. 3. Şiirde ahengi sağlayan özellikleri/unsurları belirler.",
          },
          {
            id: 4,
            totalQuestion: 45,
            correctAnswer: 40,
            wrongAnswer: 2,
            emptyAnswer: 0,
            title: "A.1. 4. Şiirin nazım biçimini ve nazım türünü tespit eder.",
          },
          {
            id: 5,
            totalQuestion: 45,
            correctAnswer: 40,
            wrongAnswer: 2,
            emptyAnswer: 0,
            title:
              "A.1. 5. Şiirdeki mazmun, imge ve edebî sanatları belirleyerek bunların anlama katkısınıdeğerlendirir",
          },
        ],
      },
    ],
  },
];

// ------------------------------------------------
// GET: Return books
// ------------------------------------------------
mock.onGet("/apps/topicReports/getTopics").reply((config) => {
  // eslint-disable-next-line object-curly-newline
  const filteredData = data.filter((lesson) => lesson.lessonId === 1)[0];
  return [
    200,
    {
      lessonTopics: filteredData.topics,
    },
  ];
});

mock.onGet("/apps/topicReports/getTopicDetails").reply((config) => {
  const filteredData = data.filter((lesson) => lesson.lessonId === 1)[0];
  const topicDetails = filteredData.topics.filter(
    (topic) => topic.id === config.params
  )[0];
  return [
    200,
    {
      topicDetails: topicDetails,
    },
  ];
});
