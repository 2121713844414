import mock from '../mock'
/* eslint-disable */
// ** Utils
import { paginateArray, sortCompare } from '../utils'

const data = {
  videos: [
    {
      "id": 197,
      "title": "2022 KPSS Genel Yetenek Genel Kültür Canlı Dersler (1.Grup)",
      "cover": "https://dijital.pegemkampus.com//Media/UserData/videolessons/20_10_2021_21_16_20{gygk-eg-i-ti-m-canli-dersler-01}.jpg",
      "description": "Videolar 2022 sınavından sonra erişime kapatılacaktır. ",
      "explanation":"Her videoyu en fazla 20 kez izleyebilirsiniz.",
      "content": [
        {
          "id": 1814,
          "name": "Türkçe",
          "videos": [
            {
              "id":1,
              "name":"GYGK Canlı Dersler Coğrafya-1 (18.10.2021)",
              "url":"https://www.youtube.com/watch?v=hUeE3XRRBwU&list=PLM88n9LcZ7dPMgOUN4vdoEgR51NvtcM7j"
            },
            {
              "id":2,
              "name":"GYGK Canlı Dersler Coğrafya-1 (18.10.2021)",
              "url":"https://www.youtube.com/watch?v=my1uImV-o28"
            },
              {
              "id":3,
              "name":"GYGK Canlı Dersler Coğrafya-1 (18.10.2021)",
              "url":"https://www.youtube.com/watch?v=2D31vHBMLHo&list=PLM88n9LcZ7dPMgOUN4vdoEgR51NvtcM7j&index=3"
            },
            {
              "id":4,
              "name":"GYGK Canlı Dersler Coğrafya-1 (18.10.2021)",
              "url":"https://www.youtube.com/watch?v=w4I5EijDr6U&list=PLM88n9LcZ7dPMgOUN4vdoEgR51NvtcM7j&index=4"
            }
            ]
        },
        {
          "id": 1815,
          "name": "Matematik-Geometri",
          "videos": [
            {
              "id":1,
              "name":"GYGK Canlı Dersler Coğrafya-1 (18.10.2021)",
              "url":"https://www.youtube.com/watch?v=hUeE3XRRBwU&list=PLM88n9LcZ7dPMgOUN4vdoEgR51NvtcM7j"
            },
            {
              "id":2,
              "name":"GYGK Canlı Dersler Coğrafya-1 (18.10.2021)",
              "url":"https://www.youtube.com/watch?v=hUeE3XRRBwU&list=PLM88n9LcZ7dPMgOUN4vdoEgR51NvtcM7j"
            },
              {
              "id":3,
              "name":"GYGK Canlı Dersler Coğrafya-1 (18.10.2021)",
              "url":"https://www.youtube.com/watch?v=hUeE3XRRBwU&list=PLM88n9LcZ7dPMgOUN4vdoEgR51NvtcM7j"
            },
            {
              "id":4,
              "name":"GYGK Canlı Dersler Coğrafya-1 (18.10.2021)",
              "url":"https://www.youtube.com/watch?v=hUeE3XRRBwU&list=PLM88n9LcZ7dPMgOUN4vdoEgR51NvtcM7j"
            }
            ]
        },
        {
          "id": 1816,
          "name": "Tarih",
          "videos": [
            {
              "id":1,
              "name":"GYGK Canlı Dersler Coğrafya-1 (18.10.2021)",
              "url":"https://www.youtube.com/watch?v=hUeE3XRRBwU&list=PLM88n9LcZ7dPMgOUN4vdoEgR51NvtcM7j"
            },
            {
              "id":2,
              "name":"GYGK Canlı Dersler Coğrafya-1 (18.10.2021)",
              "url":"https://www.youtube.com/watch?v=hUeE3XRRBwU&list=PLM88n9LcZ7dPMgOUN4vdoEgR51NvtcM7j"
            },
              {
              "id":3,
              "name":"GYGK Canlı Dersler Coğrafya-1 (18.10.2021)",
              "url":"https://www.youtube.com/watch?v=hUeE3XRRBwU&list=PLM88n9LcZ7dPMgOUN4vdoEgR51NvtcM7j"
            },
            {
              "id":4,
              "name":"GYGK Canlı Dersler Coğrafya-1 (18.10.2021)",
              "url":"https://www.youtube.com/watch?v=hUeE3XRRBwU&list=PLM88n9LcZ7dPMgOUN4vdoEgR51NvtcM7j"
            }
            ]
        },
        {
          "id": 1817,
          "name": "Coğrafya",
          "videos": [
            {
              "id":1,
              "name":"GYGK Canlı Dersler Coğrafya-1 (18.10.2021)",
              "url":"https://www.youtube.com/watch?v=hUeE3XRRBwU&list=PLM88n9LcZ7dPMgOUN4vdoEgR51NvtcM7j"
            },
            {
              "id":2,
              "name":"GYGK Canlı Dersler Coğrafya-1 (18.10.2021)",
              "url":"https://www.youtube.com/watch?v=hUeE3XRRBwU&list=PLM88n9LcZ7dPMgOUN4vdoEgR51NvtcM7j"
            },
              {
              "id":3,
              "name":"GYGK Canlı Dersler Coğrafya-1 (18.10.2021)",
              "url":"https://www.youtube.com/watch?v=hUeE3XRRBwU&list=PLM88n9LcZ7dPMgOUN4vdoEgR51NvtcM7j"
            },
            {
              "id":4,
              "name":"GYGK Canlı Dersler Coğrafya-1 (18.10.2021)",
              "url":"https://www.youtube.com/watch?v=hUeE3XRRBwU&list=PLM88n9LcZ7dPMgOUN4vdoEgR51NvtcM7j"
            }
            ]
        },
        {
          "id": 1818,
          "name": "Vatandaşlık",
          "videos": [
            {
              "id":1,
              "name":"GYGK Canlı Dersler Coğrafya-1 (18.10.2021)",
              "url":"https://www.youtube.com/watch?v=hUeE3XRRBwU&list=PLM88n9LcZ7dPMgOUN4vdoEgR51NvtcM7j"
            },
            {
              "id":2,
              "name":"GYGK Canlı Dersler Coğrafya-1 (18.10.2021)",
              "url":"https://www.youtube.com/watch?v=hUeE3XRRBwU&list=PLM88n9LcZ7dPMgOUN4vdoEgR51NvtcM7j"
            },
              {
              "id":3,
              "name":"GYGK Canlı Dersler Coğrafya-1 (18.10.2021)",
              "url":"https://www.youtube.com/watch?v=hUeE3XRRBwU&list=PLM88n9LcZ7dPMgOUN4vdoEgR51NvtcM7j"
            },
            {
              "id":4,
              "name":"GYGK Canlı Dersler Coğrafya-1 (18.10.2021)",
              "url":"https://www.youtube.com/watch?v=hUeE3XRRBwU&list=PLM88n9LcZ7dPMgOUN4vdoEgR51NvtcM7j"
            }
            ]
        }
      ]
    },
    {
      "id": 198,
      "title": "2022 KPSS  Eğitim Bilimleri Canlı Dersler (3.Grup)",
      "cover": "https://dijital.pegemkampus.com//Media/UserData/videolessons/20_10_2021_21_16_20{gygk-eg-i-ti-m-canli-dersler-01}.jpg",
      "description": "Videolar 2022 sınavından sonra erişime kapatılacaktır. ",
      "explanation":"Her videoyu en fazla 20 kez izleyebilirsiniz.",
      "content": [
        {
          "id": 1830,
          "name": "Türkçe",
          "videos": [
            {
              "id":1,
              "name":"GYGK Canlı Dersler Coğrafya-1 (18.10.2021)",
              "url":"https://www.youtube.com/watch?v=hUeE3XRRBwU&list=PLM88n9LcZ7dPMgOUN4vdoEgR51NvtcM7j"
            },
            {
              "id":2,
              "name":"GYGK Canlı Dersler Coğrafya-1 (18.10.2021)",
              "url":"https://www.youtube.com/watch?v=hUeE3XRRBwU&list=PLM88n9LcZ7dPMgOUN4vdoEgR51NvtcM7j"
            },
              {
              "id":3,
              "name":"GYGK Canlı Dersler Coğrafya-1 (18.10.2021)",
              "url":"https://www.youtube.com/watch?v=hUeE3XRRBwU&list=PLM88n9LcZ7dPMgOUN4vdoEgR51NvtcM7j"
            },
            {
              "id":4,
              "name":"GYGK Canlı Dersler Coğrafya-1 (18.10.2021)",
              "url":"https://www.youtube.com/watch?v=hUeE3XRRBwU&list=PLM88n9LcZ7dPMgOUN4vdoEgR51NvtcM7j"
            }
            ]
        },
        {
          "id": 1895,
          "name": "Program Geliştirme",
          "videos": [
            {
              "id":1,
              "name":"GYGK Canlı Dersler Coğrafya-1 (18.10.2021)",
              "url":"https://www.youtube.com/watch?v=hUeE3XRRBwU&list=PLM88n9LcZ7dPMgOUN4vdoEgR51NvtcM7j"
            },
            {
              "id":2,
              "name":"GYGK Canlı Dersler Coğrafya-1 (18.10.2021)",
              "url":"https://www.youtube.com/watch?v=hUeE3XRRBwU&list=PLM88n9LcZ7dPMgOUN4vdoEgR51NvtcM7j"
            },
              {
              "id":3,
              "name":"GYGK Canlı Dersler Coğrafya-1 (18.10.2021)",
              "url":"https://www.youtube.com/watch?v=hUeE3XRRBwU&list=PLM88n9LcZ7dPMgOUN4vdoEgR51NvtcM7j"
            },
            {
              "id":4,
              "name":"GYGK Canlı Dersler Coğrafya-1 (18.10.2021)",
              "url":"https://www.youtube.com/watch?v=hUeE3XRRBwU&list=PLM88n9LcZ7dPMgOUN4vdoEgR51NvtcM7j"
            }
            ]
        }
      ]
    },
    {
      "id": 199,
      "title": "2022 KPSS Genel Yetenek Genel Kültür Canlı Dersler (2.Grup)",
      "cover": "https://dijital.pegemkampus.com//Media/UserData/videolessons/20_10_2021_21_16_20{gygk-eg-i-ti-m-canli-dersler-01}.jpg",
      "description": "Videolar 2022 sınavından sonra erişime kapatılacaktır. ",
      "explanation":"Her videoyu en fazla 20 kez izleyebilirsiniz.",
      "content": [
        {
          "id": 1829,
          "name": "Türkçe",
          "url":"https://www.youtube.com/watch?v=hUeE3XRRBwU&list=PLM88n9LcZ7dPMgOUN4vdoEgR51NvtcM7j"
        },
        {
          "id": 1830,
          "name": "Matematik-Geometri",
          "url":"https://www.youtube.com/watch?v=hUeE3XRRBwU&list=PLM88n9LcZ7dPMgOUN4vdoEgR51NvtcM7j"
        },
        {
          "id": 1831,
          "name": "Tarih",
          "url":"https://www.youtube.com/watch?v=hUeE3XRRBwU&list=PLM88n9LcZ7dPMgOUN4vdoEgR51NvtcM7j"
        },
        {
          "id": 1832,
          "name": "Coğrafya"
        },
        {
          "id": 1833,
          "name": "Vatandaşlık"
        }
      ]
    },
    {
      "id": 200,
      "title": "2022 KPSS Genel Yetenek Genel Kültür Canlı Dersler (2.Grup)",
      "cover": "https://dijital.pegemkampus.com//Media/UserData/videolessons/20_10_2021_21_16_20{gygk-eg-i-ti-m-canli-dersler-01}.jpg",
      "description": "Videolar 2022 sınavından sonra erişime kapatılacaktır. ",
      "explanation":"Her videoyu en fazla 20 kez izleyebilirsiniz.",
      "content": [
        {
          "id": 1834,
          "name": "Türkçe"
        },
        {
          "id": 1835,
          "name": "Matematik-Geometri"
        },
        {
          "id": 1836,
          "name": "Tarih"
        },
        {
          "id": 1837,
          "name": "Coğrafya"
        },
        {
          "id": 1838,
          "name": "Vatandaşlık"
        }
      ]
    },
    {
      "id": 201,
      "title": "2022 KPSS Genel Yetenek Genel Kültür Canlı Dersler (2.Grup)",
      "cover": "https://dijital.pegemkampus.com//Media/UserData/videolessons/20_10_2021_21_16_20{gygk-eg-i-ti-m-canli-dersler-01}.jpg",
      "description": "Videolar 2022 sınavından sonra erişime kapatılacaktır. ",
      "explanation":"Her videoyu en fazla 20 kez izleyebilirsiniz.",
      "content": [
        {
          "id": 1839,
          "name": "Türkçe"
        },
        {
          "id": 1840,
          "name": "Matematik-Geometri"
        },
        {
          "id": 1841,
          "name": "Tarih"
        },
        {
          "id": 1842,
          "name": "Coğrafya"
        },
        {
          "id": 1843,
          "name": "Vatandaşlık"
        }
      ]
    }
  ]
}
// ------------------------------------------------
// GET: Return videolessons
// ------------------------------------------------
mock.onGet('/apps/videos/getVideos').reply(config => {
  // eslint-disable-next-line object-curly-newline
  const { q = '', perPage = 9, page = 1 } = config.params
 
  const queryLowered = q.toLowerCase()
  
  const filteredData = data.videos.filter(video => video.title.toLowerCase().includes(queryLowered))

  const paginatedData = JSON.parse(JSON.stringify(paginateArray(filteredData, perPage, page)))

  //  const content = filteredData.map(data => data.content)

  //  const merged = [].concat.apply([], content)
  
  // const videos = merged.map(video => video.videos)
  


  return [
    200,
    {
      videos: paginatedData,
      total: filteredData.length
    }
  ]
})

// ------------------------------------------------
// GET: Return Single Product
// ------------------------------------------------
mock.onGet(/\/apps\/videos\/getVideo\/\d+/).reply(config => {
  // Get product id from URL
  let videoId = config.url.substring(config.url.lastIndexOf('/') + 1)

  // Convert Id to number
  videoId = Number(videoId)

  const videoIndex = data.videos.findIndex(p => p.id === videoId)
  const video = data.videos[videoIndex]

  if (video) {

    video.colorOptions = ['primary', 'success', 'warning', 'danger', 'info']

    return [200, { video }]
  }
  return [404]
})
