import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

import axios from 'axios'

export const getTest = createAsyncThunk('testing/getTest', async params => {
    const response = await axios.get(`/apps/testing/getTest`, { params })

    return response.data
  })

  export const saveUserAnswer = createAsyncThunk('question/saveUserAnswer', async params => {
    const response = await axios.post(`/apps/testing/saveUserAnswer`, {questionId: params.id, userAnswer:params.userAnswer })
    return response
  })

  export const appTestingSlice = createSlice({
    name: 'testing',
    initialState: {
      test: {},
      questions: [],
      currentQuestion: {}
    },
    reducers: {

    },
    extraReducers: builder => {
      builder
        .addCase(getTest.fulfilled, (state, action) => {
    
          state.test = action.payload
          state.questions = action.payload.questions
          state.currentQuestion = action.payload.questions[0]
        })
        .addCase(saveUserAnswer.fulfilled, (state, action) => {

          const testQuestionIndex = state.questions.findIndex(x => x.id === action.payload.data.questionId)
          state.questions[testQuestionIndex].userAnswer = action.payload.data.userAnswer
        })
    
  
    }
  })
  
  export default appTestingSlice.reducer