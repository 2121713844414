import mock from './mock'

import './portal/books'
import './portal/videos'
import './portal/bookdetails'
import './portal/onlineexam'
import './portal/testing'
import './portal/calendar'
import './portal/report'
import './portal/tryingExamReport'
import './portal/smartbook'


mock.onAny().passThrough()
