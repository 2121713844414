// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
import book from  '@src/views/Portal/Books/store'
import video from '@src/views/Portal/VideoLessons/store'
import onlineExams from  '@src/views/Portal/OnlineExam/store'
import testing from  '@src/views/Portal/Testing/store'
import calendar from  '@src/views/Portal/Calendar/store'
import report from '@src/views/Portal/Reports/store'
import examReport from '@src/views/Portal/TryingExamReport/store'
import smartBook from '@src/views/Portal/WhiteBoard/store'


const rootReducer = {
  auth,
  navbar,
  layout,
  book,
  onlineExams,
  testing,
  calendar,
  report,
  video,
  examReport,
  smartBook
}

export default rootReducer
