import mock from '../mock'
/* eslint-disable */
// ** Utils
import { paginateArray, sortCompare } from '../utils'

const data = {
   id:"1",
   bookName:"9. Sınıf Matematik Soru Bankası",
   bookContents:[
      {
        id:"1",
        Name:"2. Ünite"
      },
      {
        id:"2",
        Name:"3. Ünite"
      },
      {
        id:"3",
        Name:"4. Ünite"
      }

   ]
   
}

const activities={
  items: [{
    id:1,
    type:"table",
    columns: [
        {header:"number", replacestring: null}, 
        {header:"question", replacestring: null}, 
        {header:"header1", replacestring:"İsim-Fiil"}, 
        {header:"header2", replacestring:"Olumsuzluk Eki"}
      ],
    columnsToHide: ["_id"],
    headerStyles:{
      backgroundColor:"#8cc63e",
      fontSize:"17px",
      color:"#FFF"
    },
    questionRowStyles:{
      backgroundColor:"#fffde6",
      fontSize:"15px",
      color:"#231F20"
    },
    questionNumberStyles:{
      fontSize:"15px",
      textAlign:"center"
    },
    results: [
      {
        _id: "1",
        number:"1",
        question:"Araba kullanmayı çok seviyorum.",
        header1: "{B:2,*C:'Fiilimsi Test2 Yok Artık'}",
        header2: "{B:2,*C:'Fiilimsi 22222 Yok Artık'}"
      },
      {
        _id: "2",
        number:"2",
        question:"Bir kez olsun kardeşini sormadı.",
        header1: "{B:2,*C:'Fiilimsi Test2 Yok Artık'}",
        header2: "{B:2,*C:'Fiilimsi 22222 Yok Artık'}"
      },
      {
        _id: "3",
        number:"3",
        question:"Onun çalışma sistemi harikaydı.",
        header1: "{B:2,*C:'Fiilimsi Test2 Yok Artık'}",
        header2: "{B:2,*C:'Fiilimsi 22222 Yok Artık'}"
      },
      {
        _id: "4",
        number:"4",
        question:"Benimle neden gelmedin?",
        header1: "{B:2,*C:'Fiilimsi Test2 Yok Artık'}",
        header2: "{B:2,*C:'Fiilimsi 22222 Yok Artık'}"
      }
    ]
  }]
}
// ------------------------------------------------
// GET: Return bookContents
// ------------------------------------------------
mock.onGet('/apps/books/getBookContents').reply(config => {

  return [
    200,
    {
      books: data,
    }
  ]
})

mock.onGet('/apps/books/getBookContentActivities').reply(config => {
  let bookContentId = config.url.substring(config.url.lastIndexOf('/') + 1)
  bookContentId = Number(bookId)
  const bookContentActivities = activities.items.filter(activity => activity.bookContentId==bookContentId);
  return [
    200,
    {
      bookContentActivities: bookContentActivities,
    }
  ]
})


