import mock from '../mock'
import moment from 'moment'
import { paginateArray } from '../utils'

const data = {
   exams: [
     {
       id:1,
       cover:'https://kurumsalbasariseti.palmeyayinevi.com/Media/UserData/kapak/tyt-ayt/04_01_2021_16_17_16%7Besit-agirlik-ekstra%7D.png',
       category:'activeexam',
       name:'9. Sınıf Matematik Online Deneme Sınavı',
       type: 'TYT',
       complated:'nevercomplate'
     },
     {
      id:2,
      cover:'https://kurumsalbasariseti.palmeyayinevi.com/Media/UserData/smartbookitems/52/coverimage/2021-05-1--10-55-382021-04-1--17-59-48eşit%20ağırlık%20sayısal.png',
      category:'complatedexam',
      name:'10. Sınıf Fen Bilgisi Online Deneme Sınavı',
      type: 'AYT',
      time:80,
      complated:'complate'
     },
     {
      id:3,
      cover:'https://kurumsalbasariseti.palmeyayinevi.com/Media/UserData/kapak/ayt/02_07_2020_17_49_12%7Bayttarihpaf%7D.png',
      category:'futureexam',
      name:'11. Sınıf Tarih Online Deneme Sınavı',
      type: 'TYT',
      startDate:'07.01.2022',
      endDate:'09.01.2022',
      complated:'nevercomplate'
     },
     {
      id:4,
      cover:'https://kurumsalbasariseti.palmeyayinevi.com/Media/UserData/kapak/11-sınıf/15_12_2021_09_01_40%7B11-extra-sayisal-kapak-13-12-2021-1%7D.jpg',
      category:'futureexam',
      name:'4. Sınıf Hayat Bilgisi Online Deneme Sınavı',
      type: 'AYT',
      time: 100,
      startDate:'03.01.2022',
      startTime: '14:00',
      endDate:'09.01.2022',
      endTime: '15:40',
      complated:'nevercomplate'
     },
     {
      id:6,
      cover:'https://kurumsalbasariseti.palmeyayinevi.com/Media/UserData/smartbookitems/52/coverimage/2021-05-1--10-55-382021-04-1--17-59-48eşit%20ağırlık%20sayısal.png',
      category:'activeexam',
      name:'1. Sınıf Din Kültürü ve Ahlak Bilgisi Online Deneme Sınavı',
      type: 'TYT',
      time:180,
      startDate: moment().format('01/06/2022', '15:00:00'),
      endDate:moment().format('01/07/2022', '14:27:33'),
      endTime: moment().format('19:30:10'),
      complated:'nevercomplate'
     },
     {
      id:7,
      cover:'',
      cover:'https://kurumsalbasariseti.palmeyayinevi.com/Media/UserData/kapak/ayt/02_07_2020_17_49_12%7Bayttarihpaf%7D.png',
      name:'2. Sınıf Din Kültürü ve Ahlak Bilgisi Online Deneme Sınavı',
      type: 'TYT',
      time:180,
      complated:'nevercomplate'
     },
     {
      id:8,
      cover:'https://kurumsalbasariseti.palmeyayinevi.com/Media/UserData/kapak/11-sınıf/15_12_2021_09_01_40%7B11-extra-sayisal-kapak-13-12-2021-1%7D.jpg',
      category:'activeexam',
      name:'7. Sınıf Din Kültürü ve Ahlak Bilgisi Online Deneme Sınavı',
      type: 'TYT',
      time:180,
      startDate:'09.01.2022',
      startTime: '16:00',
      endDate:'13.01.2022',
      endTime: '19:00',
      complated:'nevercomplate'
     },
     {
      id:9,
      cover:'',
      category:'activeexam',
      name:'4. Sınıf Din Kültürü ve Ahlak Bilgisi Online Deneme Sınavı',
      type: 'TYT',
      time:180,
      complated:'nevercomplate'
     },
     {
      id:10,
      cover:'https://kurumsalbasariseti.palmeyayinevi.com/Media/UserData/kapak/11-sınıf/15_12_2021_09_01_40%7B11-extra-sayisal-kapak-13-12-2021-1%7D.jpg',
      category:'activeexam',
      name:'5. Sınıf Din Kültürü ve Ahlak Bilgisi Online Deneme Sınavı',
      type: 'TYT',
      startDate:'09.01.2022',
      startTime: '16:00',
      endDate:'13.01.2022',
      endTime: '19:00',
      complated:'nevercomplate'
     },
     {
      id:11,
      cover:'',
      category:'activeexam',
      name:'7. Sınıf Din Kültürü ve Ahlak Bilgisi Online Deneme Sınavı',
      type: 'TYT',
      complated:'nevercomplate'
     },
     {
      id:12,
      cover:'https://kurumsalbasariseti.palmeyayinevi.com/Media/UserData/kapak/ayt/02_07_2020_17_49_12%7Bayttarihpaf%7D.png',
      category:'futureexam',
      name:'11. Sınıf Tarih Online Deneme Sınavı',
      type: 'TYT',
      startDate:'07.01.2022',
      endDate:'09.01.2022',
      complated:'nevercomplate'
     },
     {
      id:13,
      cover:'https://kurumsalbasariseti.palmeyayinevi.com/Media/UserData/kapak/11-sınıf/15_12_2021_09_01_40%7B11-extra-sayisal-kapak-13-12-2021-1%7D.jpg',
      category:'futureexam',
      name:'4. Sınıf Hayat Bilgisi Online Deneme Sınavı',
      type: 'AYT',
      time: 100,
      startDate:'03.01.2022',
      startTime: '14:00',
      endDate:'09.01.2022',
      endTime: '15:40',
      complated:'nevercomplate'
     },
     {
      id:14,
      cover:'https://kurumsalbasariseti.palmeyayinevi.com/Media/UserData/smartbookitems/52/coverimage/2021-05-1--10-55-382021-04-1--17-59-48eşit%20ağırlık%20sayısal.png',
      category:'activeexam',
      name:'1. Sınıf Din Kültürü ve Ahlak Bilgisi Online Deneme Sınavı',
      type: 'TYT',
      time:180,
      startDate:'09.01.2022',
      startTime: '16:00',
      endDate:'13.01.2022',
      endTime: '19:00',
      complated:'nevercomplate'
     },
     {
      id:15,
      cover:'https://kurumsalbasariseti.palmeyayinevi.com/Media/UserData/smartbookitems/52/coverimage/2021-05-1--10-55-382021-04-1--17-59-48eşit%20ağırlık%20sayısal.png',
      category:'activeexam',
      name:'1. Sınıf Din Kültürü ve Ahlak Bilgisi Online Deneme Sınavı',
      type: 'TYT',
      time:180,
      startDate:'09.01.2022',
      startTime: '16:00',
      endDate:'13.01.2022',
      endTime: '19:00',
      complated:'nevercomplate'
     },
     {
      id:16,
      cover:'https://kurumsalbasariseti.palmeyayinevi.com/Media/UserData/smartbookitems/52/coverimage/2021-05-1--10-55-382021-04-1--17-59-48eşit%20ağırlık%20sayısal.png',
      category:'activeexam',
      name:'1. Sınıf Din Kültürü ve Ahlak Bilgisi Online Deneme Sınavı',
      type: 'TYT',
      time:180,
      startDate:'09.01.2022',
      startTime: '16:00',
      endDate:'13.01.2022',
      endTime: '19:00',
      complated:'nevercomplate'
     },
     {
      id:17,
      cover:'https://kurumsalbasariseti.palmeyayinevi.com/Media/UserData/smartbookitems/52/coverimage/2021-05-1--10-55-382021-04-1--17-59-48eşit%20ağırlık%20sayısal.png',
      category:'activeexam',
      name:'1. Sınıf Din Kültürü ve Ahlak Bilgisi Online Deneme Sınavı',
      type: 'TYT',
      time:180,
      startDate:'09.01.2022',
      startTime: '16:00',
      endDate:'13.01.2022',
      endTime: '19:00',
      complated:'nevercomplate'
     },
     {
      id:18,
      cover:'https://kurumsalbasariseti.palmeyayinevi.com/Media/UserData/smartbookitems/52/coverimage/2021-05-1--10-55-382021-04-1--17-59-48eşit%20ağırlık%20sayısal.png',
      category:'activeexam',
      name:'1. Sınıf Din Kültürü ve Ahlak Bilgisi Online Deneme Sınavı',
      type: 'TYT',
      time:180,
      startDate:'09.01.2022',
      startTime: '16:00',
      endDate:'13.01.2022',
      endTime: '19:00',
      complated:'nevercomplate'
     },
     {
      id:19,
      cover:'https://kurumsalbasariseti.palmeyayinevi.com/Media/UserData/smartbookitems/52/coverimage/2021-05-1--10-55-382021-04-1--17-59-48eşit%20ağırlık%20sayısal.png',
      category:'activeexam',
      name:'1. Sınıf Din Kültürü ve Ahlak Bilgisi Online Deneme Sınavı',
      type: 'TYT',
      time:180,
      startDate: moment().format('03 03 2021'),
      startTime: moment().format('15:00:00'),
      endDate:moment().format('05 03 2021'),
      endTime: moment().format('17:00:00'),
      complated:'nevercomplate'
     }
   ]  
}

const examdata = {
  exam: [
    {
      id:6,
      name:"1. Sınıf Din Kültürü ve Ahlak Bilgisi Online Deneme Sınavı",
      time:180,
      sections:[
        {
          id: 1,
          title: "Matematik",
          questions: [
            {
              id: 1815,
              number: 1,
              title:"1. Soru",
              userAnswer:'',
              correctAnswer:'A',
              content: "<p>&Ccedil;oluk &ccedil;ocuk k&ouml;y &ccedil;eşmesinden <u>kana kana</u> su i&ccedil;tik.</p><p><strong>Bu c&uuml;mledeki altı &ccedil;izili s&ouml;zc&uuml;k grubu i&ccedil;in hangisi s&ouml;ylenebilir?</strong></p>",
              cover: "https://cdn1.raunt.com/PUBLIC_FILES/raunt/NewRauntSite/images/sub-pages/soru-cozumleri/questions_section_img_2.jpg",
              choices: [
                {
                  id:1,
                  choiceText:"A",
                  content:"Arkadaşına yalan söyleyenin yüzü kara olsun.",
                  isTrue:true,
                  isStudentAnswer:"false"
                },
                {
                  id:2,
                  choiceText:"B",
                  content:"Güneş girmeyen eve doktor girer.",
                  isTrue:true,
                  isStudentAnswer:"false"
  
                },
                {
                  id:3,
                  choiceText:"C",
                  content:"Koyunun bulunmadığı yerde keçiye Abdurrahman Çelebi derler.",
                  isTrue:true,
                  isStudentAnswer:"false"
  
                },
                {
                  id:4,
                  choiceText:"D",
                  content:"Arkadaşına yalan söyleyenin yüzü kara olsun.",
                  isTrue:true,
                  isStudentAnswer:"false"
  
                },
                {
                  id:5,
                  choiceText:"E",
                  content:"Arkadaşına yalan söyleyenin yüzü kara olsun.",
                  isTrue:true,
                  isStudentAnswer:"false"
  
                }
              ]
            },
            {
              id: 1816,
              number: 2,
              title:"2. Soru",
              userAnswer:'',
              correctAnswer:'A',
              content:"<b>Aşağıdaki atasözlerinde geçen sıfat-ﬁillerden hangisi diğerlerinden farklı bir görevde kullanılmıştır?</b>",
              cover: "https://cdn1.raunt.com/PUBLIC_FILES/raunt/NewRauntSite/images/sub-pages/soru-cozumleri/questions_section_img_2.jpg",
              choices: [
                {
                  id:1,
                  choiceText:"A",
                  content:"Arkadaşına yalan söyleyenin yüzü kara olsun.",
                  isTrue:true,
                  isStudentAnswer:"false"
  
                },
                {
                  id:2,
                  choiceText:"B",
                  content:"Güneş girmeyen eve doktor girer.",
                  isTrue:true,
                  isStudentAnswer:"false"
  
                },
                {
                  id:3,
                  choiceText:"C",
                  content:"Koyunun bulunmadığı yerde keçiye Abdurrahman Çelebi derler.",
                  isTrue:true,
                  isStudentAnswer:"false"
  
                },
                {
                  id:4,
                  choiceText:"D",
                  content:"Arkadaşına yalan söyleyenin yüzü kara olsun.",
                  isTrue:true,
                  isStudentAnswer:"false"
  
                },
                {
                  id:5,
                  choiceText:"E",
                  content:"Arkadaşına yalan söyleyenin yüzü kara olsun.",
                  isTrue:true,
                  isStudentAnswer:"false"
  
                }
              ]
            },
            {
              id: 1817,
              number: 3,
              title:"3. Soru",
              userAnswer:'',
              correctAnswer:'A',
              content: "<p>&Ccedil;oluk &ccedil;ocuk k&ouml;y &ccedil;eşmesinden <u>kana kana</u> su i&ccedil;tik.</p><p><strong>Bu c&uuml;mledeki altı &ccedil;izili s&ouml;zc&uuml;k grubu i&ccedil;in hangisi s&ouml;ylenebilir?</strong></p>",
              cover: "https://cdn1.raunt.com/PUBLIC_FILES/raunt/NewRauntSite/images/sub-pages/soru-cozumleri/questions_section_img_2.jpg",
              choices: [
                {
                  id:1,
                  choiceText:"A",
                  content:"Arkadaşına yalan söyleyenin yüzü kara olsun.",
                  isTrue:true,
                  isStudentAnswer:"false"
  
                },
                {
                  id:2,
                  choiceText:"B",
                  content:"Güneş girmeyen eve doktor girer.",
                  isTrue:true,
                  isStudentAnswer:"false"
  
                },
                {
                  id:3,
                  choiceText:"C",
                  content:"Koyunun bulunmadığı yerde keçiye Abdurrahman Çelebi derler.",
                  isTrue:true,
                  isStudentAnswer:"false"
  
                },
                {
                  id:4,
                  choiceText:"D",
                  content:"Arkadaşına yalan söyleyenin yüzü kara olsun.",
                  isTrue:true,
                  isStudentAnswer:"false"
  
                },
                {
                  id:5,
                  choiceText:"E",
                  content:"Arkadaşına yalan söyleyenin yüzü kara olsun.",
                  isTrue:true,
                  isStudentAnswer:"false"
  
                }
              ]
            },
            {
              id: 1818,
              number: 4,
              title:"4. Soru",
              userAnswer:'',
              correctAnswer:'A',
              content: "<p>&Ccedil;oluk &ccedil;ocuk k&ouml;y &ccedil;eşmesinden <u>kana kana</u> su i&ccedil;tik.</p><p><strong>Bu c&uuml;mledeki altı &ccedil;izili s&ouml;zc&uuml;k grubu i&ccedil;in hangisi s&ouml;ylenebilir?</strong></p>",
              cover: "https://cdn1.raunt.com/PUBLIC_FILES/raunt/NewRauntSite/images/sub-pages/soru-cozumleri/questions_section_img_2.jpg",
              choices: [
                {
                  id:1,
                  choiceText:"A",
                  content:"Arkadaşına yalan söyleyenin yüzü kara olsun.",
                  isTrue:true,
                  isStudentAnswer:"false"
  
                },
                {
                  id:2,
                  choiceText:"B",
                  content:"Güneş girmeyen eve doktor girer.",
                  isTrue:true,
                  isStudentAnswer:"false"
  
                },
                {
                  id:3,
                  choiceText:"C",
                  content:"Koyunun bulunmadığı yerde keçiye Abdurrahman Çelebi derler.",
                  isTrue:true,
                  isStudentAnswer:"false"
  
                },
                {
                  id:4,
                  choiceText:"D",
                  content:"Arkadaşına yalan söyleyenin yüzü kara olsun.",
                  isTrue:true,
                  isStudentAnswer:"false"
  
                },
                {
                  id:5,
                  choiceText:"E",
                  content:"Arkadaşına yalan söyleyenin yüzü kara olsun.",
                  isTrue:true,
                  isStudentAnswer:"false"
  
                }
              ]
            },
            {
              id: 1819,
              number: 5,
              title:"5. Soru",
              userAnswer:'',
              correctAnswer:'A',
              content: "<p>&Ccedil;oluk &ccedil;ocuk k&ouml;y &ccedil;eşmesinden <u>kana kana</u> su i&ccedil;tik.</p><p><strong>Bu c&uuml;mledeki altı &ccedil;izili s&ouml;zc&uuml;k grubu i&ccedil;in hangisi s&ouml;ylenebilir?</strong></p>",
              cover: "https://cdn1.raunt.com/PUBLIC_FILES/raunt/NewRauntSite/images/sub-pages/soru-cozumleri/questions_section_img_2.jpg",
              choices: [
                {
                  id:1,
                  choiceText:"A",
                  content:"Arkadaşına yalan söyleyenin yüzü kara olsun.",
                  isTrue:true,
                  isStudentAnswer:"false"
  
                },
                {
                  id:2,
                  choiceText:"B",
                  content:"Güneş girmeyen eve doktor girer.",
                  isTrue:true,
                  isStudentAnswer:"false"
  
                },
                {
                  id:3,
                  choiceText:"C",
                  content:"Koyunun bulunmadığı yerde keçiye Abdurrahman Çelebi derler.",
                  isTrue:true,
                  isStudentAnswer:"false"
  
                },
                {
                  id:4,
                  choiceText:"D",
                  content:"Arkadaşına yalan söyleyenin yüzü kara olsun.",
                  isTrue:true,
                  isStudentAnswer:"false"
  
                },
                {
                  id:5,
                  choiceText:"E",
                  content:"Arkadaşına yalan söyleyenin yüzü kara olsun.",
                  isTrue:true,
                  isStudentAnswer:"false"
  
                }
              ]
            },
            {
              id: 1820,
              number: 6,
              userAnswer:'',
              title:"6. Soru",
              correctAnswer:'A',
              content: "<p>&Ccedil;oluk &ccedil;ocuk k&ouml;y &ccedil;eşmesinden <u>kana kana</u> su i&ccedil;tik.</p><p><strong>Bu c&uuml;mledeki altı &ccedil;izili s&ouml;zc&uuml;k grubu i&ccedil;in hangisi s&ouml;ylenebilir?</strong></p>",
              cover: "https://cdn1.raunt.com/PUBLIC_FILES/raunt/NewRauntSite/images/sub-pages/soru-cozumleri/questions_section_img_2.jpg",
              choices: [
                {
                  id:1,
                  choiceText:"A",
                  content:"Arkadaşına yalan söyleyenin yüzü kara olsun.",
                  isTrue:true,
                  isStudentAnswer:"false"
  
                },
                {
                  id:2,
                  choiceText:"B",
                  content:"Güneş girmeyen eve doktor girer.",
                  isTrue:true,
                  isStudentAnswer:"false"
  
                },
                {
                  id:3,
                  choiceText:"C",
                  content:"Koyunun bulunmadığı yerde keçiye Abdurrahman Çelebi derler.",
                  isTrue:true,
                  isStudentAnswer:"false"
  
                },
                {
                  id:4,
                  choiceText:"D",
                  content:"Arkadaşına yalan söyleyenin yüzü kara olsun.",
                  isTrue:true,
                  isStudentAnswer:"false"
  
                },
                {
                  id:5,
                  choiceText:"E",
                  content:"Arkadaşına yalan söyleyenin yüzü kara olsun.",
                  isTrue:true,
                  isStudentAnswer:"false"
  
                }
              ]
            },
            {
              id: 1821,
              number: 7,
              title:"7. Soru",
              userAnswer:'',
              correctAnswer:'A',
              content: "<p>&Ccedil;oluk &ccedil;ocuk k&ouml;y &ccedil;eşmesinden <u>kana kana</u> su i&ccedil;tik.</p><p><strong>Bu c&uuml;mledeki altı &ccedil;izili s&ouml;zc&uuml;k grubu i&ccedil;in hangisi s&ouml;ylenebilir?</strong></p>",
              cover: "https://cdn1.raunt.com/PUBLIC_FILES/raunt/NewRauntSite/images/sub-pages/soru-cozumleri/questions_section_img_2.jpg",
              choices: [
                {
                  id:1,
                  choiceText:"A",
                  content:"Arkadaşına yalan söyleyenin yüzü kara olsun.",
                  isTrue:true,
                  isStudentAnswer:"false"
  
                },
                {
                  id:2,
                  choiceText:"B",
                  content:"Güneş girmeyen eve doktor girer.",
                  isTrue:true,
                  isStudentAnswer:"false"
  
                },
                {
                  id:3,
                  choiceText:"C",
                  content:"Koyunun bulunmadığı yerde keçiye Abdurrahman Çelebi derler.",
                  isTrue:true,
                  isStudentAnswer:"false"
  
                },
                {
                  id:4,
                  choiceText:"D",
                  content:"Arkadaşına yalan söyleyenin yüzü kara olsun.",
                  isTrue:true,
                  isStudentAnswer:"false"
  
                },
                {
                  id:5,
                  choiceText:"E",
                  content:"Arkadaşına yalan söyleyenin yüzü kara olsun.",
                  isTrue:true,
                  isStudentAnswer:"false"
  
                }
              ]
            }
          ]
        },
        {
          id: 2,
          title: "Fen Bilgisi",
          questions: [
            {
              id: 1825,
              number: 1,
              title:"1. Soru",
              userAnswer:'',
              correctAnswer:'A',
              content: "<p>&Ccedil;oluk &ccedil;ocuk k&ouml;y &ccedil;eşmesinden <u>kana kana</u> su i&ccedil;tik.</p><p><strong>Bu c&uuml;mledeki altı &ccedil;izili s&ouml;zc&uuml;k grubu i&ccedil;in hangisi s&ouml;ylenebilir?</strong></p>",
              cover: "https://cdn1.raunt.com/PUBLIC_FILES/raunt/NewRauntSite/images/sub-pages/soru-cozumleri/questions_section_img_2.jpg",
              choices: [
                {
                  id:1,
                  choiceText:"A",
                  content:"Arkadaşına yalan söyleyenin yüzü kara olsun.",
                  isTrue:true,
                  isStudentAnswer:"false"
  
                },
                {
                  id:2,
                  choiceText:"B",
                  content:"Güneş girmeyen eve doktor girer.",
                  isTrue:true,
                  isStudentAnswer:"false"
  
                },
                {
                  id:3,
                  choiceText:"C",
                  content:"Koyunun bulunmadığı yerde keçiye Abdurrahman Çelebi derler.",
                  isTrue:true,
                  isStudentAnswer:"false"
  
                },
                {
                  id:4,
                  choiceText:"D",
                  content:"Arkadaşına yalan söyleyenin yüzü kara olsun.",
                  isTrue:true,
                  isStudentAnswer:"false"
  
                },
                {
                  id:5,
                  choiceText:"E",
                  content:"Arkadaşına yalan söyleyenin yüzü kara olsun.",
                  isTrue:true,
                  isStudentAnswer:"false"
  
                }
              ]
            },
            {
              id: 1826,
              number: 2,
              title:"2. Soru",
              userAnswer:'',
              correctAnswer:'A',
              content:"<p>Aşağıdaki atasözlerinde geçen sıfat-ﬁillerden hangisi diğerlerinden farklı bir görevde kullanılmıştır?</p>",
              cover: "https://cdn1.raunt.com/PUBLIC_FILES/raunt/NewRauntSite/images/sub-pages/soru-cozumleri/questions_section_img_2.jpg",
              choices: [
                {
                  id:1,
                  choiceText:"A",
                  content:"Arkadaşına yalan söyleyenin yüzü kara olsun.",
                  isTrue:true,
                  isStudentAnswer:"false"
  
                },
                {
                  id:2,
                  choiceText:"B",
                  content:"Güneş girmeyen eve doktor girer.",
                  isTrue:true,
                  isStudentAnswer:"false"
  
                },
                {
                  id:3,
                  choiceText:"C",
                  content:"Koyunun bulunmadığı yerde keçiye Abdurrahman Çelebi derler.",
                  isTrue:true,
                  isStudentAnswer:"false"
  
                },
                {
                  id:4,
                  choiceText:"D",
                  content:"Arkadaşına yalan söyleyenin yüzü kara olsun.",
                  isTrue:true,
                  isStudentAnswer:"false"
  
                },
                {
                  id:5,
                  choiceText:"E",
                  content:"Arkadaşına yalan söyleyenin yüzü kara olsun.",
                  isTrue:true,
                  isStudentAnswer:"false"
  
                }
              ]
            },
            {
              id: 1827,
              number: 3,
              title:"3. Soru",
              userAnswer:'',
              correctAnswer:'A',
              content: "<p>&Ccedil;oluk &ccedil;ocuk k&ouml;y &ccedil;eşmesinden <u>kana kana</u> su i&ccedil;tik.</p><p><strong>Bu c&uuml;mledeki altı &ccedil;izili s&ouml;zc&uuml;k grubu i&ccedil;in hangisi s&ouml;ylenebilir?</strong></p>",
              cover: "https://cdn1.raunt.com/PUBLIC_FILES/raunt/NewRauntSite/images/sub-pages/soru-cozumleri/questions_section_img_2.jpg",
              choices: [
                {
                  id:1,
                  choiceText:"A",
                  content:"Arkadaşına yalan söyleyenin yüzü kara olsun.",
                  isTrue:true,
                  isStudentAnswer:"false"
  
                },
                {
                  id:2,
                  choiceText:"B",
                  content:"Güneş girmeyen eve doktor girer.",
                  isTrue:true,
                  isStudentAnswer:"false"
  
                },
                {
                  id:3,
                  choiceText:"C",
                  content:"Koyunun bulunmadığı yerde keçiye Abdurrahman Çelebi derler.",
                  isTrue:true,
                  isStudentAnswer:"false"
  
                },
                {
                  id:4,
                  choiceText:"D",
                  content:"Arkadaşına yalan söyleyenin yüzü kara olsun.",
                  isTrue:true,
                  isStudentAnswer:"false"
  
                },
                {
                  id:5,
                  choiceText:"E",
                  content:"Arkadaşına yalan söyleyenin yüzü kara olsun.",
                  isTrue:true,
                  isStudentAnswer:"false"
  
                }
              ]
            },
            {
              id: 1828,
              number: 4,
              title:"4. Soru",
              userAnswer:'',
              correctAnswer:'A',
              content: "<p>&Ccedil;oluk &ccedil;ocuk k&ouml;y &ccedil;eşmesinden <u>kana kana</u> su i&ccedil;tik.</p><p><strong>Bu c&uuml;mledeki altı &ccedil;izili s&ouml;zc&uuml;k grubu i&ccedil;in hangisi s&ouml;ylenebilir?</strong></p>",
              cover: "https://cdn1.raunt.com/PUBLIC_FILES/raunt/NewRauntSite/images/sub-pages/soru-cozumleri/questions_section_img_2.jpg",
              choices: [
                {
                  id:1,
                  choiceText:"A",
                  content:"Arkadaşına yalan söyleyenin yüzü kara olsun.",
                  isTrue:true,
                  isStudentAnswer:"false"
  
                },
                {
                  id:2,
                  choiceText:"B",
                  content:"Güneş girmeyen eve doktor girer.",
                  isTrue:true,
                  isStudentAnswer:"false"
  
                },
                {
                  id:3,
                  choiceText:"C",
                  content:"Koyunun bulunmadığı yerde keçiye Abdurrahman Çelebi derler.",
                  isTrue:true,
                  isStudentAnswer:"false"
  
                },
                {
                  id:4,
                  choiceText:"D",
                  content:"Arkadaşına yalan söyleyenin yüzü kara olsun.",
                  isTrue:true,
                  isStudentAnswer:"false"
  
                },
                {
                  id:5,
                  choiceText:"E",
                  content:"Arkadaşına yalan söyleyenin yüzü kara olsun.",
                  isTrue:true,
                  isStudentAnswer:"false"
  
                }
              ]
            },
            {
              id: 1829,
              number: 5,
              title:"5. Soru",
              userAnswer:'',
              correctAnswer:'A',
              content: "<p>&Ccedil;oluk &ccedil;ocuk k&ouml;y &ccedil;eşmesinden <u>kana kana</u> su i&ccedil;tik.</p><p><strong>Bu c&uuml;mledeki altı &ccedil;izili s&ouml;zc&uuml;k grubu i&ccedil;in hangisi s&ouml;ylenebilir?</strong></p>",
              cover: "https://cdn1.raunt.com/PUBLIC_FILES/raunt/NewRauntSite/images/sub-pages/soru-cozumleri/questions_section_img_2.jpg",
              choices: [
                {
                  id:1,
                  choiceText:"A",
                  content:"Arkadaşına yalan söyleyenin yüzü kara olsun.",
                  isTrue:true,
                  isStudentAnswer:"false"
  
                },
                {
                  id:2,
                  choiceText:"B",
                  content:"Güneş girmeyen eve doktor girer.",
                  isTrue:true,
                  isStudentAnswer:"false"
  
                },
                {
                  id:3,
                  choiceText:"C",
                  content:"Koyunun bulunmadığı yerde keçiye Abdurrahman Çelebi derler.",
                  isTrue:true,
                  isStudentAnswer:"false"
  
                },
                {
                  id:4,
                  choiceText:"D",
                  content:"Arkadaşına yalan söyleyenin yüzü kara olsun.",
                  isTrue:true,
                  isStudentAnswer:"false"
  
                },
                {
                  id:5,
                  choiceText:"E",
                  content:"Arkadaşına yalan söyleyenin yüzü kara olsun.",
                  isTrue:true,
                  isStudentAnswer:"false"
  
                }
              ]
            },
            {
              id: 1830,
              number: 6,
              title:"6. Soru",
              userAnswer:'',
              correctAnswer:'A',
              content: "<p>&Ccedil;oluk &ccedil;ocuk k&ouml;y &ccedil;eşmesinden <u>kana kana</u> su i&ccedil;tik.</p><p><strong>Bu c&uuml;mledeki altı &ccedil;izili s&ouml;zc&uuml;k grubu i&ccedil;in hangisi s&ouml;ylenebilir?</strong></p>",
              cover: "https://cdn1.raunt.com/PUBLIC_FILES/raunt/NewRauntSite/images/sub-pages/soru-cozumleri/questions_section_img_2.jpg",
              choices: [
                {
                  id:1,
                  choiceText:"A",
                  content:"Arkadaşına yalan söyleyenin yüzü kara olsun.",
                  isTrue:true,
                  isStudentAnswer:"false"
  
                },
                {
                  id:2,
                  choiceText:"B",
                  content:"Güneş girmeyen eve doktor girer.",
                  isTrue:true,
                  isStudentAnswer:"false"
  
                },
                {
                  id:3,
                  choiceText:"C",
                  content:"Koyunun bulunmadığı yerde keçiye Abdurrahman Çelebi derler.",
                  isTrue:true,
                  isStudentAnswer:"false"
  
                },
                {
                  id:4,
                  choiceText:"D",
                  content:"Arkadaşına yalan söyleyenin yüzü kara olsun.",
                  isTrue:true,
                  isStudentAnswer:"false"
  
                },
                {
                  id:5,
                  choiceText:"E",
                  content:"Arkadaşına yalan söyleyenin yüzü kara olsun.",
                  isTrue:true,
                  isStudentAnswer:"false"
  
                }
              ]
            },
            {
              id: 1831,
              number: 7,
              title:"7. Soru",
              userAnswer:'',
              correctAnswer:'A',
              content: "<p>&Ccedil;oluk &ccedil;ocuk k&ouml;y &ccedil;eşmesinden <u>kana kana</u> su i&ccedil;tik.</p><p><strong>Bu c&uuml;mledeki altı &ccedil;izili s&ouml;zc&uuml;k grubu i&ccedil;in hangisi s&ouml;ylenebilir?</strong></p>",
              cover: "https://cdn1.raunt.com/PUBLIC_FILES/raunt/NewRauntSite/images/sub-pages/soru-cozumleri/questions_section_img_2.jpg",
              choices: [
                {
                  id:1,
                  choiceText:"A",
                  content:"Arkadaşına yalan söyleyenin yüzü kara olsun.",
                  isTrue:true,
                  isStudentAnswer:"false"
  
                },
                {
                  id:2,
                  choiceText:"B",
                  content:"Güneş girmeyen eve doktor girer.",
                  isTrue:true,
                  isStudentAnswer:"false"
  
                },
                {
                  id:3,
                  choiceText:"C",
                  content:"Koyunun bulunmadığı yerde keçiye Abdurrahman Çelebi derler.",
                  isTrue:true,
                  isStudentAnswer:"false"
  
                },
                {
                  id:4,
                  choiceText:"D",
                  content:"Arkadaşına yalan söyleyenin yüzü kara olsun.",
                  isTrue:true,
                  isStudentAnswer:"false"
  
                },
                {
                  id:5,
                  choiceText:"E",
                  content:"Arkadaşına yalan söyleyenin yüzü kara olsun.",
                  isTrue:true,
                  isStudentAnswer:"false"
  
                }
              ]
            }
          ]
        }
      ]
    }
  ]
}


mock.onGet('/apps/exams/getExams').reply((config) => {
  const { category = '', q = '', perPage = 9, page = 1   } = config.params
  const queryLowered = q.toLowerCase()
  const filterExam = data.exams.filter(exam => exam.category === category)
  const filteredData = filterExam.filter(exam => exam.name.toLowerCase().includes(queryLowered))
  const paginatedData = JSON.parse(JSON.stringify(paginateArray(filteredData, perPage, page)))

 return [
   200,
   {
     exams: paginatedData,
     total: filteredData.length
   }
 ]
})

mock.onGet('/apps/exam/getExam').reply((config) => {
  const paramsId = Number(config.params)
  const filteredExam = examdata.exam.filter(exam => exam.id === paramsId)
  const questions = filteredExam[0].sections.map(aa => aa.questions) 
  const merged = [].concat.apply([], questions) // tüm questionları tek bir dizi içinde toplar

 return [
   200,
   {
     exam: filteredExam[0],
     questions: merged
   }
 ]
})

