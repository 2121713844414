// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getBooks = createAsyncThunk('books/getBooks', async params => {
  const response = await axios.get('/apps/books/getBooks', { params })
  return { params, data: response.data }
})

export const getBook = createAsyncThunk('books/getBook', async slug => {
  const response = await axios.get(`/apps/books/getBook/${slug}`)
  return response.data
})

export const getQuestions = createAsyncThunk('question/getQuestions', async params => {
  const response = await axios.get(`/apps/question/getQuestions`, {params})
  return response.data
})

export const appBookSlice = createSlice({
  name: 'books',
  initialState: {
    params: {},
    books: [],
    totalBooks: 0,
    bookDetail: {}
  },
  reducers: {
    clearBook: (state) => {
      state.books = []
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getBooks.fulfilled, (state, action) => {
        state.params = action.payload.params
        state.books = action.payload.data.books
        state.totalBooks = action.payload.data.total
      })
      .addCase(getBook.fulfilled, (state, action) => {
        state.bookDetail = action.payload.book
      })
      .addCase(getQuestions.fulfilled, (state, action) => {
        state.questions = action.payload.questions
      })

  }
})


export const {clearBook} = appBookSlice.actions
export default appBookSlice.reducer
