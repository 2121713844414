import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

export const getExams = createAsyncThunk('exams/getExams', async params => {
    const response = await axios.get('/apps/exams/getExams', {params})
    return {params, data:response.data}
})

export const getExam = createAsyncThunk('exams/getExam', async params => {
    const response = await axios.get(`/apps/exam/getExam`, {params})
    return response.data
})

const examSlice = createSlice({
    name:'exams',
    initialState: {
        params:{},
        exams:[],
        totalExams: 0,
        currentExam:[],
        index:0,
        activeTest:'',
        currentQuestion:null,
        questions:[]
    },
    reducers:{
        setIndex: (state, action) => {
            state.index = action.payload           
        },
        setCurrentQuestion: (state, action) => {
            // const currentQuestion = state.questions.find(x => x.id === action.payload)[0]
            // const index = state.questions.findIndex(x => x.id === action.payload.questionId)
            // console.log(index)
            const questions = state.currentExam.sections.map(aa => aa.questions) 
            const merged = [].concat.apply([], questions)
            state.currentQuestion = merged.find(x => x.id === action.payload)        
        },
        setUserAnswer : (state, action) => {
            const questions = state.currentExam.sections.map(aa => aa.questions) 
            const merged = [].concat.apply([], questions)
            const question = merged.find(x => x.id === action.payload.questionId)
            question.userAnswer = action.payload.userAnswer
            state.currentQuestion = question
        },
        setTest: (state, action) => {
            state.activeTest = action.payload
        },
        nextQuestion: (state) => {
            const questions = state.currentExam.sections.map(aa => aa.questions) 
            const merged = [].concat.apply([], questions)
            const currentIndex = merged.findIndex(x => x.id === state.currentQuestion.id)
            state.currentQuestion = merged[currentIndex + 1]
        },
        prevQuestion: (state) => {
            const questions = state.currentExam.sections.map(aa => aa.questions) 
            const merged = [].concat.apply([], questions)
            const currentIndex = merged.findIndex(x => x.id === state.currentQuestion.id)
            state.currentQuestion = merged[currentIndex - 1]
        }
    },
    extraReducers: builder => {
        builder
          .addCase(getExams.fulfilled, (state, action) => {
             state.exams = action.payload.data.exams
             state.params = action.payload.params
             state.totalExams = action.payload.data.total
          })    
         .addCase(getExam.fulfilled, (state, action) => {
            state.currentExam = action.payload.exam
            state.questions = action.payload.questions
            state.activeTest = action.payload.exam.sections[0].title
         }) 
        
    }
})

export const {setIndex, setTest, prevQuestion, nextQuestion, setCurrentQuestion, setUserAnswer} = examSlice.actions
export default examSlice.reducer